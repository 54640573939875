import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ContactWithPicture, ContactsService } from 'src/app/shared/services/contacts.service';
import { AvatarSize } from 'src/app/shared/avatar/avatar.component';
import { PhoneNumber, User } from 'src/generated/graphql';
import { AddEditContactComponent } from '../add-edit-contact/add-edit-contact.component';
import { DeleteContactComponent } from '../delete-contact/delete-contact.component';
import { MatDialog } from '@angular/material/dialog';
import { SelectedContactService } from 'src/app/shared/services/selection/selected-contact.service';
import { SelectedMessagingConversationService } from 'src/app/shared/services/selection/selected-messaging-conversation.service';
import { ConversationNavigationService } from 'src/app/shared/services/conversation-navigation.service';
import { TrackingService } from 'src/app/services/tracking-service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';

@Component({
  selector: 'mc-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent implements OnInit, OnDestroy {

  public readonly AvatarSize = AvatarSize;
  public readonly ON_COMPONENT_DESTROY = new Subject<void>();

  public selectedContact: ContactWithPicture;
  public preferredNumber: string;

  public user: User;

  public constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private contactsService: ContactsService,
    private selectedContactService: SelectedContactService,
    private selectedMessagingConversationService: SelectedMessagingConversationService,
    private conversationNavigationService: ConversationNavigationService,
    private trackingService: TrackingService,
  ) {
  }

  public ngOnInit(): void {
    this.loadContactFromRouteParams();
  }

  public ngOnDestroy(): void {
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }

  public goToConversationScreen(): void {
    this.selectedMessagingConversationService.openConversationByContactId(this.selectedContact.id);
    this.trackingService.trackEvent(TrackingEventType.SEND_MESSAGE_TO_CONTACT);
  }

  public callContact(): void {
    const phoneNumber = this.selectedContact.preferredPhoneNumber ?? this.selectedContact.phoneNumbers[0].phoneNumber;
    this.conversationNavigationService.callNumber(phoneNumber);
    this.trackingService.trackEvent(TrackingEventType.CALL_CONTACT);
  } 

  public openEditContactModal(): void {
    this.dialog.open(AddEditContactComponent, {
      width: '600px',
      data: this.selectedContact
    })
    .afterClosed()
    .subscribe((editedContact) => {
      if (editedContact) {
        this.selectedContact = editedContact;
        this.preferredNumber = this.selectedContact.preferredPhoneNumber;
      }
    });

    this.trackingService.trackEvent(TrackingEventType.CONTACTS_EDIT_BUTTON_CLICKED);
  }

  public openDeleteContactModal(): void {
    this.dialog.open(DeleteContactComponent, {
      width: '550px',
      data: this.selectedContact
    });
    this.trackingService.trackEvent(TrackingEventType.DELETE_CONTACT_BUTTON_CLICKED);
  }

  private loadContactFromRouteParams(): void {
    this.route.paramMap
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe((params) => {
        const contactId = Number(params.get('contactId'));
        this.loadContact(contactId);
      })
  }

  private loadContact(contactId: number): void {
    this.contactsService.getCurrentContacts().subscribe((contacts) => {
      this.selectedContact = this.findContactById(contactId, contacts);

      if (!this.selectedContact) {
        this.navigateToContactsHomePage();
        return;
      }

      this.preferredNumber = this.selectedContact.preferredPhoneNumber;
      this.selectedContactService.setSelectedItemId(contactId);
    });
  }

  private findContactById(id: number, contacts: ContactWithPicture[]): ContactWithPicture {
    return contacts.find((c) => c.id === id);
  }

  private navigateToContactsHomePage(): void {
    this.router.navigate(['contacts']);
  }

}
