import { ContactsService } from "src/app/shared/services/contacts.service";
import { first, map, Observable, of, ReplaySubject, switchMap, take, tap, zip } from "rxjs";
import { Contact, Conversation, ConversationType, CreateConversationGQL, DeleteConversationGQL } from "src/generated/graphql";

export interface ConversationItem {
  conversationId?: number;
  conversation?: Conversation;
  contact?: Contact;
}

export abstract class ConversationsService<T extends ConversationItem> {
  protected CONVERSATIONS_TO_LOAD_INITIALLY = 10;
  protected CONVERSATIONS_TO_LOAD_PER_SCROLL = 10;

  private readonly CONVERSATION_ITEM_HEIGHT_IN_PX = 64;

  protected readonly conversations = new ReplaySubject<Conversation[]>(1);

  private hasLoadedConversations = false;
  private loadedConversationsCount = 0;

  public constructor(
    protected contactsService: ContactsService,
    protected deleteConversationGQL: DeleteConversationGQL,
    protected createConversationMutation: CreateConversationGQL,
  ) {
    const numberOfConversationThatCanFitInScreen = Math.ceil(window.innerHeight/this.CONVERSATION_ITEM_HEIGHT_IN_PX);

    if(this.CONVERSATIONS_TO_LOAD_INITIALLY < numberOfConversationThatCanFitInScreen) {
      this.CONVERSATIONS_TO_LOAD_INITIALLY = numberOfConversationThatCanFitInScreen;
    }

    this.subscribeToConversationsLoadCount();
  }

  public getConversations(): Observable<Conversation[]> {
    if (!this.hasLoadedConversations) {
      return this.loadInitialConversations().pipe(tap(() => this.hasLoadedConversations = true));
    }

    return this.conversations;
  }

  public getCurrentConversations(): Observable<Conversation[]> {
    return this.getConversations().pipe(take(1));
  }

  public getConversationById(id: number): Observable<Conversation> {
    return this.getCurrentConversations().pipe(
      map((conversations) => conversations.find((c) => c.id === id)),
      switchMap((conversation) => {
        if (conversation) {
          return of(conversation);
        }

        return this.loadConversationById(id);
      })
    );
  }

  public getConversationByContactId(contactId: number): Observable<Conversation> {
    return this.getCurrentConversations().pipe(
      map((conversations) => conversations.find((c) => c.contactId === contactId)),
      switchMap((conversation) => {
        if (conversation) {
          return of(conversation);
        }

        return this.loadConversationByContactId(contactId);
      })
    );
  }

  public getConversationByParticipantNumber(participantNumber: string): Observable<Conversation> {
    return this.getCurrentConversations().pipe(
      map(conversations => conversations.find(c => c.participantNumber === participantNumber)),
      switchMap((conversation) => {
        if (conversation) {
          return of(conversation);
        }

        return this.loadConversationByParticipantNumber(participantNumber);
      })
    );
  }

  public loadMoreConversations(): Observable<Conversation[]> {
    return zip([
      this.getCurrentConversations(),
      this.loadConversationsWithContacts(this.CONVERSATIONS_TO_LOAD_PER_SCROLL, this.loadedConversationsCount)
    ])
      .pipe(
        map(([currentConversations, newlyLoadedConversations]) => [...currentConversations, ...newlyLoadedConversations]),
        tap((allLoadedConversations) => this.conversations.next(allLoadedConversations))
      );
  }

  public deleteConversation(conversationId: number): Observable<void> {
    return this.deleteConversationGQL
      .mutate({
        value: {
          conversationId: conversationId
        }
      })
      .pipe(
        switchMap(()=> this.reloadConversations()),
        switchMap(()=> of(undefined))
      );
  }

  public createAndHandleConversation(phoneNumber: string, type: ConversationType): Observable<Conversation> {
    return this.createConversation(phoneNumber, type)
      .pipe(
        switchMap((conversation) => this.handleNewlyCreatedConversation(conversation))
      )
  }

  public createConversation(phoneNumber: string, type: ConversationType): Observable<Conversation> {
    return  this.createConversationMutation
    .mutate({
      value: {
        participantNumber: phoneNumber,
        type: type,
      }
    }).pipe(map(({data}) =>  data.createConversation));
  }

  public reloadConversations(): Observable<Conversation[]> {
    return this.loadInitialConversations().pipe(first());
  }

  protected registerConversationHandlers(): void {
    this.getNewConversationItemFeed().subscribe((item) => this.handleNewConversationItem(item));
    this.getConversationItemStatusUpdateFeed().subscribe((item) => this.handleConversationItemStatusUpdateFeed(item));
  }

  protected loadConversationsWithContacts(count: number, offset: number): Observable<Conversation[]> {
    return zip(this.getLoadConversationsQuery(count, offset), this.contactsService.getCurrentContacts())
      .pipe(map(([conversations, contacts]) => this.mapContactsToConversations(conversations, contacts)));
  }

  protected abstract getLoadConversationsQuery(count: number, offset: number): Observable<Conversation[]>;
  protected abstract getLoadConversationByIdQuery(conversationId: number): Observable<Conversation>;
  protected abstract getLoadConversationByContactIdQuery(contactId: number): Observable<Conversation>;
  protected abstract getLoadConversationByParticipantNumberQuery(phoneNumber: string): Observable<Conversation>;

  protected abstract getNewConversationItemFeed(): Observable<T>;
  protected abstract getConversationItemStatusUpdateFeed(): Observable<T>;

  protected abstract handleNewItemInNewConversation(item: T, conversation?: Conversation): void;
  protected abstract handleNewItemInExistingConversation(item: T, conversation: Conversation): void;
  protected abstract handleConversationItemStatusUpdateFeed(item: T): void;

  public handleNewlyCreatedConversation(conversation: Conversation): Observable<Conversation> {
    return this.getCurrentConversations().pipe(
      switchMap((currentConversations) => {
        this.moveConversationToTheTopOfTheList(conversation, currentConversations);
        return this.mapAndReturnContactInConversation(conversation);
      }));
  }

  public handleNewConversation(conversation: Conversation, currentConversations: Conversation[]): void {
    this.moveConversationToTheTopOfTheList(conversation, currentConversations);
    this.mapContactInConversation(conversation);
  }

  protected handleNewConversationItem(item: T): void {
    this.getCurrentConversations().subscribe((conversations) => {
      const conversation = conversations.find((conversation) => item.conversationId === conversation.id);
      if (conversation) {
        this.handleNewItemInExistingConversation(item, conversation);
        this.moveConversationToTheTopOfTheList(conversation, conversations);
      } else {
        this.loadAndMoveConversationToTheTopOfTheList(item, conversations);
      }
    });
  }

  private loadAndMoveConversationToTheTopOfTheList(item: T, currentConversations: Conversation[]): void {
    this.moveConversationToTheTopOfTheList(item.conversation, currentConversations);
    this.mapContactInConversation(item.conversation);
    this.handleNewItemInNewConversation(item);
  }

  private moveConversationToTheTopOfTheList(conversation: Conversation, currentConversations: Conversation[]): void {
    const conversationsWithTheNewOneOnTop = currentConversations.filter((c) => c.id !== conversation.id);
    conversationsWithTheNewOneOnTop.unshift(conversation);
    this.conversations.next(conversationsWithTheNewOneOnTop);
  }

  private loadConversationById(id: number): Observable<Conversation> {
    return this.getLoadConversationByIdQuery(id)
      .pipe(
        switchMap(conversation => {
          if (!conversation) {
            return of(null);
          }

          return this.getContacts().pipe(
            map(contacts => {
              conversation.contact = this.getContactForConversation(conversation, contacts);
              return conversation;
            })
          );
        }
        )
      );
  }

  private loadConversationByContactId(id: number): Observable<Conversation> {
    return this.getLoadConversationByContactIdQuery(id)
      .pipe(
        switchMap(conversation => {
          if (!conversation) {
            return of(null);
          }

          return this.getContacts().pipe(
            map(contacts => {
              conversation.contact = this.getContactForConversation(conversation, contacts);
              return conversation;
            })
          );
        }
        )
      );
  }

  private loadConversationByParticipantNumber(phoneNumber: string): Observable<Conversation> {
    return this.getLoadConversationByParticipantNumberQuery(phoneNumber)
      .pipe(
        switchMap(conversation => {
          if (!conversation) {
            return of(null);
          }

          return this.getContacts().pipe(
            map(contacts => {
              conversation.contact = this.getContactForConversation(conversation, contacts);
              return conversation;
            })
          );
        }
        )
      );
  }

  private loadInitialConversations(): Observable<Conversation[]> {
    return this.loadConversationsWithContacts(this.CONVERSATIONS_TO_LOAD_INITIALLY, 0)
      .pipe(
        tap(conversations => this.conversations.next(conversations)),
        switchMap(() => this.conversations));
  }

  private getContacts(): Observable<Contact[]> {
    return this.contactsService.getCurrentContacts();
  }

  private mapContactInConversation(conversation: Conversation): void {
    this.getContacts().subscribe((contacts) => this.mapContactForConversation(conversation, contacts))
  }

  private mapAndReturnContactInConversation(conversation: Conversation): Observable<Conversation> {
    return this.getContacts().pipe(
      map((contacts) => this.mapContactForConversation(conversation, contacts))
    );
  }

  private mapContactsToConversations(conversations: Conversation[], contacts: Contact[]): Conversation[] {
    return conversations.map(c => this.mapContactForConversation(c, contacts));
  }

  private mapContactForConversation(conversation: Conversation, contacts: Contact[]): Conversation {
    conversation.contact = this.getContactForConversation(conversation, contacts);
    return conversation;
  }

  private getContactForConversation(conversation: Conversation, contacts: Contact[]): Contact {
    return contacts.find(({ phoneNumbers }) => phoneNumbers.some(phoneNumber => phoneNumber.phoneNumber === conversation.participantNumber));
  }

  private subscribeToConversationsLoadCount(): void {
    this.conversations.subscribe((conversations) => this.loadedConversationsCount = conversations.length);
  }
}
