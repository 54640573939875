<!-- Call history -->
<div *ngIf="!invalidConversation && selectedConversation">
  <!-- Header with person's info -->
  <div class="bg-white flex flex-row justify-between pl-7 pr-4 py-4 absolute-header">
    <div *ngIf="selectedConversation" class="flex flex-row items-center">
      <button [class.no-active]="!selectedConversation.contact" (click)="goToContact()">
        <mc-avatar [size]="AvatarSize.SMALL" [avatarUrl]="selectedConversationContactPicture" class="header-img"></mc-avatar>
      </button>
      <div *ngIf="selectedConversation.contact; let contact" class="grid leading-3 ml-3">
        <button (click)="goToContact()" class="header-btn">
          <span class="text-lato secondary-color text-base leading-4">{{ contact.fullName }}</span>        
        </button>
        <span class="text-lato secondary-color-500 text-sm font-light">{{ selectedConversation.participantNumber | phoneNumber }}</span>
      </div>
  
      <div *ngIf="!selectedConversation.contact" class="grid ml-3">
        <span class="text-lato secondary-color text-base leading-4">{{ selectedConversation.participantNumber | phoneNumber }}</span>
      </div>
    </div>
    <div class="flex flex-row items-center">
      <button [disabled]="callInProgress" mat-icon-button class="p-3 hover:bg-gray-450 hover:rounded-full hover:p-3" [routerLink]="'/calls/' + selectedConversation.id + '/outgoing'">
        <img class="w-5 h-5" src="assets/images/icons/phone-call.svg" alt="phone call"/>
      </button>
      <button mat-icon-button class="ml-2 p-3 hover:bg-gray-450 hover:rounded-full hover:p-3" (click)="navigateToSmsConversationId()">
        <img class="w-5 h-5" src="assets/images/icons/sms.svg" alt="sms"/>
      </button>
      <button [disabled]="callInProgress" mat-icon-button [matMenuTriggerFor]="menu" class="p-3 ml-1 hover:bg-gray-450 hover:rounded-full hover:p-3">
        <img class="w-5 h-5" src="assets/images/icons/more.svg" alt="more"/>
      </button>
    </div>
  </div>
  
  <router-outlet></router-outlet>

  <mat-menu #menu="matMenu" class="custom-menu">
    <button *ngIf="!selectedConversation?.contact" mat-menu-item (click)="addNumber()">
      <span class="font-lato text-base font-normal secondary-color">Add number</span>
    </button>
    <button mat-menu-item (click)="deleteConversation()">
      <span class="font-lato text-base font-normal secondary-color">Delete</span>
    </button>
  </mat-menu>
</div>

