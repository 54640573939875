import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { BehaviorSubject, Subject, Subscription, takeUntil } from "rxjs";

export enum AccountSetupStep {
  WELCOME_PAGE,
  TWILIO_NUMBER_PICKER,
  PHONE_NUMBER,
  PHONE_NUMBER_CODE_VERIFICATION,
  SUMMARY,
}

@Component({ template: '' })
export abstract class AccountSetupStepComponent implements OnInit, OnDestroy {
  
  public readonly ON_DESTROY = new Subject<void>();

  @Input()
  public form: FormGroup;

  @Input()
  public control: FormControl;

  @Input()
  public currentStep: BehaviorSubject<AccountSetupStep>;

  @Input()
  public moveForward = new Subject<void>();

  @Input()
  public forwardButtonClick = new Subject<void>();

  @Input()
  public isCurrentStepValid: BehaviorSubject<boolean>;

  @Input()
  public canMoveBackward: Subject<boolean>;

  public hasAttemptedToProceedWithErrors = false;

  private hasPerformedInitialSelectionAction = false;
  private forwardButtonClickSubscription: Subscription;
  private isStepCurrentlySelected = false;

  public ngOnInit(): void {
    this.handleStepChanges();
    this.handleValueChanges();
  }

  public ngOnDestroy(): void {
    this.ON_DESTROY.next();
    this.ON_DESTROY.complete();
  }

  public handleStepChanges(): void {
    this.currentStep.pipe(
      takeUntil(this.ON_DESTROY)
    ).subscribe((value) => {
      if (this.step === value) {
        this.handleCurrentStepSelected();
      } else if (this.isStepCurrentlySelected) {
        this.handleCurrentStepDeselected();
      }
    });
  }

  public handleValueChanges(): void {
    if (!this.control) {
      return;
    }

    this.control.valueChanges
      .pipe(takeUntil(this.ON_DESTROY))
      .subscribe((value) => {
        this.handleNewValue(value);

        const isValid = this.control.valid;
        this.updateCurrentStepValidity(isValid);
      });
  }

  public handleNewValue(value: any): void {
    this.hasAttemptedToProceedWithErrors = false;
  }

  public updateCurrentStepValidity(isValid: boolean): void {
    this.isCurrentStepValid.next(isValid);
  }

  /**
   * Gets executed every time the step is activated.
   */
  public handleStepSelection(): void {
    this.forwardButtonClickSubscription = this.forwardButtonClick.subscribe(() => {
      if (this.isFormControlValid()) {
        this.handleForwardProgress();
      } else {
        this.handleForwardProgressWithErrors();
      }
    });
  };

  /**
   * Gets executed only at the first time that the step is selected by the user.
   */
  public handleInitialStepSelection(): void { }

  public handleForwardProgress(): void { }

  public handleForwardProgressWithErrors(): void {
    this.hasAttemptedToProceedWithErrors = true;
  }

  public isFormControlValid(): boolean {
    if (!this.control) {
      return true;
    }

    return this.control.valid;
  }

  public moveForwardManually(): void {
    this.moveForward.next();
  }

  public showBackwardButton(): void {
    this.canMoveBackward.next(true);
  }

  public hideBackwardButton(): void {
    this.canMoveBackward.next(false);
  }

  public get elementToFocus(): HTMLElement {
    return null;
  }

  public abstract get step(): AccountSetupStep;

  public isCurrentStepSelected(): boolean {
    return this.step === this.currentStep.value;
  }

  private handleCurrentStepSelected(): void {
    this.isStepCurrentlySelected = true;

    this.focusOnInputElementIfNecessary();

    if (!this.hasPerformedInitialSelectionAction) {
      this.handleInitialStepSelection();
      this.hasPerformedInitialSelectionAction = true;
    }

    this.handleStepSelection();
  }

  private handleCurrentStepDeselected(): void {
    this.forwardButtonClickSubscription.unsubscribe();
    this.isStepCurrentlySelected = false;
  }

  private focusOnInputElementIfNecessary(): void {
    if (this.elementToFocus) {
      setTimeout(() => this.elementToFocus.focus(), 300);
    }
  }
}
