import { Component, OnDestroy, OnInit } from '@angular/core';
import { AvatarSize } from 'src/app/shared/avatar/avatar.component';
import { MessagingService } from '../../services/messaging.service';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { ContactWithPicture, ContactsService } from '../../services/contacts.service';
import { UserService } from 'src/app/user/user.service';
import { Contact, Conversation, Sms, SmsStatus, User } from 'src/generated/graphql';
import { PhoneNumberFormattingService } from '../../services/phone-number-formatting.service';
import { Router } from '@angular/router';
import { SelectedMessagingConversationService } from '../../services/selection/selected-messaging-conversation.service';
import { SelectedContactService } from '../../services/selection/selected-contact.service';
import { TrackingService } from 'src/app/services/tracking-service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';

export interface MessageWithConversationInfo {
  id: number,
  conversationId: number,
  body: string,
  smsType: string,
  senderName: string,
  senderPictureUrl: string,
  createdAt: Date
}

@Component({
  selector: 'mc-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  public readonly AvatarSize = AvatarSize;

  private readonly INPUT_DEBOUNCE_TIME_IN_MS = 300;
  private readonly MAXIMUM_ENTRIES_TO_SHOW_PER_TYPE = 3;

  public queryValue: string = "";
  public messagesSearchResults: MessageWithConversationInfo[] = [];
  public contactsSearchResults: ContactWithPicture[] = [];
  public unfilteredContacts = new Map<number, ContactWithPicture>();
  public showInput: boolean = false;

  public hasMoreResults = false;

  private ON_DESTOY = new Subject<void>();
  private queryValueUpdated = new Subject<void>();
  private _areOptionsShown: boolean = false;
  private user: User;
  private searchingSmses: boolean;
  private searchingContacts: boolean;

  constructor(
    private router: Router,
    private trackingService: TrackingService,
    private messagingService: MessagingService,
    private userService: UserService,
    private phoneNumberFormattingService: PhoneNumberFormattingService,
    private contactService: ContactsService,
    private selectedMessagingConversationService: SelectedMessagingConversationService,
    private selectedContactService: SelectedContactService,
    ) {
  }

  public ngOnInit(): void {
    this.userService.getUser().subscribe(user => this.user = user)

    this.contactService
      .getContacts()
      .pipe(takeUntil(this.ON_DESTOY))
      .subscribe(usersContacts => {
        this.unfilteredContacts = new Map<number, ContactWithPicture>();
        usersContacts.forEach(contact => this.unfilteredContacts.set(contact.id, contact));
      });

    this.queryValueUpdated
      .pipe(
        debounceTime(this.INPUT_DEBOUNCE_TIME_IN_MS),
        takeUntil(this.ON_DESTOY)
      ).subscribe(() => {
        this.hasMoreResults = false;

        this.searchMessages();
        this.searchContacts();
      });
  }

  public ngOnDestroy(): void {
    this.ON_DESTOY.next();
    this.ON_DESTOY.complete();
  }

  public get areOptionsShown() {
    return this.queryValue.length && this._areOptionsShown;
  }

  public get searching() {
    return this.searchingSmses || this.searchingContacts;
  }

  public get hasResults() {
    return this.messagesSearchResults.length || this.contactsSearchResults.length;
  }

  public hideOptions(): void {
    this._areOptionsShown = false;
  }

  public showOptions(): void {
    this._areOptionsShown = true;
  }

  public expandSearchBar(): void {
    this.showInput = !this.showInput;
    this._areOptionsShown = false;
  }

  public hideSearchBar(): void {
    this.showInput = false;
    this.hideOptions();
    this.queryValue = '';
  }

  public onTextTyped(): void {
    this.searchingSmses = true;
    this.searchingContacts = true;
    this.queryValueUpdated.next();
  }

  public goToContactProfile(contactId: number): void {
    this.selectedContactService.openContactById(contactId);
    this.hideOptions();
  }

  public goToMessagesConversation(conversationId: number): void {
    this.selectedMessagingConversationService.openConversationById(conversationId);
    this.hideOptions();
  }

  public goToContactMessagingConversation(contactId: number): void {
    this.selectedMessagingConversationService.openConversationByContactId(contactId);
    this.hideOptions();
  }

  public goToResultPage(): void {
    this.router.navigate(['/search'], { queryParams: { query: this.queryValue } });
    this.trackingService.trackEvent(TrackingEventType.PROFILE_CLICKED);
    this.hideOptions();
  }

  private searchMessages(): void {
    if (!this.queryValue.length) {
      this.messagesSearchResults = [];
      this.searchingSmses = false;
      return;
    }

    this.searchingSmses = true;
    this.messagingService.searchAndCountSmses(this.queryValue, this.MAXIMUM_ENTRIES_TO_SHOW_PER_TYPE, 0)
      .subscribe({
        next: (results) => {
          this.searchingSmses = false;
          this.messagesSearchResults = results.messages.map(message => this.getSmsWithConversationInfo(message));
          this.trackingService.trackEvent(TrackingEventType.SEARCH_HELLO_GROVE);

          if (!this.hasMoreResults) {
            this.hasMoreResults = results.totalCount > this.MAXIMUM_ENTRIES_TO_SHOW_PER_TYPE;
          }
        },
        error: () => {
          this.searchingSmses = false;
          this.trackingService.trackEvent(TrackingEventType.SEARCH_HELLO_GROVE_FAILURE);
        }
    });
  }

  private searchContacts(): void {
    if (!this.queryValue.length) {
      this.contactsSearchResults = [];
      this.searchingContacts = false;
      return;
    }

    const matchingContacts = [...this.unfilteredContacts.values()].filter(contact => this.queryMatchedNumberOrNumber(contact));

    if (!this.hasMoreResults) {
      this.hasMoreResults = matchingContacts.length > this.MAXIMUM_ENTRIES_TO_SHOW_PER_TYPE;
    }

    this.contactsSearchResults = matchingContacts.slice(0, this.MAXIMUM_ENTRIES_TO_SHOW_PER_TYPE);
    this.searchingContacts = false;
  }

  private getSmsWithConversationInfo(sms: Sms): MessageWithConversationInfo {
    const isSentMessage = sms.smsType === SmsStatus.SENT;
    const contact = this.unfilteredContacts.get(sms.conversation.contactId);

    const senderName = this.getSenderInfo(isSentMessage, sms.conversation, contact);
    const senderPictureUrl = isSentMessage ? this.userService.buildUserAvatarUrl(this.user) : contact?.pictureUrl;

    return {
      id: sms.id,
      body: sms.body,
      conversationId: sms.conversationId,
      smsType: sms.smsType,
      senderName: senderName,
      senderPictureUrl: senderPictureUrl,
      createdAt: sms.createdAt
    }
  }

  private getSenderInfo(isSmsSent: boolean, conversation: Conversation, contact: Contact): string {
    if (isSmsSent) {
      return this.user.firstName + " " + this.user.lastName;
    }

    if (contact && contact.fullName) {
      return contact.fullName;
    }

    return this.phoneNumberFormattingService.format(conversation.participantNumber);
  }

  private queryMatchedNumberOrNumber(contact: ContactWithPicture) {
    const anyPhoneNumberMatch = contact.phoneNumbers.some(number => number.phoneNumber.includes(this.queryValue));

    return anyPhoneNumberMatch || contact.fullName.toLocaleLowerCase().includes(this.queryValue.toLocaleLowerCase());
  }
}
