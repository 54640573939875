import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { Observable } from "rxjs";

@Component({ template: '' })
export abstract class ConversationPage implements AfterViewInit {
  
  @ViewChild(MatSidenav)
  private conversationsContainer: MatSidenav;

  public loadingMoreConversations = false;

  public ngAfterViewInit(): void {
    this.subscribeToConversationsScrollingEvent();
  }

  public abstract loadMoreConversations(): Observable<any>;

  private subscribeToConversationsScrollingEvent(): void {
    const conversationsElement = this.conversationsContainer._content.nativeElement;
    conversationsElement.onscroll = () => this.onConversationsListScroll(conversationsElement);
  }

  private onConversationsListScroll(conversationsElement: HTMLElement): void {
    const hasScrolledAtTheBottom = conversationsElement.scrollHeight - conversationsElement.scrollTop === conversationsElement.clientHeight;
    if (hasScrolledAtTheBottom) {
      this.loadMoreConversationsIfPossible();
    }
  }

  private loadMoreConversationsIfPossible(): void {
    if (this.loadingMoreConversations) {
      return;
    }

    this.loadingMoreConversations = true;
    this.loadMoreConversations()
      .subscribe({
        next: () => this.loadingMoreConversations = false,
        error: () => this.loadingMoreConversations = false
      });
  }

}
