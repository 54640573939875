// LOCAL DEVELOPMENT
let environment = {
  auth0: {
    domain: 'login.connectourkids.org',
    clientID: 'rtqADlB2TMtzoGG70FfWrqFBwBTyeXdG', // noboost
    audience: 'https://api-dev.connectourkids.org/',
  },
  intercom: {
    appId: 'rz1hwc7q',
    enable: false,
  },
};

// STAGING
if (window.location.hostname === 'hello-staging.connectourkids.org') {
  environment = {
    auth0: {
      domain: 'login.connectourkids.org',
      clientID: 'rfPrbxjFBHFZYD80ndN1MoZarIf3Mza7', // noboost
      audience: 'https://api-staging.connectourkids.org/',
    },
    intercom: {
      appId: 'rz1hwc7q',
      enable: true,
    },
  };

  // PRODUCTION
} else if (window.location.hostname === 'hello.connectourkids.org') {
  environment = {
    auth0: {
      domain: 'login.connectourkids.org',
      clientID: 'uyqcjxzNlUtELYwm89z42A2yQ44CwPiC', // noboost
      audience: 'https://api.connectourkids.org/',
    },
    intercom: {
      appId: 'rz1hwc7q',
      enable: true,
    },
  };
}

export { environment };
