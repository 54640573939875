import { Injectable } from '@angular/core';
export interface Template {
  id?: number;
  title?: string;
  content?: string;
}

@Injectable({ providedIn: 'root' })
export class TemplateService {

  public messageTemplates: Template[] = [];
  public phoneCallTemplates: Template[] = [];
  public voicemailTemplates: Template[] = [];
  public emailTemplates: Template[] = []; 

  constructor() {
    this.addStaticMessageTemplates();
    this.addStaticPhoneCallTemplates();
    this.addStaticVoicemailTemplates();
    this.addStaticEmailTemplates();
  }

  public getMessageTemplates(): Template[] {
    return this.messageTemplates;
  }

  public getPhoneCallTemplates(): Template[] {
    return this.phoneCallTemplates;
  }

  public getVoicemailTemplates(): Template[] {
    return this.voicemailTemplates;
  }

  public getEmailTemplates(): Template[] {
    return this.emailTemplates;
  }

  public addStaticMessageTemplates(): void {
    this.messageTemplates = [
      {
        id: 1, 
        title: 'First Text',
        content: `"Hi, (name). My name is (social workers name) and I work as a (job title) at (social services municipality). I am reaching out to you regarding a private and urgent matter. You can give me a call at (phone number) or email me at (email) at your earliest convenience. I'm looking forward to hearing from you. Thank you!"`,
      },
      {
        id: 2,
        title: 'Second Text',
        content: `"Hi, (name). My name is (social worker's name) and I work as a (job title) at (social services municipality). I am following up from my previous text on (date) regarding a private and urgent matter. You can give me a call at (phone number) or email me at (email) at your earliest convenience. I'm looking forward to hearing from you. Thank you!"`,
      },
    ]
  }

  public addStaticPhoneCallTemplates(): void {
    this.phoneCallTemplates = [
      {
        id: 1, 
        title: 'First Phone Call', 
        content: `<i>*This script is to serve as an example only. Feel free to add more or less information on the child and biological parents depending on your custodial agency's guidelines. All information provided and connections contacted must be approved by the custodial agency.</i>
          "Hello, (address by first name), this is(social worker name) with (social services municipality)."
          <br>
          [Allow a brief pause for them to ask any questions or confirm their identity.] "I'm calling on behalf of(child's first name) who is a child of(mother's first name) and(father's first name). "
          "We have identified you as a potential relative or connection, and wanted to inform you that(child's first name) is in the custody of(social services municipality).”
          [Allow a brief pause for them to ask any questions]
          "I am calling in an effort to increase(child's first name) supports and connections as well as find permanency options.Do you have a moment to help me out ?”
          <br>
          [Tip: If they say they are not able to be a permanent option you can talk about other options like helping you expand the child's genogram, learn more about the family's history, pictures for the child's lifebook, and / or talk about reconnecting if it is appropriate.]`,
      },
      {
        id: 2, 
        title: 'Resistant Caller', 
        content: `<i>*This script is to serve as an example only. Feel free to add more or less information on the child and biological parents depending on your custodial agency's guidelines. All information provided and connections contacted must be approved by the custodial agency.</i>
          <br>
          It sounds like you might have had a bad experience, can you share with me what happened or why you are hesitant? [Listen to what their concerns are and validate]. 
          <br>
          There are other ways that you can help (child's name) without getting involved like (e.g., sharing family information like family history and contact information for potential supporters, helping me expand the genogram, family pictures for the youth's lifebook, etc.) Which of these options would you feel most comfortable with?`,
      },
      {
        id: 3,
        title: 'Distraught Caller',
        content: `<i>*This script is to serve as an example only. Feel free to add more or less information on the child and biological parents depending on your custodial agency's guidelines. All information provided and connections contacted must be approved by the custodial agency.</i>
          <br>  
          Yes, it is incredibly sad that (child's name) is/still in the foster care system. There are over 20,000 youth that age out of foster care every year and this is why it is so important that we work together to build (child's name) support system and find permanency options. I'd love to chat with you about the best way you can help today! (Remember that being a permanent option is not the only way to support, they can help by sharing family information like family history and contact information for potential supporters, helping me expand the genogram, family pictures for the youth's lifebook, etc.)`
      },
    ]
  }

  public addStaticVoicemailTemplates(): void {
    this.voicemailTemplates = [
      {
        id: 1,
        title: 'First Voicemail',
        content: `"Hi, (name)”
          <br>
          "This is (social worker name) with (social services municipality) regarding a private and urgent matter. Please return my call or 
          email me at (phone number and email) at your earliest convenience.” 
          <br>
          I often would recommend offering a number that the potential relative can text as well.]`
      },
      {
        id: 2,
        title: 'Second Voicemail',
        content: `"Hi, (name)” 
          <br>
          "This is (social worker name) with (social services municipality) calling again about a private and urgent matter. I've reached out 
          via email, letter, and call but haven't heard from you. Please give me a call at your earliest convenience.” 
          <br>
          [Consider offering a number that the potential relative can text as well.]`
      },
    ]
  }

  public addStaticEmailTemplates(): void {
    this.emailTemplates = [
      {
        id: 1,
        title: 'First Email',
        content: `<i>*This is to serve as an example only. Feel free to add more or less information on the child and biological parents depending on your custodial agency's guidelines. All information provided and connections contacted must be approved by the custodial agency.</i>
          <br>
          Dear (name),
          <br>
          I am reaching out on behalf of (social services municipality). We have identified you as a potential relative or connection of (child's first name), child of (mother's first name) and (father's first name), who is (child' age). (Child's first name) is now in the custody of (social services municipality). Children are removed from their homes, typically, due to either abuse or neglect. Sadly, this is the case for (child's first name).
          <br>
          [[if sent letter]] I've sent a letter to (address here) but haven't received any response.
          It is my role in the agency to reach out to the relatives and connections of our children in foster care. We recognize that relatives and connections often play an essential role in the lives of these children and can make an enormous impact on their lives. Children are most likely to thrive when connected or placed in homes with relatives and connections because, often, relationships have already been forged and can exemplify stability. Children in foster care who are able to stay connected to their extended families and cultures are subject to less trauma than those who are not connected to their families. I am reaching out to see if you're interested in exploring ways to impact (child's first name).
          <br>
          There are many ways that relatives can impact the lives of children in foster care, some serve as a permanent or temporary home placement, some offer to help connect (social services municipality) with other potential relatives and connections, and some simply keep in touch with the child. Some examples of ways to keep in touch with the child are, visiting with them, telephone contact, writing letters, attending school events, or providing family history. I'd be happy to explore which option may be the best fit for you and your family. If you are interested in welcoming this child into your home, please note, that you may be eligible for financial assistance for health insurance, housing, food, and clothing.
          <br>
          I'd be happy to help answer any questions you may have. Please let me know if you're able to help at your earliest convenience.
          <br>
          Sincerely,
          <br>
          (Social Worker Name)
          <br>
          (additional contact information)`
      },
      {
        id: 2,
        title: 'Second Email',
        content: `<i>*This is to serve as an example only. Feel free to add more or less information on the child and biological parents depending on your custodial agency's guidelines. All information provided and connections contacted must be approved by the custodial agency.</i>
          <br>
          Dear (name),
          <br>
          As you may recall, I've recently reached out on behalf of the (social services municipality) in regard to (child's first name). As I noted before, we have identified you as a relative or connection of (child's first name) and are still looking for a safe and reliable home for (him/her/they/them). I wanted to touch base with you to see if you're still considering some ways that you can help (child's first name). As a relative or connection, you were one of the first people we notified regarding this sensitive and urgent situation. As you can imagine, living with strangers in a foster home can be very challenging.
          <br>
          On average, children in foster care are placed in more than six foster homes over the course of their time in care. This instability can be detrimental to the attachment process of the child and is easily avoided by maintaining relationships with stable, loving family members and connections. Additionally, we're hoping to build a small army of champions for each child. We're confident that you can, at the minimum, be a great mentor to (child's first name)!
          (Child's first name) enjoys (insert activity child enjoys), (insert food child enjoys), and (insert challenge child would appreciate support in). There are many ways that you can help make an impact! [[example: Sally enjoys reading at the park, her favorite food is chicken nuggets, and she would love some help with her math homework.]]
          <br>
          Please let me know if you're able to help with (child's first name). I'd be glad to answer any questions you might have.
          <br>
          Sincerely,
          <br>
          (Social Worker Name)
          <br>
          (additional contact information)`
      },
      {
        id: 3,
        title: 'Third Email',
        content: `<i>*This is to serve as an example only. Feel free to add more or less information on the child and biological parents depending on your custodial agency's guidelines. All information provided and connections contacted must be approved by the custodial agency.</i>
          <br>
          Dear, (name)
          <br>
          I'm reaching out on behalf of (social services municipality) in regard to (child's first name). As you know, I've reached out a few times via letter, email, and phone. We have identified you as a relative or connection of (child's first name). As I've noted before, (child's first name) is still in the custody of (social services municipality) and I am working hard to identify the best possible placement for long-term foster care.
          <br>
          The foster care system is overwhelmed by the number of children needing safe and loving homes. Right now, there are more than 400,000 kids in the foster care system, with 20,000 aging out each year, many without any connections or family to go home to. This leads to a staggering statistic that two out of three foster teens that age out of care will be homeless, incarcerated, or deceased by the age of 26 years old.
          <br>
          Studies show that having safe and loving connections with extended family members and connections before foster youth age out of the system makes them more likely to become productive, healthy members of our society. I'm certain you'd agree that family connections are essential to living a stable, happy life, and that every child deserves this opportunity.
          I hope you will consider helping (child's first name) during this difficult time. Please get in contact with us so you don't lose the opportunity to connect with (child's first name) now or in the future.
          <br>
          Sincerely,
          <br>
          (Social worker)
          <br>
          (additional contact info)`
      },
    ]
  }
}
