import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AvatarSize } from 'src/app/shared/avatar/avatar.component';
import {
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { ContactsService, ContactWithPicture } from '../../../shared/services/contacts.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Contact, PhoneNumber } from '../../../../generated/graphql';
import { Observable, Subject, map, zip } from 'rxjs';
import { ContactsPhoneNumbersManager } from 'src/app/shared/contacts/contacts-phone-numbers-manager';
import { PhoneNumberFormattingService } from 'src/app/shared/services/phone-number-formatting.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { FileValidationService } from 'src/app/services/file-validation-service';
import { CallService } from 'src/app/shared/services/call.service';
import { TrackingService } from 'src/app/services/tracking-service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';

@Component({
  selector: 'add-edit-contact',
  templateUrl: './add-edit-contact.component.html',
  styleUrls: ['./add-edit-contact.component.scss'],
})
export class AddEditContactComponent extends ContactsPhoneNumbersManager implements OnInit, OnDestroy {

  public readonly AvatarSize = AvatarSize;

  private readonly ON_COMPONENT_DESTROY = new Subject<void>();

  public contactId: number;

  public candidatePictureData: string;

  public imageFormGroup = new FormGroup({});

  private saving: boolean;

  constructor(
    private dialogRef: MatDialogRef<AddEditContactComponent>,
    private messagingService: MessagingService,
    private callService: CallService,
    private fileValidationService: FileValidationService,
    protected contactsService: ContactsService,
    protected phoneFormattingService: PhoneNumberFormattingService,
    @Inject(MAT_DIALOG_DATA) public data: ContactWithPicture,
    private trackingService: TrackingService,
  ) {
    super(new FormBuilder(), phoneFormattingService, contactsService);

    if (data) {
      this.contactId = data.id;
      this.contactForm.controls.fullName.setValue(data.fullName);
      this.contactForm.controls.fullName.setValidators(this.contactNameValidator(this.contactId));

      if (data.pictureUrl) {
        this.pictureUrl = data.pictureUrl;
      }

      if (data.phoneNumbers) {
        this.phoneNumbersForm = new FormGroup({
          phoneNumbers: new FormArray([]),
        });

        data.phoneNumbers.forEach((phoneNumber: PhoneNumber) => {
          this.addPhoneNumber(phoneNumber);
        });
      }
    }

    dialogRef.backdropClick().subscribe(() => {
      this.trackingService.trackEvent(TrackingEventType.CONTACTS_ADD_NEW_CANCEL);
    });
  }

  public ngOnInit(): void {
    this.contactsService.getCurrentContacts()
      .subscribe(contacts => this.contacts = contacts)
  }

  public ngOnDestroy(): void {
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }

  public get formIsInvalid(): boolean {
    return this.imageFormGroup.hasError('invalidType')
      || this.contactForm.invalid
      || this.phoneNumbersForm.invalid
      || this.phoneNumbersForm.controls.phoneNumbers.controls.length === 0
      || this.saving;
  }

  public onImageSelected(event: Event): void {
    const reader = new FileReader();
    const file = (event.target as HTMLInputElement).files[0];

    if (!this.fileValidationService.isSupported(file)) {
      this.imageFormGroup.setErrors({ invalidType: true })
      return;
    }

    this.imageFormGroup.setErrors({});
    reader.readAsDataURL(file);
    reader.onload = () => this.candidatePictureData = reader.result.toString();
  }

  public validateAndSubmit(): void {
    this.contactForm.markAsTouched();
    this.phoneNumbersForm.markAsTouched();

    if (this.contactForm.invalid || this.phoneNumbersForm.invalid) {
      return;
    }

    if (!this.saving) {
      this.submit();
    }
  }

  protected submit(): void {
    this.saving = true;
    
    const hasPrefferedNumber = this.phoneNumbersForm.controls.phoneNumbers.value.some(phoneNumber => phoneNumber.isPreferred);

    if(!hasPrefferedNumber) {
      this.phoneNumbersForm.controls.phoneNumbers.controls[0].value.isPreferred = true;
    }
    
    if (this.contactId) {
      const phoneNumbers = this.phoneNumbersForm.controls.phoneNumbers.value.map((value: any) => {
        return {
          id: value.phoneNumberId,
          label: value.label,
          phoneNumber: this.phoneFormattingService.formatE164(value.phoneNumber),
          isPreferred: value.isPreferred
        };
      });

      this.contactsService.editContact({
        contactId: this.contactId,
        fullName: this.contactForm.controls.fullName.value,
        pictureBase64: this.candidatePictureData ?? undefined,
        phoneNumbers
      }).subscribe({
        next: (editedContact) => {
          this.reloadConversations().subscribe(() => {
            this.saving = false;
            this.close(editedContact);
            this.trackingService.trackEvent(TrackingEventType.CONTACTS_EDIT_NEW_SUCCESS);
          })
        },
        error: () => {
          this.saving = false;
          this.trackingService.trackEvent(TrackingEventType.CONTACTS_EDIT_NEW_FAILURE);
        }
      });
    } else {      
      const phoneNumbers = this.phoneNumbersForm.controls.phoneNumbers.value.map((value: any) => {
        return { 
          label: value.label, 
          phoneNumber: this.phoneFormattingService.formatE164(value.phoneNumber), 
          isPreferred: value.isPreferred };
      });

      this.contactsService.addContact({
        fullName: this.contactForm.controls.fullName.value,
        pictureBase64: this.candidatePictureData ?? undefined,
        phoneNumbers
      }).subscribe({
        next: (contact) => {
          this.reloadConversations().subscribe(() => {
            this.saving = false;
            this.close(contact);
            this.trackingService.trackEvent(TrackingEventType.CONTACTS_ADD_NEW_SUCCESS);
          })
        },
        error: () => {
          this.saving = false;
          this.trackingService.trackEvent(TrackingEventType.CONTACTS_ADD_NEW_FAILURE);
        }
      });
    }
  }

  public close(contact?: Contact): void {
    this.dialogRef.close(contact);
  }

  public cancel(): void {
    this.dialogRef.close();
    this.trackingService.trackEvent(TrackingEventType.CONTACTS_ADD_NEW_CANCEL);
  }

  private reloadConversations(): Observable<void> {
    return zip(
      this.messagingService.reloadConversations(),
      this.callService.reloadConversations()
    ).pipe(map(()=>  undefined));
  }
}
