import { NgModule } from "@angular/core";
import { VoiceRecordingService } from "src/app/services/voice-recording.service";
import { PipesModule } from "../../shared/pipes/index";
import { GreetingsModalModule } from "./modals/greetings-modal.module";

@NgModule({
    providers: [VoiceRecordingService],
    imports: [GreetingsModalModule, PipesModule]
})
export class VoicemailsModule {}
