<mat-sidenav-content class="bg-white w-full h-full" id="templatesPg">
  <mat-sidenav-container autosize class="h-full bg-white">
    <mat-sidenav opened mode="side" class="right-gray-border w-[350px]">
      <!-- No templates section -->
      <!-- <div class="absolute top-[60px] bottom-0 left-0 right-0 flex items-center justify-center px-8" *ngIf="!showTemplateInfo">
        <p class="font-lato text-base secondary-color pb-[60px]">No Templates</p>
      </div> -->

      <!-- One and many users section -->
      <div class="w-full max-w-md bg-white flow-root">
        <p class="py-3 px-7 secondary-color text-base md:text-lg">Templates</p>
        <!-- Skeleton -->
        <!-- <mc-templates-skeleton></mc-templates-skeleton> -->

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="border-b">
          <mat-expansion-panel-header (click)="trackTemplateCategories(ElementToTrack.PHONE_CALL_TEMPLATES)" class="py-4 px-7">
            <mat-panel-title class="font-lato text-sm md:text-base font-normal secondary-color truncate">
              Phone Call Templates
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list #shoes [multiple]="false">
            <mat-list-option class="font-lato text-sm font-normal secondary-color-500" *ngFor="let phoneTemplate of phoneCallTemplates" [value]="phoneTemplate" (click)="selectTemplate(phoneTemplate, ElementToTrack.PHONE_CALL_TEMPLATES)">
              {{phoneTemplate.title}}
            </mat-list-option>
          </mat-selection-list>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="border-b">
          <mat-expansion-panel-header (click)="trackTemplateCategories(ElementToTrack.MESSAGE_TEMPLATES)" class="py-4 px-7">
            <mat-panel-title class="font-lato text-sm md:text-base font-normal secondary-color truncate">
              Text Templates
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list #shoes [multiple]="false">
            <mat-list-option class="font-lato text-sm font-normal secondary-color-500" *ngFor="let messageTemplate of messageTemplates" [value]="messageTemplate" (click)="selectTemplate(messageTemplate, ElementToTrack.MESSAGE_TEMPLATES)">
              {{messageTemplate.title}}
            </mat-list-option>
          </mat-selection-list>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="border-b">
          <mat-expansion-panel-header (click)="trackTemplateCategories(ElementToTrack.VOICEMAIL_TEMPLATES)" class="py-4 px-7">
            <mat-panel-title class="font-lato text-base font-normal secondary-color truncate">
              Voicemail Templates
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list #shoes [multiple]="false">
            <mat-list-option class="font-lato text-sm font-normal secondary-color-500" *ngFor="let voicemailTemplate of voicemailTemplates" [value]="voicemailTemplate" (click)="selectTemplate(voicemailTemplate, ElementToTrack.VOICEMAIL_TEMPLATES)">
              {{voicemailTemplate.title}}
            </mat-list-option>
          </mat-selection-list>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="border-b">
          <mat-expansion-panel-header (click)="trackTemplateCategories(ElementToTrack.VOICEMAIL_TEMPLATES)" class="py-4 px-7">
            <mat-panel-title class="font-lato text-base font-normal secondary-color truncate">
              Email templates
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list #shoes [multiple]="false">
            <mat-list-option class="font-lato text-sm font-normal secondary-color-500" *ngFor="let emailTemplate of emailTemplates" [value]="emailTemplate" (click)="selectTemplate(emailTemplate, ElementToTrack.EMAIL_TEMPLATES)">
              {{emailTemplate.title}}
            </mat-list-option>
          </mat-selection-list>
        </mat-expansion-panel>
        
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="relative">
      <div class="flex flex-col justify-start min-h-full bg-white">
        <!-- welcoming text when there is no info -->
        <div class="absolute inset-0 flex items-center justify-center px-8" *ngIf="!showTemplateInfo">
          <p class="font-lato text-2xl secondary-color">Choose a template!</p>
        </div>

        <div *ngIf="showTemplateInfo" class="flex flex-col w-10/12 mx-auto px-7 py-16">
          <div class="text-lato secondary-color text-base" [innerHTML]="selectedTemplate.content"></div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-sidenav-content>
