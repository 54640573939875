<mat-sidenav-content class="bg-white w-full h-full" id="voicemailPage">
  <mat-sidenav-container autosize class="h-full">
    <mat-sidenav class="right-gray-border w-[250px] md:w-[350px]" mode="side" opened>
      <div class="bottom-gray-border px-7 py-4 flex flex-row items-center justify-center bg-white top-0 sticky">
        <button (click)="showGreetingList()" class="main-button cursor-pointer shadow w-full hover:bg-blue-250" mat-stroked-button>
          <img class="mr-2" src="assets/images/icons/greeting.svg" class="mr-2 w-[22px]"/>
          <span class="font-lato text-base font-normal">Record a greeting</span>
        </button>
      </div>

      <!-- No messages section -->
      <div *ngIf="loadedInitialVoicemails && voicemails.length === 0"
           class="absolute top-[60px] bottom-0 left-0 right-0 flex items-center justify-center px-7">
        <p class="font-lato text-base secondary-color pb-[60px]">No voicemails</p>
      </div>

      <!-- Voicemails list -->
      <div class="w-full max-w-md bg-white flow-root">
        <ng-container *ngIf="loadedInitialVoicemails else loadingTemplate">
          <ng-container *ngIf="voicemails.length > 0">
            <p class="py-3 px-7 secondary-color text-base md:text-lg">Voicemails</p>
              <ul role="list">
                <li *ngFor="let voicemail of voicemails"
                    (click)="openVoicemailDetails(voicemail.id)"
                    class="py-4 px-7 hover:bg-gray-150 cursor-pointer border-b border-gray-100"
                    [ngClass]="{'bg-gray-350': selectedVoicemailId === voicemail.id}"
                    >
                  <mc-voicemail-list-item [voicemail]="voicemail"></mc-voicemail-list-item>
                </li>
              </ul>
          </ng-container>
        </ng-container>

        <ng-template #loadingTemplate>
          <p class="py-3 px-7 secondary-color text-base md:text-lg">Voicemails</p>
          <mc-skeleton *ngIf="!loadedInitialVoicemails"></mc-skeleton>
        </ng-template>
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="relative bg-white">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-sidenav-content>
