<div class="border-b py-3 px-5">
  <h1 class="text-2xl font-lato font-normal tracking-wide secondary-color">Text message - <span class="dark-blue-text">Templates</span></h1>
</div>
<div class="p-6">
  <div class="grid grid-cols-3 gap-10">
    <div class="px-1">
      <span class="text-base font-lato secondary-color tracking-wide">Choose one template</span>
      <!-- <mc-message-templates-skeleton></mc-message-templates-skeleton> -->
      <div class="mt-4 h-[243px] overflow-auto">
        <ul role="list template-list">
          <li *ngFor="let messageTemplate of messageTemplates;"
            (click)="selectTemplate(messageTemplate)"
            class="py-3 px-5 hover:bg-gray-150 mb-3 cursor-pointer border rounded"
            [ngClass]="{'bg-gray-250': selectedMessageTemplate?.id === messageTemplate.id}">
            <span class="text-[15px] font-lato gray tracking-wide text-gray-900 font-normal"> {{ messageTemplate.title }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-span-2 px-1">
      <span class="text-base font-lato secondary-color tracking-wide">Preview</span>
      <div class="border box-shadow rounded p-5 mt-4 h-[243px] overflow-auto">
        <p class="text-sm font-lato text-gray-700 tracking-wide font-normal">
          {{ selectedMessageTemplate.content }}
        </p>
      </div>
    </div>
  </div>
</div>
<div class="border-t flex flex-row justify-between py-3 px-5">
  <button mat-dialog-close
          class="bg-gray-150 border rounded focus:ring-violet-300 hover:bg-gray-250 focus:outline-none py-1 px-5" (click)="trackCloseButton()">
    <span class="tracking-wide font-lato secondary-color text-sm leading-loose">Cancel</span>
  </button>
  <button mat-dialog-close cdkFocusInitial
          (click)="closeDialogWithTemplate()"
          class="bg-primary-color border rounded focus:bg-primary-color hover:bg-primary-light-color focus:outline-none py-1 px-5">
    <span class="text-white tracking-wide font-lato font-light text-sm leading-loose">Insert text</span>
  </button>
</div>
