
  <div *ngIf="startingNewConversation && !selectedContact" class="flex flex-row justify-between px-4 py-4 absolute-header">
    <search-contacts 
      [type]="ConversationType.SMS"
      [defaultValue]="selectedPhoneNumber"
      (onNumberOrContactSelected)="selectContactOrPhoneNumber($event)"
      (onEnter)="handleOnEnter()"
      class="w-full">
    </search-contacts>
  
    <ng-container *ngIf="usedInvalidPhoneNumber">
      Invalid phone number used
    </ng-container>
  </div>
  
  <div *ngIf="startingNewConversation && selectedContact" class="flex flex-row justify-between pl-7 pr-4 py-4 absolute-header">
    <div class="flex flex-row items-center">
      <button (click)="goToSelectedContact()">
        <mc-avatar [size]="AvatarSize.SMALL" [avatarUrl]="getContactImageUrl(selectedContact)" class="header-img"></mc-avatar>
      </button>
      
      <div class="grid leading-3 ml-3">
       <button (click)="goToSelectedContact()" class="header-btn">
        <span class="text-lato secondary-color text-base leading-4">{{ selectedContact.fullName }}</span>
       </button>
        <span class="text-lato secondary-color-500 text-sm font-light">{{ selectedContact.preferredPhoneNumber }}</span>
      </div>
    </div>
    <div class="flex flex-row items-center">
      <button (click)="callNumber()" mat-icon-button class="p-3 hover:bg-gray-450 hover:rounded-full hover:p-3">
        <img class="w-5 h-5" src="assets/images/icons/phone-call.svg" alt="phone call" />
      </button>
      <button mat-icon-button [matMenuTriggerFor]="menu" class="p-3 ml-1 hover:bg-gray-450 hover:rounded-full hover:p-3">
        <img class="w-5 h-5" src="assets/images/icons/more.svg" alt="more" />
      </button>
    </div>
  </div>
  
  <div *ngIf="!startingNewConversation" class="flex flex-row justify-between pl-7 pr-4 py-4 absolute-header">
    <div *ngIf="selectedConversation" class="flex flex-row items-center">
      <button [class.no-active]="!selectedConversation.contact" (click)="goToContact()">
        <mc-avatar [size]="AvatarSize.SMALL" [avatarUrl]="getContactImageUrl(selectedConversation.contact)" class="header-img"></mc-avatar>
      </button>
      <div *ngIf="selectedConversation.contact; let contact" class="grid leading-3 ml-3">
        <button [disabled]="!selectedConversation.contact" (click)="goToContact()" class="header-btn">
          <span class="text-lato secondary-color text-base leading-4">{{ contact.fullName }}</span>        
        </button>
        <span class="text-lato secondary-color-500 text-sm font-light">{{ selectedConversation.participantNumber | phoneNumber }}</span>
      </div>
  
      <div *ngIf="!selectedConversation.contact" class="grid ml-3">
        <span class="text-lato secondary-color text-base leading-4">{{ selectedConversation.participantNumber | phoneNumber }}</span>
      </div>
    </div>
    <div class="flex flex-row items-center">
      <button (click)="callNumber()" mat-icon-button class="p-3 hover:bg-gray-450 hover:rounded-full hover:p-3">
        <img class="w-5 h-5" src="assets/images/icons/phone-call.svg" alt="phone call" />
      </button>
      <button mat-icon-button [matMenuTriggerFor]="menu" class="p-3 ml-1 hover:bg-gray-450 hover:rounded-full hover:p-3">
        <img class="w-5 h-5" src="assets/images/icons/more.svg" alt="more" />
      </button>
    </div>
  </div>
  
  <div class="message-wrapper" #messagesContainer>
    <div class="flex justify-end flex-col message-container">
      <mc-messages-skeleton *ngIf="loadingMessages"></mc-messages-skeleton>
      <ng-container *ngFor="let message of messages; trackBy: messageTrackBy">
        <mc-messages-sms-item
          [sms]="message"
          [contactImageUrl]="getContactImageUrl(selectedConversation.contact)"
          [userImageUrl]="userAvatarUrl | async"
          [contactId]="getContactId()"></mc-messages-sms-item>
      </ng-container>
    </div>
  </div>
    
<!-- Type a message input at the bottom with buttons for (files, emoji, images and templaes) and 'send a message' button -->
<div class="flex flex-row justify-between items-center w-custom border-t fixed bottom-0">
  <div class="flex flex-row min-h-[50px]">
    <button (click)="toggleEmojiPicker()" mat-button matSuffix mat-stroked-button aria-label="search"
      class="cursor-pointer bg-white border-r hover:bg-gray-250 py-2 px-3">
      <img src="assets/images/icons/happy.svg" class="w-6 h-6" alt="happy" />
    </button>
    <!-- <button (click)="toggleEmojiPicker()" mat-button matSuffix mat-stroked-button aria-label="search"
      class="cursor-pointer bg-white border-r hover:bg-gray-250 py-2 px-3">
      <img src="assets/images/icons/image.svg" class="w-6 h-6" alt="image" />
    </button> -->
    <button (click)="openTemplatePicker()" mat-button matSuffix mat-stroked-button aria-label="search"
      class="border-r cursor-pointer bg-white hover:bg-gray-250 py-2 px-3">
      <img src="assets/images/sidebar-icons/template.svg" class="w-6 h-6" alt="templates" />
    </button>
  </div>
  <form class="w-full p-0 m-0">
    <mat-form-field class="w-full p-0 m-0">
      <textarea matInput
       #messageInput
       (keyup.control.enter)="sendMessage()" 
       (keyup.meta.enter)="sendMessage()" 
       [(ngModel)]="message"
       [ngModelOptions]="{ standalone: true }"
       placeholder="Type a message"
        class="custom-textarea p-0 m-0 secondary-color placeholder:text-gray-300 placeholder:font-lato placeholder:font-light"></textarea>
      <button mat-button matSuffix mat-stroked-button aria-label="send" class="mr-5 p-0 m-0 cursor-pointer"
        (click)="sendMessage()">
        <mat-icon class="primary-color">send</mat-icon>
      </button>
    </mat-form-field>
  </form>
</div>

<emoji-mart class="emoji-mart" [darkMode]=false [style]="{ position: 'absolute', bottom: '58px', left: '20px' }"
  *ngIf="isEmojiPickerVisible" (emojiSelect)="addEmoji($event)" title="Choose your emoji"></emoji-mart>

<mat-menu #menu="matMenu" class="custom-menu">
  <button *ngIf="!(selectedConversation?.contact)" mat-menu-item (click)="addNumber()">
    <span class="font-lato text-base font-normal secondary-color">Add number</span>
  </button>
  <button mat-menu-item (click)="openDeleteConversationModal()">
    <span class="font-lato text-base font-normal secondary-color">Delete</span>
  </button>
</mat-menu>
