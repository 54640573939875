import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, map, take, tap, zip } from "rxjs";
import { VoicemailsGQL, Voicemail, User, DeleteVoicemailGQL } from "src/generated/graphql";
import { ContactWithPicture, ContactsService } from "./contacts.service";
import { UserService } from "src/app/user/user.service";

export interface VoicemailWithContactInformation extends Voicemail {
  contact: ContactWithPicture;
  recordingUrl: string;
}

@Injectable({
  providedIn: "root"
})
export class VoicemailsService {

  private readonly INITIAL_VOICEMAILS_TO_LOAD_COUNT = 20;
  
  private voicemails = new ReplaySubject<VoicemailWithContactInformation[]>();
  private hasLoadedInitialVoicemails = false;

  constructor(
    private voicemailsGQL: VoicemailsGQL,
    private deleteVoicemailGQL: DeleteVoicemailGQL,
    private contactsService: ContactsService,
    private userService: UserService,
  ) {
  }

  public getVoicemails(): Observable<VoicemailWithContactInformation[]> {
    if (!this.hasLoadedInitialVoicemails) {
      this.loadInitialVoicemails();
    }

    return this.voicemails;
  }

  public getCurrentVoicemails(): Observable<VoicemailWithContactInformation[]> {
    return this.getVoicemails().pipe(take(1));
  }

  public getVoicemailById(id: number, voicemails: VoicemailWithContactInformation[]): VoicemailWithContactInformation {
    return voicemails.find((voicemail) => voicemail.id === id);
  }

  public loadVoicemails(count: number, offset: number): Observable<VoicemailWithContactInformation[]> {
    return zip([
      this.voicemailsGQL.fetch({ input: { count, offset }}),
      this.contactsService.getCurrentContacts(),
      this.userService.getCurrentUser()
    ]).pipe(
      map(([response, contacts, user]) => {
        const voicemails = response.data?.voicemails;
        if (!voicemails) {
          return [];
        }

        return this.mapContactsToVoicemails(voicemails, contacts, user);
      })
    );
  }

  public reloadVoicemails(): Observable<Voicemail[]> {
    return this.loadVoicemails(this.INITIAL_VOICEMAILS_TO_LOAD_COUNT, 0)
      .pipe(
        tap((mostRecentVoicemails) => this.updateVoicemails(mostRecentVoicemails))
      );
  }

  public deleteVoicemail(voicemailId: number): Observable<any> {
    return this.deleteVoicemailGQL.mutate({
      value: { voicemailId }
    })
    .pipe(
      tap(() => this.reloadVoicemails())
    );
  }

  private loadInitialVoicemails(): void {
    this.reloadVoicemails().subscribe();
  }

  private mapContactsToVoicemails(voicemails: Voicemail[], contacts: ContactWithPicture[], user: User): VoicemailWithContactInformation[] {
    return voicemails.map((voicemail) => {
      return {
        ...voicemail,
        contact: this.contactsService.getContactByPhoneNumberAndContactsList(voicemail.callerPhoneNumber, contacts),
        recordingUrl: `/api/storage/user/${user.id}/voicemail-recording/${voicemail.id}`
      }
    });
  }

  private updateVoicemails(newVoicemails: VoicemailWithContactInformation[]): void {
    this.voicemails.next(newVoicemails);
  }
}
