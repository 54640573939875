<div class="flex justify-center container mx-auto sm:px-14 px-5 sm:pt-12 md:14 pt-5 user-profile">
  <div class="w-full lg:w-8/12">
    <div class="font-lato font-normal tracking-wide secondary-color text-xl sm:text-2xl">Personal info</div>
    <div class="font-lato font-light tracking-wide secondary-color-500 mb-5 text-base sm:text-lg">
      Update your photo and personal info here.
    </div>
    <div class="border px-5 sm:px-10 md:px-16 pt-5 sm:pt-10 pb-5 rounded shadow relative">
      <div class="flex flex-col">
        <form [formGroup]="form" (keyup.enter)="saveChanges()">
          <div class="flex items-center w-full mb-5 profile-photo">
            <label
              for="dropzone-file"
              class="flex flex-col items-center justify-center border rounded-full  hover:cursor-pointer border-gray-300 bg-gray-50">
              <div class="flex flex-col items-center justify-center relative rounded-full overflow-hidden">
                <mc-avatar [size]="AvatarSize.LARGE" [avatarUrl]="avatar"></mc-avatar>
                <div class="absolute bg-blue-250 w-full bottom-0 flex items-center justify-center p-1 opacity-0	transition-opacity hover-image">
                  <div class="w-4 h-5">
                    <mat-icon class="text-sky-900 text-base max-w-full max-h-full">camera_alt</mat-icon>
                  </div>
                </div>
              </div>
              <input
                #fileInput
                (change)="uploadAvatar($event)"
                id="dropzone-file"
                type="file"
                class="hidden">
            </label>
            <label for="dropzone-file" class="flex flex-col ml-4 hover:cursor-pointer ">
              <span class="font-lato font-normal secondary-color tracking-wide text-base sm:text-lg">Update your photo </span>
              <span class="text-xs sm:text-sm font-lato secondary-color-500">The supported image types are jpeg, png and webp.</span>
            </label>
          </div>

          <div
            class="flex flex-col sm:flex-row gap-4">
            <div class="flex flex-col grow">
              <span class="font-lato font-normal secondary-color-500 tracking-wide text-xs sm:text-sm mb-1">First name</span>
              <mat-form-field class="example-full-width bg-white personal-form">
                <input
                  matInput
                  placeholder="Ex. John"
                  formControlName="firstName">
                <mat-error *ngIf="firstName.hasError('required')" class="text-sm rounded">
                  First name is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="flex flex-col grow">
              <span class="font-lato font-normal secondary-color-500 tracking-wide text-xs sm:text-sm mb-1">Last name</span>
              <mat-form-field class="example-full-width bg-white personal-form">
                <input
                  matInput
                  placeholder="Ex. Doe"
                  formControlName="lastName">
                <mat-error *ngIf="lastName.hasError('required')" class="text-sm rounded">
                  Last name is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div>
            <div class="flex flex-col grow relative">
              <span class="font-lato font-normal secondary-color-500 tracking-wide text-xs sm:text-sm mb-1">Phone number</span>
              <ngx-mat-intl-tel-input 
                [preferredCountries]="['us', 'ca']" 
                [enablePlaceholder]="true" 
                [enableSearch]="true"
                name="phone" 
                describedBy="phoneNumber" 
                format="national"
                inputPlaceholder="(123) 456 7890"
                class="phone-input mb-6 placeholder:text-gray-300 placeholder:font-lato placeholder:font-light placeholder:tracking-wide tracking-wide font-lato secondary-color font-normal block bg-white border border-slate-200 rounded focus:outline-none w-full py-3 px-4"
                formControlName="phoneNumber">
              </ngx-mat-intl-tel-input>
            </div>
          </div>

          <div>
            <div class="flex flex-col grow relative">
              <span class="font-lato font-normal secondary-color-500 tracking-wide text-xs sm:text-sm mb-1">Purchased phone number</span>
              <ngx-mat-intl-tel-input 
                [preferredCountries]="['us', 'ca']" 
                [enablePlaceholder]="true" 
                [enableSearch]="true"
                name="phone" 
                describedBy="phoneInput" 
                format="national"
                inputPlaceholder="(123) 456 7890"
                class="phone-input disabled placeholder:text-gray-300 placeholder:font-lato placeholder:font-light placeholder:tracking-wide tracking-wide font-lato secondary-color font-normal block bg-white border border-slate-200 rounded focus:outline-none w-full py-3 px-4"
                formControlName="twilioPhoneNumber">
              </ngx-mat-intl-tel-input>
            </div>
          </div>
        </form>
      </div>

      <div class="flex justify-end border-t border-gray-100 mt-5 pt-5">
        <button
          (click)="saveChanges()"
          mat-raised-button
          [class.disabled]="this.invalidForm"
          class="bg-primary-color text-white tracking-wide font-lato font-light rounded focus:bg-primary-color hover:bg-primary-light-color focus:outline-none text-sm py-2 px-4">
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>
