import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, zip } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { TrackingService } from 'src/app/services/tracking-service';
import { AvatarSize } from 'src/app/shared/avatar/avatar.component';
import { ContactsService, ContactWithPicture } from 'src/app/shared/services/contacts.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { PhoneNumberFormattingService } from 'src/app/shared/services/phone-number-formatting.service';
import { SelectedContactService } from 'src/app/shared/services/selection/selected-contact.service';
import { SelectedMessagingConversationService } from 'src/app/shared/services/selection/selected-messaging-conversation.service';
import { UserService } from 'src/app/user/user.service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';
import { Contact, Conversation, Sms, SmsStatus, User } from 'src/generated/graphql';

export interface MessageWithConversationInfo {
  id: number,
  conversationId: number,
  body: string,
  smsType: string,
  senderName: string,
  senderPictureUrl: string,
  createdAt: Date
}

@Component({
  selector: 'mc-search-result-page',
  templateUrl: './search-result-page.component.html',
  styleUrls: ['./search-result-page.component.scss']
})
export class SearchResultPageComponent {
  public readonly AvatarSize = AvatarSize;

  private readonly ITEMS_TO_LOAD_PER_SCROLL = 6;
  private readonly ON_DESTOY = new Subject<void>();

  public queryValue: string;
  public messagesSearchResults: MessageWithConversationInfo[] = [];
  public contactsSearchResults: ContactWithPicture[] = [];
  public unfilteredContacts = new Map<number, ContactWithPicture>();

  public totalMessagesCount: number;
  public loadingMessages: boolean;

  private user: User;

  constructor(
    private route: ActivatedRoute,
    private trackingService: TrackingService,
    private messagingService: MessagingService,
    private userService: UserService,
    private phoneNumberFormattingService: PhoneNumberFormattingService,
    private contactService: ContactsService,
    private selectedMessagingConversationService: SelectedMessagingConversationService,
    private selectedContactService: SelectedContactService,
    ) {
  }

  public ngOnInit(): void {
    zip([this.userService.getCurrentUser(), this.contactService.getCurrentContacts()])
    .subscribe(([user, contacts]) => {
      this.user = user;
      this.unfilteredContacts = new Map<number, ContactWithPicture>();
      contacts.forEach(contact => this.unfilteredContacts.set(contact.id, contact));

      this.route.queryParams
      .pipe(takeUntil(this.ON_DESTOY))
      .subscribe((queryParams) => {
        this.queryValue = queryParams['query'];
        this.searchAndCountMessages();
        this.searchContacts();
      });
    });
  }

  public ngOnDestroy(): void {
    this.ON_DESTOY.next();
    this.ON_DESTOY.complete();
  }

  public get hasMoreMessagesToLoad() {
    return this.messagesSearchResults.length < this.totalMessagesCount;
  }

  public goToContactProfile(contactId: number): void {
    this.selectedContactService.openContactById(contactId);
  }

  public goToMessagesConversation(conversationId: number): void {
    this.selectedMessagingConversationService.openConversationById(conversationId);
  }

  public goToContactMessagingConversation(contactId: number): void {
    this.selectedMessagingConversationService.openConversationByContactId(contactId);
  }

  public searchMoreMessages(): void {
    if (!this.queryValue.length) {
      this.messagesSearchResults = [];
      this.loadingMessages = false;
      return;
    }

    this.loadingMessages = true;
    this.messagingService.searchSmses(this.queryValue, this.ITEMS_TO_LOAD_PER_SCROLL, this.messagesSearchResults.length)
      .subscribe({
        next: (results) => {
          this.loadingMessages = false;
          const messages = results.map(message => this.getSmsWithConversationInfo(message));
          this.messagesSearchResults.push(...messages);
          this.trackingService.trackEvent(TrackingEventType.SEARCH_HELLO_GROVE);
        },
        error: () => {
          this.loadingMessages = false;
          this.trackingService.trackEvent(TrackingEventType.SEARCH_HELLO_GROVE_FAILURE);
        }
      });
  }

  private searchAndCountMessages(): void {
    if (!this.queryValue.length) {
      this.messagesSearchResults = [];
      this.loadingMessages = false;
      return;
    }

    this.loadingMessages = true;
    this.messagingService.searchAndCountSmses(this.queryValue, this.ITEMS_TO_LOAD_PER_SCROLL, 0)
      .subscribe({
        next: (results) => {
          this.loadingMessages = false;
          this.messagesSearchResults = results.messages.map(sms => this.getSmsWithConversationInfo(sms));
          this.totalMessagesCount = results.totalCount;
          this.trackingService.trackEvent(TrackingEventType.SEARCH_HELLO_GROVE);
        },
        error: () => {
          this.trackingService.trackEvent(TrackingEventType.SEARCH_HELLO_GROVE_FAILURE);
        }
      });
  }

  private searchContacts(): void {
    if (!this.queryValue.length) {
      this.contactsSearchResults = [];
      return;
    }

    this.contactsSearchResults = [...this.unfilteredContacts.values()].filter(contact => this.queryMatchedNumberOrNumber(contact));
  }

  private getSmsWithConversationInfo(sms: Sms): MessageWithConversationInfo {
    const isSentMessage = sms.smsType === SmsStatus.SENT;
    const contact = this.unfilteredContacts.get(sms.conversation.contactId);

    const senderName = this.getSenderInfo(isSentMessage, sms.conversation, contact);
    const senderPictureUrl = isSentMessage ? this.userService.buildUserAvatarUrl(this.user) : contact?.pictureUrl;

    return {
      id: sms.id,
      body: sms.body,
      conversationId: sms.conversationId,
      smsType: sms.smsType,
      senderName: senderName,
      senderPictureUrl: senderPictureUrl,
      createdAt: sms.createdAt
    }
  }

  private getSenderInfo(isSmsSent: boolean, conversation: Conversation, contact: Contact): string {
    if (isSmsSent) {
      return this.user.firstName + " " + this.user.lastName;
    }

    if (contact && contact.fullName) {
      return contact.fullName;
    }

    return this.phoneNumberFormattingService.format(conversation.participantNumber);
  }

  private queryMatchedNumberOrNumber(contact: ContactWithPicture) {
    const anyPhoneNumberMatch = contact.phoneNumbers.some(number => number.phoneNumber.includes(this.queryValue));

    return anyPhoneNumberMatch || contact.fullName.toLocaleLowerCase().includes(this.queryValue.toLocaleLowerCase());
  }
}
