import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ContactsComponent} from "./pages/contacts/contacts.component";
import {CallsComponent} from "./pages/calls/calls.component";
import {MessagesComponent} from "./pages/messages/messages.component";
import {VoicemailsComponent} from "./pages/voicemails/voicemails.component";
import {TemplatesComponent} from "./pages/templates/templates.component";
import { AccountSetupRoutingModule } from './pages/account-setup/account-setup-routing.module';
import { AuthGuard } from './auth/auth.guard';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { ContactDetailsComponent } from './pages/contacts/contact-details/contact-details.component';
import { ContactDetailsPlaceholderComponent } from './pages/contacts/contact-details-placeholder/contact-details-placeholder.component';
import { ConversationMessagesComponent } from './pages/messages/conversation-messages/conversation-messages.component';
import { ConversationPlaceholderComponent } from './pages/messages/conversation-placeholder/conversation-placeholder.component';
import { CallConversationPageComponent } from './pages/calls/call-conversation/call-conversation.component';
import { OutgoingCallPageComponent } from './pages/calls/calling/outgoing-call/outgoing-call.component';
import { DialPageComponent } from './pages/calls/calling/dial-page/dial.component';
import { VoicemailGreetingsComponent } from './pages/voicemails/voicemail-greetings/voicemail-greetings.component';
import { VoicemailDetailsComponent } from './pages/voicemails/voicemail-details/voicemail-details.component';
import { CallConversationSelectedComponent } from './pages/calls/call-conversation/call-conversation-selected/call-conversation-selected.component';
import { IncomingCallPageComponent } from './pages/calls/calling/incoming-call/incoming-call.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { SearchResultPageComponent } from './pages/search-result-page/search-result-page.component';

export enum ConversationRouteType {
  MESSAGE,
  CALL,
  VOICEMAIL
}

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "contacts"
  }
  ,
  {
    path: "contacts",
    component: ContactsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ContactDetailsPlaceholderComponent,
      },
      {
        path: ':contactId',
        component: ContactDetailsComponent,
      }
    ]
  },
  {
    path: "calls",
    component: CallsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ConversationPlaceholderComponent,
        data: { type: ConversationRouteType.CALL }
      },
      {
        path: 'dial',
        component: DialPageComponent,
      },
      {
        path: ':conversationId',
        component: CallConversationPageComponent,
        children:[
          {
            path:'',
            component: CallConversationSelectedComponent
          },
          {
            path: 'outgoing',
            component: OutgoingCallPageComponent,
          },
          {
            path: 'incoming',
            component: IncomingCallPageComponent,
          },
        ]
      },

    ]
  },
  {
    path: "messages",
    component: MessagesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ConversationPlaceholderComponent,
        data: { type: ConversationRouteType.MESSAGE }
      },
      {
        path: 'new',
        component: ConversationMessagesComponent,
      },
      {
        path: ':conversationId',
        component: ConversationMessagesComponent,
      }
    ]
  },
  {
    path: "voicemails",
    component: VoicemailsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ConversationPlaceholderComponent,
        data: { type: ConversationRouteType.VOICEMAIL }
      },
      {
        path: 'greetings',
        component: VoicemailGreetingsComponent,
      },
      {
        path: ':voicemailId',
        component: VoicemailDetailsComponent,
      }
    ]
  },
  {
    path: "templates",
    component: TemplatesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "profile",
    component: UserProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "search",
    component: SearchResultPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: NotFoundPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' }), AccountSetupRoutingModule],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
