<div class="max-w-xs rounded notification-box cursor-pointer">
    <div class="flex flex-row items-center px-4 py-2">
        <img src="../../../assets/images/icons/error-message.svg"> 
        <div class="ml-2 font-lato font-normal text-base secondary-color">{{title}}</div>
    </div>
    <div class="px-4 pt-2 pb-3">
      <div class="text-gray-700 text-sm tracking-wide my-2">
        {{message}}
      </div>
    </div>
</div>
