<mat-sidenav-content class="bg-white w-full h-full" id="contactsPage">
    <mat-sidenav-container autosize class="h-full">
      <mat-sidenav opened mode="side" class="right-gray-border w-[250px] md:w-[350px]">
        <div class="bottom-gray-border px-7 py-4 flex flex-row items-center justify-center bg-white top-0 sticky">
          <button mat-stroked-button class="main-button cursor-pointer shadow w-full hover:bg-blue-250" (click)="addNewContact()">
            <img src="assets/images/icons/plus.svg" class="mr-3 w-[24px]"/>
            <span class="font-lato text-base font-normal">Add new contact</span>
          </button>
        </div>

        <!-- No contacts section }} -->
        <div class="absolute top-[60px] bottom-0 left-0 right-0 flex items-center justify-center px-7"
             *ngIf="contacts?.length === 0">
          <p class="font-lato text-base secondary-color pb-[60px]">No contacts</p>
        </div>

        <!-- Existing contacts section -->
        <div class="w-full max-w-md bg-white flow-root contacts-list" *ngIf="!contacts || contacts?.length > 0">
            <p class="py-3 px-7 secondary-color text-base md:text-lg">Contacts</p>
            <ul role="list" *ngIf="contacts?.length > 0 else loading">
              <li class="py-4 px-7 cursor-pointer hover:bg-gray-150 border-b border-gray-100"
                  *ngFor="let contact of contacts"
                  (click)="selectContact(contact.id)"
                  [ngClass]="{'bg-gray-350': selectedContactId === contact.id}">
                  <div class="flex space-x-4">
                      <div class="flex-shrink-0 self-center">
                        <mc-avatar [avatarUrl]="contact.pictureUrl" [size]="AvatarSize.SMALL"></mc-avatar>
                      </div>
                      <div class="flex-1 min-w-0 self-center">
                        <div class="font-lato text-sm md:text-base font-normal text-gray-900 truncate">{{ contact.fullName }}</div>
                      </div>
                  </div>
              </li>
            </ul>
            <!-- Skeleton -->
          <ng-template #loading>
            <mc-skeleton></mc-skeleton>
          </ng-template>
        </div>
      </mat-sidenav>

      <mat-sidenav-content class="relative">
        <div class="flex flex-col min-h-full bg-white">
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </mat-sidenav-content>
