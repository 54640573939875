<div class="container main-content mx-0 pb-[100px] sm:mx-auto px-6 md:px-4 lg:px-12 pt-6 md:pt-12 lg:pt-16 ">
  <p class="font-lato font-normal tracking-wide secondary-color leading-loose mb-2 text-xl sm:text-2xl">Claim a number</p>
  <p class="font-lato tracking-wide secondary-color-500 mb-8 text-base sm:text-lg">Write one area code number and click ‘Next ’ to search for available numbers on the next page.</p>
  
  <div class="flex justify-start flex-col">
    <div class="flex flex-col mb-5">
      <span class="font-lato font-normal secondary-color tracking-wide text-sm sm:text-base mb-1">Area Code</span>
      <mat-form-field class="example-full-width bg-white mat-style w-full md:w-5/12">
        <input type="text" placeholder="Area Code" aria-label="Area Code" matInput [matAutocomplete]="auto" [formControl]="areaCodeControl">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="loadAvailablePhoneNumbers($event.option?.value)">
          <cdk-virtual-scroll-viewport autosize [ngStyle]="{height: '100%', contain: 'none', minHeight: '56px'}" itemSize="25">
            <mat-option *cdkVirtualFor="let areaCode of filteredAreaCodes" [value]="areaCode.code">
              {{ areaCode.code + ' - ' + areaCode.state }}
            </mat-option>
          </cdk-virtual-scroll-viewport>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="flex flex-col relative" *ngIf="!availablePhoneNumbers.length && !initialLoadingOfAvailablePhoneNumbers && !loadingAvailablePhoneNumbers">
      <span class="font-lato font-normal secondary-color tracking-wide text-sm sm:text-base mb-1">There are no available phone numbers for this area code at this time.</span>
    </div>

    <div class="flex items-start" *ngIf="loadingAvailablePhoneNumbers">
      <mc-loader></mc-loader>
    </div>
    
    <div class="flex flex-col relative" *ngIf="availablePhoneNumbers.length && !loadingAvailablePhoneNumbers">
      <span class="font-lato font-normal secondary-color tracking-wide text-sm sm:text-base mb-1">Select a number</span>
      <mat-form-field class="example-full-width bg-white mat-style w-full md:w-5/12">
        <mat-select [formControl]="control" placeholder="Select a number" class="placeholder:text-gray-400 placeholder:font-lato placeholder:font-light font-lato font-normal secondary-color">
          <mat-option *ngFor="let phoneNumber of availablePhoneNumbers" [value]="phoneNumber.phoneNumber">{{ phoneNumber.friendlyName }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="hasAttemptedToProceedWithErrors" class="absolute bottom-[-22px] text-sm">
        Please select a phone number to continue.
      </mat-error>
    </div>
  </div>
  <div class="flex md:justify-end justify-center">
    <img class="float-right lg:w-4/12 md:w-6/12 sm:w-7/12 w-9/12 mt-12 sm:mt-8" src="../../../assets/images/claim-number.svg" alt="Location img">
  </div>
</div>
