import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ConversationRouteType } from 'src/app/app-routing.module';
import { SelectedCallingConversationService } from 'src/app/shared/services/selection/selected-call-conversation.service';
import { SelectedItemService } from 'src/app/shared/services/selection/selected-item.service';
import { SelectedMessagingConversationService } from 'src/app/shared/services/selection/selected-messaging-conversation.service';
import { SelectedVoicemailService } from 'src/app/shared/services/selection/selected-voicemail.service';
import { UserService } from 'src/app/user/user.service';
import { User } from 'src/generated/graphql';

@Component({
  selector: 'mc-conversation-placeholder',
  templateUrl: './conversation-placeholder.component.html',
  styleUrls: ['./conversation-placeholder.component.scss']
})
export class ConversationPlaceholderComponent {

  public readonly ON_COMPONENT_DESTROY = new Subject<void>();

  public user: User;

  private readonly selectedItemService: SelectedItemService;

  public constructor(
    route: ActivatedRoute,
    selectedMessageConversationService: SelectedMessagingConversationService,
    selectedCallConversationService: SelectedCallingConversationService,
    selectedVoicemailService: SelectedVoicemailService,
    private userService: UserService,
  ) {
    const routeType = route.snapshot.data['type'];
    if (routeType === ConversationRouteType.MESSAGE) {
      this.selectedItemService = selectedMessageConversationService;
    } else if (routeType === ConversationRouteType.CALL) {
      this.selectedItemService = selectedCallConversationService;
    } else if (routeType === ConversationRouteType.VOICEMAIL) {
      this.selectedItemService = selectedVoicemailService;
    }

    this.clearSelectedConversationService();
  }

  public ngOnInit(): void {
    this.loadUser();
  }

  public ngOnDestroy(): void {
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }

  private loadUser(): void {
    this.userService.getUser()
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe((user) => this.user = user);
  }

  private clearSelectedConversationService(): void {
    this.selectedItemService.setSelectedItemId(null);
  }
  
}
