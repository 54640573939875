import { Injectable } from "@angular/core";
import { SelectedItemService } from "./selected-item.service";
import { MessagingService } from "../messaging.service";
import { Router } from "@angular/router";
import { ContactsService } from "../contacts.service";

@Injectable({ providedIn: 'root' })
export class SelectedMessagingConversationService extends SelectedItemService {

  constructor(
    private messagingService: MessagingService,
    private contactService: ContactsService,
    private router: Router,
  ) {
    super();
  }

  public openConversationByContactId(contactId: number): void {
    this.messagingService.getConversationByContactId(contactId).subscribe((conversation) => {
      this.setSelectedItemId(conversation?.id);
      if (conversation) {
        this.navigateToConversationById(conversation.id);
      } else {
        this.navigateToNewConversationForContact(contactId);
      }
    });
  }

  public openConversationById(conversationId: number): void {
    this.setSelectedItemId(conversationId);
    this.navigateToConversationById(conversationId);
  }

  public openConversationWithParticipantNumber(participantNumber: string): void {
    this.messagingService.getConversationByParticipantNumber(participantNumber).subscribe((conversation) => {

      if (conversation) {
        this.setSelectedItemId(conversation.id);
        this.navigateToConversationById(conversation.id);
        return;
      }

      this.contactService.getContactByPhoneNumber(participantNumber).subscribe(contact => {
        if(contact) {
          this.navigateToNewConversationForContact(contact.id)
          return;
        }

        this.navigateToNewConversationWithNumber(participantNumber)
      })
    });
  }

  public navigateToConversationById(conversationId: number): void {
    if (this.isAlreadyNavigatedToConversationPage(conversationId)) {
      return;
    }

    this.router.navigate(['messages', conversationId]);
  }

  private navigateToNewConversationForContact(contactId: number): void {
    this.router.navigate(['messages', 'new'], { queryParams: { contactId } });
  }

  private navigateToNewConversationWithNumber(participantNumber: string): void {
    this.router.navigate(['messages', 'new'], { queryParams: { "phoneNumber" :participantNumber } });
  }

  private isAlreadyNavigatedToConversationPage(conversationId: number): boolean {
    return this.router.isActive('/messages/' + conversationId, { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' });
  }
}
