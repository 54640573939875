import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TrackingService } from 'src/app/services/tracking-service';
import { CallService } from 'src/app/shared/services/call.service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';
import { Conversation } from 'src/generated/graphql';

@Component({
  selector: 'mc-delete-call',
  templateUrl: './delete-calls.component.html',
  styleUrls: ['./delete-calls.component.scss']
})
export class DeleteCallComponent {

  public conversation: Conversation;

  public deleting: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) data: Conversation,
    private dialogRef: MatDialogRef<DeleteCallComponent>,
    private callService: CallService,
    private trackingService: TrackingService,) {
    this.conversation = data;
  }

  public onConfirmDelete(): void {
    this.deleting = true;
    this.callService.deleteConversation(this.conversation.id).subscribe({
      next: () => {
        this.deleting = false;
        this.close(true);
        this.trackingService.trackEvent(TrackingEventType.CALLS_DELETE_CONVERSATION_SUCCESS);
      },
      error: () => {
        this.deleting = false;
        this.trackingService.trackEvent(TrackingEventType.CALLS_DELETE_CONVERSATION_FAILURE);
      }
    });
  }

  public close(value?: boolean) {
    this.dialogRef.close(value);
    this.trackingService.trackEvent(TrackingEventType.CALLS_DELETE_CONVERSATION_CANCEL);
  }
}
