import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Template, TemplateService } from './template.service';
import { TrackingService } from 'src/app/services/tracking-service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';

@Component({
  selector: 'mc-message-templates-picker',
  templateUrl: './message-templates-picker.component.html',
  styleUrls: ['./message-templates-picker.component.scss']
})
export class MessageTemplatesPickerComponent {

  public messageTemplates: Template[] = [];
  public selectedMessageTemplate: Template = {};

  constructor(
    private dialogRef: MatDialogRef<MessageTemplatesPickerComponent>,
    private templateService: TemplateService,
    private trackingService: TrackingService,
  ) { }

  public ngOnInit(): void {
    this.messageTemplates = this.templateService.getMessageTemplates();
    this.selectedMessageTemplate = this.messageTemplates[0];
  }

  public selectTemplate(messageTemplate: Template): void {
    this.selectedMessageTemplate = messageTemplate;
    this.trackingService.trackEvent(TrackingEventType.TEMPLATES, { name: messageTemplate.title });
  }

  public closeDialogWithTemplate(): void {
    this.dialogRef.close(this.selectedMessageTemplate);
    this.trackingService.trackEvent(TrackingEventType.MESSAGES_TEMPLATES_SAVE);
  }

  public trackCloseButton() {
    this.trackingService.trackEvent(TrackingEventType.MESSAGES_TEMPLATES_CANCEL);
  }
}
