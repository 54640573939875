import {Component, Input} from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/user/user.service';
import {AuthService} from "../../auth/auth.service";
import { AvatarSize } from '../avatar/avatar.component';
import { TrackingService } from 'src/app/services/tracking-service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';

@Component({
  selector: 'mc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public readonly AvatarSize = AvatarSize;
  public readonly avatarUrl = this.userService.getAvatarUrl();

  @Input()
  public hasUserCompletedAccountSetup = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private trackingService: TrackingService,
  ) {
  }

  public logout() {
    this.authService.logout();
    this.trackingService.trackEvent(TrackingEventType.LOGOUT);
  }

  public goToUserProfile() {
    this.router.navigate(['/profile']);
    this.trackingService.trackEvent(TrackingEventType.PROFILE_CLICKED);
  }
}
