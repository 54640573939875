<div class="flex space-x-4">
  <div class="flex-shrink-0 self-center">
    <mc-avatar [size]="AvatarSize.SMALL" [avatarUrl]="contactImageUrl"></mc-avatar>
  </div>
  <div class="flex-1 min-w-0 self-start">
    <div class="flex justify-between items-center">
      <div class="font-lato text-sm md:text-base font-normal text-gray-900 truncate grow">
        <ng-container *ngIf="conversation.contact; let contact;">
          {{ contact.fullName }}
        </ng-container>

        <ng-container *ngIf="!conversation.contact">
          {{ conversation.participantNumber | phoneNumber }}
        </ng-container>
      </div>
      <div class="inline-flex font-lato text-xs md:text-[13px] font-light secondary-color-500 flex-none ml-2">
        {{ timestamp }}
      </div>
    </div>

    <div *ngIf="conversation.latestCall" class="mt-0 flex flex-row items-center">
      <ng-container *ngIf="conversation.latestCall.callType === CallType.INCOMING">
        <mat-icon
          *ngIf="conversation.latestCall.status === CallStatus.NO_ANSWER"
          class="call-icon missed-call-icon">call_missed</mat-icon>

        <mat-icon
          *ngIf="conversation.latestCall.status !== CallStatus.NO_ANSWER"
          class="call-icon incoming-call-icon">call_received</mat-icon>
      </ng-container>

      <ng-container *ngIf="conversation.latestCall.callType === CallType.OUTGOING">
        <mat-icon
          *ngIf="conversation.latestCall.status === CallStatus.NO_ANSWER"
          class="call-icon missed-call-icon">call_missed_outgoing</mat-icon>

        <mat-icon
          *ngIf="conversation.latestCall.status !== CallStatus.NO_ANSWER"
          class="call-icon outgoing-call-icon">call_made</mat-icon>
      </ng-container>

      <p class="text-xs md:text-[13px] font-light text-gray-400 tracking-wide truncate">
        {{ conversation.participantNumber | phoneNumber }}
      </p>
    </div>
  </div>
</div>
