import {Component, OnDestroy, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {MatDialogRef} from "@angular/material/dialog";
import { Subject, takeUntil } from 'rxjs';
import { TrackingService } from 'src/app/services/tracking-service';
import { VoiceRecordingService } from 'src/app/services/voice-recording.service';
import { VoicemailGreetingService } from 'src/app/services/voicemail-greeting.service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';

@Component({
    selector: 'mc-greeting-modal',
    templateUrl: './greeting-modal.component.html',
    styleUrls: ['./greeting-modal.component.scss']
})
export class GreetingModalComponent implements OnInit, OnDestroy {
    public readonly VOICE_RECORDING_TIME_LIMIT_IN_SECONDS = 20;
    private readonly ON_DESTROY = new Subject<void>();

    public recording = false;
    public hasStartedRecording = false;
    public hasFinishedRecording = false;
    public hasAttemptedToSaveGreeting = false;

    public recordingBlob: Blob;

    public greetingFormGroup = new FormGroup({
        name: new FormControl("", [Validators.required])
    });
    public secondsRecording = 0;

    private savingRecord = false;

    constructor(private dialogRef: MatDialogRef<GreetingModalComponent>,
                private voiceRecordingService: VoiceRecordingService,
                private voicemailGreetingService: VoicemailGreetingService,
                private trackingService: TrackingService,) {
    }

    public get isInvalid(): boolean {
        const hasNoRecording = !this.recordingBlob;
        const greetingNameIsEmpty = this.greetingFormGroup.invalid;

        return (this.hasAttemptedToSaveGreeting && (greetingNameIsEmpty || hasNoRecording)) 
                || this.recording || this.savingRecord;
    }

    public ngOnInit(): void {
        this.dialogRef.afterClosed().subscribe(() => this.resetTimeAndRecording());

        this.voiceRecordingService.getTimeOfRecording()
            .pipe(takeUntil(this.ON_DESTROY))
            .subscribe(() => {
                this.secondsRecording++;
                if (this.hasReachedTimeLimit()) {
                    this.stopRecording();
                }
            })
    }

    public ngOnDestroy(): void {
        this.ON_DESTROY.next();
        this.ON_DESTROY.complete();
    }

    public hasReachedTimeLimit(): boolean {
        return this.secondsRecording >= this.VOICE_RECORDING_TIME_LIMIT_IN_SECONDS;
    }

    public startRecording(): void {
        if (this.hasReachedTimeLimit() || this.hasFinishedRecording || this.hasStartedRecording) {
            return;
        }

        this.voiceRecordingService.startRecording().then(() => {
            this.recording = true;
            this.hasStartedRecording = true;
        });
        this.trackingService.trackEvent(TrackingEventType.VOICEMAIL_GREETINGS_RECORD_A_GREETING_RECORD_BUTTON_CLICKED);
    }

    public pauseRecording(): void {
        this.voiceRecordingService.pauseRecording();
        this.recording = false;
        this.trackingService.trackEvent(TrackingEventType.RECORD_A_GREETING_PAUSE);
    }

    public resumeRecording(): void {
        this.voiceRecordingService.resumeRecording();
        this.recording = true;
        this.trackingService.trackEvent(TrackingEventType.RECORD_A_GREETING_RESUME);
    }

    public stopRecording(): void {
        this.voiceRecordingService.stopRecording().then((recordingBlob) => {
            this.recording = false;
            this.hasFinishedRecording = true;
            this.hasStartedRecording = false;
            this.recordingBlob = recordingBlob;
        });
        this.trackingService.trackEvent(TrackingEventType.RECORD_A_GREETING_STOP);
    }

    public redoRecording(): void {
        this.resetTimeAndRecording();
        this.hasFinishedRecording = false;
        this.trackingService.trackEvent(TrackingEventType.RECORD_A_GREETING_REDO);
    }

    public playRecording(): void {
        const blobUrl = URL.createObjectURL(this.recordingBlob);
        const audio = new Audio(blobUrl);
        audio.play();
        this.trackingService.trackEvent(TrackingEventType.RECORD_A_GREETING_PLAY);
    }

    public cancelRecording(): void {
        this.dialogRef.close();
        this.trackingService.trackEvent(TrackingEventType.RECORD_A_GREETING_CANCEL);
    }

    public async saveRecording(): Promise<void> {
        this.hasAttemptedToSaveGreeting = true;

        if (this.greetingFormGroup.invalid) {
            return;
        }

        const recordingBlob = await this.voiceRecordingService.stopRecording();
        this.savingRecord = true;
        this.voicemailGreetingService.createVoicemailGreeting({
            voicemailGreeting: recordingBlob,
            name: this.greetingFormGroup.controls.name.value,
            duration: this.secondsRecording
        }).subscribe(createdVoicemailGreeting => {
            this.savingRecord = false;
            this.dialogRef.close(createdVoicemailGreeting);
        })
        this.trackingService.trackEvent(TrackingEventType.RECORD_A_GREETING_SAVE);
    }

    private resetTimeAndRecording(): void {
        this.hasAttemptedToSaveGreeting = false;
        if (this.voiceRecordingService.isRecorderInitialized()) {
            this.voiceRecordingService.clearRecording();
        }
    }
}
