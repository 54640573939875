import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mc-error-notification',
  templateUrl: './error-notification.component.html',
  styleUrls: ['./error-notification.component.scss']
})
export class ErrorNotificationComponent extends Toast {

  constructor(protected toastService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastService, toastPackage);
    this.title = toastPackage.config.payload.title;
    this.message = toastPackage.config.payload.message;
  }
}
