import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VoicemailsService } from 'src/app/shared/services/voicemails.service';
import { Voicemail } from 'src/generated/graphql';

@Component({
  selector: 'mc-delete-messages',
  templateUrl: './delete-messages.component.html',
  styleUrls: ['./delete-messages.component.scss']
})
export class DeleteVoicemailComponent {

  public voicemail: Voicemail;

  public deleting: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: Voicemail,
    private dialogRef: MatDialogRef<DeleteVoicemailComponent>,
    private voicemailService: VoicemailsService,
    ) {
    this.voicemail = data;
  }

  public onConfirmDelete(): void {
    this.deleting = true;
    this.voicemailService.deleteVoicemail(this.voicemail.id).subscribe({
      next: () => {
        this.deleting = false;
        this.close(true);
      },
      error: () => {
        this.deleting = false;
      }
    })
  }

  public close(deleted?: boolean) {
    this.dialogRef.close(deleted);
  }
}
