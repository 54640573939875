<div class="contact-info" *ngIf="selectedContact">
  <div class="flex flex-row justify-between pl-7 pr-4 py-4 bottom-gray-border">
    <div class="flex flex-row items-center">
      <mc-avatar [avatarUrl]="selectedContact.pictureUrl" [size]="AvatarSize.SMALL"></mc-avatar>
      <div class="grid ml-3">
        <span class="text-lato secondary-color text-base leading-4">{{ selectedContact.fullName }}</span>
        <span class="text-lato secondary-color-500 text-sm font-light">{{ preferredNumber | phoneNumber }}</span>
      </div>
    </div>

    <div class="flex flex-row items-center">
      <button (click)="callContact()" mat-icon-button class="p-3 hover:bg-gray-450 hover:rounded-full hover:p-3">
        <img class="w-5 h-5" src="assets/images/icons/phone-call.svg" alt="phone call" />
      </button>
      <button (click)="goToConversationScreen()" mat-icon-button class="ml-2 p-3 hover:bg-gray-450 hover:rounded-full hover:p-3">
        <img class="w-5 h-5" src="assets/images/icons/sms.svg" alt="sms" />
      </button>
      <button mat-icon-button [matMenuTriggerFor]="menu"
        class="p-3 ml-1 hover:bg-gray-450 hover:rounded-full hover:p-3">
        <img class="w-5 h-5" src="assets/images/icons/more.svg" alt="more" />
      </button>
    </div>
  </div>
  <div class="py-4 px-7 text-lato secondary-color text-base">Contact Info</div>
  <div class="flex items-center justify-center">
    <div class="flex items-center flex-col border-b border-gray-100 pt-28 pb-10 w-9/12">
      <mc-avatar [avatarUrl]="selectedContact.pictureUrl" [size]="AvatarSize.LARGE"></mc-avatar>
      <div class="ml-2 my-3 text-lato secondary-color text-lg">{{ selectedContact.fullName }}</div>
    </div>
  </div>
  <div class="flex items-center justify-center">
    <div class="grid grid-flow-col auto-cols-max flex justify-around pt-10 w-10/12">
      <div class="grid text-center" *ngFor="let phoneNumber of selectedContact.phoneNumbers">
        <span class="text-lato secondary-color text-base flex justify-center">
          <!-- Show the icon only for the preferred number -->
          <mat-icon class="preferred-nr" *ngIf="phoneNumber.isPreferred">star</mat-icon>
          {{ phoneNumber.label ? phoneNumber.label : '-' }}
        </span>
        <span class="text-lato primary-color text-base font-normal">{{ phoneNumber.phoneNumber | phoneNumber }}</span>
      </div>
    </div>
  </div>
</div>

<mat-menu #menu="matMenu" class="custom-menu">
  <button (click)="openEditContactModal()" mat-menu-item >
    <span class="font-lato text-base font-normal secondary-color">Edit</span>
  </button>
  <button (click)="openDeleteContactModal()" mat-menu-item>
    <span class="font-lato text-base font-normal secondary-color">Delete</span>
  </button>
</mat-menu>