import { Observable, ReplaySubject, Subject, take } from "rxjs";
import { Injectable } from "@angular/core";
import { Call as VoiceSdkCall } from '@twilio/voice-sdk';
import { CallService, VoiceSdkCallEvents } from "./call.service";
import { Call, CallType, Conversation } from "src/generated/graphql";
import { Timer, TimerService } from './timer-service';

export type CallManager = {
  type: CallType;
  twilioCall: VoiceSdkCall;
  conversation: Conversation;
  call?: Call;
  callDurationTimer?: Timer;
};

@Injectable({ providedIn: 'root' })
export class CallManagerService {

  private readonly CLEAR_PENDING_CALL_LISTENER = () => this.clearPendingCall();
  private readonly DISCONNECT_CALL_LISTENER = (callManager: CallManager) => { this.disconnectCall((callManager) => this.disconnectedEvent.next(callManager)) };

  private readonly acceptedCall = new ReplaySubject<CallManager | null>(1);
  private readonly pendingCall = new ReplaySubject<CallManager | null>(1);
  private readonly disconnectedEvent = new Subject<CallManager>();

  private hasOpenedCall: boolean;
  private callInProgress: boolean;

  constructor(
    private callService: CallService,
    private timerService: TimerService,
  ) {
    this.setupIncomingCallHandler();
  }

  public getPendingCall(): Observable<CallManager | null> {
    return this.pendingCall.asObservable();
  }

  public getAcceptedCall(): Observable<CallManager | null> {
    return this.acceptedCall.asObservable();
  }

  public isCallInProgress(): boolean {
    return this.callInProgress;
  }

  public acceptPendingCall(callback: (callManager: CallManager) => void): void {
    this.getPendingCall()
      .pipe(take(1))
      .subscribe(callManager => {
        if (!callManager) {
          return;
        }

        callManager.twilioCall.accept();

        const caller = callManager.twilioCall.parameters['From'];
        const callSid = callManager.twilioCall.parameters['CallSid'];

        this.callService.fetchCallWithTwilioIdOrParticipantNumber(callSid, caller, (call) => {
          callManager.call = call;
          callManager.callDurationTimer = this.timerService.startTimer();
          this.hasOpenedCall = true;
          this.acceptedCall.next({ ...callManager });
          this.clearPendingCall();

          callback(callManager);
        });
      });
  }

  public async startNewCall(
    participantNumber: string,
    callback: (callManager: CallManager) => void
  ): Promise<void> {
    if (this.callInProgress) {
      return;
    }
    this.callService.createVoiceCall(participantNumber).subscribe((pendingTwilioCall) => {
      this.callInProgress = true;
      pendingTwilioCall.addListener(VoiceSdkCallEvents.DISCONNECT, this.DISCONNECT_CALL_LISTENER);

      pendingTwilioCall.on(VoiceSdkCallEvents.ACCEPT, (twilioCall: VoiceSdkCall) => {
        this.callService.getCallByTwillioId(twilioCall.parameters['CallSid'])
          .subscribe((call) => {
            const callManager: CallManager = {
              twilioCall,
              call,
              conversation: call.conversation,
              type: CallType.OUTGOING,
              callDurationTimer: this.timerService.startTimer(),
            };

            this.hasOpenedCall = true;

            this.acceptedCall.next(callManager);
            callback(callManager);
          });
      });
    });
  }

  public rejectPendingCall(): void {
    this.getPendingCall()
      .pipe(take(1))
      .subscribe(callManager => {
        if (!callManager) {
          return;
        }

        this.hasOpenedCall = false;
        this.callInProgress = false;
        callManager.twilioCall.removeListener(VoiceSdkCallEvents.DISCONNECT, this.DISCONNECT_CALL_LISTENER);
        callManager.twilioCall.reject();

        this.clearPendingCall();
      });
  }

  public onDisconnectedEvent(): Observable<CallManager> {
    return this.disconnectedEvent.asObservable();
  }

  public disconnectCall(callback?: (callManager: CallManager) => void): void {
    const callSubject = this.hasOpenedCall ? this.acceptedCall : this.pendingCall;

    callSubject
      .pipe(take(1))
      .subscribe(callManager => {
        if (!callManager) {
          return;
        }

        callManager.twilioCall.removeListener(VoiceSdkCallEvents.DISCONNECT, this.DISCONNECT_CALL_LISTENER);

        this.callInProgress = false;

        callManager.call.duration = this.timerService.stopTimer(callManager.callDurationTimer.id);
        this.disconnectedEvent.next(callManager);
        callManager.twilioCall.disconnect();
        callSubject.next(null);

        if (callback) {
          callback(callManager);
        }
      });
  }

  private setupIncomingCallHandler(): void {
    this.callService.handleIncomingCall(pendingTwilioCall => {
      const participantNumber = pendingTwilioCall.parameters['From'];

      pendingTwilioCall.addListener(VoiceSdkCallEvents.DISCONNECT, this.DISCONNECT_CALL_LISTENER);

      this.callService.getConversationByParticipantNumber(participantNumber)
        .subscribe((conversation) => {
          if (!conversation) {
            return;
          }

          this.registerCallHandlers(pendingTwilioCall);

          const caller = pendingTwilioCall.parameters['From'];
          const callSid = pendingTwilioCall.parameters['CallSid'];

          this.callService.fetchCallWithTwilioIdOrParticipantNumber(callSid, caller, (call) => {
            this.pendingCall.next({
              type: CallType.INCOMING,
              call: call,
              twilioCall: pendingTwilioCall,
              conversation,
            });
          });
        });
    });
  }

  private registerCallHandlers(call: VoiceSdkCall): void {
    call.on(VoiceSdkCallEvents.CANCEL, this.CLEAR_PENDING_CALL_LISTENER);
    call.on(VoiceSdkCallEvents.DISCONNECT, this.CLEAR_PENDING_CALL_LISTENER);
    call.on(VoiceSdkCallEvents.REJECT, this.CLEAR_PENDING_CALL_LISTENER);
  }

  private clearCallHandlers(call: VoiceSdkCall): void {
    call.removeListener(VoiceSdkCallEvents.CANCEL, this.CLEAR_PENDING_CALL_LISTENER);
    call.removeListener(VoiceSdkCallEvents.DISCONNECT, this.CLEAR_PENDING_CALL_LISTENER);
    call.removeListener(VoiceSdkCallEvents.REJECT, this.CLEAR_PENDING_CALL_LISTENER);
  }

  private clearPendingCall(): void {
    this.pendingCall.pipe(take(1)).subscribe((call) => {
      if (call) {
        this.clearCallHandlers(call.twilioCall);
      }

      this.pendingCall.next(null);
    });
  }
}
