import {Component, OnDestroy} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';
import {AuthService} from 'src/app/auth/auth.service';
import {UserService} from './user/user.service';
import { Router } from '@angular/router';
import { PhoneNumberStatus, User } from 'src/generated/graphql';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {

  public user: User;
  public userHasCompletedSetup = false;
  private readonly ON_COMPONENT_DESTROY = new Subject<void>();

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
  ) {
    this.userService.getUser()
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe((user) => {
        this.user = user;
        this.userHasCompletedSetup = user.twilioPhoneNumber && PhoneNumberStatus.APPROVED === user.phoneNumberStatus;

        if (!this.userHasCompletedSetup) {
          this.router.navigate(['account-setup']);
        }
      });
  }

  public ngOnDestroy(): void {
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }
}
