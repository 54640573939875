import { Component, OnDestroy, OnInit } from "@angular/core";
import { Call, CallStatus, CallType, Conversation } from '../../../../../generated/graphql';
import { AvatarSize } from '../../../../shared/avatar/avatar.component';
import * as dayjs from 'dayjs';
import { ApolloError } from '@apollo/client/errors';
import { CallService } from "src/app/shared/services/call.service";
import { ActivatedRoute } from "@angular/router";
import { Subject, takeUntil } from "rxjs";

type OrganizedCall = {
  name: string;
  calls: Call[];
};

@Component({
  selector: 'mc-call-conversation-selected',
  templateUrl: './call-conversation-selected.component.html',
  styleUrls: ['./call-conversation-selected.component.scss']
})
export class CallConversationSelectedComponent implements OnInit, OnDestroy  {
  public readonly AvatarSize = AvatarSize;
  private readonly ON_COMPONENT_DESTROY = new Subject<void>();

  public conversation: Conversation;
  
  public calls: OrganizedCall[] = [];
  public loadingCalls = true;
  public callError: ApolloError;
  
  public CallType = CallType;
  public CallStatus = CallStatus;
  
  constructor(
    private callService: CallService,
    private route: ActivatedRoute,
  ) {}

  
  public ngOnInit(): void {
    this.loadConversationFromRouteParamsConversation();
  }

  public ngOnDestroy(): void {
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }

  public msToNormal(sec: number): string {
    if (!sec || Number.isNaN(sec) || sec === 0) return '-';

    const min = Math.ceil(sec / 60);
    const hr = Math.ceil(min / 60);

    if (sec < 60) return `${sec} seconds`;
    if (min < 60) return `${min} minutes`;
    return `${hr} hours`;
  }

  private loadConversationFromRouteParamsConversation(): void {
    this.route.paramMap
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe((params) => {
        const selectedConversationId = Number(params.get('conversationId'));
        
        if (selectedConversationId) {
          this.loadConversation(selectedConversationId);
        }
      });
  }

  private loadConversation(conversationId: number): void {
    this.callService.getConversationById(conversationId)
    .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe(conversation => {        
        this.conversation = conversation;
        if(!conversation) {
          this.loadingCalls = false;
          return;
        } 
        this.fetchCalls();
      });
  }

  private fetchCalls() {
    this.callService
      .getLatestCallsByConversationId(this.conversation.id)
      .pipe(
        takeUntil(this.ON_COMPONENT_DESTROY)
      ).subscribe((calls) => {
        this.loadingCalls = false;
        if(calls) {
          this.reset();
          this.setCalls(calls);
          this.loadingCalls = false;
        }
      });
  }

  private setCalls(data: Call[]) {
    const organizedCalls = new Map<string, Call[]>();
    const today = dayjs(new Date());
    const yesterday = today.subtract(1, 'day');

    data.forEach(call => {
      const date = dayjs(call.createdAt);
      let key = '';
      if (date.isSame(today, 'day')) {
        key = 'Today';
      } else if (date.isSame(yesterday, 'day')) {
        key = 'Yesterday';
      } else {
        key = 'Other days';
      }

      const calls: Call[] | undefined = organizedCalls.get(key);
      if (calls) {
        calls.push(call);
      } else {
        organizedCalls.set(key, [call]);
      }
    });
    organizedCalls.forEach((val, key) => this.calls.push({ name: key, calls: val }));
  }

  private reset() {
    this.loadingCalls = true;
    this.calls = [];
    this.callError = null;
  }
}