import { Component } from '@angular/core';

@Component({
  selector: 'mc-music-player-skeleton',
  templateUrl: './music-player-skeleton.component.html',
  styleUrls: ['./music-player-skeleton.component.scss']
})
export class MusicPlayerSkeletonComponent {

}
