import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AddNumberComponent } from 'src/app/shared/add-number/add-number.component';
import { AvatarSize } from 'src/app/shared/avatar/avatar.component';
import { MessageTemplatesPickerComponent } from 'src/app/shared/message-templates-picker/message-templates-picker.component';
import { Template } from 'src/app/shared/message-templates-picker/template.service';
import { ContactWithPicture, ContactsService } from 'src/app/shared/services/contacts.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { PhoneNumberFormattingService } from 'src/app/shared/services/phone-number-formatting.service';
import { SelectedMessagingConversationService } from 'src/app/shared/services/selection/selected-messaging-conversation.service';
import { UserService } from 'src/app/user/user.service';
import { Contact, Conversation, Sms, ConversationType } from 'src/generated/graphql';
import { DeleteMessagesComponent } from '../delete-messages/delete-messages.component';
import { SelectedContactService } from 'src/app/shared/services/selection/selected-contact.service';
import { ConversationNavigationService } from 'src/app/shared/services/conversation-navigation.service';
import { TrackingService } from 'src/app/services/tracking-service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';

@Component({
  selector: 'mc-conversation-messages',
  templateUrl: './conversation-messages.component.html',
  styleUrls: ['./conversation-messages.component.scss']
})
export class ConversationMessagesComponent implements OnInit, OnDestroy {
  private readonly ON_COMPONENT_DESTROY = new Subject<void>();
  public readonly AvatarSize = AvatarSize;
  public readonly ConversationType = ConversationType;

  @ViewChild('messagesContainer')
  private messagesContainer: ElementRef;

  @ViewChild('messageInput')
  private messageInputRef: ElementRef;

  public selectedConversation: Conversation;
  public selectedContact: ContactWithPicture;
  public selectedPhoneNumber: string;

  public startingNewConversation = false;
  public usedInvalidPhoneNumber = false;

  public message: string = '';
  public messages: Sms[] = [];

  public loadingMessages = false;

  public isEmojiPickerVisible: boolean;
  public userAvatarUrl: Observable<string>;

  private sendingMessage = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService,
    private contactsService: ContactsService,
    private messagingService: MessagingService,
    private phoneNumberFormattingService: PhoneNumberFormattingService,
    private selectedConversationService: SelectedMessagingConversationService,
    private selectedContactService: SelectedContactService,
    private conversationNavigationService: ConversationNavigationService,
    private trackingService: TrackingService,
  ) {
    this.userAvatarUrl = this.userService.getAvatarUrl();
  }

  public ngOnInit(): void {
    this.loadMessagesFromRouteParamsConversation();
  }

  public ngOnDestroy(): void {
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }

  public getContactImageUrl(contact: ContactWithPicture): string {
    return contact?.pictureUrl;
  }

  public openTemplatePicker(): void {
    this.dialog.open(MessageTemplatesPickerComponent, {
      width: '878px',
    })
      .afterClosed()
      .subscribe((pickedTemplate: Template | undefined) => {
        if (!pickedTemplate) {
          return;
        }

        this.message = this.message.concat(pickedTemplate.content);
      });
    this.trackingService.trackEvent(TrackingEventType.MESSAGES_TEMPLATES);
  }

  public toggleEmojiPicker(): void {
    this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
  }

  public addEmoji(event: { emoji: { native: any; }; }): void {
    this.message = this.message.concat(event.emoji.native);
    this.isEmojiPickerVisible = false;
    this.trackingService.trackEvent(TrackingEventType.MESSAGES_ADD_EMOJI);
  }

  public messageTrackBy(index: number, sms: Sms) {
    return sms.id + '-' + sms.deliveryStatus;
  }

  public sendMessage(): void {
    if (this.sendingMessage) {
      return;
    }

    const trimmedMessage = this.message.trim();
    if (trimmedMessage.length === 0) {
      return;
    }

    const isValidPhoneNumber = this.phoneNumberFormattingService.isValidPhoneNumber(this.selectedPhoneNumber);
    if (!isValidPhoneNumber) {
      this.usedInvalidPhoneNumber = true;
      return;
    }

    const formattedSelectedNumber = this.formatSelectedPhoneNumberToInternationalFormat();

    this.sendingMessage = true;
    this.messagingService.sendMessage(formattedSelectedNumber, trimmedMessage, this.selectedConversation)
      .subscribe({
        next: (message) => {
          if (this.startingNewConversation) {
            this.message = '';
            this.setSelectedConversationId(message.conversationId);
          }

          this.sendingMessage = false;
        },
        error: () => this.sendingMessage = false
      });

    if (!this.startingNewConversation) {
      this.message = '';
    }
  }

  public addNumber(): void {
    this.dialog.open(AddNumberComponent, {
      width: '580px',
      data: this.selectedConversation.participantNumber
    }).afterClosed().subscribe(() => {
      this.loadConversationAndMessages(this.selectedConversation.id);
    });
  }

  public openDeleteConversationModal(): void {
    this.dialog.open(DeleteMessagesComponent, {
      width: '550px',
      data: this.selectedConversation
    }).afterClosed().subscribe((deleted) => {
      if (deleted) {
        this.selectedConversationService.setSelectedItemId(undefined);
        this.openMessagesHomepage();
      }
    });
    this.trackingService.trackEvent(TrackingEventType.MESSAGES_DELETE_CONVERSATION_BUTTON_CLICK);
  }

  public callNumber(): void {
    this.conversationNavigationService.callNumber(this.selectedPhoneNumber);
  }

  public selectContactOrPhoneNumber(contact: string | Contact): void {
    this.usedInvalidPhoneNumber = false;
    if (typeof contact === 'string') {
      this.selectedPhoneNumber = contact;
      return;
    }

    this.messagingService.getConversationByContactId(contact.id).subscribe((conversation) => {
      if (conversation) {
        this.setSelectedConversation(conversation);
        return;
      }

      this.selectContact(contact);
      this.setSelectedConversationId(null);
    });
  }

  public handleOnEnter(): void {
    this.contactsService.getContactByPhoneNumber(this.selectedPhoneNumber)
      .subscribe(contact => {
        if (contact) {
          this.selectContactOrPhoneNumber(contact);
          return;
        }

        this.selectContactOrPhoneNumber(this.selectedPhoneNumber);
        this.messageInputRef.nativeElement.focus();
      });
  }

  public goToContact(): void {
    this.selectedContactService.openContactById(this.selectedConversation.contact?.id);
  }

  public goToSelectedContact(): void {
    this.selectedContactService.openContactById(this.selectedContact?.id);
  }

  public getContactId(): number {
    return this.selectedContact?.id;
  }

  private formatSelectedPhoneNumberToInternationalFormat(): string {
    return this.phoneNumberFormattingService.formatE164(this.selectedPhoneNumber);
  }

  private scrollToTheBottomOfChat(): void {
    this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
  }

  private loadMessagesFromRouteParamsConversation(): void {
    this.route.paramMap
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe((params) => {
        const selectedConversationId = Number(params.get('conversationId'));
        if (selectedConversationId) {
          this.loadConversationAndMessages(selectedConversationId);
        } else {
          this.loadConversationForSelectedContactOrStartANewOne();
        }
      });
  }

  private loadConversationAndMessages(selectedConversationId: number): void {
    this.messagingService.getConversationById(selectedConversationId).subscribe((conversation) => {
      this.selectedConversation = conversation;
      this.loadConversationMessages(this.selectedConversation);
    });
  }

  private loadConversationMessages(conversation: Conversation): void {
    if (!this.selectedConversation) {
      this.openMessagesHomepage();
      this.setSelectedConversation(undefined);
      return;
    }

    this.setSelectedConversation(conversation);
    this.selectedPhoneNumber = this.selectedConversation.participantNumber;
    this.loadConversationMessagesByConversationId(conversation.id);
  }

  private loadConversationForSelectedContactOrStartANewOne(): void {
    this.route.queryParamMap
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe((params) => {
        const selectedContactId = Number(params.get('contactId'));

        if (selectedContactId) {
          this.contactsService.getContactById(selectedContactId)
            .subscribe((contact) => this.selectContactOrPhoneNumber(contact))
        } else {
          this.resetSelectedConversation();
          this.selectedPhoneNumber = params.get('phoneNumber');
        }
      })
  }

  private loadConversationMessagesByConversationId(conversationId: number): void {
    this.loadingMessages = true;
    this.messagingService.getLatestMessagesByConversationId(conversationId)
      .subscribe((messages) => {
        this.messages = [...messages];
        this.loadingMessages = false;
        setTimeout(() => this.scrollToTheBottomOfChat());
      });
  }

  private resetSelectedConversation(): void {
    this.messages = [];
    this.selectedConversation = null;
    this.startingNewConversation = true;
    this.selectContact(null);
  }

  private setSelectedConversation(conversation: Conversation) {
    this.selectContact(conversation?.contact);
    this.setSelectedConversationId(conversation?.id);
  }

  private selectContact(contact: ContactWithPicture): void {
    this.selectedContact = contact;
    this.selectedPhoneNumber = contact?.preferredPhoneNumber;
  }

  private setSelectedConversationId(conversationId: number) {
    if (!conversationId) {
      this.startingNewConversation = true;
    } else {
      this.selectedConversationService.setSelectedItemId(conversationId);
    }
  }

  private openMessagesHomepage() {
    this.router.navigate(['messages']);
  }
}
