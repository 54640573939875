<div id="addEditContact">
  <h1 class="text-2xl font-lato font-normal tracking-wide secondary-color py-4 px-5">{{ contactId ? 'Edit contact' : 'Add a contact' }}</h1>
  <div mat-dialog-content class="border-y p-6">
    <form [formGroup]="contactForm" (keyup.enter)="validateAndSubmit()" class="mb-8 relative">
      <div class="flex items-center w-full pb-5">
        <label for="dropzone-file"
               class="flex flex-col items-center justify-center border rounded-full border-gray-300 bg-gray-50 hover:cursor-pointer profile-photo">
          <div class="flex flex-col items-center justify-center relative rounded-full overflow-hidden">
            <mc-avatar [size]="AvatarSize.LARGE" [avatarUrl]="candidatePictureData ?? pictureUrl"></mc-avatar>
            <div class="absolute bg-blue-250 w-full bottom-0 flex items-center justify-center p-1 opacity-0	transition-opacity hover-image">
              <div class="w-4 h-5">
                <mat-icon class="text-sky-900 text-base max-w-full max-h-full">camera_alt</mat-icon>
              </div>
            </div>
          </div>
          <input id="dropzone-file" type="file" class="hidden" 
          accept="image/webp, image/jpg, image/jpeg, image/png"
          (change)="onImageSelected($event)"/>
        </label>
        <label class="font-lato font-normal secondary-color tracking-wide text-base ml-4 flex flex-col">
          Upload picture
          <span class="text-sm font-lato secondary-color-500">The supported image types are jpeg, png and webp.</span>
        </label>
      </div>
      <mat-error *ngIf="imageFormGroup.hasError('invalidType')" class="bottom-[70px]">File type is not supported. Please select a jpg, png or Webp.
      </mat-error>


      <label class="block relative">
        <span class="font-lato font-normal secondary-color-500 tracking-wide text-sm">Full name</span>
        <input formControlName="fullName" matInput
               class="tracking-wide font-lato secondary-color font-normal block bg-white border border-slate-200 rounded focus:outline-none w-full py-3 px-4"
               placeholder="John Doe" type="text" name="name"/>
               <mat-error *ngIf="contactForm.touched && contactForm.controls.fullName.hasError('required')">Not a valid Full name
              </mat-error>
              <mat-error *ngIf="contactForm.touched &&  contactForm.controls.fullName.hasError('contactExists')">Contact with this name already exists
              </mat-error>
      </label>
    </form>

    <form [formGroup]="phoneNumbersForm" (keyup.enter)="validateAndSubmit()">
      <div formArrayName="phoneNumbers">
        <ng-container *ngFor="let item of phoneNumbersForm.controls.phoneNumbers.controls; let i=index"
                      [formGroupName]="i">
          <div class="flex flex-row gap-4 mt-5">
            <label class="flex flex-col">
              <span class="font-lato font-normal secondary-color-500 text-sm" matTooltip="Preferred number"
                    aria-label="Button that displays a tooltip when focused or hovered over">Pn</span>
              <label class="form__answer">
                <input type="radio" id="i" formControlName="isPreferred" value="guy" (click)="setPreffered (item)">
                <img *ngIf="!item.value.isPreferred" src="../../../../assets/images/icons/star-white.svg" alt="Preferred number white" class="relative bottom-2 cursor-pointer">
                <img *ngIf="item.value.isPreferred" src="../../../../assets/images/icons/star-yellow.svg" alt="Preferred number" class="relative bottom-2 cursor-pointer">
              </label>
            </label>
            <label class="flex flex-col ">
              <span class="font-lato font-normal secondary-color-500 text-sm">Label</span>
              <mat-form-field appearance="outline" class="w-50">
                <mat-select formControlName="label" placeholder="None"
                    class="focus:outline-none border rounded bg-white border-slate-200">
                  <mat-option value="" class="font-lato font-normal secondary-color text-base tracking-wide">None</mat-option>
                  <mat-option value="home" class="font-lato font-normal secondary-color text-base tracking-wide">Home</mat-option>
                  <mat-option value="mobile" class="font-lato font-normal secondary-color text-base tracking-wide">Mobile</mat-option>
                </mat-select>
              </mat-form-field>
            </label>
            <label class="flex flex-col grow relative">
              <span class="font-lato font-normal secondary-color-500 tracking-wide text-sm mb-1">Phone number</span>
              <ngx-mat-intl-tel-input 
                [preferredCountries]="['us', 'ca']" 
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="phone"
                describedBy="phoneNumber"
                format="national"
                inputPlaceholder="(123) 456 7890"
                class="phone-input tracking-wide font-lato secondary-color font-normal block bg-white border border-slate-200 rounded focus:outline-none w-full py-3 px-4"
                formControlName="phoneNumber">
              </ngx-mat-intl-tel-input>
            </label>
            <label *ngIf="(phoneNumbersForm.controls.phoneNumbers.controls.length -1 > 0)"
                   class="flex flex-none ml-auto items-center justify-start mt-5 cursor-pointer" (click)="removeRow(i)">
              <mat-icon class="secondary-color">close</mat-icon>
            </label>
          </div>
          <ng-container *ngIf="item.controls.phoneNumber.invalid && item.controls.phoneNumber.errors">
            <mat-error *ngIf="item.controls.phoneNumber.errors['invalidPhoneNumber']" class="pl-[11.6rem]">
              Not a valid Phone Number
            </mat-error>
            <mat-error
              *ngIf="item.controls.phoneNumber.errors['phoneNumberExists']" class="pl-[11.6rem]">
              Same phone number exists
            </mat-error>
          </ng-container>
        </ng-container>
      </div>

      <a (click)="addRow()" class="flex flex-row items-center cursor-pointer primary-color w-fit mt-3">
        <mat-icon>add_circle</mat-icon>
        <span class="secondary-color-500 underline text-sm font-lato font-light tracking-normal ml-2">Add phone</span>
      </a>
    </form>
  </div>
  <div class="flex justify-between py-3 px-5">
    <button (click)="cancel()"
            class="bg-white border rounded focus:ring-violet-300 hover:bg-gray-250 focus:outline-none py-1 px-5 ">
      <span class="tracking-wide font-lato secondary-color text-sm leading-loose"> Close</span>
    </button>
    <button (click)="validateAndSubmit()" [class.disabled]="contactForm.invalid || phoneNumbersForm.invalid || phoneNumbersForm.controls.phoneNumbers.controls.length === 0"
            class="bg-primary-color border rounded focus:bg-primary-color hover:bg-primary-light-color focus:outline-none py-1 px-5">
      <span class="text-white tracking-wide font-lato font-light text-sm leading-loose">Save</span>
    </button>
  </div>

  <mat-menu #menu="matMenu" class="custom-menu">
    <button mat-menu-item><span class="secondary-color text-base font-normal font-lato">Home</span></button>
    <button mat-menu-item><span class="secondary-color text-base font-normal font-lato">Mobile</span></button>
    <button mat-menu-item><span class="secondary-color text-base font-normal font-lato">Work</span></button>
    <button mat-menu-item><span class="secondary-color text-base font-normal font-lato">Other</span></button>
  </mat-menu>
</div>
