import { Component, Input, OnInit } from "@angular/core";
import { CallStatus, CallType, Conversation } from '../../../../generated/graphql';
import { AvatarSize } from '../../../shared/avatar/avatar.component';
import * as dayjs from 'dayjs';
import { ContactWithPicture } from "src/app/shared/services/contacts.service";

@Component({
  selector: 'mc-messages-call-conversation-list-item',
  templateUrl: './call-conversation-list-item.component.html',
  styleUrls: ['./call-conversation-list-item.component.scss']
})
export class MessageCallConversationListItemComponent implements OnInit {
  @Input()
  public conversation: Conversation;

  public CallType = CallType;
  public CallStatus = CallStatus;

  public readonly AvatarSize = AvatarSize;

  public timestamp: string;

  public ngOnInit(): void {
    this.timestamp = this.computeTimestamp();
  }

  public computeTimestamp(): string {
    const today = dayjs(new Date());
    const date = dayjs(this.conversation.updatedAt);
    if (date.isSame(today, 'day')) return date.format('h:mm a');
    if (date.isSame(today, 'week')) return date.format('dddd');
    if (date.isSame(today, 'year')) return date.format('MMM D')
    return date.format('L');
  }

  public get contactImageUrl(): string {
    const contact = this.conversation.contact as ContactWithPicture;
    return contact?.pictureUrl;
  }
}
