<mat-sidenav-content class="bg-white w-full h-full" id="messagesPage">
  <mat-sidenav-container autosize class="h-full bg-white">
    <mat-sidenav opened mode="side" class="right-gray-border w-[250px] md:w-[350px]">
      <div class="bottom-gray-border px-7 py-4 flex flex-row items-center justify-center bg-white top-0 sticky">
        <button (click)="startNewConversation()" mat-stroked-button class="main-button cursor-pointer shadow w-full hover:bg-blue-250">
          <img src="assets/images/icons/pen.svg" class="mr-3 w-[22px]"/>
          <span class="font-lato text-base font-normal">Send a message</span>
        </button>
      </div>

      <!-- Loading messages section -->
      <div *ngIf="loadingConversations" class="w-full max-w-md bg-white flow-root">
        <p class="py-3 px-7 secondary-color text-base md:text-lg">Messages</p>
        <mc-skeleton></mc-skeleton>
      </div>

      <ng-container *ngIf="!loadingConversations">
        <ng-container *ngIf="!conversations.length">
          <div class="absolute top-[60px] bottom-0 left-0 right-0 flex items-center justify-center px-8">
            <p class="font-lato text-base secondary-color pb-[60px]">No messages</p>
          </div>
        </ng-container>

        <ng-container *ngIf="conversations.length">
          <!-- Existing conversations section -->

          <div class="w-full max-w-md bg-white flow-root">
            <p class="py-3 px-7 secondary-color text-base md:text-lg">Messages</p>

            <ul role="list">
              <li
                *ngFor="let conversation of conversations"
                (click)="openConversation(conversation.id)"
                class="py-4 px-7 hover:bg-gray-150 cursor-pointer border-b border-gray-100"
                [ngClass]="{'bg-gray-350': selectedConversationId === conversation.id}">
                <mc-messages-conversation-list-item
                  [conversation]="conversation">
                </mc-messages-conversation-list-item>
              </li>
            </ul>
          </div>
        </ng-container>
      </ng-container>
    </mat-sidenav>

    <router-outlet></router-outlet>
    
  </mat-sidenav-container>
</mat-sidenav-content>