import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, switchMap, take } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private readonly AUTHORIZATION_HEADER_KEY = 'Authorization';
  private readonly AUTHORIZATION_TOKEN_PREFIX = 'Bearer ';

  constructor(private authService: AuthService) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.getToken().pipe(
      switchMap((token) => {
          const authReq = req.clone({
            headers: req.headers.set(this.AUTHORIZATION_HEADER_KEY, this.AUTHORIZATION_TOKEN_PREFIX + token)
          });

          return next.handle(authReq);
        }
      )
    );
  }

  private getToken(): Observable<string> {
    return this.authService.getJwtToken();
  }
}
