import { Component, OnDestroy, OnInit } from "@angular/core";
import { Conversation } from '../../../../generated/graphql';
import { AvatarSize } from '../../../shared/avatar/avatar.component';
import { CallService } from "src/app/shared/services/call.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { ContactWithPicture } from "src/app/shared/services/contacts.service";
import { SelectedCallingConversationService } from "src/app/shared/services/selection/selected-call-conversation.service";
import { CallManagerService } from "src/app/shared/services/call-manager.service";
import { AddNumberComponent } from "src/app/shared/add-number/add-number.component";
import { MatDialog } from "@angular/material/dialog";
import { ConversationNavigationService } from "src/app/shared/services/conversation-navigation.service";
import { DeleteCallComponent } from "./delete-conversation/delete-calls.component";
import { TrackingService } from "src/app/services/tracking-service";
import { TrackingEventType } from "src/app/util/tracking-event-type";
import { SelectedContactService } from "src/app/shared/services/selection/selected-contact.service";

@Component({
  selector: 'mc-messages-call-conversation',
  templateUrl: './call-conversation.component.html',
  styleUrls: ['./call-conversation.component.scss']
})
export class CallConversationPageComponent implements OnInit, OnDestroy {

  private readonly ON_COMPONENT_DESTROY = new Subject<void>();
  public readonly AvatarSize = AvatarSize;

  public selectedConversation?: Conversation = undefined;
  public invalidConversation = false;
  public loadingConversation = true;
  public callInProgress: boolean;
  
  public conversations : Conversation[];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private callService: CallService,
    private selectedContactService: SelectedContactService,
    private conversationNavigationService: ConversationNavigationService,
    private callManagerService: CallManagerService,
    private selectedCallingConversationService: SelectedCallingConversationService,
    private trackingService: TrackingService
  ) {}

  public get selectedConversationContactPicture(): string {
    const contact = this.selectedConversation?.contact as ContactWithPicture;
    return contact?.pictureUrl;
  }

  public ngOnInit(): void {
    this.loadConversationFromRouteParamsConversation();

    this.callManagerService.getAcceptedCall()
    .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
    .subscribe(call => {
      if(call) {
        this.callInProgress = true;
        return;
      }      
      this.callInProgress = false;
    });
  }
 
  public ngOnDestroy(): void {
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }

  public goToContact(): void {
    this.selectedContactService.openContactById(this.selectedConversation.contact?.id);
  }

  public navigateToSmsConversationId(): void {
    this.conversationNavigationService.navigateToMessagingConversation(this.selectedConversation.participantNumber);
  }

  public deleteConversation(): void {
    this.dialog.open(DeleteCallComponent, {
      width: '550px',
      data: this.selectedConversation
    }).afterClosed().subscribe(()=> {
      this.selectedCallingConversationService.setSelectedItemId(undefined);
      this.router.navigate(['calls']);
    });
    this.trackingService.trackEvent(TrackingEventType.CALLS_DELETE_CONVERSATION_BUTTON_CLICK);
  }
  
  public addNumber(): void {
    this.dialog.open(AddNumberComponent, {
      width: '580px',
      data: this.selectedConversation.participantNumber
    });
  }

  private loadConversationFromRouteParamsConversation(): void {
    this.route.paramMap
      .subscribe((params) => {
        const selectedConversationId = Number(params.get('conversationId'));
        if (selectedConversationId) {
          this.selectedCallingConversationService.setSelectedItemId(selectedConversationId);

          this.invalidConversation = false;
          this.loadingConversation = true;  

          this.loadConversation(selectedConversationId);
        }
      });
  }

  private loadConversation(conversationId: number): void {
    this.callService.getConversationById(conversationId)
      .subscribe(conversation => {
        this.selectedConversation = conversation;
      });
  }
}