

<div class="call-list relative">
  <div class="py-3 px-7 relative z-10">
    <p class="text-lg font-lato font-medium secondary-color">Call history</p>
  </div>
  
  <div
    *ngIf="loadingCalls"
    class="w-full h-full"
    style="height: 20vh">
    <mc-call-details-skeleton></mc-call-details-skeleton>
  </div>
  
  <div
    *ngIf="!loadingCalls && !calls.length"
    class="flex justify-center items-center w-full h-full absolute inset-0 px-7">
    <p>No calls</p>
  </div>
  
  <div *ngFor="let c of calls" class="flex flex-col pt-4 px-7">
    <p class="font-lato text-base font-normal secondary-color">{{ c.name }}</p>
  
    <div *ngFor="let call of c.calls" class="flex flex-row items-center gap-x-3 pr-4">
      <div class="pr-2">
        <ng-container *ngIf="call.callType === CallType.INCOMING">
          <mat-icon
            *ngIf="call.status === CallStatus.NO_ANSWER"
            class="big-icon missed-call-icon">call_missed</mat-icon>
  
          <mat-icon
            *ngIf="call.status !== CallStatus.NO_ANSWER"
            class="big-icon incoming-call-icon">call_received</mat-icon>
        </ng-container>
  
        <ng-container *ngIf="call.callType === CallType.OUTGOING">
          <mat-icon
            *ngIf="call.status === CallStatus.NO_ANSWER"
            class="big-icon missed-call-icon">call_missed_outgoing</mat-icon>
  
          <mat-icon
            *ngIf="call.status !== CallStatus.NO_ANSWER"
            class="big-icon outgoing-call-icon">call_made</mat-icon>
        </ng-container>
      </div>
      <div class="flex flex-row py-3 gap-x-3 w-full justify-between items-end border-b">
        <div class="flex flex-row">
          <div class="flex flex-col">
            <p class="font-lato text-base font-normal secondary-color">
              <ng-container *ngIf="call.callType === CallType.INCOMING">
                Incoming call
              </ng-container>
  
              <ng-container *ngIf="call.callType === CallType.OUTGOING">
                Outgoing call
              </ng-container>
            </p>
            <p class="font-lato text-sm font-normal secondary-color-500">
              {{ call.createdAt | date: 'MMMM d, y' }}, 
              <span class="primary-light-color ml-2 text-xs">{{ call.createdAt | date: 'h:mm a' }}</span>
            </p>
          </div>
        </div>
        <div class="flex flex-col text-right">
          <p class="font-lato text-[13px] font-normal secondary-color-500">
            {{ msToNormal(call.duration) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>