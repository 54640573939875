import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { OnDestroy, OnInit } from '@angular/core';
import { AvatarSize } from 'src/app/shared/avatar/avatar.component';
import { UserService } from 'src/app/user/user.service';
import { User } from 'src/generated/graphql';
import { UpdateMyProfileGQL } from '../../../generated/graphql';
import { fileToBase64 } from 'src/app/util/imageUploads';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { PhoneNumberFormattingService } from 'src/app/shared/services/phone-number-formatting.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VerifyCodeDialogComponent } from './verify-code-dialog/verify-code-dialog.component';
import { TrackingService } from 'src/app/services/tracking-service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {

  private readonly ON_COMPONENT_DESTROY = new Subject<void>();
  private readonly title = 'Hello Grove - My profile';
  private readonly PHONE_NUMBER_PATTERN = '[- +()0-9]{10,}';
  public readonly AvatarSize = AvatarSize;

  public user: User;
  public selectedAvatarFile: File;
  public changesSaved = false;
  public loading = false;
  public form: FormGroup;

  private initialPhonenumber: string;

  constructor(
    private titleService: Title,
    private userService: UserService,
    public dialog: MatDialog,
    private updateProfileMutation: UpdateMyProfileGQL,
    private phoneNumberFormattingService: PhoneNumberFormattingService,
    private trackingService: TrackingService,
    private notificationService: NotificationService,
  ) { }

  public ngOnInit(): void {
    this.titleService.setTitle(this.title);

    this.userService.getUser()
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe(user => {
        this.user = user;

        this.form = new FormGroup({
          firstName: new FormControl(user.firstName, [
            Validators.required,
          ]),
          lastName: new FormControl(user.lastName, [
            Validators.required,
          ]),
          avatarBase64: new FormControl(''),
          phoneNumber: new FormControl(user.phoneNumber, [
            this.phoneNumberFormattingService.phoneNumberValidator(),
          ]),
          twilioPhoneNumber: new FormControl(user.twilioPhoneNumber, [
            Validators.pattern(this.PHONE_NUMBER_PATTERN),
          ]),
        });

        this.initialPhonenumber = user.phoneNumber;
      });
  }

  public get invalidForm(): boolean {

    return !this.form.dirty || this.form.invalid || this.loading;
  }
  public get firstName() {
    return this.form.get('firstName');
  }
  public get lastName() {
    return this.form.get('lastName');
  }
  public get phoneNumber() {
    return this.form.get('phoneNumber');
  }
  public get twilioPhoneNumber() {
    return this.form.get('twilioPhoneNumber');
  }
  public get avatarBase64() {
    return this.form.get('avatarBase64');
  }

  public get avatar(): string {
    if (this.selectedAvatarFile && this.avatarBase64.value) {
      return `data:${this.selectedAvatarFile.type};base64,${this.avatarBase64.value}`;
    }

    return this.userService.buildUserAvatarUrl(this.user);
  }

  public uploadAvatar($event: any): void {
    this.selectedAvatarFile = $event.target.files[0];
    fileToBase64(this.selectedAvatarFile).subscribe(base64 => {
      this.avatarBase64.setValue(base64);
      this.avatarBase64.markAsDirty();
    });
  }

  public saveChanges(): void {
    if (!this.form.dirty || this.form.invalid) {
      return;
    }

    if (this.phoneNumber.value !== this.initialPhonenumber) {
        this.verifyCode().afterClosed().subscribe((verified) => {
          if (verified) {
            this.changesSaved = true;
            this.userService.reloadUser();
            this.notificationService.showSuccess('Profile updated successfully', 'Success');
          }
        })   
    } else {
      this.updateUser();
    }
  }

  private updateUser(): void {
    this.loading = true;
    this.updateProfileMutation.mutate({
      value: {
        firstName: this.firstName.value,
        lastName: this.lastName.value,
        avatarBase64: this.avatarBase64.value,
      }
    }).subscribe(({ errors, loading }) => {
      this.loading = loading;
      if (errors) {
        this.trackingService.trackEvent(TrackingEventType.PROFILE_SAVE_FAILURE);
        this.notificationService.showError('Can\'t update profile', 'Fail');
        console.error(errors);
        return;
      }

      this.changesSaved = true;
      this.trackingService.trackEvent(TrackingEventType.PROFILE_SAVE_SUCCESS);
      this.userService.reloadUser();
      this.notificationService.showSuccess('Profile updated successfully', 'Success');
    });
  }

  public ngOnDestroy(): void {
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }

  public verifyCode(): MatDialogRef<VerifyCodeDialogComponent> {
    this.trackingService.trackEvent(TrackingEventType.PROFILE_CHANGE_PHONE_NUMBER);
    return this.dialog.open(VerifyCodeDialogComponent, {
      width: '450px',
      data: {
        firstName: this.firstName.value,
        lastName: this.lastName.value,
        avatarBase64: this.avatarBase64.value,
        phoneNumber: this.phoneNumber.value,
      }
    });
  }
}
