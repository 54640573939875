import { Component, OnDestroy, OnInit } from "@angular/core";
import { AvatarSize } from '../../../../shared/avatar/avatar.component';
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, take, takeUntil } from "rxjs";
import { ContactWithPicture } from "src/app/shared/services/contacts.service";
import { CallManager, CallManagerService } from '../../../../shared/services/call-manager.service';
import { SelectedCallingConversationService } from "src/app/shared/services/selection/selected-call-conversation.service";
import { CallService, VoiceSdkCallEvents } from '../../../../shared/services/call.service';

@Component({
  selector: 'mc-messages-incoming-call',
  templateUrl: './incoming-call.component.html',
  styleUrls: ['./incoming-call.component.scss']
})
export class IncomingCallPageComponent implements OnInit, OnDestroy {

  private readonly ON_COMPONENT_DESTROY = new Subject<void>();
  public readonly AvatarSize = AvatarSize;

  public callInProgress: boolean;
  public loading = true;
  public callManager: CallManager;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private callManagerService: CallManagerService,
    private selectedCallingConversationService: SelectedCallingConversationService,
    private callService: CallService,
  ) {
  }

  public ngOnInit(): void {
    this.callInProgress = true;
    this.loadConversationFromRoute();

    this.callManagerService.onDisconnectedEvent()
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe(callManager => {
        this.callService.updateCachedCall(this.callManager.call.id, this.callManager.conversation.id, {
          duration: callManager.call.duration
        });

        this.callInProgress = false;
        setTimeout(() => this.selectedCallingConversationService.goBackToConversationPage(callManager.conversation.id), 1000);
      });
  }

  public ngOnDestroy(): void {
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }

  public get callLoaded(): boolean {
    return !this.loading && this.callManager != null;
  }

  public hangupCall(): void {
    this.callManagerService.disconnectCall();
  }

  public get conversationContactPicture(): string {
    const contact = this.callManager.conversation?.contact as ContactWithPicture;
    return contact?.pictureUrl;
  }

  private loadConversationFromRoute(): void {
    this.route.paramMap
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe((params) => {
        const conversationId = Number(params.get('conversationId'));
        this.selectedCallingConversationService.setSelectedItemId(conversationId);

        this.callService.getConversationById(conversationId)
          .pipe(take(1))
          .subscribe(conversation => {
            if (!conversation) {
              this.router.navigate(['calls']);
              return;
            }

            // start call once conversation is ready
            this.callManagerService.getAcceptedCall()
              .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
              .subscribe(callManager => {
                if (!callManager) return;

                this.callManager = callManager;
                this.loading = false;
              })
          });
      });
  }
}
