export const WS_CONNECTION_RETRY_WAITING_TIME_IN_MS = 5000;

export const calculateReconnectingRetryTimeToWaitInSeconds = (retryAttempts: number) => {
  return WS_CONNECTION_RETRY_WAITING_TIME_IN_MS + (retryAttempts * 1000);
}

export const waitForReconnectingTime = (retryAttempts: number): Promise<void> => {
  return new Promise((resolve) => {
    const secondsToWait = calculateReconnectingRetryTimeToWaitInSeconds(retryAttempts);
    setTimeout(resolve, secondsToWait);
  });
};
