import { Component, Input } from '@angular/core';

export enum AvatarSize {
  EXTRA_SMALL = "EXTRA_SMALL",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

@Component({
  selector: 'mc-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  @Input()
  public size: AvatarSize = AvatarSize.SMALL;

  public readonly AvatarSize = AvatarSize;

  public readonly placeholderUrl: string = '../assets/images/icons/blue-avatar.svg';

  @Input()
  public avatarUrl: string;
}
