import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { AccountSetupComponent } from './account-setup.component';
import { AuthGuard } from '../../auth/auth.guard';
import { PhoneNumberVerificationGuard } from 'src/app/guards/phone-number-verification.guard';

const routes: Routes = [
  {
    path: "account-setup",
    component: AccountSetupComponent,
    canActivate: [AuthGuard, PhoneNumberVerificationGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AccountSetupRoutingModule {
}
