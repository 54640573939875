import { Injectable } from '@angular/core';
import {
    CreateVoicemailGreetingGQL,
    CreateVoicemailGreetingInput,
    DeleteVoicemailGreetingGQL,
    DeleteVoicemailGreetingInput,
    UpdateVoicemailGreetingGQL,
    UpdateVoicemailGreetingInput,
    VoicemailGreeting,
    VoicemailGreetingsGQL
} from "../../generated/graphql";
import { ReplaySubject, Observable, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { UserService } from '../user/user.service';

export interface VoicemailGreetingWithRecording extends VoicemailGreeting {
  recordingUrl: string
}

@Injectable({
  providedIn: 'root',
})
export class VoicemailGreetingService {

private voicemailGreetingSubject = new ReplaySubject<VoicemailGreetingWithRecording[]>(1);
private voicemailGreetingHaveBeenLoaded = false;

constructor(
    private voicemailGreetingsGQL: VoicemailGreetingsGQL,
    private userService : UserService,
    private updateVoicemailGreetingMutation: UpdateVoicemailGreetingGQL,
    private deleteVoicemailGreetingGQL: DeleteVoicemailGreetingGQL,
    private createVoicemailGreetingMutation: CreateVoicemailGreetingGQL) {
}

  public getVoicemailGreetingsOfUser(): Observable<VoicemailGreetingWithRecording[]> {
    if (this.voicemailGreetingHaveBeenLoaded) {
      return this.voicemailGreetingSubject.asObservable();
    }
    return this.loadVoicemailGreetingsOfUser();
  }

  public createVoicemailGreeting(createVoicemailGreetingInput: CreateVoicemailGreetingInput): Observable<VoicemailGreeting> {
    return this.createVoicemailGreetingMutation.mutate({
      value: {
        name: createVoicemailGreetingInput.name,
        voicemailGreeting: createVoicemailGreetingInput.voicemailGreeting,
        duration: createVoicemailGreetingInput.duration
      }
    }).pipe(map(data => {
      return data.data.createVoicemailGreeting;
    }))
  }

  public updateVoicemailGreeting(updateVoicemailGreetingInput: UpdateVoicemailGreetingInput): Observable<VoicemailGreeting> {
    return this.updateVoicemailGreetingMutation.mutate({
      value: {
        ...updateVoicemailGreetingInput
      }
    }).pipe(map(data => {
      return data.data.updateVoicemailGreeting;
    }));
  }

  public deleteVoicemailGreeting(deleteVoicemailGreetingInput: DeleteVoicemailGreetingInput): Observable<VoicemailGreeting> {
    return this.deleteVoicemailGreetingGQL.mutate({
        value: {
            voicemailGreetingId: deleteVoicemailGreetingInput.voicemailGreetingId
        }
    }).pipe(map((data) => data.data.deleteVoicemailGreeting));

  }

  public reloadGreetings(): Observable<VoicemailGreeting[]> {
    this.voicemailGreetingHaveBeenLoaded = false;
    return this.loadVoicemailGreetingsOfUser();
  }


  private loadVoicemailGreetingsOfUser(): Observable<VoicemailGreetingWithRecording[]> {
    return this.voicemailGreetingsGQL.fetch().pipe(
      switchMap(data => 
        this.userService.getCurrentUser()
        .pipe(
          switchMap(user => {
            const voicemailGreetingsWithRecording: VoicemailGreetingWithRecording[] = data.data.voicemailGreetings.map(voicemailGreeting => {
              return {
                ...voicemailGreeting,
                recordingUrl: `/api/storage/user/${user.id}/voicemail-greeting/${voicemailGreeting.id}`
              }
            });

            this.voicemailGreetingHaveBeenLoaded = true;
            this.voicemailGreetingSubject.next(voicemailGreetingsWithRecording);
            return this.voicemailGreetingSubject.asObservable();
         }))
      ));
  }
}
