import { Component } from '@angular/core';
import { AccountSetupStep, AccountSetupStepComponent } from '../account-setup-step.component';

@Component({
  selector: 'mc-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent extends AccountSetupStepComponent {

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public handleStepSelection(): void {
    super.handleStepSelection();
    this.hideBackwardButton();
    this.updateCurrentStepValidity(true);
  }
  
  public get step(): AccountSetupStep {
    return AccountSetupStep.WELCOME_PAGE;
  }

}
