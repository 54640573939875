import { NgModule } from '@angular/core';
import { PhoneNumberPipe } from './phone-number.pipe';
import { SecondsToMinutesPipe } from "./seconds-to-minute-pipe";
import { ContactPreferredPhoneNumberPipe  } from './contact-preffered-phone-number.pipe';

export const PIPES = [
  PhoneNumberPipe,
  SecondsToMinutesPipe,
  ContactPreferredPhoneNumberPipe 
];

@NgModule({
  providers: PIPES,
  declarations: PIPES,
  exports: PIPES,
})
export class PipesModule {
}
