import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule as Auth0Module } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { AuthInterceptor } from './auth.interceptor';
import { SecurePipe } from './secure.pipe';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  declarations: [SecurePipe],
  imports: [
    CommonModule,
    Auth0Module.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientID,
      audience: environment.auth0.audience,
      scope: 'openid profile email everything',
    }),
  ],
  exports: [SecurePipe],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
})
export class AuthModule {}
