import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TrackingService } from 'src/app/services/tracking-service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';
import { GraphQlError, SendSmsVerificationCodeGQL, UpdateMyProfileGQL } from 'src/generated/graphql';

@Component({
  selector: 'mc-verify-code-dialog',
  templateUrl: './verify-code-dialog.component.html',
  styleUrls: ['./verify-code-dialog.component.scss']
})
export class VerifyCodeDialogComponent implements OnInit {
  private readonly CODE_LENGTH = 4;

  @ViewChildren('codeNumber,codeNumber2,codeNumber3,codeNumber4')
  public codeNumbers: QueryList<ElementRef>;

  public verificationFailed: boolean;
  public hasErrorsInEnteredCode: boolean;
  public verificationSent: boolean;

  public hasAttemptedToProceedWithAnInvalidPhoneNumber = false;
  public verificationMessageSendLimitError = false;

  private phoneNumber: string;
  private firstName: string;
  private lastName: string;
  private avatarBase64: string;
  private code: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private trackingService: TrackingService,
    private sendSMSVerificationCodeGQL: SendSmsVerificationCodeGQL,
    private updateProfileMutation: UpdateMyProfileGQL,
    private dialogRef: MatDialogRef<VerifyCodeDialogComponent>
  ) {
    this.phoneNumber = data.phoneNumber;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.avatarBase64 = data.avatarBase64;
  }

  public ngOnInit(): void {
    this.sendSMSVerificationCodeGQL.mutate({
      value: {
        phoneNumber: this.phoneNumber
      }
    }).subscribe((response) => {
      if (response.errors && response.errors.length > 0) {
        const error = response.errors[0];
        if (error.extensions['code'] === GraphQlError.TOO_MANY_REQUESTS) {
          this.verificationMessageSendLimitError = true;
        } else if (error.extensions['code'] === GraphQlError.BAD_REQUEST) {
          this.hasAttemptedToProceedWithAnInvalidPhoneNumber = true;
        }
      } else {
        this.verificationMessageSendLimitError = false;
        this.hasAttemptedToProceedWithAnInvalidPhoneNumber = false;
      }
    });
  }

  public moveCaretToInput(input: HTMLInputElement): void {
    input.focus();
  }

  public verifyPhoneNumber(): void {
    if (this.verificationMessageSendLimitError || this.hasAttemptedToProceedWithAnInvalidPhoneNumber) {
      return;
    }

    this.code = this.buildCodeFromInputs();
    this.hasErrorsInEnteredCode = false;
    this.verificationFailed = false;

    if (!this.isFormControlValid()) {
      this.hasErrorsInEnteredCode = true;
      this.trackingService.trackEvent(TrackingEventType.PROFILE_CHANGE_PHONE_NUMBER_FAILURE);
      return;
    }

    this.updateProfileMutation.mutate({
      value: {
        firstName: this.firstName,
        lastName: this.lastName,
        avatarBase64: this.avatarBase64,
        phoneNumber: this.phoneNumber,
        verificationCode: this.code
      }
    }).subscribe(({ errors }) => {
      if (errors) {
        this.verificationFailed = true;
        this.trackingService.trackEvent(TrackingEventType.PROFILE_CHANGE_PHONE_NUMBER_FAILURE);
        return;
      }

      this.trackingService.trackEvent(TrackingEventType.PROFILE_CHANGE_PHONE_NUMBER_SUCCESS);
      this.close(true);
    });
  }

  public resendCode(): void {
    if (this.verificationMessageSendLimitError || this.hasAttemptedToProceedWithAnInvalidPhoneNumber) {
      return;
    }

    this.sendSMSVerificationCodeGQL.mutate({
      value: {
        phoneNumber: this.phoneNumber
      }
    }).subscribe(() => {
      this.verificationSent = true;
    })
  }

  public close(verified?: boolean): void {
    this.dialogRef.close(verified);
  }

  private isFormControlValid(): boolean {
    return this.code.length === this.CODE_LENGTH;
  }

  private buildCodeFromInputs(): string {
    return this.codeNumbers.map((codeElement) => codeElement.nativeElement.value).join('');
  }
}
