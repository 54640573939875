import { NgModule } from '@angular/core';
import {VoicemailGreetingModalFactoryService as GreetingsModalService} from "./greetings-modal-service";
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { DeleteGreetingModalComponent } from './delete-greeting-modal/delete-greeting-modal.component';
import { GreetingModalComponent } from './greeting-modal/greeting-modal.component';
import { SetActiveModalComponent } from './set-active-modal/set-active-modal.component';

@NgModule({
  providers:[GreetingsModalService],
  declarations: [
    GreetingModalComponent,
    SetActiveModalComponent,
    DeleteGreetingModalComponent,
  ],
  imports: [
    MatDialogModule,
    FormsModule,
    SharedModule
  ]
})
export class GreetingsModalModule { }
