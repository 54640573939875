<mat-sidenav-content class="bg-white w-full h-full" id="callsPage">
  <mat-sidenav-container autosize class="h-full bg-white">
    <mat-sidenav opened mode="side" class="right-gray-border w-[250px] md:w-[350px]">
      <div class="bottom-gray-border px-7 py-4 flex flex-row items-center justify-center bg-white top-0 sticky">
        <button [disabled]="callingUnavailable" [class.disabled]="callingUnavailable" mat-stroked-button class="main-button cursor-pointer shadow w-full hover:bg-blue-250" [routerLink]="'/calls/dial/'" (click)="trackMakeACallButton()">
          <img src="assets/images/icons/dial.svg" class="mr-3 w-[22px]" />
          <span class="font-lato text-base font-normal">Make a call</span>
        </button>
      </div>

      <!-- Skeleton -->
      <mc-skeleton *ngIf="loadingConversations"></mc-skeleton>

      <!-- No calls section -->
      <div class="absolute top-[60px] bottom-0 left-0 right-0 flex items-center justify-center px-7" *ngIf="!loadingConversations && !conversations.length">
        <p class="font-lato text-base secondary-color pb-[60px]">No calls</p>
      </div>

      <!-- Existing calls section -->
      <div class="w-full max-w-md bg-white flow-root" *ngIf="!loadingConversations && conversations.length">
        <p class="py-3 px-7 font-lato font-normal secondary-color text-base md:text-lg">Recents</p>

        <ul role="list">
          <li
            *ngFor="let conversation of conversations"
            (click)="openCallConversation(conversation)"
            class="py-4 px-7 hover:bg-gray-150 cursor-pointer border-b border-gray-100"
            [ngClass]="{'bg-gray-350': selectedConversationId === conversation.id}">
            <mc-messages-call-conversation-list-item
              [conversation]="conversation"></mc-messages-call-conversation-list-item>
          </li>
        </ul>
      </div>
    </mat-sidenav>

    <router-outlet></router-outlet>

  </mat-sidenav-container>
</mat-sidenav-content>
