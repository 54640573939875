<div *ngIf="conversation">
  <div class="flex flex-row items-center justify-end p-4">
    <div class="bg-gray-200 light-red-border-1 rounded px-3 py-2 font-lato font-normal text-base">
      <span>Calling as:</span>
      <span class="primary-color ml-1">
        {{ conversation.userNumber | phoneNumber }}
      </span>
    </div>
  </div>
  <div class="flex flex-col items-center justify-center my-14 m-auto gap-y-9 w-12/12 sm:w-8/12">
    <div class="flex flex-col items-center gap-y-3">
      <mc-avatar [size]="AvatarSize.LARGE" [avatarUrl]="contactImageUrl"></mc-avatar>
      <p class="font-lato text-lg font-normal secondary-color text-center">
        <ng-container *ngIf="conversation.contact">
          {{ conversation.contact.fullName }}
        </ng-container>
        
        <ng-container *ngIf="!conversation.contact">
          {{ conversation.participantNumber | phoneNumber }}
        </ng-container>
      </p>
      <p class="font-lato text-base font-normal primary-color">
        {{ seconds | secondsToMinutes }}
      </p>
      <p class="font-lato text-base font-normal primary-color">
        {{ currentCallStatus }}
      </p>
    </div>

    <div class="border-t w-full pt-6 flex flex-row items-center justify-center gap-x-7">
      <div class="relative">
        <div (click)="toggleMuteCall()" class="flex flex-col items-center gap-y-3 cursor-pointer">
          <button mat-stroked-button class="bg-gray-200 w-11 h-11 rounded-full justify-center items-center flex">
            <img *ngIf="isCallMuted" class="w-5 h-5" src="../../../../../assets/images/icons/mute-call.svg">
            <img *ngIf="!isCallMuted" class="w-5 h-5" src="../../../../../assets/images/icons/unmute-call.svg">
          </button>
          <span class="font-lato text-sm font-light secondary-color text-center w-[60px]">
            <ng-container *ngIf="isCallMuted">Unmute</ng-container>
            <ng-container *ngIf="!isCallMuted">Mute</ng-container>
          </span>
        </div>

        <button 
          class="bg-gray-200 hover:bg-gray-300 absolute top-[-4px] right-[-1px] flex justify-center items-center rounded-full border-white border-2 w-[23px] h-[23px]" 
          [matMenuTriggerFor]="audioDeviceMenu">
          <mat-icon class="primary-color text-base" >arrow_drop_up</mat-icon>
        </button>

        <mat-menu #audioDeviceMenu="matMenu" yPosition="above" class="custom-call-menu">
          <button 
            class="custom-menu-btn"
            *ngFor="let device of audioDevices" 
            (click)="setNewAudioDevice(device)"
            [ngClass]="{'secondary-color': isAudioDeviceSelected(device.deviceId)}"
            mat-menu-item>
            <span 
              class="font-lato text-sm"
              [ngClass]="{'primary-color': isAudioDeviceSelected(device.deviceId)}">
              {{ device.label }}
            </span>
          </button>
        </mat-menu>
      </div>

      <div (click)="endCall()" class="flex flex-col items-center gap-y-3 cursor-pointer">
        <button mat-stroked-button class="bg-red-500 w-11 h-11 rounded-full justify-center items-center flex">
          <img class="w-6 h-6" src="../../../../../assets/images/icons/end-call.svg">
        </button>
        <span class="font-lato text-sm font-light secondary-color">End call</span>
      </div>
    </div>
  </div>
</div>