<div class="h-full flex flex-col" style="overflow-y: hidden;">
  <ng-container *ngIf="user else loading">
    <mc-header [hasUserCompletedAccountSetup]="userHasCompletedSetup"></mc-header>
    <div class="flex h-full">
      <mat-sidenav-container class="w-full bg-white">
        <router-outlet></router-outlet>
      </mat-sidenav-container>
    </div>
    <mc-incoming-call-widget></mc-incoming-call-widget>
    <mc-ongoing-call-dialog></mc-ongoing-call-dialog>
  </ng-container>
  <ng-template #loading></ng-template>
</div>
