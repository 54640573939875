import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { PhoneNumber } from "src/generated/graphql";
import { PhoneNumberFormattingService } from "../services/phone-number-formatting.service";
import { ContactWithPicture, ContactsService } from "../services/contacts.service";

@Component({
    template: ''
})
export abstract class ContactsPhoneNumbersManager {
    protected readonly FULL_NAME_REGEX = /^[\p{L}\s]{2,100}$/iu;

    public pictureUrl = '../../../assets/images/icons/blue-avatar.svg';

    protected readonly contactForm = this.formBuilder.group({
        fullName: ['', [Validators.required, this.contactNameValidator(), Validators.pattern(this.FULL_NAME_REGEX)]],
    });

    protected phoneNumbersForm = new FormGroup({
        phoneNumbers: new FormArray([
            new FormGroup({
                id: new FormControl(undefined),
                isPreferred: new FormControl(false),
                label: new FormControl(''),
                phoneNumber: new FormControl('', [Validators.required, this.phoneNumberFormattingService.phoneNumberValidator(), this.phoneNumberCheckValidator(undefined)]),
            }),
        ]),
    });

    protected selectedContact: ContactWithPicture;

    protected contacts: ContactWithPicture[] = [];

    constructor(protected formBuilder: FormBuilder,
        protected phoneNumberFormattingService: PhoneNumberFormattingService,
        protected contactsService: ContactsService) {
    }

    protected addPhoneNumber(phoneNumber: Partial<PhoneNumber>): void {
        const newItem = new FormGroup({
            id: new FormControl(phoneNumber?.id ?? ''),
            isPreferred: new FormControl(phoneNumber.isPreferred ?? false),
            label: new FormControl(phoneNumber.label ?? ''),
            phoneNumber: new FormControl(phoneNumber.phoneNumber ?? '', [Validators.required, this.phoneNumberFormattingService.phoneNumberValidator(), this.phoneNumberCheckValidator(phoneNumber.id)]),
        });
        this.phoneNumbersForm.controls.phoneNumbers.push(newItem);
    }

    protected setPreffered(item: AbstractControl) {
        this.phoneNumbersForm.controls.phoneNumbers.controls.forEach(phoneNumber => {
            if (item === phoneNumber) {
                if (!item.value.isPreferred) {
                    phoneNumber.patchValue({ isPreferred: !item.value.isPreferred })
                }
            } else {
                phoneNumber.patchValue({ isPreferred: false })
            }
        })
    }

    protected addRow(): void {
        this.phoneNumbersForm.markAllAsTouched();
        this.contactForm.markAllAsTouched();

        if (this.phoneNumbersForm.invalid) {

            return;
        }

        this.phoneNumbersForm.markAsUntouched();

        const newItem = new FormGroup({
            label: new FormControl(''),
            isPreferred: new FormControl(false),
            phoneNumber: new FormControl('', [Validators.required, this.phoneNumberFormattingService.phoneNumberValidator(), this.phoneNumberCheckValidator(undefined)]),
        });
        (this.phoneNumbersForm.controls.phoneNumbers as FormArray).push(newItem);
    }

    protected removeRow(index: number): void {
        this.phoneNumbersForm.markAsUntouched();
        (this.phoneNumbersForm.controls.phoneNumbers as FormArray).removeAt(index);
    }


    protected phoneNumberCheckValidator(phoneNumberId: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (this.hasSamePhoneNumberInOtherContacts(phoneNumberId, control) || this.hasSamePhoneNumberInPhoneNumbersForm(phoneNumberId, control)) {
                return { 'phoneNumberExists': true };
            }
            return null;
        };
    }
    protected contactNameValidator(contactId?: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (this.hasSameNameInOtherContact(contactId, control)) {
                return { 'contactExists': true };
            }
            return null;
        };
    }

    private hasSamePhoneNumberInOtherContacts(phoneNumberId: number, control: AbstractControl) {
        return this.contacts?.find((contact: ContactWithPicture) => {
            const otherContacts = contact.phoneNumbers.filter((phoneNumber: PhoneNumber) => phoneNumber.id !== phoneNumberId);
            return otherContacts.find((phoneNumber: PhoneNumber) => phoneNumber.phoneNumber === control.value);
        });
    }

    private hasSameNameInOtherContact(contactId: number, control: AbstractControl) {
        return this.contacts?.find((contact: ContactWithPicture) => {
            return control.value && contact.id !== contactId && contact.fullName.trim() === control.value.trim();
        });
    }

    private hasSamePhoneNumberInPhoneNumbersForm(phoneNumberId: number, control: AbstractControl) {
        return !phoneNumberId && this.phoneNumbersForm?.controls?.phoneNumbers?.length > 1 && this.phoneNumbersForm?.controls?.phoneNumbers?.value.find((value: any) => control.value && value.phoneNumber === control.value);
    }

    public abstract validateAndSubmit(): void;

    protected abstract submit(): void;
}
