import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AvatarSize } from '../avatar/avatar.component';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PhoneNumber } from 'src/generated/graphql';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PhoneNumberFormattingService } from '../services/phone-number-formatting.service';

import { Observable, Subject, map, takeUntil, zip } from 'rxjs';
import { ContactsPhoneNumbersManager } from '../contacts/contacts-phone-numbers-manager';
import { ContactWithPicture, ContactsService } from '../services/contacts.service';
import { MessagingService } from '../services/messaging.service';
import { MatRadioChange } from '@angular/material/radio';
import { CallService } from '../services/call.service';
import { VoicemailsService } from '../services/voicemails.service';


@Component({
  selector: 'mc-add-number',
  templateUrl: './add-number.component.html',
  styleUrls: ['./add-number.component.scss']
})
export class AddNumberComponent extends ContactsPhoneNumbersManager implements OnInit, OnDestroy {
  public readonly AvatarSize = AvatarSize;
  private readonly ON_COMPONENT_DESTROY = new Subject<void>();

  public isPreferred: boolean = true;
  public createNewContact: boolean = true;
  public addToExistingContact: boolean = false;
  public selectedContact: ContactWithPicture

  public phoneNumbersForm = new FormGroup({
    phoneNumbers: new FormArray([]),
  });

  public newPhoneNumber: PhoneNumber;

  private saving: boolean;

  public get hasInvalidState(): boolean {
    const createContactNameError = (this.createNewContact && this.contactForm.invalid);
    const addToExistingContactError = (this.addToExistingContact && !this.selectedContact);
    return createContactNameError
      || addToExistingContactError
      || this.phoneNumbersForm.invalid
      || this.saving
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) data: string,
    protected contactsService: ContactsService,
    protected phoneNumberFormattingService: PhoneNumberFormattingService,
    protected dialogRef: MatDialogRef<AddNumberComponent>,
    private messagingService: MessagingService,
    private callService: CallService,
    private voicemailsService: VoicemailsService,
  ) {
    super(new FormBuilder(), phoneNumberFormattingService, contactsService);

    this.phoneNumbersForm.controls.phoneNumbers.push(new FormGroup({
      id: new FormControl(undefined),
      isPreferred: new FormControl(false),
      label: new FormControl('none'),
      phoneNumber: new FormControl('', [Validators.required, this.phoneNumberFormattingService.phoneNumberValidator(), this.phoneNumberCheckValidator(undefined)]),
    }));

    if (data) {
      this.phoneNumbersForm = new FormGroup({
        phoneNumbers: new FormArray([]),
      });

      this.newPhoneNumber = {
        phoneNumber: data,
        label: ''
      };

      this.addPhoneNumber(this.newPhoneNumber);
    }
  }

  public ngOnInit(): void {
    this.contactsService.getContacts()
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe(contacts => {
        this.contacts = contacts;
      })
  }

  public ngOnDestroy(): void {
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }

  public onTabSwitch(tabClicked: MatRadioChange) {
    const addToExistingTabSelected = tabClicked.value;
    if (addToExistingTabSelected) {
      this.createNewContact = false;
      this.addToExistingContact = true;
    } else {
      this.createNewContact = true;
      this.addToExistingContact = false;
    }

    this.clearField();
    this.addPhoneNumber(this.newPhoneNumber);
  }

  public validateAndSubmit(): void {
    this.contactForm.controls.fullName.markAsTouched();
    this.phoneNumbersForm.markAsTouched();

    if ((this.createNewContact && this.contactForm.invalid) || this.phoneNumbersForm.invalid) {
      return;
    }

    if (!this.saving) {
      this.submit();
    }
  }

  protected submit(): void {
    this.saving = true;
    const hasNewPrefferedNumber = this.phoneNumbersForm.controls.phoneNumbers.value.some((phoneNumber: any) => phoneNumber.isPreferred);

    if (!this.createNewContact) {
      if (hasNewPrefferedNumber) {
        this.selectedContact.phoneNumbers.forEach(phoneNumber => phoneNumber.isPreferred = false);
      }
 
      const newAndExistingPhoneNumbers = [
        ...this.selectedContact.phoneNumbers,
        ...this.phoneNumbersForm.controls.phoneNumbers.value.map((value: any) => {
          return { 
                  label: value.label, 
                  phoneNumber: this.phoneNumberFormattingService.formatE164(value.phoneNumber), 
                  isPreferred: value.isPreferred 
          };
        })
      ];

      const phoneNumbers = newAndExistingPhoneNumbers.map(phoneNumber => {
        return { id: phoneNumber.id, label: phoneNumber.label, phoneNumber: phoneNumber.phoneNumber, isPreferred: phoneNumber.isPreferred };
      });

      this.contactsService.editContact({
        contactId: this.selectedContact.id,
        fullName: this.selectedContact.fullName,
        pictureBase64: undefined,
        phoneNumbers
      }).pipe(takeUntil(this.ON_COMPONENT_DESTROY)).subscribe({
        next: (contact) => {
          this.reloadConversations().subscribe(() => {
            this.saving = false;
            this.dialogRef.close(contact);
          })
        },
        error: () => {
          this.saving = false;
        }
      });
    } else {
      if (!hasNewPrefferedNumber) {
        this.phoneNumbersForm.controls.phoneNumbers.value[0].isPreferred = true;
      }

      const phoneNumbers = this.phoneNumbersForm.controls.phoneNumbers.value.map((value: any) => {
        return { label: value.label, phoneNumber: value.phoneNumber, isPreferred: value.isPreferred };
      });

      this.contactsService.addContact({
        fullName: this.contactForm.controls.fullName.value,
        pictureBase64: undefined,
        phoneNumbers
      }).subscribe({
        next: (contact) => {
          this.reloadConversations().subscribe(() => {
            this.saving = false;
            this.dialogRef.close(contact);
          })
        },
        error: () => {
          this.saving = false;
        }
      });
    }
  }

  private reloadConversations(): Observable<void> {
    return zip(
      this.messagingService.reloadConversations(),
      this.callService.reloadConversations(),
      this.voicemailsService.reloadVoicemails(),
    ).pipe(map(()=>  undefined));
  }

  private clearField(): void {
    this.contactForm.reset();
    this.selectedContact = null;
    this.phoneNumbersForm = new FormGroup({
      phoneNumbers: new FormArray([]),
    });
  }
}
