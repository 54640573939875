<div class="flex space-x-4">
  <div class="flex-shrink-0 self-center">
    <mc-avatar [size]="AvatarSize.SMALL" [avatarUrl]="voicemail.contact?.pictureUrl"></mc-avatar>
  </div>
  <div class="flex-1 min-w-0 self-center">
    <div class="flex justify-between items-center">
      <div class="font-lato text-sm md:text-base font-normal text-gray-900 truncate grow">{{ voicemail.contact ? voicemail.contact.fullName : voicemail.callerPhoneNumber }}</div>
      <div class="inline-flex font-lato text-xs md:text-[13px] font-light secondary-color-500 flex-none ml-2">{{ timestamp }}</div>
    </div>
    <p class="font-lato text-xs md:text-[13px] font-light secondary-color-500 tracking-wide truncate">
      {{ voicemail.transcriptionText }}
    </p>
  </div>
</div>
