import { NgModule } from "@angular/core";
import { environment } from 'src/environments/environment';
import { UserService } from "./user/user.service";
import { User } from "src/generated/graphql";

declare let window: any;

@NgModule()
export class IntercomModule {
  constructor(private userService: UserService) {
    if (environment.intercom.enable) {
      userService.getCurrentUser().subscribe(user => {
        this.addIntercomMessageWidget(user);
      })
    }
  }

  private addIntercomMessageWidget(user?: User) {
    window.intercomSettings = {
      app_id: environment.intercom.appId,
      alignment: 'left',
      name: user.firstName + ' ' + user.lastName,
      email: user.emailAddress,
      user_hash: user.emailAddressHash
    };

    (function () {
      const w = window;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        const i: any = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args: any) {
          i.q.push(args);
        };
        w.Intercom = i;
        const l = function () {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/' + environment.intercom.appId;
          const x: any = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }
}
