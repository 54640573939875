import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSetupComponent } from './account-setup.component';
import { TwilioNumberPickerComponent } from './twilio-number-picker/twilio-number-picker.component';
import { PersonalPhoneNumberComponent } from './personal-phone-number/personal-phone-number.component';
import { VerifyPhoneNumberComponent } from './verify-phone-number/verify-phone-number.component';
import { SummaryComponent } from './summary/summary.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'src/app/shared/shared.module';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { KycQuestionsComponent } from './kyc-questions/kyc-questions.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    AccountSetupComponent,
    TwilioNumberPickerComponent,
    PersonalPhoneNumberComponent,
    VerifyPhoneNumberComponent,
    SummaryComponent,
    WelcomePageComponent,
    KycQuestionsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatTooltipModule,
    ScrollingModule
  ],
  exports: [
    AccountSetupComponent
  ]
})
export class AccountSetupModule { }
