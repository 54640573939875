<div class="flex flex-col min-h-full bg-white relative">
  <div class="flex flex-row justify-between items-center px-6 py-4 bottom-gray-border z-10 absolute-header">
    <p class="font-lato secondary-color text-base">Voicemail greetings</p>
    <button (click)="openCreateVoicemailGreetingModal()"
      class="flex flex-row items-center border rounded-md bg-white cursor-pointer drop-shadow-sm px-3 py-2"
      mat-stroked-button>
      <img class="mr-3 w-5" src="assets/images/icons/plus-blue.svg" />
      <span class="font-lato text-base font-normal secondary-color">Add new</span>
    </button>
  </div>
  <div class="flex flex-row w-full pt-6">
    <div *ngIf="!voicemailGreetings.length" class="absolute inset-0 flex items-center justify-center px-8">
      <p class="font-lato text-lg md:text-lg secondary-color">No voicemail greetings</p>
    </div>
    <ng-container *ngIf="!loadingGreetings;else loadingGreetingsTemplate">
      <div class="flex flex-col w-full" id="greetingList">
        <div *ngIf="activeGreeting" class="font-lato text-base secondary-color font-normal tracking-wide my-0 px-7">Active
        </div>
        <div class="flex flex-row gap-x-4 px-6 hover:bg-gray-150 cursor-pointer items-center" *ngIf="activeGreeting">
          <div>
            <mat-icon (click)="playVoicemailGreeting(audio,activeGreeting)"
              *ngIf="voicemailGreetingIdBeingPlayed !== activeGreeting.id"
              class="icon active-icon hover:cursor-pointer">play_circle_filled
            </mat-icon>
            <mat-icon (click)="playVoicemailGreeting(audio,activeGreeting)"
              *ngIf="voicemailGreetingIdBeingPlayed === activeGreeting.id" class="icon active-icon hover:cursor-pointer"
              matListItemIcon>pause_circle_filled
            </mat-icon>
          </div>
          <div class="flex flex-row py-4 w-full justify-between items-end">
            <div class="flex flex-row">
              <div class="flex flex-col">
                <div class="font-lato text-base font-normal secondary-color" matListItemTitle>{{activeGreeting.name}}
                </div>
                <div class="font-lato text-sm font-normal secondary-color-500 tracking-wide">{{activeGreeting.duration |
                  secondsToMinutes}}</div>
              </div>
            </div>
            <button [matMenuTriggerData]="{voicemailGreeting:activeGreeting}" [matMenuTriggerFor]="menu"
              class="p-3 hover:bg-gray-450 hover:rounded-full hover:p-3" mat-icon-button>
              <img alt="more" class="w-[20px]" src="assets/images/icons/more.svg" />
            </button>
            <div class="hidden">
              <ang-music-player #audio
                [audioList]="voicemailGreetingsRecordingsById.get(activeGreeting.id)"></ang-music-player>
            </div>
          </div>
        </div>
  
        <div class="pt-5" *ngIf="others.length > 0">
          <div class="font-lato text-base secondary-color font-normal tracking-wide my-0 px-6">Others</div>
          <div class="flex flex-row gap-x-4 px-6 hover:bg-gray-150 cursor-pointer items-center"
            *ngFor="let voicemailGreeting of others">
            <mat-icon (click)="playVoicemailGreeting(audio,voicemailGreeting)"
              *ngIf="voicemailGreetingIdBeingPlayed !== voicemailGreeting.id" class="icon hover:cursor-pointer"
              matListItemIcon>play_circle_filled
            </mat-icon>
            <mat-icon (click)="playVoicemailGreeting(audio,voicemailGreeting)"
              *ngIf="voicemailGreetingIdBeingPlayed === voicemailGreeting.id" class="icon hover:cursor-pointer"
              matListItemIcon>pause_circle_filled
            </mat-icon>
            <div class="flex flex-row py-3 w-full justify-between items-end border-b">
              <div class="flex flex-row">
                <div class="flex flex-col">
                  <div class="font-lato text-text-base font-normal secondary-color" matListItemTitle>
                    {{voicemailGreeting.name}}</div>
                  <div class="font-lato text-sm font-normal secondary-color-500 tracking-wide">
                    {{voicemailGreeting.duration | secondsToMinutes}}</div>
                </div>
              </div>
              <button [matMenuTriggerData]="{voicemailGreeting:voicemailGreeting}" [matMenuTriggerFor]="menu"
                class="p-3 hover:bg-gray-250 hover:rounded-full hover:p-3" mat-icon-button>
                <img alt="more" class="w-[20px]" src="assets/images/icons/more.svg" />
              </button>
              <div class="hidden">
                <ang-music-player #audio
                  [audioList]="voicemailGreetingsRecordingsById.get(voicemailGreeting.id)"></ang-music-player>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #loadingGreetingsTemplate>
  <mc-greetings-skeleton class="w-full"></mc-greetings-skeleton>
</ng-template>

<mat-menu #menu="matMenu" class="custom-menu" menu>
  <ng-template let-voicemailGreeting="voicemailGreeting" matMenuContent>
    <button (click)="setAsActive(voicemailGreeting)" *ngIf="!voicemailGreeting.active" mat-menu-item class="menu-item">
      <span class="font-lato text-base font-normal secondary-color">Set as active</span>
    </button>
    <button (click)="openDeleteVoicemailGreetingModal(voicemailGreeting)" mat-menu-item class="menu-item">
      <span class="font-lato text-base font-normal secondary-color">Delete</span>
    </button>
  </ng-template>
</mat-menu>
