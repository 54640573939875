import { Component, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import {
  GraphQlError,
  PhoneNumberStatus,
  PurchaseTwilioPhoneNumberGQL,
  SendSmsVerificationCodeGQL,
  VerifyPhoneNumberGQL
} from 'src/generated/graphql';
import { AccountSetupStep, AccountSetupStepComponent } from '../account-setup-step.component';

@Component({
  selector: 'verify-phone-number',
  templateUrl: './verify-phone-number.component.html',
  styleUrls: ['./verify-phone-number.component.scss']
})
export class VerifyPhoneNumberComponent extends AccountSetupStepComponent {

  private readonly CODE_LENGTH = 4;

  @ViewChild('codeNumber', { static: true })
  public codeNumber: ElementRef;

  @ViewChildren('codeNumber,codeNumber2,codeNumber3,codeNumber4')
  public codeNumbers: QueryList<ElementRef>;

  public submittingCode = false;
  public resentCode = false;

  public hasErrorsInEnteredCode = false;
  public verificationMessageSendLimitError: boolean;

  public verificationFailed = false;
  public twilioNumberPurchasingFailed = false;

  public constructor(
    private verifyPhoneNumberGQL: VerifyPhoneNumberGQL,
    private sendSmsVerificationCodeGQL: SendSmsVerificationCodeGQL,
    private purchaseTwilioPhoneNumberGQL: PurchaseTwilioPhoneNumberGQL
  ) {
    super();
  }

  public get step(): AccountSetupStep {
    return AccountSetupStep.PHONE_NUMBER_CODE_VERIFICATION;
  }

  public get elementToFocus(): HTMLElement {
    return this.codeNumber.nativeElement
  }

  public moveCaretToInput(input: HTMLInputElement): void {
    input.focus();
  }

  public handleForwardProgress(): void {
    super.handleForwardProgress();

    this.submitVerificationCode();
  }

  public handleForwardProgressWithErrors(): void {
    super.handleForwardProgressWithErrors();

    this.hasErrorsInEnteredCode = true;
  }

  public isFormControlValid(): boolean {
    const code = this.buildCodeFromInputs();
    return code.length === this.CODE_LENGTH;
  }

  public resendVerificationCode(): void {
    if (this.verificationMessageSendLimitError || this.submittingCode) {
      return;
    }

    this.resentCode = true;
    this.sendSmsVerificationCodeGQL.mutate({value: {
      phoneNumber: this.form.value.phoneNumber
    }}).subscribe((response) => {
      if (response.errors && response.errors.length > 0) {
        const error = response.errors[0];
        if (error.extensions['code'] === GraphQlError.TOO_MANY_REQUESTS) {
          this.verificationMessageSendLimitError = true;
        }
      } else {
        this.verificationMessageSendLimitError = false;
      }
    });
  }

  public submitVerificationCode(): void {
    if (this.submittingCode || this.resentCode) {
      return;
    }

    const code = this.buildCodeFromInputs();
    if (code.length != this.CODE_LENGTH) {
      return;
    }

    this.submittingCode = true;
    this.verificationFailed = false;
    this.verifyPhoneNumberGQL.mutate({ value: { code } })
      .subscribe((response) => {
        if (!response.data) {
          this.markVerificationAsFailed();
          return;
        }

        if (PhoneNumberStatus.APPROVED === response.data.verifyPhoneNumber.phoneNumberStatus) {
          this.form.controls['phoneNumberVerified'].setValue(true);
          this.purchaseTwilioPhoneNumber();
        } else {
          this.markVerificationAsFailed();
        }
      });
  }

  private markVerificationAsFailed(): void {
    this.submittingCode = false;
    this.verificationFailed = true;
  }

  private purchaseTwilioPhoneNumber(): void {
    this.twilioNumberPurchasingFailed = false;
    this.purchaseTwilioPhoneNumberGQL.mutate({
      value: { phoneNumber: this.form.value.twilioPhoneNumber }
    }).subscribe((response) => {
      if (response.errors) {
        this.twilioNumberPurchasingFailed = true;
        return;
      }

      this.hideBackwardButton();
      this.moveForwardManually();
    })
  }

  private buildCodeFromInputs(): string {
    return this.codeNumbers.map((codeElement) => codeElement.nativeElement.value).join('');
  }
}
