import { NgModule } from '@angular/core';
import { setContext } from '@apollo/client/link/context';
import { HttpClientModule } from '@angular/common/http';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular'
import { ApolloLink, InMemoryCache, split } from '@apollo/client/core'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { AuthService } from '../auth/auth.service';
import { getMainDefinition } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws';
import { createUploadLink } from 'apollo-upload-client';
import { waitForReconnectingTime } from '../shared/ws-connection-properties';
import { ConnectionStateService } from '../services/connection-state.service';
import { ConnectionStateType } from '../util/connection-state-type';
import { GraphqlConnectionStateService } from '../services/graphql-connection-state.service';

const uri = "/graphql";

const buildWebsocketUrl = () => {
  const protocol = window.location.protocol === "https:" ? "wss://" : "ws://";
  return protocol + window.location.host + uri;
}

const buildApolloHttpLink = (): ApolloLink => {
  return createUploadLink({
    uri,
    headers: { 'Apollo-Require-Preflight': 'true' }
  });
}


const buildApolloWsLink = (auth0Service: AuthService, connectionStateService: ConnectionStateService) => {
  const wsClient = new GraphQLWsLink(createClient({
    url: buildWebsocketUrl(),
    connectionParams: async () => {
      const token = await auth0Service.getJwtTokenAsPromise();

      return {
        authenticationToken: 'Bearer ' + token
      };
    },
    lazy: false,
    onNonLazyError(errorOrCloseEvent) {
      connectionStateService.updateConnectionState(ConnectionStateType.FAILED);
    },
    retryWait: waitForReconnectingTime,
    shouldRetry: () => true,
  }));
  
  wsClient.client.on("connected", (error) => {
    connectionStateService.updateConnectionState(ConnectionStateType.CONNECTED);
  });

  return wsClient;
}

const buildHttpAuthLink = (auth0Service: AuthService) => {
  return setContext(async (_, value) => {
    const token = await auth0Service.getJwtTokenAsPromise();
    return {
      ...value,
      headers: {
        ...value['headers'],
        Authorization: `Bearer ${token}`
      },
    };
  });
}

const buildApolloLink = (auth0Service: AuthService, connectionStateService: ConnectionStateService) => split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query) as any; // TODO: Find out why operation is missing in FragmentDefinitionNode
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  buildApolloWsLink(auth0Service, connectionStateService),
  buildHttpAuthLink(auth0Service).concat(buildApolloHttpLink())
);

const buildApolloClient = (authService: AuthService, connectionStateService: ConnectionStateService) => {
  return {
    link: buildApolloLink(authService, connectionStateService),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all"
      },
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "all"
      },
      mutate: {
        fetchPolicy: "no-cache",
        errorPolicy: "all"
      }
    }
  };
}

@NgModule({
  declarations: [],
  imports: [
    ApolloModule,
    HttpClientModule
  ],
  exports: [HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: buildApolloClient,
      deps: [AuthService, GraphqlConnectionStateService]
    }
  ]
})
export class GraphqlModule {
}
