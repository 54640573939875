import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'secondsToMinutes'
})
export class SecondsToMinutesPipe implements PipeTransform {

    public transform(totalTime: number): string {
        const minutes = totalTime / 60 | 0;
        const seconds = totalTime % 60;
        const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

        return formattedMinutes + ":" + formattedSeconds;
    }
}
