import { Component, Input } from "@angular/core";
import { Sms, SmsStatus } from '../../../../generated/graphql';
import { AvatarSize } from '../../../shared/avatar/avatar.component';
import * as dayjs from 'dayjs';
import { SelectedContactService } from "src/app/shared/services/selection/selected-contact.service";

@Component({
  selector: 'mc-messages-sms-item',
  templateUrl: './sms-item.component.html',
  styleUrls: ['./sms-item.component.scss']
})
export class MessageSmsItemComponent {

  @Input()
  public sms: Sms;

  @Input()
  public userImageUrl: string;

  @Input()
  public contactImageUrl: string;

  @Input()
  public contactId: number;

  public SmsStatus = SmsStatus;

  public readonly AvatarSize = AvatarSize;

  constructor(
    private selectedContactService: SelectedContactService,
  ) {}

  public goToContactProfile(): void {
    if(this.contactId != null) {
      this.selectedContactService.openContactById(this.contactId);
    }
  }

  public get timestamp(): string {
    const today = dayjs(new Date());
    const date = dayjs(this.sms.createdAt);
    if (date.isSame(today, 'day')) return date.format('h:mm a');
    if (date.isSame(today, 'week')) return date.format('dddd');
    if (date.isSame(today, 'year')) return date.format('MMM D')
    return date.format('L');
  }
}
