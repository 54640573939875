<div id="verifyCode">
    <h1 class="text-2xl font-lato font-normal tracking-wide secondary-color py-3 px-5">Verify phone number</h1>
    <div mat-dialog-content class="border-y px-6">
      <div id="form" class="flex justify-start flex-col py-10">
        <div class="flex flex-row">
          <input matInput #codeNumber (input)="moveCaretToInput(codeNumber2)" type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" class="border rounded secondary-color font-lato font-normal text-center text-l sm:text-lg p-3 sm:p-4 mr-6 sm:mr-8 focus:outline-none focus:border-gray-200 focus:ring-gray-200 focus:ring-1"/>
          <input matInput #codeNumber2 (input)="moveCaretToInput(codeNumber3)" type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" class="border rounded secondary-color font-lato font-normal text-center text-l sm:text-lg p-3 sm:p-4 mr-6 sm:mr-8 focus:outline-none focus:border-gray-200 focus:ring-gray-200 focus:ring-1"/>
          <input matInput #codeNumber3 (input)="moveCaretToInput(codeNumber4)" type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" class="border rounded secondary-color font-lato font-normal text-center text-l sm:text-lg p-3 sm:p-4 mr-6 sm:mr-8 focus:outline-none focus:border-gray-200 focus:ring-gray-200 focus:ring-1"/>
          <input matInput #codeNumber4 type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" class="border rounded secondary-color font-lato font-normal text-center text-l sm:text-lg p-3 sm:p-4 mr-6 sm:mr-8 focus:outline-none focus:border-gray-200 focus:ring-gray-200 focus:ring-1"/>
        </div>
      </div>
      <div class="relative">
        <mat-error *ngIf="hasErrorsInEnteredCode" class=" text-sm">The given code seems to have some errors. Please verify that it's the a valid one.</mat-error>
        <mat-error *ngIf="verificationFailed" class=" text-sm">Failed to verify phone number</mat-error>
        <mat-error *ngIf="verificationMessageSendLimitError" class=" text-sm">Max verification message send attempts reached. Please try again later.</mat-error>
        <mat-error *ngIf="hasAttemptedToProceedWithAnInvalidPhoneNumber" class=" text-sm">Invalid phone number. Please try again later.</mat-error>
      </div>
        <p *ngIf="!verificationSent" class="font-lato text-sm font-light tracking-wide secondary-color-500 mb-3">Didn’t receive the code? <button (click)="resendCode()" class="font-normal primary-color cursor-pointer">Resend</button></p>
        <p *ngIf="verificationSent" class="font-lato text-sm font-light tracking-wide secondary-color-500 mb-3">Code was resent</p>
    </div>
    <div class="flex justify-between py-3 px-4">
      <button mat-dialog-close
              (click)="close()"
              class="bg-white border rounded focus:ring-violet-300 hover:bg-gray-150 focus:outline-none py-1 px-5 ">
        <span class="tracking-wide font-lato secondary-color text-sm leading-loose">Close</span>
      </button>
      <button (click)="verifyPhoneNumber()" class="bg-primary-color border rounded focus:bg-primary-color hover:bg-primary-light-color focus:outline-none py-1 px-5">
          <span class="text-white tracking-wide font-lato font-light text-sm leading-loose">Verify</span>
      </button>
    </div>
  </div>
