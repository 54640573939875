import { Observable, ReplaySubject } from "rxjs";

/**
 * Used to convert a file input into base64 for upload using GraphQL
 * @param file 
 * @returns base64 output of the given file
 */
export const fileToBase64 = (file: File): Observable<string> => {
  const result = new ReplaySubject<string>(1);
  const reader = new FileReader();
  reader.readAsBinaryString(file);
  reader.onload = (event) => result.next(btoa(event.target.result.toString()));
  return result;
};