<div>
    <h1 class="text-2xl font-lato font-medium tracking-wide secondary-color py-4 px-5">Delete greeting</h1>
    <div class="border-y px-6 py-8">
        <div class="text-base font-lato font-normal tracking-wide secondary-color">Are you sure you want to delete
            <span class="primary-color">´{{voicemailGreeting.name}}</span>´?
        </div>
    </div>
    <div class="flex justify-between py-3 px-5">
        <button class="bg-white border rounded focus:ring-violet-300 hover:bg-gray-150 focus:outline-none py-1 px-5" mat-dialog-close (click)="trackCancelButton()">
            <span class="tracking-wide font-lato secondary-color text-sm leading-loose">Cancel</span>
        </button>
        <button (click)="onConfirmDelete()" class="bg-primary-color border rounded focus:bg-primary-color hover:bg-primary-light-color focus:outline-none py-1 px-5">
            <span class="text-white tracking-wide font-lato font-light text-sm leading-loose">Delete</span>
        </button>
    </div>
</div>
