<div id="deleteConversation">
  <h1 class="text-2xl font-lato font-normal tracking-wide secondary-color py-3 px-5">Delete voicemail</h1>
  <div class="border-y px-5 py-7">
    <div class="font-lato font-normal secondary-color tracking-wide text-base">Are you sure that you want to delete this voicemail?</div>
  </div>
  <div class="flex justify-between py-3 px-4">
    <button class="bg-white border rounded focus:ring-violet-300 hover:bg-gray-150 focus:outline-none py-1 px-5" mat-dialog-close (click)="close()">
        <span class="tracking-wide font-lato secondary-color text-sm leading-loose">Cancel</span>
    </button>
    <button (click)="onConfirmDelete()" [disabled]="deleting" [class.disabled]="deleting"  class="bg-primary-color border rounded focus:bg-primary-color hover:bg-primary-light-color focus:outline-none py-1 px-5">
        <span class="text-white tracking-wide font-lato font-light text-sm leading-loose">Delete</span>
    </button>
  </div>
</div>
