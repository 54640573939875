import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CallService } from "./call.service";
import { MessagingService } from "./messaging.service";
import { ConversationType } from "src/generated/graphql";
import { SelectedMessagingConversationService } from "./selection/selected-messaging-conversation.service";
import { SelectedCallingConversationService } from "./selection/selected-call-conversation.service";

@Injectable({
  providedIn: 'root'
})
export class ConversationNavigationService {

  constructor(
    private router: Router,
    private callService: CallService,
    private messagingService: MessagingService,
    private selectedMessagingConversationService: SelectedMessagingConversationService,
    private selectedCallingConversationService: SelectedCallingConversationService,
  ) {
  }

  public navigateToMessagingConversation(phoneNumber: string): void {
    this.messagingService.getConversationByParticipantNumber(phoneNumber)
      .subscribe(conversation => {
        if (conversation) {
          this.selectedMessagingConversationService.openConversationById(conversation.id);
          return;
        }

        this.messagingService.createAndHandleConversation(phoneNumber, ConversationType.SMS)
          .subscribe(conversation => this.selectedMessagingConversationService.openConversationById(conversation.id));
      });
  }

  public callNumber(phoneNumber: string): void {
    this.callService.getConversationByParticipantNumber(phoneNumber)
      .subscribe(conversation => {
        if (conversation) {
          this.navigateToCall(conversation.id);
          return;
        }

        // conversation for phone number not found, create new one...
        this.callService.createConversation(phoneNumber, ConversationType.CALL)
          .subscribe(conversation => {
            this.callService.cacheNewCallAndConversation(undefined, conversation);
            this.navigateToCall(conversation.id);
          });
      });
  }

  private navigateToCall(conversationId: number): void {
    this.selectedCallingConversationService.callInConversationById(conversationId);
  }
}
