<div id="greetingModal">
  <h1 class="text-2xl font-lato font-medium tracking-wide secondary-color py-4 px-5">Record a greeting</h1>
  <div class="border-y p-6">
    <div class="text-base font-lato font-normal font-normal tracking-wide secondary-color border-b pb-10 flex flex-row justify-between items-end relative">
      <div class="flex flex-row gap-x-5">
        <button mat-icon-button color="primary" (click)="startRecording()" [class.record-button]="recording" class="green-background rounded-full w-10 h-10 p-2.5">
          <img src="assets/images/icons/microphone.svg" />
        </button>
        <div class="flex flex-col">
          <p *ngIf="!recording" class="text-basesecondary-color font-lato font-normal">Tap the microphone to record</p>
          <p *ngIf="recording" class="text-basesecondary-color font-lato font-normal">Recording...</p>

          <span class="text-sm font-lato font-normal secondary-color-500">
            {{secondsRecording | secondsToMinutes}} / {{VOICE_RECORDING_TIME_LIMIT_IN_SECONDS | secondsToMinutes}}
          </span>
          <div class="flex flex-row gap-x-3 mt-3">
            <ng-container *ngIf="hasStartedRecording">
                <button (click)="stopRecording()" mat-stroked-button class="greeting-button flex flex-row items-center border">
                  <img class="mr-2 w-5" src="assets/images/icons/stop-button.svg" />
                  <span class="font-inter text-sm font-normal secondary-color">Stop</span>
                </button>

              <ng-container *ngIf="recording else notRecordingButtons">
                <button (click)="pauseRecording()" mat-stroked-button class="greeting-button flex flex-row items-center border">
                  <img class="mr-2 w-5" src="assets/images/icons/pause.svg" />
                  <span class="font-inter text-sm font-normal secondary-color">Pause</span>
                </button>
              </ng-container>

              <ng-template #notRecordingButtons>
                <button (click)="resumeRecording()" mat-stroked-button class="greeting-button flex flex-row items-center border">
                  <img class="mr-2 w-5" src="assets/images/icons/play-button.svg" />
                  <span class="font-inter text-sm font-normal secondary-color">Resume</span>
                </button>
              </ng-template>
            </ng-container>

            <ng-container *ngIf="!hasStartedRecording && !hasFinishedRecording">
                <button mat-stroked-button class="greeting-button disabled flex flex-row items-center">
                  <img class="mr-2 w-5" src="assets/images/icons/stop-button-2.svg" />
                  <span class="font-inter text-sm font-normal secondary-color">Stop</span>
                </button>

                <button mat-stroked-button class="greeting-button disabled flex flex-row items-center border">
                  <img class="mr-2 w-5" src="assets/images/icons/pause-2.svg" />
                  <span class="font-inter text-sm font-normal secondary-color">Pause</span>
                </button>
            </ng-container>

            <ng-container *ngIf="hasFinishedRecording">
              <button (click)="playRecording()" mat-stroked-button class="greeting-button flex flex-row items-center border">
                <img class="mr-2 w-5" src="assets/images/icons/play-button.svg" />
                <span class="font-inter text-sm font-normal secondary-color">Play</span>
              </button>
              <button (click)="redoRecording()" mat-stroked-button class="greeting-button flex flex-row items-center border">
                <img class="mr-2 w-4" src="assets/images/icons/restart.svg" />
                <span class="font-inter text-sm font-normal secondary-color">Redo</span>
              </button>
            </ng-container>
          </div>
          <mat-error *ngIf="hasAttemptedToSaveGreeting && (!recordingBlob && !recording)">Recording can not be empty. Please record something.</mat-error>
        </div>
      </div>
    </div>
    <form [formGroup]="greetingFormGroup" (keyup.enter)="saveRecording()">
      <div class="flex flex-col grow py-8 relative">
          <span class="font-lato font-normal secondary-color tracking-wide text-xs sm:text-sm mb-1">Name of the greeting</span>
          <mat-form-field class="example-full-width bg-white greeting-name text-sm font-lato">
            <input
              formControlName="name"
              class="placeholder:text-gray-300 placeholder:font-lato placeholder:font-light placeholder:tracking-wide tracking-wide font-lato secondary-color text-sm font-normal"
              matInput
              placeholder="John's Greeting">
          </mat-form-field>
          <mat-error *ngIf="hasAttemptedToSaveGreeting && greetingFormGroup.invalid">Greeting name can not be empty</mat-error>
      </div>
    </form>
  </div>
  <div class="flex justify-between py-3 px-5">
    <button (click)="cancelRecording()" class="bg-white border rounded focus:ring-violet-300 hover:bg-gray-150 focus:outline-none py-1 px-5">
      <span class="tracking-wide font-lato secondary-color text-sm leading-loose">Cancel</span>
    </button>
    <button (click)="saveRecording()" [disabled]="isInvalid" class="bg-primary-color border rounded focus:bg-primary-color hover:bg-primary-light-color focus:outline-none py-1 px-5" [class.disabled]="isInvalid">
      <span class="text-white tracking-wide font-lato font-light text-sm leading-loose">Save</span>
    </button>
  </div>
</div>
