import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, map, switchMap, take, tap } from 'rxjs';
import {
  Contact,
  ContactDeleteInput,
  ContactEditInput,
  ContactInput,
  CreateContactGQL,
  DeleteContactGQL,
  DeleteContactMutation,
  EditContactGQL,
  EditContactMutation,
  MyContactsGQL
} from 'src/generated/graphql';
import { MutationResult } from 'apollo-angular/src/types';
import { User } from '@auth0/auth0-spa-js';
import { UserService } from 'src/app/user/user.service';

export interface ContactWithPicture extends Contact {
  pictureUrl?: string;
  preferredPhoneNumber?: string;
}

@Injectable({
  providedIn: "root"
})
export class ContactsService {

  private readonly contacts = new ReplaySubject<ContactWithPicture[]>(1);
  private userId: number;

  public constructor(
    private myContactsGQL: MyContactsGQL,
    private editContactGQL: EditContactGQL,
    private createContactGQL: CreateContactGQL,
    private deleteContactGQL: DeleteContactGQL,
    private userService: UserService,
  ) {
    this.loadUserAndContacts();
  }

  public getContacts(): Observable<ContactWithPicture[]> {
    return this.contacts;
  }

  public getContactById(id: number): Observable<Contact> {
    return this.getCurrentContacts()
    .pipe(
      map((contacts) => contacts.find((contact) => contact.id === id))
    );
  }

  public getContactByPhoneNumber(phoneNumber: string): Observable<ContactWithPicture> {
    return this.getCurrentContacts().pipe(
      map(contacts => this.getContactByPhoneNumberAndContactsList(phoneNumber, contacts))
    );
  }

  public getContactByPhoneNumberAndContactsList(phoneNumber: string, contacts: ContactWithPicture[]): ContactWithPicture {
    return contacts.find((contact) => contact.phoneNumbers.find((contactPhoneNumber) => contactPhoneNumber.phoneNumber === phoneNumber));
  }

  public getCurrentContacts(): Observable<ContactWithPicture[]> {
    return this.contacts.pipe(take(1));
  }

  public addContact(contact: ContactInput): Observable<Contact> {
    return this.createContactGQL.mutate({
      value: { fullName: contact.fullName, pictureBase64: contact.pictureBase64, phoneNumbers: contact.phoneNumbers }
    }).pipe(
      switchMap((response) => 
        this.loadContacts().pipe(map(() => response.data.createContact))
      )
    );
  }

  public editContact(contact: ContactEditInput): Observable<ContactWithPicture> {
    return this.editContactGQL.mutate({
      value: contact
    }).pipe(
      switchMap(
        () => this.loadContacts().pipe(map((contacts) => contacts.find((c) => c.id === contact.contactId)))
      )
    );
  }

  public deleteContact(contactDeleteInput: ContactDeleteInput): Observable<MutationResult<DeleteContactMutation>> {
    return this.deleteContactGQL.mutate({
      value: contactDeleteInput
    })
    .pipe(
      switchMap(
        deleteContactResponse => this.loadContacts().pipe(map(()=>  deleteContactResponse))
      )
    );
  }

  private loadUserAndContacts(): void {
    this.loadUser()
    .pipe(
      switchMap(() => this.loadContacts())
      ).subscribe();
  }

  private loadUser(): Observable<User> {
    return this.userService.getUser().pipe(tap((user) => this.userId = user.id));
  }

  private loadContacts(): Observable<ContactWithPicture[]> {
    return this.myContactsGQL.fetch()
      .pipe(
        map((data) => {
          const response: ContactWithPicture[] = data.data.contacts;

          if (response) {
            response.forEach(contact => {
              if (contact.pictureIdentifier) {
                contact.pictureUrl = this.buildPictureUrl(contact);
              }

              contact.preferredPhoneNumber = contact.phoneNumbers.find((phoneNumber) => phoneNumber.isPreferred)?.phoneNumber || contact.phoneNumbers[0]?.phoneNumber;
            });
          }
          return response;
        }),
        tap(contacts => this.contacts.next(contacts)),
      );
  }

  private buildPictureUrl(contact: Contact): string {
    return `/api/storage/user/${this.userId}/contact-picture/${contact.id}/${contact.pictureIdentifier}`;
  }
}
