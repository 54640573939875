import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { SecurePipe } from 'src/app/auth/secure.pipe';
import { AddNumberComponent } from 'src/app/shared/add-number/add-number.component';
import { AvatarSize } from 'src/app/shared/avatar/avatar.component';
import { ConversationNavigationService } from 'src/app/shared/services/conversation-navigation.service';
import { SelectedContactService } from 'src/app/shared/services/selection/selected-contact.service';
import { SelectedVoicemailService } from 'src/app/shared/services/selection/selected-voicemail.service';
import { VoicemailWithContactInformation, VoicemailsService } from 'src/app/shared/services/voicemails.service';
import { DeleteVoicemailComponent } from '../modals/delete-voicemail/delete-messages.component';

interface AngMusicPlayerInput {
  url: SafeUrl,
  title: string,
}

@Component({
  selector: 'mc-voicemail-details',
  templateUrl: './voicemail-details.component.html',
  styleUrls: ['./voicemail-details.component.scss']
})
export class VoicemailDetailsComponent implements OnInit, OnDestroy {

  public readonly AvatarSize = AvatarSize;
  private readonly ON_COMPONENT_DESTROY = new Subject<void>();

  public voicemail: VoicemailWithContactInformation;

  public loadedVoicemail = false;
  public loadedRecording = false;

  public musicPlayerAudioList: AngMusicPlayerInput[] = [];

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private securePipe: SecurePipe,
    private voicemailsService: VoicemailsService,
    private selectedVoicemailService: SelectedVoicemailService,
    private conversationNavigationService: ConversationNavigationService,
    private selectedContactService: SelectedContactService,
  ) { }

  public ngOnInit(): void {
    this.loadVoicemailFromRouteParams();
  }

  public ngOnDestroy(): void {
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }

  public callNumber(): void {
    this.conversationNavigationService.callNumber(this.voicemail.callerPhoneNumber);
  }

  public messageNumber(): void {
    this.conversationNavigationService.navigateToMessagingConversation(this.voicemail.callerPhoneNumber);
  }

  public goToContactsProfilePage(): void {
    if (!this.voicemail.contact) {
      return;
    }

    this.selectedContactService.openContactById(this.voicemail.contact.id);
  }

  public openAddNumberDialog(): void {
    this.dialog.open(AddNumberComponent, {
      width: '580px',
      data: this.voicemail.callerPhoneNumber
    }).afterClosed()
      .subscribe((contact) => {
        if (contact) {
          this.voicemail.contact = contact;
        }
      });
  }

  public openDeleteVoicemailDialog(): void {
    this.dialog.open(DeleteVoicemailComponent, {
      width: '580px',
      data: this.voicemail
    }).afterClosed()
      .subscribe((deleted) => {
        if (deleted) {
          this.navigateToVoicemailsHomePage();
        }
      });
  }

  private loadVoicemailFromRouteParams(): void {
    this.route.paramMap
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe((params) => {
        const voicemailId = Number(params.get('voicemailId'));
        this.loadVoicemail(voicemailId);
      });
  }

  private loadVoicemail(voicemailId: number): void {
    this.loadedVoicemail = false;
    this.loadedRecording = false;

    this.voicemailsService.getCurrentVoicemails().subscribe((voicemails) => {
      this.voicemail = this.voicemailsService.getVoicemailById(voicemailId, voicemails);

      if (!this.voicemail) {
        this.navigateToVoicemailsHomePage();
        return;
      }

      this.getRecordingSafeUrl(this.voicemail.recordingUrl).subscribe((recording) => {
        this.musicPlayerAudioList = [{
          title: 'Voicemail recording',
          url: recording
        }];

        this.loadedRecording = true;
      });

      this.loadedVoicemail = true;
      this.selectedVoicemailService.setSelectedItemId(voicemailId);
    });
  }

  private navigateToVoicemailsHomePage(): void {
    this.selectedVoicemailService.navigateToVoicemailHomepage();
  }

  private getRecordingSafeUrl(url: string): Observable<SafeUrl> {
    return this.securePipe.transform(url);
  }

}
