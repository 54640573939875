import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TrackingService } from 'src/app/services/tracking-service';
import { AvatarSize } from 'src/app/shared/avatar/avatar.component';
import { Template, TemplateService } from 'src/app/shared/message-templates-picker/template.service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';

@Component({
  selector: 'mc-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent {

  private readonly title = 'Hello Grove - Templates';
  public readonly AvatarSize = AvatarSize;
  public showTemplateInfo: boolean = false;
  panelOpenState = false;

  public messageTemplates: Template[] = [];
  public phoneCallTemplates: Template[] = [];
  public voicemailTemplates: Template[] = [];
  public emailTemplates: Template[] = []; 
  public selectedTemplate: Template = {};
  public ElementToTrack = TrackingEventType;

  constructor(
    private titleService: Title,
    private templateService: TemplateService,
    private trackingService: TrackingService,
  ) {}

  public ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.messageTemplates = this.templateService.getMessageTemplates();
    this.phoneCallTemplates = this.templateService.getPhoneCallTemplates();
    this.voicemailTemplates = this.templateService.getVoicemailTemplates();
    this.emailTemplates = this.templateService.getEmailTemplates();
  }

  public selectTemplate(template: Template, elementToTrack: TrackingEventType): void {
    this.selectedTemplate = template;
    this.showTemplateInfo = true;
    this.trackingService.trackEvent(elementToTrack, { name: template.title });
  }

  public trackTemplateCategories(element: TrackingEventType) {
    this.trackingService.trackEvent(element);
  }
}
