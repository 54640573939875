<div class="flex justify-center container mx-auto sm:px-14 px-5 sm:py-12 md:14 py-5 search-result">
    <div class="w-full lg:w-8/12" [@.disabled]="true">
        <div class="font-lato font-normal tracking-wide secondary-color text-xl sm:text-2xl">Search result</div>
        <div class="font-lato font-light tracking-wide secondary-color-500 mb-5 text-base sm:text-lg">
          <span class="primary-color">{{queryValue}} - </span> About {{totalMessagesCount + contactsSearchResults.length}} results
        </div>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
            <mat-tab>
              <ng-template mat-tab-label>Contacts <span class="circle-nr">{{contactsSearchResults.length}}</span></ng-template>
               <ng-container *ngIf="contactsSearchResults.length !== 0; else noContactTemplate">
                <div class="content">
                  <ul role="list">
                    <li *ngFor="let contact of contactsSearchResults" (click)="goToContactProfile(contact.id)" class="hover:bg-gray-250 cursor-pointer">
                      <div class="flex space-x-3">
                        <div class="flex-1 min-w-0 self-start py-3 pr-2 pl-3 border-b border-gray-100">
                          <div class="flex flex-row items-center justify-between">
                            <div class="left-div">
                              <mc-avatar class="pr-3" [avatarUrl]="contact?.pictureUrl" [size]="AvatarSize.SMALL"></mc-avatar>
                              <span class="person-name font-lato font-normal secondary-color truncate text-sm md:text-base">{{contact.fullName}}</span>
                            </div>
                            <div class="right-div">
                              <button aria-label="Call"><img src="../../../assets/images/sidebar-icons/blue-phone-call.svg"></button>
                              <span class="span-line"></span>
                              <button aria-label="Message" (click)="goToContactMessagingConversation(contact.id)"><img src="../../../assets/images/sidebar-icons/blue-sms.svg"></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                 </div>
               </ng-container>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>Conversations <span class="circle-nr">{{ totalMessagesCount }}</span></ng-template>
              <ng-container>
                <div class="content" *ngIf="messagesSearchResults.length !== 0; else noMessageTemplate">
                  <ul role="list">
                    <li (click)="goToMessagesConversation(message.conversationId)" *ngFor="let message of messagesSearchResults" class="hover:bg-gray-250 cursor-pointer">
                      <div class="flex space-x-3">
                        <div class="flex-1 min-w-0 self-start py-3 pr-2 pl-3 border-b border-gray-100">
                          <div class="flex flex-row items-center justify-between">
                            <div class="flex flex-row">  
                              <mc-avatar class="pr-3" [avatarUrl]="message.senderPictureUrl" [size]="AvatarSize.SMALL"></mc-avatar>
                              <span class="person-name font-lato font-normal secondary-color truncate text-sm md:text-base">{{message.senderName}}</span>
                            </div>
                            <div class="flex flex-row gap-x-2.5">
                              <div class="font-lato font-normal secondary-color-500 text-xs">{{ message.createdAt | date: 'MM/dd/yy' }}</div>
                              <button aria-label="More">
                                <img src="../../../assets/images/icons/arrow-down-sign-to-navigate.svg" alt="Arrow down" />
                              </button>
                            </div>
                          </div>
                          <p class="font-lato text-sm font-light secondary-color-500 truncate whitespace-normal mt-2 pl-1"> {{message.body}}</p>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <div class="py-4 flex justify-center">
                    <button *ngIf="hasMoreMessagesToLoad" [disabled]="loadingMessages" (click)="searchMoreMessages()" class="more-btn">
                      <span *ngIf="!loadingMessages" class="font-lato text-sm font-light">Show more</span>
                      <span *ngIf="loadingMessages" class="font-lato text-sm font-light">Loading...</span>
                    </button>
                  </div>
                </div>
              </ng-container>
            </mat-tab>
          </mat-tab-group>
    </div>
  </div>

  <ng-template #noMessageTemplate>
    <div class="py-20 flex justify-center items-center">
      <span class="font-lato text-base font-medium">No conversations found</span>
    </div>
  </ng-template>

  <ng-template #noContactTemplate>
    <div class="py-20 flex justify-center items-center">
      <span class="font-lato text-base font-medium">No contacts found</span>
    </div>
  </ng-template>