<div class="container main-content mx-0 pb-[100px] sm:mx-auto px-6 md:px-4 lg:px-12 pt-6 md:pt-12 lg:pt-16 ">
  <p class="font-lato font-normal tracking-wide secondary-color leading-loose mb-2 text-xl sm:text-2xl">Verify phone number</p>
  <p class="font-lato font-light tracking-wide secondary-color-500 mb-8 text-l sm:text-lg">Please enter the verification code sent to: <span class="primary-color font-normal">{{ form.value.phoneNumber | phoneNumber }}</span></p>
  <div id="form" class="flex justify-start flex-col">
    <div class="flex flex-row">
      <input matInput [disabled]="submittingCode" #codeNumber (input)="moveCaretToInput(codeNumber2)" type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" class="border rounded secondary-color font-lato font-normal text-center text-l sm:text-lg p-3 sm:p-4 mr-6 sm:mr-8 focus:outline-none focus:border-gray-200 focus:ring-gray-200 focus:ring-1"/>
      <input matInput [disabled]="submittingCode" #codeNumber2 (input)="moveCaretToInput(codeNumber3)" type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" class="border rounded secondary-color font-lato font-normal text-center text-l sm:text-lg p-3 sm:p-4 mr-6 sm:mr-8 focus:outline-none focus:border-gray-200 focus:ring-gray-200 focus:ring-1"/>
      <input matInput [disabled]="submittingCode" #codeNumber3 (input)="moveCaretToInput(codeNumber4)" type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" class="border rounded secondary-color font-lato font-normal text-center text-l sm:text-lg p-3 sm:p-4 mr-6 sm:mr-8 focus:outline-none focus:border-gray-200 focus:ring-gray-200 focus:ring-1"/>
      <input matInput [disabled]="submittingCode" #codeNumber4 (input)="submitVerificationCode()" type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" class="border rounded secondary-color font-lato font-normal text-center text-l sm:text-lg p-3 sm:p-4 mr-6 sm:mr-8 focus:outline-none focus:border-gray-200 focus:ring-gray-200 focus:ring-1"/>
    </div>
    <mat-error *ngIf="hasErrorsInEnteredCode" class="text-sm">The given code seems to have some errors. Please verify that it's the correct one.</mat-error>
    <mat-error *ngIf="verificationFailed" class="text-sm">Failed to verify phone number</mat-error>
    <mat-error *ngIf="twilioNumberPurchasingFailed" class="text-sm">Failed to purchase phone number</mat-error>
    <mat-error *ngIf="verificationMessageSendLimitError" class="font-lato text-sm">Max verification message send attempts reached. Please try again later.</mat-error>
  </div>
  <p class="font-lato text-sm font-light tracking-wide secondary-color-500 mt-6">Didn’t receive the code? <a (click)="resendVerificationCode()" [class.disabled]="verificationMessageSendLimitError" href="javascript:void(0)" class="font-normal primary-color cursor-pointer">Resend</a></p>
  <div class="flex md:justify-end justify-center">
    <img class="float-right lg:w-4/12 md:w-6/12 sm:w-7/12 w-9/12 mt-12 sm:mt-8" src="../../../assets/images/verify-phone-number.svg" alt="Location img">
  </div>
</div>
