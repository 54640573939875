import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApolloError } from '@apollo/client/errors';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AvatarSize } from '../../shared/avatar/avatar.component';
import { Conversation, ListCallConversationQuery, ConversationType } from '../../../generated/graphql';
import { CallService } from 'src/app/shared/services/call.service';
import { Call as VoiceSdkCall } from '@twilio/voice-sdk';
import { ConversationPage } from 'src/app/shared/conversations/conversation-page';
import { ContactWithPicture } from 'src/app/shared/services/contacts.service';
import { SelectedCallingConversationService } from 'src/app/shared/services/selection/selected-call-conversation.service';
import { CallManagerService } from 'src/app/shared/services/call-manager.service';
import { TrackingService } from 'src/app/services/tracking-service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';

@Component({
  selector: 'mc-calls',
  templateUrl: './calls.component.html',
  styleUrls: ['./calls.component.scss']
})
export class CallsComponent extends ConversationPage implements OnInit, OnDestroy {
  public readonly ConversationType = ConversationType;
  public readonly AvatarSize = AvatarSize;

  private readonly ON_COMPONENT_DESTROY = new Subject<void>();
  private readonly title = 'Hello Grove - Calls';

  public loadingConversations: boolean = true;
  public conversations: ListCallConversationQuery['listCallConversations'] = [];
  public conversationError: ApolloError;

  public selectedConversationId?: number;

  public pendingIncomingCall = false;
  public pendingIncomingTwilioCall?: VoiceSdkCall;
  public pendingIncomingCallConversation?: Conversation;

  public incomingTwilioCall?: VoiceSdkCall;

  private callInProgress: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private callService: CallService,
    private callManagerService: CallManagerService,
    private titleService:Title,
    private selectedCallingConversationService: SelectedCallingConversationService,
    private trackingService: TrackingService,
  ) {
    super();
  }

  public get callingUnavailable(): boolean {
    return this.callInProgress || this.pendingIncomingCall;
  }

  public ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.loadSelectedConversationId();
    this.loadConversations();
    
    this.callManagerService.getAcceptedCall()
    .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
    .subscribe(call => {
      if(call) {
        this.callInProgress = true;
        return;
      }
      
      this.callInProgress = false;
    });

    this.callManagerService.getPendingCall()
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe(incomingCall => {
        if(incomingCall) {
          this.pendingIncomingCall = true;
          return;
        }

        this.pendingIncomingCall = false;
    });
  }

  public ngOnDestroy(): void {
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }

  private loadConversations(): void {
    this.loadingConversations = true;
    this.callService.getConversations()
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe((conversations) => {    
        this.conversations = conversations;
        this.loadingConversations = false;
      });
  }

  public loadMoreConversations(): Observable<Conversation[]> {
    return this.callService.loadMoreConversations();
  }

  public openCallConversation(conversation: Conversation) {
    if (conversation.id === this.selectedConversationId) return;

    this.selectedConversationId = conversation.id;
    this.selectedCallingConversationService.openConversationById(conversation.id);
    this.trackingService.trackEvent(TrackingEventType.CALLS_OPEN_CALL_CONVERSATION);
  }

  public get pendingIncomingCallConversationContactPicture(): string {
    const contact = this.pendingIncomingCallConversation?.contact as ContactWithPicture;
    return contact?.pictureUrl;
  }
  
  private loadSelectedConversationId(): void {
    this.selectedCallingConversationService.getSelectedItemId()
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe(conversationId => {
        this.selectedConversationId = conversationId;

        if (conversationId) {
          this.selectedCallingConversationService.navigateToConversationById(conversationId);
        }

        this.changeDetectorRef.detectChanges();
      });
  }
  
  public trackMakeACallButton(): void {
    this.trackingService.trackEvent(TrackingEventType.MAKE_CALL_BUTTON_CLICK);
  }
}
