import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { catchError, map, Observable, of } from 'rxjs';

/**
 * This pipe is used to add the authentication header to resource requests such as images.
 * It works by executing the HTTP requests using the HttpClient, which is intercepted by the {@link ./AuthInterceptor}.
*/
@Pipe({
  name: 'secure'
})
export class SecurePipe implements PipeTransform {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  public transform(url: string, fallbackUrl?: string): Observable<SafeUrl> {
    if (!url) {
      return of(fallbackUrl);
    }

    return this.http
      .get(url, { responseType: 'blob' })
      .pipe(
        map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))),
        catchError(() => of(fallbackUrl))
      )
  }
}
