<div *ngIf="pendingIncomingCall && pendingIncomingTwilioCall" class="flex flex-col p-4 pending-box">
    <div class="flex flex-row items-center">
    <mc-avatar [size]="AvatarSize.SMALL" [avatarUrl]="pendingIncomingCallConversationContactPicture"></mc-avatar>
    <div class="grid ml-3">
        <ng-container *ngIf="pendingIncomingCallConversation && pendingIncomingCallConversation.contact; let contact">
        <span class="text-lato secondary-color text-base leading-4">{{ contact.fullName }}</span>
        <span class="text-lato text-gray-400 text-sm font-light">{{ pendingIncomingCallConversation.participantNumber | phoneNumber}}</span>
        </ng-container>

        <ng-container *ngIf="!pendingIncomingCallConversation || !pendingIncomingCallConversation.contact">
        <span class="text-lato secondary-color text-base leading-4">{{ pendingIncomingTwilioCall.parameters['From'] }}</span>
        </ng-container>
    </div>
    </div>

    <div class="flex flex-row items-center mt-3">
        <button class="flex flex-row cursor-pointer shadow px-5 py-2 rounded w-full mr-2 accept-btn" (click)="acceptPendingIncomingCall()">
            <img src="../../../assets/images/icons/end-call.svg" class="w-[24px] mr-3">
            <span class="text-white">Accept</span>
        </button>

        <button class="flex cursor-pointer shadow px-5 py-2 rounded w-full decline-btn" (click)="rejectPendingIncomingCall()">
            <img src="../../../assets/images/icons/end-call.svg" class="w-[24px] mr-3">
            <span class="text-white">Decline</span>
        </button>
    </div>
</div>