import { Injectable } from "@angular/core";
import { NotificationService } from "../shared/services/notification.service";
import { ConnectionStateService } from "./connection-state.service";
import { ConnectionStateType } from "../util/connection-state-type";

@Injectable({
    providedIn:"root"
})
export class GraphqlConnectionStateService extends ConnectionStateService {
  protected title: string = "Connection error message";
  protected failMessage: string = "Something went wrong. Please try refreshing!";

  constructor(protected notifcationService: NotificationService) {
    super(notifcationService);
  }
}
