import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberFormattingService } from '../services/phone-number-formatting.service';
import { Contact } from 'src/generated/graphql';

@Pipe({
    name: 'prefferedPhoneNumber'
})
export class ContactPreferredPhoneNumberPipe implements PipeTransform {

    constructor(private phoneNumberFormattingService: PhoneNumberFormattingService) {
    }

    public transform(contact: Contact): string {
        const prefferedPhoneNumber = contact.phoneNumbers.find(phoneNumber => phoneNumber.isPreferred);
        if (prefferedPhoneNumber) {
            return this.phoneNumberFormattingService.format(prefferedPhoneNumber.phoneNumber);
        }
        
        return this.phoneNumberFormattingService.format(contact.phoneNumbers[0]?.phoneNumber);
    }
}
