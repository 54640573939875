<form (clickOutside)="hideSearchBar()" class="h-full p-0 m-0 custom-form">
  <div class="wrap flex justify-between items-center" [class.active]="showInput">
    <div *ngIf="showInput" class="w-full">
      <input type="text" autofocus class="global-input" placeholder="Search Hello Grove" [class.active]="showInput"
      name="searchInput"  autocomplete="off" [(ngModel)]="queryValue" (ngModelChange)="onTextTyped()"
        (keydown.esc)="hideSearchBar()" (click)="showOptions()" (focus)="showOptions()" matInput>
    </div>
    <mat-icon aria-hidden="true" (click)="expandSearchBar()">search</mat-icon>
  </div>

  <div class="global-options bg-white z-10 border shadow" *ngIf="areOptionsShown">
    <ng-container *ngIf="!searching; else loaderTemplate">
      <ng-container *ngIf="hasResults; else noResultTemplate">
        <div class="mt-3">
          <span *ngIf="contactsSearchResults.length" class="p-4 font-lato text-sm md:text-base font-medium">Contacts</span>
          <ul role="list" class="mt-2">
            <li *ngFor="let contact of contactsSearchResults" (click)="goToContactProfile(contact.id)" class="px-4 hover:bg-gray-250 cursor-pointer">
              <div class="flex space-x-3">
                <div class="flex-shrink-0 self-center">
                  <mc-avatar [avatarUrl]="contact?.pictureUrl" [size]="AvatarSize.SMALL"></mc-avatar>
                </div>
                <div class="flex-1 min-w-0 self-start py-3 md:py-4 border-b border-gray-100">
                  <div class="flex flex-row items-center justify-between">
                    <div class="font-lato font-normal secondary-color truncate text-sm md:text-base">{{contact.fullName}}</div>
                    <div>
                      <button aria-label="Call" class="p-2 mr-3 rounded-full bg-gray-450 hover:bg-blue-250 border-gray-100">
                        <img src="assets/images/sidebar-icons/blue-phone-call.svg" class="w-[15px]" alt="Call" />
                      </button>
                      <button (click)="goToContactMessagingConversation(contact.id)" aria-label="Message" class="p-2 rounded-full bg-gray-450 hover:bg-blue-250 border-gray-100">
                        <img src="assets/images/sidebar-icons/blue-sms.svg" class="w-[15px]" alt="Message" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="mt-3" *ngIf="messagesSearchResults.length">
          <span class="px-4 font-lato text-sm md:text-base font-medium">Conversations</span>
          <ul role="list" class="mt-2">
            <li (click)="goToMessagesConversation(message.conversationId)" *ngFor="let message of messagesSearchResults.slice(0, 3)" class="px-3 hover:bg-gray-150 cursor-pointer">
              <div class="flex space-x-3">
                <div class="flex-shrink-0 self-center">
                  <mc-avatar [avatarUrl]="message.senderPictureUrl" [size]="AvatarSize.SMALL"></mc-avatar>
                </div>
                <div class="flex-1 min-w-0 self-start py-4 border-b border-gray-100">
                  <div class="flex flex-row items-center justify-between">
                    <div class="font-lato font-normal secondary-color truncate text-sm md:text-base">{{message.senderName}}</div>
                    <div class="flex flex-row gap-x-2.5">
                      <div class="font-lato font-normal secondary-color-500 text-xs">{{ message.createdAt | date: 'MM/dd/yy' }}</div>
                      <button aria-label="More">
                        <img src="assets/images/icons/arrow-down-sign-to-navigate.svg" alt="Arrow down" />
                      </button>
                    </div>
                  </div>
                  <p class="font-lato text-xs font-light secondary-color-500 whitespace-normal line-clamp-2">
                    {{message.body}}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <a *ngIf="hasMoreResults" class="flex justify-center py-2 px-3 primary-color text-sm font-medium cursor-pointer hover:bg-gray-150" (click)="goToResultPage()">Show more</a>
      </ng-container>
    </ng-container>
  </div>
</form>


<ng-template #loaderTemplate>
  <div class="result-container flex justify-center items-center">
    <mc-loader></mc-loader>
  </div>
</ng-template>

<ng-template #noResultTemplate>
  <div class="result-container flex justify-center items-center">
    <span class="font-lato text-base font-medium">No results found</span>
  </div>
</ng-template>