import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";

@Injectable()
export class PhoneNumberFormattingService {

  private readonly DEFAULT_REGION = 'US';
  private readonly DEFAULT_FORMAT = PhoneNumberFormat.NATIONAL; // (123) 456-7890
  private readonly phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();

  public formatE164(rawPhoneNumber: string): string {
    return this.format(rawPhoneNumber, PhoneNumberFormat.E164);
  }

  public format(rawPhoneNumber: string, format = this.DEFAULT_FORMAT, region = this.DEFAULT_REGION): string {
    if (!this.isValidPhoneNumber(rawPhoneNumber)) {
      return rawPhoneNumber;
    }

    const phoneNumber = this.phoneUtil.parse(rawPhoneNumber, region);
    return this.phoneUtil.format(phoneNumber, format);
  }

  public isValidPhoneNumber(rawPhoneNumber: string, region = this.DEFAULT_REGION): boolean {
    return this.phoneUtil.isPossibleNumberString(rawPhoneNumber, region);
  }

  public phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl<string>): ValidationErrors | null => {
      const isValid = this.isValidPhoneNumber(control?.value);
      return isValid ? null : { invalidPhoneNumber: control?.value }
    }
  }
}
