export enum TrackingEventType {
    ADD_NUMBER = "add-number",
    CALL_CONTACT = "call-contact",
    SEND_MESSAGE_TO_CONTACT = "send-message-to-contact",
    MAKE_CALL_BUTTON_CLICK = "make-a-call-button-click",
    DIAL_NUMBER= "dial-number",
    VOICEMAIL_GREETINGS_CLICKED = "voicemail-greetings-clicked",
    ADD_NEW_VOICEMAIL_GREETING = "add-new-voicemail-greeting",
    PHONE_CALL_TEMPLATES = "phone-call-templates",
    CONTACTS_MENU_CLICKED = "contacts-menu-clicked",
    CONTACTS_DELETE_CONTACT_CANCEL_BUTTON_CLICKED = "contacts-delete-contact-cancel-button-clicked",
    DELETE_CONTACT_BUTTON_CLICKED = "delete-contact-button-clicked",
    CALLS_MENU_CLICKED = "calls-menu-clicked",
    MESSAGES_MENU_CLICKED = "messages-menu-clicked",
    VOICEMAILS_MENU_CLICKED = "voicemails-menu-clicked",
    TEMPLATES_MENU_CLICKED = "templates-menu-clicked",
    CONTACTS_ADD_NEW_BUTTON_CLICKED = "contacts-add-new-button-clicked",
    CONTACTS_ADD_NEW_SUCCESS = "contacts-add-new-success",
    CONTACTS_ADD_NEW_FAILURE = "contacts-add-new-failure",
    CONTACTS_ADD_NEW_CANCEL = "contacts-add-new-cancel",
    CONTACTS_EDIT_BUTTON_CLICKED = "contacts-edit-button-clicked",
    CONTACTS_EDIT_NEW_SUCCESS = "contacts-edit-new-success",
    CONTACTS_EDIT_NEW_FAILURE = "contacts-edit-new-failure",
    CALLS_OPEN_CALL_CONVERSATION = "calls-open-call-conversation",
    CALLS_DELETE_CONVERSATION_BUTTON_CLICK = "calls-delete-conversation-button-click",
    CALLS_DELETE_CONVERSATION_SUCCESS = "calls-delete-conversation-success",
    CALLS_DELETE_CONVERSATION_FAILURE = "calls-delete-conversation-failure",
    CALLS_DELETE_CONVERSATION_CANCEL = "calls-delete-conversation-cancel",
    MESSAGES_DELETE_CONVERSATION_BUTTON_CLICK = "messages-delete-conversation-button-click", 
    MESSAGES_DELETE_CONVERSATION_SUCCESS = "messages-delete-conversation-success",
    MESSAGES_DELETE_CONVERSATION_FAILURE = "messages-delete-conversation-failure",
    MESSAGES_DELETE_CONVERSATION_CANCEL = "messages-delete-conversation-cancel",
    MESSAGES_OPEN_CONVERSATION = "messages-open-conversation",
    MESSAGES_TEMPLATES_CANCEL = "messages-templates-cancel-button-clicked",
    MESSAGES_TEMPLATES_SAVE = "messages-templates-save-button-clicked",
    MESSAGES_TEMPLATES_FIRST_TEMPLATE_CLICK = "messages-templates-first-template-clicked",
    MESSAGES_TEMPLATES_SECOND_TEMPLATE_CLICK = "messages-templates-second-template-clicked",
    SMS_SEND_MESSAGE = "send-message",
    MESSAGES_ADD_EMOJI = "messages-add-emoji",
    MESSAGES_ADD_PICTURE = "message-add-picture",
    MESSAGES_TEMPLATES = "messages-templates-clicked",
    VOICEMAIL_GREETINGS_SET_AS_ACTIVE_BUTTON_CLICK = "voicemail-greetings-set-as-active-button-click",
    VOICEMAIL_GREETINGS_SET_AS_ACTIVE_SUCCESS = "voicemail-greetings-set-as-active-success",
    VOICEMAIL_GREETINGS_SET_AS_ACTIVE_FAILURE = "voicemail-greetings-set-as-active-failure",
    VOICEMAIL_GREETINGS_SET_AS_ACTIVE_CANCEL_BUTTON_CLICK = "voicemail-greetings-set-as-active-cancel-button-click",
    VOICEMAIL_GREETINGS_DELETE_BUTTON_CLICK = "voicemail-greetings-delete-button-click",
    VOICEMAIL_GREETINGS_DELETE_GREETING_SUCCESS = "voicemail-greetings-delete-greeting-success",
    VOICEMAIL_GREETINGS_DELETE_GREETING_FAILURE = "voicemail-greetings-delete-greeting-failure",
    VOICEMAIL_GREETINGS_DELETE_GREETING_CANCEL = "voicemail-greetings-delete-greeting-cancel",
    VOICEMAIL_GREETINGS_PLAY = "voicemail-greetings-play",
    VOICEMAIL_GREETINGS_RECORD_A_GREETING_RECORD_BUTTON_CLICKED = "voicemail-greetings-record-a-greeting-record-button-clicked",
    RECORD_A_GREETING_PLAY = "record-a-greeting-play-button-clicked",
    RECORD_A_GREETING_REDO = "record-a-greeting-redo-button-clicked",
    RECORD_A_GREETING_STOP = "record-a-greeting-stop-button-clicked",
    RECORD_A_GREETING_PAUSE = "record-a-greeting-pause-button-clicked",
    RECORD_A_GREETING_RESUME = "record-a-greeting-resume-button-clicked",
    RECORD_A_GREETING_SAVE = "record-a-greeting-save-button-clicked",
    RECORD_A_GREETING_CANCEL = "record-a-greeting-cancel-button-clicked",
    RECORD_A_GREETING_CANCEL_WHEN_CLICKED_OUTSIDE_THE_MODAL = "record-a-greeting-modal-closed",
    PROFILE_CLICKED = "profile-clicked",
    PROFILE_SAVE_SUCCESS = "profile-save-success",
    PROFILE_SAVE_FAILURE = "profile-save-failure",
    PROFILE_CHANGE_PHONE_NUMBER = "profile-change-phone-number",
    PROFILE_CHANGE_PHONE_NUMBER_SUCCESS = "profile-change-phone-number-success",
    PROFILE_CHANGE_PHONE_NUMBER_FAILURE = "profile-change-phone-number-failure",
    TEMPLATES = "templates",
    MESSAGE_TEMPLATES = "message-templates",
    VOICEMAIL_TEMPLATES = "voicemail-templates",
    EMAIL_TEMPLATES = "email-templates",
    TEMPLATE_1ST_CALL = "1st-call-template",
    TEMPLATE_2ND_CALL = "2nd-call-template",
    HELP_ICON_CLICKED = "help-icon-clicked",
    SEARCH_HELLO_GROVE = "seach-hello-grove",
    SEARCH_HELLO_GROVE_FAILURE = "seach-hello-grove-failure",
    LOGOUT = "logout",
}
