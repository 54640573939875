import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from './auth/auth.module';
import { GraphqlModule } from './graphql/graphql.module';
import { UserModule } from './user/user.module';
import { SharedModule } from './shared/shared.module';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { VoicemailsComponent } from './pages/voicemails/voicemails.component';
import { CallsComponent } from './pages/calls/calls.component';
import { TemplatesComponent } from './pages/templates/templates.component';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { AccountSetupModule } from './pages/account-setup/account-setup.module';
import { AddEditContactComponent } from './pages/contacts/add-edit-contact/add-edit-contact.component';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { AngMusicPlayerModule } from 'ang-music-player';
import { VoicemailGreetingService } from "./services/voicemail-greeting.service";
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MessageConversationListItemComponent } from './pages/messages/conversation-list-item/conversation-list-item.component';
import { MessageSmsItemComponent } from './pages/messages/sms-item/sms-item.component';
import { DeleteContactComponent } from './pages/contacts/delete-contact/delete-contact.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MessageCallConversationListItemComponent } from './pages/calls/call-conversation-list-item/call-conversation-list-item.component';
import { CallConversationSelectedComponent } from './pages/calls/call-conversation/call-conversation-selected/call-conversation-selected.component';
import { MessageCallInprogressComponent } from './pages/calls/calling/call-inprogress/call-inprogress.component';
import { VoicemailsModule } from './pages/voicemails/voicemails.module';
import { ContactDetailsComponent } from './pages/contacts/contact-details/contact-details.component';
import { ContactDetailsPlaceholderComponent } from './pages/contacts/contact-details-placeholder/contact-details-placeholder.component';
import { ConversationMessagesComponent } from './pages/messages/conversation-messages/conversation-messages.component';
import { ConversationPlaceholderComponent } from './pages/messages/conversation-placeholder/conversation-placeholder.component';
import { IntercomModule } from './intercom.module';
import { CallConversationPageComponent } from './pages/calls/call-conversation/call-conversation.component';
import { OutgoingCallPageComponent } from './pages/calls/calling/outgoing-call/outgoing-call.component';
import { VerifyCodeDialogComponent } from './pages/user-profile/verify-code-dialog/verify-code-dialog.component';
import { DialPageComponent } from './pages/calls/calling/dial-page/dial.component';
import { IncomingCallPageComponent } from './pages/calls/calling/incoming-call/incoming-call.component';
import { VoicemailGreetingsComponent } from './pages/voicemails/voicemail-greetings/voicemail-greetings.component';
import { VoicemailListItemComponent } from './pages/voicemails/voicemail-list-item/voicemail-list-item.component';
import { VoicemailDetailsComponent } from './pages/voicemails/voicemail-details/voicemail-details.component';
import { DeleteVoicemailComponent } from './pages/voicemails/modals/delete-voicemail/delete-messages.component';
import { ErrorNotificationComponent } from './pages/calls/error-message-notification/error-notification.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { SearchResultPageComponent } from './pages/search-result-page/search-result-page.component';


@NgModule({
  declarations: [
    AppComponent,
    ContactsComponent,
    MessagesComponent,
    VoicemailsComponent,
    VoicemailListItemComponent,
    VoicemailDetailsComponent,
    VoicemailGreetingsComponent,
    DeleteVoicemailComponent,
    CallsComponent,
    CallConversationPageComponent,
    DialPageComponent,
    IncomingCallPageComponent,
    OutgoingCallPageComponent,
    TemplatesComponent,
    AddEditContactComponent,
    UserProfileComponent,
    MessageConversationListItemComponent,
    MessageSmsItemComponent,
    DeleteContactComponent,
    MessageConversationListItemComponent,
    MessageSmsItemComponent,
    MessageCallConversationListItemComponent,
    CallConversationSelectedComponent,
    MessageCallInprogressComponent,
    ContactDetailsComponent,
    ContactDetailsPlaceholderComponent,
    ConversationMessagesComponent,
    ConversationPlaceholderComponent,
    VerifyCodeDialogComponent,
    ErrorNotificationComponent, 
    NotFoundPageComponent,
    SearchResultPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    IntercomModule,
    SharedModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    CommonModule,
    GraphqlModule,
    AuthModule,
    UserModule,
    AccountSetupModule,
    MatStepperModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    PickerModule,
    AccountSetupModule,
    MatSelectModule,
    MatListModule,
    AngMusicPlayerModule,
    MatExpansionModule,
    MatRadioModule,
    MatTooltipModule,
    VoicemailsModule,
    MatTabsModule
  ],
  providers: [VoicemailGreetingService, Title],
  bootstrap: [AppComponent],
  entryComponents: [AddEditContactComponent]
})
export class AppModule {
}
