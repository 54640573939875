import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TrackingService } from "src/app/services/tracking-service";
import { VoicemailGreetingWithRecording, VoicemailGreetingService } from "src/app/services/voicemail-greeting.service";
import { TrackingEventType } from "src/app/util/tracking-event-type";

@Component({
    selector: 'mc-delete-greeting',
    templateUrl: './delete-greeting-modal.component.html',
    styleUrls: ['./delete-greeting-modal.component.scss']
})
export class DeleteGreetingModalComponent {

  public voicemailGreeting: VoicemailGreetingWithRecording;

  constructor(@Inject(MAT_DIALOG_DATA) data: VoicemailGreetingWithRecording,
              private voicemailGreetingService: VoicemailGreetingService,
              private dialogRef: MatDialogRef<DeleteGreetingModalComponent>,
              private trackingService: TrackingService,) {
    this.voicemailGreeting = data;
  }

  public onConfirmDelete(): void {
    this.voicemailGreetingService.deleteVoicemailGreeting({
        voicemailGreetingId: this.voicemailGreeting.id
    }).subscribe(() => {
      this.dialogRef.close(true);
      this.trackingService.trackEvent(TrackingEventType.VOICEMAIL_GREETINGS_DELETE_GREETING_SUCCESS);
    });
  }

  public trackCancelButton(): void {
    this.trackingService.trackEvent(TrackingEventType.VOICEMAIL_GREETINGS_DELETE_GREETING_CANCEL);
  }
}
