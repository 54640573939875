import { Component } from '@angular/core';
import { AvatarSize } from '../avatar/avatar.component';
import { Observable, Subject, filter, map, take, takeUntil } from 'rxjs';
import { CallType, Contact, Conversation } from 'src/generated/graphql';
import { NavigationEnd, Router } from '@angular/router';
import { CallManager, CallManagerService } from '../services/call-manager.service';
import { CallService } from '../services/call.service';
import { ContactWithPicture, ContactsService } from '../services/contacts.service';

@Component({
  selector: 'mc-ongoing-call-dialog',
  templateUrl: './ongoing-call-dialog.component.html',
  styleUrls: ['./ongoing-call-dialog.component.scss']
})
export class OngoingCallDialogComponent {
  public readonly AvatarSize = AvatarSize;
  private readonly ON_COMPONENT_DESTROY = new Subject<void>();

  public contactPicture: string;
  public contact: ContactWithPicture;
  public participantNumber: string;
  public callDurationInSeconds: number;
  public callMuted = false;
  public displayOverlay = false;

  private callDisconnected = new Subject<void>();
  private callType: CallType;
  private conversationId: number;
  private callManager: CallManager;

  constructor(
    private router: Router,
    private callManagerService: CallManagerService,
    private contactsService: ContactsService,
    private callService: CallService) {
  }

  public ngOnInit(): void {
    this.callManagerService.getAcceptedCall().pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe(callManager => {
        if (!callManager) {
          return;
        }

        this.callManager = callManager;
        this.conversationId = callManager.conversation.id;
        this.callType = callManager.call.callType;
        this.participantNumber = callManager.conversation.participantNumber;

        this.getContactOfConversation(callManager.conversation).subscribe(contact => {
          this.contact = contact;
          this.getCallOngoingTime();

          this.onRouteChange().subscribe(() => {
            this.displayOverlay = this.shouldShowOverlay();
          });
        });
      });

    this.handleCallDisconnect();
  }

  public ngOnDestroy(): void {
    this.callDisconnected.next();
    this.callDisconnected.complete();
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }

  public toggleMute(): void {
    this.callMuted = !this.callMuted;
    this.callManager.twilioCall.mute(this.callMuted);
  }

  public hangupCall(): void {
    this.callManagerService.disconnectCall();
  }

  public maximizePage() {
    this.router.navigate(['calls', this.conversationId, this.callType.toLocaleLowerCase()]);
  }

  private handleCallDisconnect(): void {
    this.callManagerService.onDisconnectedEvent().pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe(callManager => {
        this.callService.updateCachedCall(callManager.call.id, callManager.conversation.id, {
          duration: callManager.call.duration,
        });

        this.callDisconnected.next();
        this, this.displayOverlay = false;
      });
  }

  private onRouteChange(): Observable<void> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.ON_COMPONENT_DESTROY),
      takeUntil(this.callDisconnected),
      map(() => { return; })
    );
  }

  private getCallOngoingTime(): void {
    this.callManager.callDurationTimer.observable.pipe(takeUntil(this.callDisconnected),
      takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe(callDurationInSeconds => {
        this.callDurationInSeconds = callDurationInSeconds;
      });
  }

  private getContactOfConversation(conversation: Conversation): Observable<Contact> {
    if (conversation.contactId) {
      return this.contactsService.getContactById(conversation.contactId);
    }

    return this.contactsService.getContactByPhoneNumber(conversation.participantNumber);
  }

  private shouldShowOverlay(): boolean {
    if (this.callManager.call.callType == "INCOMING") {
      return !this.inIncomingCallPage(this.callManager.conversation.id);
    }

    return !this.inOutgoingCallPage(this.callManager.conversation.id);
  }

  private inIncomingCallPage(conversationId: number): boolean {
    return this.router.isActive(`/calls/${conversationId}/incoming`, { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' });
  }

  private inOutgoingCallPage(conversationId: number): boolean {
    return this.router.isActive(`/calls/${conversationId}/outgoing`, { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' });
  }
}

