import { Component, Input } from '@angular/core';
import * as dayjs from 'dayjs';
import { AvatarSize } from 'src/app/shared/avatar/avatar.component';
import { VoicemailWithContactInformation } from 'src/app/shared/services/voicemails.service';

@Component({
  selector: 'mc-voicemail-list-item',
  templateUrl: './voicemail-list-item.component.html',
  styleUrls: ['./voicemail-list-item.component.scss']
})
export class VoicemailListItemComponent {
  
  public readonly AvatarSize = AvatarSize;

  @Input()
  public voicemail: VoicemailWithContactInformation;

  public get timestamp(): string {
    const today = dayjs(new Date());
    const date = dayjs(this.voicemail.createdAt);
    if (date.isSame(today, 'day')) return date.format('h:mm a');
    if (date.isSame(today, 'week')) return date.format('dddd');
    if (date.isSame(today, 'year')) return date.format('MMM D')
    return date.format('L');
  }

}
