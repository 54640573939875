import { Injectable } from "@angular/core";
import { Call as VoiceSdkCall, Device } from "@twilio/voice-sdk";
import { Observable, ReplaySubject, map, switchMap, take, tap } from "rxjs";
import { GenerateTwilioAccessTokenGQL, TwilioAccessToken, TwilioAccessTokenType } from "src/generated/graphql";
import { VoiceSdkCallEvents } from "./call.service";
import { ConnectionStateType } from "src/app/util/connection-state-type";
import { TwillioConnectionStateService } from "src/app/services/twillio-connection-state.service";

@Injectable({
    providedIn: 'root'
})
export class VoiceDeviceService {

  private voiceDevice: ReplaySubject<Device> = new ReplaySubject();

  private hasRegisteredDevice = false;

  public constructor(private generateTwilioAccessTokenGQL: GenerateTwilioAccessTokenGQL, private connectionStateService: TwillioConnectionStateService) {  
    this.autoRenewTwilioAccessToken();
  }

  public getVoiceDevice(): Observable<Device> {
    if (!this.hasRegisteredDevice) {
        return this.setupTwilioDevice();
    }

    return this.voiceDevice.asObservable();      
  }

  public setAudioDevice(audioDevice: MediaDeviceInfo): void {
    this.getVoiceDevice()
      .pipe(take(1))
      .subscribe(device => {
        device.audio.setInputDevice(audioDevice.deviceId);
      });
  }

  public registerCallBack(eventName: VoiceSdkCallEvents, callback: (call: VoiceSdkCall) => void): Observable<Device> {
   return this.getVoiceDevice()
      .pipe(
        tap(device => device.on(eventName, callback))
      );
  }

  public createVoiceCall(callConfig : any) : Observable<VoiceSdkCall> {
    return this.getVoiceDevice()
    .pipe(
      switchMap(device => {
      return device.connect(callConfig)}));
  } 

  private setupTwilioDevice(): Observable<Device> {
    return this.newTwilioAccessToken()
        .pipe(
          switchMap(({token}) => {
            const device = new Device(token, { closeProtection: true });
            
            device.on("error", (error) => {
              this.connectionStateService.updateConnectionState(ConnectionStateType.FAILED);
            });
            
            device.on("registered", () => {
              this.connectionStateService.updateConnectionState(ConnectionStateType.CONNECTED);
            });
            
            device.register();

            this.hasRegisteredDevice = true;            
            this.voiceDevice.next(device);
            return this.voiceDevice.asObservable();
          })
        );
  }

  private newTwilioAccessToken(): Observable<TwilioAccessToken> {
    return this.generateTwilioAccessTokenGQL
      .fetch({ type: TwilioAccessTokenType.VOICE })
      .pipe(map(({ data }) => data?.generateTwilioAccessToken));
  }

  private autoRenewTwilioAccessToken(): void {
    const interval = 1000 * 60 * 60;
    setInterval(() => {
      this.renewTwilioDeviceToken();
    }, interval); // calls every hour
  }


  private renewTwilioDeviceToken(): void {
    this.getVoiceDevice()
    .pipe(
        switchMap(device => this.newTwilioAccessToken()
        .pipe(tap(({ token }) => device.updateToken(token)))
    )).subscribe();
  }
}
