import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HeaderComponent } from './header/header.component';
import { AvatarComponent } from './avatar/avatar.component';
import { HeaderLinksComponent } from './header/header-links/header-links.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgClass, NgIf } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { SkeletonItemComponent } from './skeleton-item/skeleton-item.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatInputModule } from '@angular/material/input';
import { AuthModule } from "../auth/auth.module";
import { SearchContactsComponent } from './search-contacts/search-contacts.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PhoneNumberFormattingService } from "./services/phone-number-formatting.service";
import { MessageTemplatesPickerComponent } from './message-templates-picker/message-templates-picker.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { SecurePipe } from "../auth/secure.pipe";
import { SecondsToMinutesPipe } from "./pipes/seconds-to-minute-pipe";
import { GlobalSearchComponent } from './header/global-search/global-search.component';
import { AddNumberComponent } from './add-number/add-number.component';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { LoaderComponent } from './loader/loader.component';
import { PipesModule } from './pipes';
import { MessagesSkeletonComponent } from './messages-skeleton/messages-skeleton.component';
import { MessagesSkeletonItemComponent } from './messages-skeleton-item/messages-skeleton-item.component';
import { ClickOutsideDirective } from './directives/click-outside-directive.directive';
import { GreetingsSkeletonComponent } from './greetings-skeleton/greetings-skeleton.component';
import { GreetingsSkeletonItemComponent } from './greetings-skeleton-item/greetings-skeleton-item.component';
import { TemplatesSkeletonComponent } from './templates-skeleton/templates-skeleton.component';
import { MessageTemplatesSkeletonComponent } from './message-templates-skeleton/message-templates-skeleton.component';
import { IncomingCallWidgetComponent } from './incoming-call-widget/incoming-call-widget.component';
import { MusicPlayerSkeletonComponent } from './music-player-skeleton/music-player-skeleton.component';
import { MessageNotificationComponent } from './message-notification/message-notification.component';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { NgxMatIntlTelInputComponent } from "ngx-mat-intl-tel-input";
import { ToastrModule } from 'ngx-toastr';
import { CallDetailsSkeletonComponent } from '../pages/calls/call-details-skeleton/call-details-skeleton.component';
import { CallDetailsSkeletonItemComponent } from '../pages/calls/call-details-skeleton/call-details-skeleton-item/call-details-skeleton-item.component';
import { OngoingCallDialogComponent } from './ongoing-call-dialog/ongoing-call-dialog.component';
@NgModule({
  declarations: [
    HeaderComponent,
    AvatarComponent,
    HeaderLinksComponent,
    SkeletonComponent,
    SkeletonItemComponent,
    GlobalSearchComponent,
    SearchContactsComponent,
    MessageTemplatesPickerComponent,
    AddNumberComponent,
    LoaderComponent,
    MessagesSkeletonComponent,
    MessagesSkeletonItemComponent,
    ClickOutsideDirective,
    GreetingsSkeletonComponent,
    GreetingsSkeletonItemComponent,
    TemplatesSkeletonComponent,
    MessageTemplatesSkeletonComponent,
    IncomingCallWidgetComponent,
    MusicPlayerSkeletonComponent,
    MessageNotificationComponent,
    CallDetailsSkeletonComponent,
    CallDetailsSkeletonItemComponent,
    OngoingCallDialogComponent,
  ],
  imports: [
    BrowserModule,
    AuthModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    NgIf,
    RouterLink,
    NgClass,
    RouterLinkActive,
    MatAutocompleteModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatDialogModule,
    MatGridListModule,
    MatSelectModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    PipesModule,
    NgxMatIntlTelInputComponent,
    ToastrModule.forRoot(),
  ],
  exports: [
    HeaderComponent,
    HeaderLinksComponent,
    SkeletonComponent,
    AvatarComponent,
    SearchContactsComponent,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatSidenavModule,
    PipesModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    LoaderComponent,
    MessagesSkeletonComponent,
    MessagesSkeletonItemComponent,
    GreetingsSkeletonComponent,
    GreetingsSkeletonItemComponent,
    TemplatesSkeletonComponent,
    IncomingCallWidgetComponent,
    MusicPlayerSkeletonComponent,
    MessageNotificationComponent,
    NgxMatIntlTelInputComponent,
    CallDetailsSkeletonComponent,
    CallDetailsSkeletonItemComponent,
    OngoingCallDialogComponent
  ],
  providers: [PhoneNumberFormattingService, SecurePipe, SecondsToMinutesPipe, PhoneNumberPipe],
  bootstrap: [],
})
export class SharedModule {
}
