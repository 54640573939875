import { Component } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { AngMusicPlayerComponent } from 'ang-music-player';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { SecurePipe } from 'src/app/auth/secure.pipe';
import { VoicemailGreetingService, VoicemailGreetingWithRecording } from 'src/app/services/voicemail-greeting.service';
import { VoicemailGreetingModalFactoryService } from '../modals/greetings-modal-service';
import { MatDialogConfig } from '@angular/material/dialog';
import { TrackingService } from 'src/app/services/tracking-service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';

interface AngMusicPlayerInput {
  url: SafeUrl,
  title: string,
}

@Component({
  selector: 'mc-voicemail-greetings',
  templateUrl: './voicemail-greetings.component.html',
  styleUrls: ['./voicemail-greetings.component.scss']
})
export class VoicemailGreetingsComponent {

  private readonly ON_DESTROY = new Subject<void>();

  public loadingGreetings: boolean = false;
  public playing: boolean = false;
  public voicemailGreetingIdBeingPlayed: number;
  public voicemailGreetings: VoicemailGreetingWithRecording[] = [];
  public voicemailGreetingsRecordingsById = new Map<number, AngMusicPlayerInput[]>();

  public activeGreeting: VoicemailGreetingWithRecording;
  public others: VoicemailGreetingWithRecording[] = [];

  private currentVoicemailAudioObject: AngMusicPlayerComponent = undefined;

  constructor(
    private modalFactoryService: VoicemailGreetingModalFactoryService,
    private securePipe: SecurePipe,
    private voicemailGreetingsService: VoicemailGreetingService,
    private trackingService: TrackingService,
  ) {
  }

  public ngOnInit(): void {
    this.loadVoicemailGreetings();
  }

  public ngOnDestroy(): void {
    this.ON_DESTROY.next();
    this.ON_DESTROY.complete();
  }

  public openCreateVoicemailGreetingModal(): void {
    this.modalFactoryService.openCreateVoicemailGreetingModal({
      width: '589px',
    }).afterClosed().subscribe(newGreeting => {
      if (newGreeting) {
        this.loadingGreetings = true;
        this.voicemailGreetingsService.reloadGreetings().subscribe();
      }
    });
    this.trackingService.trackEvent(TrackingEventType.ADD_NEW_VOICEMAIL_GREETING);
  }

  public setAsActive(voicemailGreeting: VoicemailGreetingWithRecording): void {
    const dialogConfig: MatDialogConfig = {
      width: '589px',
      data: voicemailGreeting
    }
    this.modalFactoryService.openSetActiveModal(dialogConfig).afterClosed()
      .subscribe(
        (saved) => {
          if (saved) {
            this.loadingGreetings = true;
            this.voicemailGreetingsService.reloadGreetings()
              .pipe(takeUntil(this.ON_DESTROY))
              .subscribe();
          }
        }
      );

    this.trackingService.trackEvent(TrackingEventType.VOICEMAIL_GREETINGS_SET_AS_ACTIVE_BUTTON_CLICK);
  }

  public openDeleteVoicemailGreetingModal(voicemailGreeting: VoicemailGreetingWithRecording): void {
    this.modalFactoryService.openDeleteVoicemailGreetingModal({
      width: '589px',
      data: voicemailGreeting
    }).afterClosed().pipe(takeUntil(this.ON_DESTROY)).subscribe((deleted) => {
      if (deleted) {
        this.loadingGreetings = true;
        this.activeGreeting = undefined;
        this.voicemailGreetingsRecordingsById.delete(voicemailGreeting.id);
        this.voicemailGreetingsService.reloadGreetings().subscribe();
      }
    }
    );
    this.trackingService.trackEvent(TrackingEventType.VOICEMAIL_GREETINGS_DELETE_BUTTON_CLICK);
  }

  public playVoicemailGreeting(audioPlayer: AngMusicPlayerComponent, voicemailGreetingToPlay: VoicemailGreetingWithRecording): void {
    audioPlayer.isAudioEnded.pipe(take(1)).subscribe(() => {
      this.playing = false;
      this.voicemailGreetingIdBeingPlayed = undefined;
    });

    if (this.currentVoicemailAudioObject?.isAudioPlaying) {
      if (this.voicemailGreetingIdBeingPlayed == voicemailGreetingToPlay.id) {
        audioPlayer.pause();
        this.voicemailGreetingIdBeingPlayed = undefined;
        this.playing = false;
        return;
      }

      this.currentVoicemailAudioObject.pause();
      audioPlayer.play();
      this.playing = true
      this.currentVoicemailAudioObject = audioPlayer;
      this.voicemailGreetingIdBeingPlayed = voicemailGreetingToPlay.id;
      return;
    }

    this.currentVoicemailAudioObject = audioPlayer;

    audioPlayer.play();
    this.voicemailGreetingIdBeingPlayed = voicemailGreetingToPlay.id;
    this.playing = true;
  }

  private loadVoicemailGreetings(): void {
    this.loadingGreetings = true;

    this.voicemailGreetingsService.getVoicemailGreetingsOfUser()
      .pipe(takeUntil(this.ON_DESTROY))
      .subscribe(voicemailGreetings => {
        this.voicemailGreetings = voicemailGreetings;
        this.others = [];

        voicemailGreetings.forEach(voicemailGreeting => {
          if (!this.voicemailGreetingsRecordingsById.has(voicemailGreeting.id)) {
            this.getRecordingOfVoicemailGreeting(voicemailGreeting).subscribe((recording: SafeUrl) => {
              this.voicemailGreetingsRecordingsById.set(voicemailGreeting.id, [{
                url: recording,
                title: voicemailGreeting.name,
              }]);
              if (this.voicemailGreetingsRecordingsById.size == voicemailGreetings.length) {
                this.loadingGreetings = false;
              }
            });
          }

          if (voicemailGreeting.active) {
            this.activeGreeting = voicemailGreeting;
          } else {
            this.others.push(voicemailGreeting);
          }

        })
          if (this.voicemailGreetingsRecordingsById.size == voicemailGreetings.length) {
            this.loadingGreetings = false;
          }
      });
  }

  private getRecordingOfVoicemailGreeting(voicemailGreeting: VoicemailGreetingWithRecording): Observable<SafeUrl> {
    return this.securePipe.transform(voicemailGreeting.recordingUrl);
  }

}
