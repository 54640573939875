import { Injectable } from "@angular/core";
import { makeUniqueId } from "@apollo/client/utilities";
import { BehaviorSubject, Observable } from "rxjs";


export interface Timer {
  id: string,
  observable: Observable<number>
}

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  private activeTimers = new Map<string, BehaviorSubject<number>>();

  public constructor() {
  }

  public startTimer(): Timer {
    return this.createTimer(0, (timer) => {
      const currentTime = timer.getValue();
      timer.next(currentTime + 1);
    });
  }

  public stopTimer(timerId: string): number {
    const timer = this.activeTimers.get(timerId);

    if (timer) {
      const time = timer.getValue();
      timer.complete();
      clearInterval(timerId);
      this.activeTimers.delete(timerId);
      return time;
    }
    
    return 0;
  }

  public startCountDown(countDown: number): Timer {
    return this.createTimer(countDown, (timer) => {
      const currentTime = timer.getValue();
      timer.next(currentTime - 1);
    });
  }

  private createTimer(
    start: number,
    intervalCallback: (timer: BehaviorSubject<number>) => void
  ): Timer {
    const timer = new BehaviorSubject<number>(start);
    const timerId = setInterval(() => intervalCallback(timer), 1000);

    this.activeTimers.set(timerId.toString(), timer);

    return {
      id: timerId.toString(),
      observable: timer.asObservable()
    };
  }
}
