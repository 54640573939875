import { Inject, Injectable } from '@angular/core';
import {
  Auth0ClientService,
  AuthService as Auth0Service,
} from '@auth0/auth0-angular';
import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  map,
  Observable,
  ReplaySubject, Subject,
  switchMap,
  take, takeUntil,
  tap,
} from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public readonly isLoggedIn = new ReplaySubject<boolean>(1);

  private jwtToken = new BehaviorSubject<string>(null);

  private logoutNotifier = new Subject<void>();

  constructor(
    private auth0Service: Auth0Service,
    @Inject(Auth0ClientService) private auth0Client: Auth0Client,
    private router: Router,
    private location: Location
  ) {
    this.auth0Service.user$.pipe(
      takeUntil(this.logoutNotifier)
    )
    .subscribe((user) => {
      if (!user) {

        if (!localStorage.getItem('redirectUrl')) {
          localStorage.setItem('redirectUrl', this.location.path());
        }

        this.login();
        return;
      }

      this.updateLoggedInStatus(true);
      const redirectUrl = localStorage.getItem('redirectUrl');

      if (redirectUrl) {
        const { path, queryParams } = this.getNavigationFieldsFromURL(redirectUrl);

        this.router.navigate([path], { queryParams })
        .catch(() => {
          this.router.navigate(['']);
        })
        .finally(() => {
          localStorage.removeItem('redirectUrl');
        });
      }
    });
  }

  public login(): void {
    this.auth0Service
      .loginWithRedirect({
        audience: environment.auth0.audience,
      })
      .subscribe();
  }

  public logout(): void {
    this.auth0Service.logout({ returnTo: window.origin });
    localStorage.removeItem('redirectUrl');

    this.logoutNotifier.next();
  }

  public getJwtTokenAsPromise(): Promise<string> {
    return firstValueFrom(this.getJwtToken());
  }

  public updateLoggedInStatus(value: boolean): void {
    this.isLoggedIn.next(value);
  }

  public getJwtToken(): Observable<string> {
    if (this.jwtToken.value) {
      return this.jwtToken;
    }

    return this.auth0Service.getAccessTokenSilently();

    return this.auth0Service.getIdTokenClaims().pipe(
      filter((c) => c != null),
      map((c) => c.__raw),
      take(1),
      tap((token) => this.jwtToken.next(token)),
      switchMap(() => this.jwtToken)
    );
  }

  private getNavigationFieldsFromURL(redirectUrl: string) {
    const url = new URL(redirectUrl, window.location.origin);

    const path = url.pathname;
    const params = new URLSearchParams(url.search);

    const queryParams: { [key: string]: string } = {};
    params.forEach((value, key) => {
      queryParams[key] = value;
    });
    return { path, queryParams };
  }
}
