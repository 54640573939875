import { Injectable } from "@angular/core";
import { TrackEventGQL } from "src/generated/graphql";
import { TrackingEventType } from "../util/tracking-event-type";

@Injectable({
    providedIn: 'root'
})
export class TrackingService {
    constructor(private trackEventGQL: TrackEventGQL) {
    }

    public trackEvent(eventName: TrackingEventType, eventData: object = {}): void {
        this.trackEventGQL.mutate({
            value: {
                eventName: eventName.toString(),
                eventData: JSON.stringify(eventData)
            }
        }).subscribe();
    }
}
