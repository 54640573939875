import { Component, OnDestroy, OnInit } from "@angular/core";
import { AvatarSize } from 'src/app/shared/avatar/avatar.component';
import { Subject, takeUntil } from "rxjs";
import { ConversationType } from 'src/generated/graphql';
import { PhoneNumberOrContact } from "src/app/shared/search-contacts/search-contacts.component";
import { PhoneNumberFormattingService } from "src/app/shared/services/phone-number-formatting.service";
import { ConversationNavigationService } from "src/app/shared/services/conversation-navigation.service";
import { TrackingService } from "src/app/services/tracking-service";
import { TrackingEventType } from "src/app/util/tracking-event-type";
import { ActivatedRoute, ParamMap } from '@angular/router';
import { stringToBool } from "src/app/util/strings";
import { Timer, TimerService } from "src/app/shared/services/timer-service";

@Component({
  selector: 'mc-messages-dial',
  templateUrl: './dial.component.html',
  styleUrls: ['./dial.component.scss']
})
export class DialPageComponent implements OnInit, OnDestroy {

  private readonly ON_COMPONENT_DESTROY = new Subject<void>();
  private readonly PHONE_NUMBER_QUERY_KEY = 'phoneNumber';
  private readonly START_CALL_QUERY_KEY = 'startCall';

  public readonly ConversationType = ConversationType;
  public readonly AvatarSize = AvatarSize;

  public selectedPhoneNumber?: string;
  public phoneNumberFromURL?: string;
  public shouldStartCall = false;
  public seconds: number;
  public disableCallButton = false;

  private countDown: Timer;

  constructor(
    private conversationNavigationService: ConversationNavigationService,
    private phoneFormattingService: PhoneNumberFormattingService,
    private trackingService: TrackingService,
    private route: ActivatedRoute,
    private timerService: TimerService,
  ) {
  }

  public ngOnInit(): void {
    this.handleQueryParams(this.route.snapshot.queryParamMap);
  }

  private handleQueryParams(queryParamMap: ParamMap): void {
    this.phoneNumberFromURL = queryParamMap.get(this.PHONE_NUMBER_QUERY_KEY);
    if (this.phoneNumberFromURL) {
      this.onNumberOrContactSelected(this.phoneNumberFromURL);
      this.shouldStartCall = stringToBool(queryParamMap.get(this.START_CALL_QUERY_KEY));
      if (this.shouldStartCall) this.startAutoCall();
    }
  }

  private startAutoCall(): void {
    this.disableCallButton = true;
    this.countDown = this.timerService.startCountDown(5);
    this.countDown.observable
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe(seconds => {
        if (seconds === 0) {
          this.timerService.stopTimer(this.countDown.id);
          return this.startCall();
        }
        this.seconds = seconds;
      });
  }

  public cancelAutoCall(): void {
    this.timerService.stopTimer(this.countDown.id);
    this.disableCallButton = false;
    this.shouldStartCall = false;
  }

  public onNumberOrContactSelected(value: PhoneNumberOrContact): void {
    if (typeof value === 'string') {
      this.selectedPhoneNumber = this.phoneFormattingService.formatE164(value);
      return;
    }
    this.selectedPhoneNumber = value.preferredPhoneNumber || value.phoneNumbers[0].phoneNumber;
  }

  public startCall(): void {
    this.disableCallButton = true;
    this.conversationNavigationService.callNumber(this.selectedPhoneNumber);
    this.trackingService.trackEvent(TrackingEventType.MAKE_CALL_BUTTON_CLICK);
  }

  public ngOnDestroy(): void {
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }

}
