import { Component, OnDestroy, OnInit } from "@angular/core";
import { Conversation } from '../../../../../generated/graphql';
import { AvatarSize } from '../../../../shared/avatar/avatar.component';
import { CallService, VoiceSdkCallEvents } from "src/app/shared/services/call.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, take, takeUntil } from "rxjs";
import { ContactWithPicture } from "src/app/shared/services/contacts.service";
import { ContactsService } from '../../../../shared/services/contacts.service';
import { SelectedCallingConversationService } from "src/app/shared/services/selection/selected-call-conversation.service";
import { CallManager, CallManagerService } from '../../../../shared/services/call-manager.service';

@Component({
  selector: 'mc-messages-outgoing-call',
  templateUrl: './outgoing-call.component.html',
  styleUrls: ['./outgoing-call.component.scss']
})
export class OutgoingCallPageComponent implements OnInit, OnDestroy {

  private readonly ON_COMPONENT_DESTROY = new Subject<void>();
  public readonly AvatarSize = AvatarSize;

  public loading = true;
  public callInProgress: boolean;
  public callManager: CallManager | null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private callService: CallService,
    private contactsService: ContactsService,
    private selectedCallingConversationService: SelectedCallingConversationService,
    private callManagerService: CallManagerService,
  ) {
  }

  public ngOnInit(): void {
    this.callInProgress = true;
    this.loadConversationFromRoute();

    this.handleCallDisconnect();
  }

  public ngOnDestroy(): void {
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }

  public get callLoaded(): boolean {
    return !this.loading && this.callManager != null;
  }

  public get conversationContactPicture(): string {
    const contact = this.callManager.conversation?.contact as ContactWithPicture;
    return contact?.pictureUrl;
  }

  public startCall(conversation: Conversation) {
    this.callManagerService.startNewCall(conversation.participantNumber, (callManager) => {
      this.callManager = callManager;
      this.loading = false;

      if (!callManager.conversation.contact) {
        this.contactsService
          .getContactById(callManager.conversation.contactId)
          .pipe(take(1))
          .subscribe(contact => callManager.conversation.contact = contact);
      }

      // cache call
      this.callService.cacheNewCall(callManager.call, callManager.conversation);
    });
  }

  public disconnectCall(): void {
    this.callManagerService.disconnectCall();
  }

  private loadConversationFromRoute(): void {
    this.route.paramMap
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe((params) => {
        const conversationId = Number(params.get('conversationId'));
        this.callService.getConversationById(conversationId)
          .subscribe(conversation => {
            if (!conversation) {
              this.router.navigate(['calls']);
              return;
            }

            this.selectedCallingConversationService.setSelectedItemId(conversationId);

            if (!this.callManagerService.isCallInProgress()) {
              // start call once conversation is ready
              this.startCall(conversation);
              return;
            }

            this.continueCall();
          });
      });
  }

  private handleCallDisconnect(): void {
    this.callManagerService.onDisconnectedEvent()
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe(callManager => {
        this.callService.updateCachedCall(this.callManager.call.id, this.callManager.conversation.id, {
          duration: callManager.call.duration
        });

        this.callInProgress = false;
        setTimeout(() => this.selectedCallingConversationService.goBackToConversationPage(callManager.conversation.id), 1000);
      });
  }

  private continueCall(): void {
    this.callManagerService.getAcceptedCall()
      .subscribe(callManager => {
        if (!callManager) {
          return;
        }

        this.callManager = callManager;
        this.loading = false;

        if (!callManager.conversation.contact) {
          this.contactsService
            .getContactById(callManager.conversation.contactId)
            .pipe(take(1))
            .subscribe(contact => callManager.conversation.contact = contact);
        }
      })
  }
}
