export interface AreaCode {
  code: number,
  state: string;
  lowercaseState: string;
}

export const USA_AREA_CODES: AreaCode[] = [
  { code: 205, state: 'Alabama', lowercaseState: 'alabama' },
  { code: 251, state: 'Alabama', lowercaseState: 'alabama' },
  { code: 256, state: 'Alabama', lowercaseState: 'alabama' },
  { code: 334, state: 'Alabama', lowercaseState: 'alabama' },
  { code: 907, state: 'Alaska', lowercaseState: 'alaska' },
  { code: 480, state: 'Arizona', lowercaseState: 'arizona' },
  { code: 520, state: 'Arizona', lowercaseState: 'arizona' },
  { code: 602, state: 'Arizona', lowercaseState: 'arizona' },
  { code: 623, state: 'Arizona', lowercaseState: 'arizona' },
  { code: 928, state: 'Arizona', lowercaseState: 'arizona' },
  { code: 479, state: 'Arkansas', lowercaseState: 'arkansas' },
  { code: 501, state: 'Arkansas', lowercaseState: 'arkansas' },
  { code: 870, state: 'Arkansas', lowercaseState: 'arkansas' },
  { code: 209, state: 'California', lowercaseState: 'california' },
  { code: 213, state: 'California', lowercaseState: 'california' },
  { code: 310, state: 'California', lowercaseState: 'california' },
  { code: 323, state: 'California', lowercaseState: 'california' },
  { code: 408, state: 'California', lowercaseState: 'california' },
  { code: 415, state: 'California', lowercaseState: 'california' },
  { code: 424, state: 'California', lowercaseState: 'california' },
  { code: 442, state: 'California', lowercaseState: 'california' },
  { code: 510, state: 'California', lowercaseState: 'california' },
  { code: 530, state: 'California', lowercaseState: 'california' },
  { code: 559, state: 'California', lowercaseState: 'california' },
  { code: 562, state: 'California', lowercaseState: 'california' },
  { code: 619, state: 'California', lowercaseState: 'california' },
  { code: 626, state: 'California', lowercaseState: 'california' },
  { code: 628, state: 'California', lowercaseState: 'california' },
  { code: 650, state: 'California', lowercaseState: 'california' },
  { code: 657, state: 'California', lowercaseState: 'california' },
  { code: 661, state: 'California', lowercaseState: 'california' },
  { code: 669, state: 'California', lowercaseState: 'california' },
  { code: 707, state: 'California', lowercaseState: 'california' },
  { code: 714, state: 'California', lowercaseState: 'california' },
  { code: 747, state: 'California', lowercaseState: 'california' },
  { code: 760, state: 'California', lowercaseState: 'california' },
  { code: 805, state: 'California', lowercaseState: 'california' },
  { code: 818, state: 'California', lowercaseState: 'california' },
  { code: 831, state: 'California', lowercaseState: 'california' },
  { code: 858, state: 'California', lowercaseState: 'california' },
  { code: 909, state: 'California', lowercaseState: 'california' },
  { code: 916, state: 'California', lowercaseState: 'california' },
  { code: 925, state: 'California', lowercaseState: 'california' },
  { code: 949, state: 'California', lowercaseState: 'california' },
  { code: 951, state: 'California', lowercaseState: 'california' },
  { code: 303, state: 'Colorado', lowercaseState: 'colorado' },
  { code: 719, state: 'Colorado', lowercaseState: 'colorado' },
  { code: 720, state: 'Colorado', lowercaseState: 'colorado' },
  { code: 970, state: 'Colorado', lowercaseState: 'colorado' },
  { code: 203, state: 'Connecticut', lowercaseState: 'connecticut' },
  { code: 860, state: 'Connecticut', lowercaseState: 'connecticut' },
  { code: 959, state: 'Connecticut', lowercaseState: 'connecticut' },
  { code: 302, state: 'Delaware', lowercaseState: 'delaware' },
  { code: 202, state: 'District of Columbia', lowercaseState: 'district of columbia' },
  { code: 239, state: 'Florida', lowercaseState: 'florida' },
  { code: 305, state: 'Florida', lowercaseState: 'florida' },
  { code: 321, state: 'Florida', lowercaseState: 'florida' },
  { code: 352, state: 'Florida', lowercaseState: 'florida' },
  { code: 386, state: 'Florida', lowercaseState: 'florida' },
  { code: 407, state: 'Florida', lowercaseState: 'florida' },
  { code: 561, state: 'Florida', lowercaseState: 'florida' },
  { code: 727, state: 'Florida', lowercaseState: 'florida' },
  { code: 754, state: 'Florida', lowercaseState: 'florida' },
  { code: 772, state: 'Florida', lowercaseState: 'florida' },
  { code: 786, state: 'Florida', lowercaseState: 'florida' },
  { code: 813, state: 'Florida', lowercaseState: 'florida' },
  { code: 850, state: 'Florida', lowercaseState: 'florida' },
  { code: 863, state: 'Florida', lowercaseState: 'florida' },
  { code: 904, state: 'Florida', lowercaseState: 'florida' },
  { code: 941, state: 'Florida', lowercaseState: 'florida' },
  { code: 954, state: 'Florida', lowercaseState: 'florida' },
  { code: 229, state: 'Georgia', lowercaseState: 'georgia' },
  { code: 404, state: 'Georgia', lowercaseState: 'georgia' },
  { code: 478, state: 'Georgia', lowercaseState: 'georgia' },
  { code: 678, state: 'Georgia', lowercaseState: 'georgia' },
  { code: 706, state: 'Georgia', lowercaseState: 'georgia' },
  { code: 762, state: 'Georgia', lowercaseState: 'georgia' },
  { code: 770, state: 'Georgia', lowercaseState: 'georgia' },
  { code: 912, state: 'Georgia', lowercaseState: 'georgia' },
  { code: 808, state: 'Hawaii', lowercaseState: 'hawaii' },
  { code: 208, state: 'Idaho', lowercaseState: 'idaho' },
  { code: 217, state: 'Illinois', lowercaseState: 'illinois' },
  { code: 224, state: 'Illinois', lowercaseState: 'illinois' },
  { code: 309, state: 'Illinois', lowercaseState: 'illinois' },
  { code: 312, state: 'Illinois', lowercaseState: 'illinois' },
  { code: 331, state: 'Illinois', lowercaseState: 'illinois' },
  { code: 618, state: 'Illinois', lowercaseState: 'illinois' },
  { code: 630, state: 'Illinois', lowercaseState: 'illinois' },
  { code: 708, state: 'Illinois', lowercaseState: 'illinois' },
  { code: 773, state: 'Illinois', lowercaseState: 'illinois' },
  { code: 779, state: 'Illinois', lowercaseState: 'illinois' },
  { code: 815, state: 'Illinois', lowercaseState: 'illinois' },
  { code: 847, state: 'Illinois', lowercaseState: 'illinois' },
  { code: 219, state: 'Indiana', lowercaseState: 'indiana' },
  { code: 260, state: 'Indiana', lowercaseState: 'indiana' },
  { code: 317, state: 'Indiana', lowercaseState: 'indiana' },
  { code: 574, state: 'Indiana', lowercaseState: 'indiana' },
  { code: 765, state: 'Indiana', lowercaseState: 'indiana' },
  { code: 812, state: 'Indiana', lowercaseState: 'indiana' },
  { code: 930, state: 'Indiana', lowercaseState: 'indiana' },
  { code: 319, state: 'Iowa', lowercaseState: 'iowa' },
  { code: 515, state: 'Iowa', lowercaseState: 'iowa' },
  { code: 563, state: 'Iowa', lowercaseState: 'iowa' },
  { code: 641, state: 'Iowa', lowercaseState: 'iowa' },
  { code: 712, state: 'Iowa', lowercaseState: 'iowa' },
  { code: 316, state: 'Kansas', lowercaseState: 'kansas' },
  { code: 620, state: 'Kansas', lowercaseState: 'kansas' },
  { code: 785, state: 'Kansas', lowercaseState: 'kansas' },
  { code: 913, state: 'Kansas', lowercaseState: 'kansas' },
  { code: 270, state: 'Kentucky', lowercaseState: 'kentucky' },
  { code: 364, state: 'Kentucky', lowercaseState: 'kentucky' },
  { code: 502, state: 'Kentucky', lowercaseState: 'kentucky' },
  { code: 606, state: 'Kentucky', lowercaseState: 'kentucky' },
  { code: 859, state: 'Kentucky', lowercaseState: 'kentucky' },
  { code: 225, state: 'Louisiana', lowercaseState: 'louisiana' },
  { code: 318, state: 'Louisiana', lowercaseState: 'louisiana' },
  { code: 337, state: 'Louisiana', lowercaseState: 'louisiana' },
  { code: 504, state: 'Louisiana', lowercaseState: 'louisiana' },
  { code: 985, state: 'Louisiana', lowercaseState: 'louisiana' },
  { code: 207, state: 'Maine', lowercaseState: 'maine' },
  { code: 240, state: 'Maryland', lowercaseState: 'maryland' },
  { code: 301, state: 'Maryland', lowercaseState: 'maryland' },
  { code: 410, state: 'Maryland', lowercaseState: 'maryland' },
  { code: 443, state: 'Maryland', lowercaseState: 'maryland' },
  { code: 667, state: 'Maryland', lowercaseState: 'maryland' },
  { code: 339, state: 'Massachusetts', lowercaseState: 'massachusetts' },
  { code: 351, state: 'Massachusetts', lowercaseState: 'massachusetts' },
  { code: 413, state: 'Massachusetts', lowercaseState: 'massachusetts' },
  { code: 508, state: 'Massachusetts', lowercaseState: 'massachusetts' },
  { code: 617, state: 'Massachusetts', lowercaseState: 'massachusetts' },
  { code: 774, state: 'Massachusetts', lowercaseState: 'massachusetts' },
  { code: 781, state: 'Massachusetts', lowercaseState: 'massachusetts' },
  { code: 857, state: 'Massachusetts', lowercaseState: 'massachusetts' },
  { code: 978, state: 'Massachusetts', lowercaseState: 'massachusetts' },
  { code: 231, state: 'Michigan', lowercaseState: 'michigan' },
  { code: 248, state: 'Michigan', lowercaseState: 'michigan' },
  { code: 269, state: 'Michigan', lowercaseState: 'michigan' },
  { code: 313, state: 'Michigan', lowercaseState: 'michigan' },
  { code: 517, state: 'Michigan', lowercaseState: 'michigan' },
  { code: 586, state: 'Michigan', lowercaseState: 'michigan' },
  { code: 616, state: 'Michigan', lowercaseState: 'michigan' },
  { code: 734, state: 'Michigan', lowercaseState: 'michigan' },
  { code: 810, state: 'Michigan', lowercaseState: 'michigan' },
  { code: 947, state: 'Michigan', lowercaseState: 'michigan' },
  { code: 989, state: 'Michigan', lowercaseState: 'michigan' },
  { code: 218, state: 'Minnesota', lowercaseState: 'minnesota' },
  { code: 320, state: 'Minnesota', lowercaseState: 'minnesota' },
  { code: 507, state: 'Minnesota', lowercaseState: 'minnesota' },
  { code: 612, state: 'Minnesota', lowercaseState: 'minnesota' },
  { code: 651, state: 'Minnesota', lowercaseState: 'minnesota' },
  { code: 763, state: 'Minnesota', lowercaseState: 'minnesota' },
  { code: 952, state: 'Minnesota', lowercaseState: 'minnesota' },
  { code: 228, state: 'Mississippi', lowercaseState: 'mississippi' },
  { code: 601, state: 'Mississippi', lowercaseState: 'mississippi' },
  { code: 662, state: 'Mississippi', lowercaseState: 'mississippi' },
  { code: 769, state: 'Mississippi', lowercaseState: 'mississippi' },
  { code: 314, state: 'Missouri', lowercaseState: 'missouri' },
  { code: 417, state: 'Missouri', lowercaseState: 'missouri' },
  { code: 573, state: 'Missouri', lowercaseState: 'missouri' },
  { code: 636, state: 'Missouri', lowercaseState: 'missouri' },
  { code: 660, state: 'Missouri', lowercaseState: 'missouri' },
  { code: 816, state: 'Missouri', lowercaseState: 'missouri' },
  { code: 406, state: 'Montana', lowercaseState: 'montana' },
  { code: 308, state: 'Nebraska', lowercaseState: 'nebraska' },
  { code: 402, state: 'Nebraska', lowercaseState: 'nebraska' },
  { code: 531, state: 'Nebraska', lowercaseState: 'nebraska' },
  { code: 702, state: 'Nevada', lowercaseState: 'nevada' },
  { code: 725, state: 'Nevada', lowercaseState: 'nevada' },
  { code: 775, state: 'Nevada', lowercaseState: 'nevada' },
  { code: 603, state: 'New Hampshire', lowercaseState: 'new hampshire' },
  { code: 201, state: 'New Jersey', lowercaseState: 'new jersey' },
  { code: 551, state: 'New Jersey', lowercaseState: 'new jersey' },
  { code: 609, state: 'New Jersey', lowercaseState: 'new jersey' },
  { code: 732, state: 'New Jersey', lowercaseState: 'new jersey' },
  { code: 848, state: 'New Jersey', lowercaseState: 'new jersey' },
  { code: 856, state: 'New Jersey', lowercaseState: 'new jersey' },
  { code: 862, state: 'New Jersey', lowercaseState: 'new jersey' },
  { code: 908, state: 'New Jersey', lowercaseState: 'new jersey' },
  { code: 973, state: 'New Jersey', lowercaseState: 'new jersey' },
  { code: 505, state: 'New Mexico', lowercaseState: 'new mexico' },
  { code: 575, state: 'New Mexico', lowercaseState: 'new mexico' },
  { code: 212, state: 'New York', lowercaseState: 'new york' },
  { code: 315, state: 'New York', lowercaseState: 'new york' },
  { code: 347, state: 'New York', lowercaseState: 'new york' },
  { code: 516, state: 'New York', lowercaseState: 'new york' },
  { code: 518, state: 'New York', lowercaseState: 'new york' },
  { code: 585, state: 'New York', lowercaseState: 'new york' },
  { code: 607, state: 'New York', lowercaseState: 'new york' },
  { code: 631, state: 'New York', lowercaseState: 'new york' },
  { code: 646, state: 'New York', lowercaseState: 'new york' },
  { code: 716, state: 'New York', lowercaseState: 'new york' },
  { code: 718, state: 'New York', lowercaseState: 'new york' },
  { code: 845, state: 'New York', lowercaseState: 'new york' },
  { code: 914, state: 'New York', lowercaseState: 'new york' },
  { code: 917, state: 'New York', lowercaseState: 'new york' },
  { code: 929, state: 'New York', lowercaseState: 'new york' },
  { code: 252, state: 'North Carolina', lowercaseState: 'north carolina' },
  { code: 336, state: 'North Carolina', lowercaseState: 'north carolina' },
  { code: 704, state: 'North Carolina', lowercaseState: 'north carolina' },
  { code: 743, state: 'North Carolina', lowercaseState: 'north carolina' },
  { code: 828, state: 'North Carolina', lowercaseState: 'north carolina' },
  { code: 910, state: 'North Carolina', lowercaseState: 'north carolina' },
  { code: 919, state: 'North Carolina', lowercaseState: 'north carolina' },
  { code: 980, state: 'North Carolina', lowercaseState: 'north carolina' },
  { code: 984, state: 'North Carolina', lowercaseState: 'north carolina' },
  { code: 701, state: 'North Dakota', lowercaseState: 'north dakota' },
  { code: 216, state: 'Ohio', lowercaseState: 'ohio' },
  { code: 220, state: 'Ohio', lowercaseState: 'ohio' },
  { code: 234, state: 'Ohio', lowercaseState: 'ohio' },
  { code: 330, state: 'Ohio', lowercaseState: 'ohio' },
  { code: 419, state: 'Ohio', lowercaseState: 'ohio' },
  { code: 440, state: 'Ohio', lowercaseState: 'ohio' },
  { code: 513, state: 'Ohio', lowercaseState: 'ohio' },
  { code: 567, state: 'Ohio', lowercaseState: 'ohio' },
  { code: 614, state: 'Ohio', lowercaseState: 'ohio' },
  { code: 740, state: 'Ohio', lowercaseState: 'ohio' },
  { code: 937, state: 'Ohio', lowercaseState: 'ohio' },
  { code: 405, state: 'Oklahoma', lowercaseState: 'oklahoma' },
  { code: 539, state: 'Oklahoma', lowercaseState: 'oklahoma' },
  { code: 580, state: 'Oklahoma', lowercaseState: 'oklahoma' },
  { code: 918, state: 'Oklahoma', lowercaseState: 'oklahoma' },
  { code: 458, state: 'Oregon', lowercaseState: 'oregon' },
  { code: 503, state: 'Oregon', lowercaseState: 'oregon' },
  { code: 541, state: 'Oregon', lowercaseState: 'oregon' },
  { code: 971, state: 'Oregon', lowercaseState: 'oregon' },
  { code: 215, state: 'Pennsylvania', lowercaseState: 'pennsylvania' },
  { code: 267, state: 'Pennsylvania', lowercaseState: 'pennsylvania' },
  { code: 272, state: 'Pennsylvania', lowercaseState: 'pennsylvania' },
  { code: 412, state: 'Pennsylvania', lowercaseState: 'pennsylvania' },
  { code: 484, state: 'Pennsylvania', lowercaseState: 'pennsylvania' },
  { code: 570, state: 'Pennsylvania', lowercaseState: 'pennsylvania' },
  { code: 610, state: 'Pennsylvania', lowercaseState: 'pennsylvania' },
  { code: 717, state: 'Pennsylvania', lowercaseState: 'pennsylvania' },
  { code: 724, state: 'Pennsylvania', lowercaseState: 'pennsylvania' },
  { code: 814, state: 'Pennsylvania', lowercaseState: 'pennsylvania' },
  { code: 878, state: 'Pennsylvania', lowercaseState: 'pennsylvania' },
  { code: 401, state: 'Rhode Island', lowercaseState: 'rhode island' },
  { code: 803, state: 'South Carolina', lowercaseState: 'south carolina' },
  { code: 843, state: 'South Carolina', lowercaseState: 'south carolina' },
  { code: 854, state: 'South Carolina', lowercaseState: 'south carolina' },
  { code: 864, state: 'South Carolina', lowercaseState: 'south carolina' },
  { code: 605, state: 'South Dakota', lowercaseState: 'south dakota' },
  { code: 423, state: 'Tennessee', lowercaseState: 'tennessee' },
  { code: 615, state: 'Tennessee', lowercaseState: 'tennessee' },
  { code: 629, state: 'Tennessee', lowercaseState: 'tennessee' },
  { code: 731, state: 'Tennessee', lowercaseState: 'tennessee' },
  { code: 865, state: 'Tennessee', lowercaseState: 'tennessee' },
  { code: 901, state: 'Tennessee', lowercaseState: 'tennessee' },
  { code: 931, state: 'Tennessee', lowercaseState: 'tennessee' },
  { code: 210, state: 'Texas', lowercaseState: 'texas' },
  { code: 214, state: 'Texas', lowercaseState: 'texas' },
  { code: 254, state: 'Texas', lowercaseState: 'texas' },
  { code: 281, state: 'Texas', lowercaseState: 'texas' },
  { code: 325, state: 'Texas', lowercaseState: 'texas' },
  { code: 346, state: 'Texas', lowercaseState: 'texas' },
  { code: 361, state: 'Texas', lowercaseState: 'texas' },
  { code: 409, state: 'Texas', lowercaseState: 'texas' },
  { code: 432, state: 'Texas', lowercaseState: 'texas' },
  { code: 469, state: 'Texas', lowercaseState: 'texas' },
  { code: 512, state: 'Texas', lowercaseState: 'texas' },
  { code: 682, state: 'Texas', lowercaseState: 'texas' },
  { code: 713, state: 'Texas', lowercaseState: 'texas' },
  { code: 737, state: 'Texas', lowercaseState: 'texas' },
  { code: 806, state: 'Texas', lowercaseState: 'texas' },
  { code: 817, state: 'Texas', lowercaseState: 'texas' },
  { code: 830, state: 'Texas', lowercaseState: 'texas' },
  { code: 832, state: 'Texas', lowercaseState: 'texas' },
  { code: 903, state: 'Texas', lowercaseState: 'texas' },
  { code: 915, state: 'Texas', lowercaseState: 'texas' },
  { code: 936, state: 'Texas', lowercaseState: 'texas' },
  { code: 940, state: 'Texas', lowercaseState: 'texas' },
  { code: 956, state: 'Texas', lowercaseState: 'texas' },
  { code: 972, state: 'Texas', lowercaseState: 'texas' },
  { code: 979, state: 'Texas', lowercaseState: 'texas' },
  { code: 385, state: 'Utah', lowercaseState: 'utah' },
  { code: 435, state: 'Utah', lowercaseState: 'utah' },
  { code: 801, state: 'Utah', lowercaseState: 'utah' },
  { code: 802, state: 'Vermont', lowercaseState: 'vermont' },
  { code: 276, state: 'Virginia', lowercaseState: 'virginia' },
  { code: 434, state: 'Virginia', lowercaseState: 'virginia' },
  { code: 540, state: 'Virginia', lowercaseState: 'virginia' },
  { code: 571, state: 'Virginia', lowercaseState: 'virginia' },
  { code: 703, state: 'Virginia', lowercaseState: 'virginia' },
  { code: 757, state: 'Virginia', lowercaseState: 'virginia' },
  { code: 804, state: 'Virginia', lowercaseState: 'virginia' },
  { code: 206, state: 'Washington', lowercaseState: 'washington' },
  { code: 253, state: 'Washington', lowercaseState: 'washington' },
  { code: 360, state: 'Washington', lowercaseState: 'washington' },
  { code: 425, state: 'Washington', lowercaseState: 'washington' },
  { code: 509, state: 'Washington', lowercaseState: 'washington' },
  { code: 304, state: 'West Virginia', lowercaseState: 'west virginia' },
  { code: 681, state: 'West Virginia', lowercaseState: 'west virginia' },
  { code: 262, state: 'Wisconsin', lowercaseState: 'wisconsin' },
  { code: 414, state: 'Wisconsin', lowercaseState: 'wisconsin' },
  { code: 608, state: 'Wisconsin', lowercaseState: 'wisconsin' },
  { code: 715, state: 'Wisconsin', lowercaseState: 'wisconsin' },
  { code: 920, state: 'Wisconsin', lowercaseState: 'wisconsin' },
  { code: 307, state: 'Wyoming', lowercaseState: 'wyoming' },
];
