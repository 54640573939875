import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree, } from '@angular/router';
import { Observable, map, tap } from 'rxjs';
import { UserService } from '../user/user.service';
import { PhoneNumberStatus } from 'src/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class PhoneNumberVerificationGuard implements CanActivate {

  constructor(private userService: UserService, 
              private router: Router) { }

  public canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.getUser()
    .pipe(map(user => {
      
      return  user.twilioPhoneNumber && PhoneNumberStatus.APPROVED === user.phoneNumberStatus;

    }))
  }
}
