<div class="w-full animate-pulse flex flex-row gap-x-4 px-6 items-center">
  <div>
    <mat-icon class="icon text-gray-300">play_circle_filled</mat-icon>
  </div>
  <div class="flex flex-row py-4 w-full justify-between items-center">
    <div class="flex flex-col">
      <div class="w-32 h-2.5 rounded-full bg-gray-300 mb-2"></div>
      <div class="w-16 h-2 rounded-full bg-gray-300"></div>
    </div>
    <button class="p-3" mat-icon-button>
      <mat-icon class="text-gray-300">more_vert</mat-icon>
    </button>
  </div>
</div>
