import { Injectable } from "@angular/core";
import { SelectedItemService } from "./selected-item.service";
import { Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class SelectedContactService extends SelectedItemService { 
  constructor(private router : Router) {
      super();
  }
  
  public openContactById(contactId: number): void {
    this.setSelectedItemId(contactId);
    this.navigateToContactById(contactId);
  }
  
  public navigateToContactById(contactId: number): void {
    if (this.isAlreadyNavigatedToContactPage(contactId)) {
      return;
    }

    this.router.navigate(['contacts', contactId]);
  }

  private isAlreadyNavigatedToContactPage(contactId: number): boolean {
    return this.router.isActive('/contacts/' + contactId, { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' });
  }
}
