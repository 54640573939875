<div id="claimPN" class="flex flex-col w-full">
  <div class="container-fluid">
    <mat-stepper linear #stepper>
      <mat-step>
        <mc-welcome-page
          [currentStep]="currentStep"
          [isCurrentStepValid]="isCurrentStepValid"
          [moveForward]="moveForward"
          [canMoveBackward]="canMoveBackward"
          [forwardButtonClick]="forwardButtonClick">
        </mc-welcome-page>
      </mat-step>
      <!-- <mat-step>
        <mc-kyc-questions ></mc-kyc-questions>
      </mat-step> -->
      <mat-step [stepControl]="form.controls.twilioPhoneNumber">
        <twilio-number-picker
          [currentStep]="currentStep"
          [isCurrentStepValid]="isCurrentStepValid"
          [moveForward]="moveForward"
          [canMoveBackward]="canMoveBackward"
          [forwardButtonClick]="forwardButtonClick"
          [form]="form"
          [control]="form.controls.twilioPhoneNumber">
        </twilio-number-picker>
      </mat-step>
      <mat-step [stepControl]="form.controls.phoneNumber">
        <personal-phone-number
          [currentStep]="currentStep"
          [isCurrentStepValid]="isCurrentStepValid"
          [moveForward]="moveForward"
          [canMoveBackward]="canMoveBackward"
          [forwardButtonClick]="forwardButtonClick"
          [form]="form"
          [control]="form.controls.phoneNumber"></personal-phone-number>
      </mat-step>
      <mat-step [stepControl]="form.controls.phoneNumberVerified">
        <verify-phone-number
          [currentStep]="currentStep"
          [isCurrentStepValid]="isCurrentStepValid"
          [moveForward]="moveForward"
          [canMoveBackward]="canMoveBackward"
          [forwardButtonClick]="forwardButtonClick"
          [form]="form"
          [control]="form.controls.phoneNumberVerified">
        </verify-phone-number>
      </mat-step>
      <mat-step>
        <summary
          [currentStep]="currentStep"
          [isCurrentStepValid]="isCurrentStepValid"
          [moveForward]="moveForward"
          [canMoveBackward]="canMoveBackward"
          [forwardButtonClick]="forwardButtonClick"
          [form]="form"></summary>
      </mat-step>
    </mat-stepper>
  </div>

  <footer class="container-fluid 2xl mt-auto w-full bg-white border-t px-4 md:px-6 py-4 align-middle fixed bottom-0">
    <div class="flex" [ngClass]="(isLastStep || isFirstStep) ? 'justify-end' : 'justify-between'">
        <button *ngIf="canMoveBackward | async" (click)="goBack()" class="order-last bg-white border rounded cursor-pointer hover:bg-gray-150 focus:outline-none py-2 md:py-2 px-6 md:px-8">
           <span class="tracking-wide font-lato font-light text-sm md:text-base secondary-color">Back</span>
          </button>
        <button (click)="goForward()" class="order-last bg-primary-color border rounded focus:bg-primary-color hover:bg-primary-light-color focus:outline-none py-2 md:py-2 px-6 md:px-8">         
            <span class="tracking-wide font-lato font-light text-sm md:text-base text-white">  {{ isLastStep ? 'Finish' : 'Next' }}</span>
          </button>
      </div>
  </footer>
</div>
