import { Observable, ReplaySubject, distinctUntilChanged, take } from "rxjs";

export abstract class SelectedItemService {

  private readonly selectedItemId = new ReplaySubject<number>(1);
  
  public setSelectedItemId(id: number): void {
    this.selectedItemId.next(id);
  }

  public getSelectedItemId(): Observable<number> {
    return this.selectedItemId.pipe(distinctUntilChanged());
  }

  public getCurrentSelectedItemId(): Observable<number> {
    return this.selectedItemId.pipe(take(1));
  }
}
