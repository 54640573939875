import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TrackingService } from 'src/app/services/tracking-service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';
import { Conversation } from 'src/generated/graphql';

@Component({
  selector: 'mc-delete-messages',
  templateUrl: './delete-messages.component.html',
  styleUrls: ['./delete-messages.component.scss']
})
export class DeleteMessagesComponent {

  public conversation: Conversation;

  public deleting: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) data: Conversation,
    private dialogRef: MatDialogRef<DeleteMessagesComponent>,
    private messagingService: MessagingService, 
    private trackingService: TrackingService,) {
    this.conversation = data;
  }

  public onConfirmDelete(): void {
    this.deleting = true;
    this.messagingService.deleteConversation(this.conversation.id).subscribe({
      next: () => {
        this.deleting = false;
        this.close(true);
        this.trackingService.trackEvent(TrackingEventType.MESSAGES_DELETE_CONVERSATION_SUCCESS);
      },
      error: () => {
        this.deleting = false;
        this.trackingService.trackEvent(TrackingEventType.MESSAGES_DELETE_CONVERSATION_FAILURE);
      }
    })
  }

  public close(value?: boolean) {
    this.dialogRef.close(value);
    this.trackingService.trackEvent(TrackingEventType.MESSAGES_DELETE_CONVERSATION_CANCEL);
  }
}
