<!-- Received messages that appear on the left side -->
<div class="flex flex-row px-7 py-3" *ngIf="sms.smsType === SmsStatus.RECEIVED">
  <div class="flex">
    <button (click)="goToContactProfile()" class="mr-3">
      <mc-avatar class="mr-3 flex-none header-img" [avatarUrl]="contactImageUrl" [size]="AvatarSize.SMALL"></mc-avatar>
    </button>
    <div class="grow">
      <div class="bg-gray-450 light-red-border px-4 py-1">
        <p class="font-lato text-base font-light primary-color">
          {{ sms.body }}
        </p>
      </div>
      <span class="ml-1 font-lato text-[12px] font-light secondary-color-500 tracking-wide">{{ timestamp }}</span>
    </div>
    
  </div>
</div>

<!-- Sent messages that appear on the right side -->
<div class="flex flex-row justify-end px-7 py-3" *ngIf="sms.smsType === SmsStatus.SENT">
  <div class="flex">
    <div class="grow">
      <div class="bg-light-green light-red-border px-4 py-1">
        <p class="font-lato text-base font-light green-color">
          {{ sms.body }}
        </p>
      </div>
      <div class="text-right">
        <span class="mr-1 font-lato text-[12px] font-light secondary-color-500 tracking-wide">{{ timestamp }}</span>
      </div>
    </div>
      <mc-avatar class="ml-3 flex-none" [avatarUrl]="userImageUrl" [size]="AvatarSize.SMALL"></mc-avatar>
  </div>
</div>