<ul class="inline-flex">
  <li class="flex justify-center">
    <a [class.disabled-link]="!hasUserCompletedAccountSetup" routerLinkActive="active" [routerLink]="HeaderLinksElement.CONTACTS" (click)="trackHeaderLinkClicks(HeaderLinksToTrack.CONTACTS_MENU_CLICKED)">
      Contacts
    </a>
  </li>
  <li class="flex justify-center">
    <a [class.disabled-link]="!hasUserCompletedAccountSetup" routerLinkActive="active" [routerLink]="HeaderLinksElement.CALLS" (click)="trackHeaderLinkClicks(HeaderLinksToTrack.CALLS_MENU_CLICKED)">
      Calls
    </a>
  </li>
  <li class="flex justify-center">
    <a [class.disabled-link]="!hasUserCompletedAccountSetup" routerLinkActive="active" [routerLink]="HeaderLinksElement.MESSAGES" (click)="trackHeaderLinkClicks(HeaderLinksToTrack.MESSAGES_MENU_CLICKED)">
      Texts
    </a>
  </li>
  <li class="flex justify-center">
    <a [class.disabled-link]="!hasUserCompletedAccountSetup" routerLinkActive="active" [routerLink]="HeaderLinksElement.VOICEMAILS" (click)="trackHeaderLinkClicks(HeaderLinksToTrack.VOICEMAILS_MENU_CLICKED)">
      Voicemails
    </a>
  </li>
  <li class="flex justify-center">
    <a [class.disabled-link]="!hasUserCompletedAccountSetup" routerLinkActive="active" [routerLink]="HeaderLinksElement.TEMPLATES" (click)="trackHeaderLinkClicks(HeaderLinksToTrack.TEMPLATES_MENU_CLICKED)">
      Templates
    </a>
  </li>
</ul>
