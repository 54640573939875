import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AvatarSize } from 'src/app/shared/avatar/avatar.component';
import {Observable, Subject, of, takeUntil, tap } from "rxjs";
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { VoicemailWithContactInformation, VoicemailsService } from 'src/app/shared/services/voicemails.service';
import { ConversationPage } from 'src/app/shared/conversations/conversation-page';
import { SelectedVoicemailService } from 'src/app/shared/services/selection/selected-voicemail.service';
import { TrackingService } from 'src/app/services/tracking-service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';

@Component({
  selector: 'mc-voicemails',
  templateUrl: './voicemails.component.html',
  styleUrls: ['./voicemails.component.scss']
})
export class VoicemailsComponent extends ConversationPage implements OnInit, OnDestroy {

  public readonly AvatarSize = AvatarSize;

  private readonly ON_DESTROY = new Subject<void>();
  private readonly title = 'Hello Grove - Voicemails';

  private readonly ADDITIONAL_VOICEMAILS_TO_LOAD_ON_SCROLL = 10;

  public voicemails: VoicemailWithContactInformation[] = [];
  public loadedInitialVoicemails = false;
  
  public selectedVoicemailId: number;

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: Title,
    private voicemailsService: VoicemailsService,
    private selectedVoicemailService: SelectedVoicemailService,
    private trackingService: TrackingService,
  ) {
    super();
  }

  public ngOnDestroy(): void {
    this.ON_DESTROY.next();
    this.ON_DESTROY.complete();
  }

  public ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.loadMostRecentVoicemails();
    this.loadSelectedVoicemailId();
  }

  public showGreetingList(): void {
    this.router.navigate(['voicemails', 'greetings']);
    this.trackingService.trackEvent(TrackingEventType.VOICEMAIL_GREETINGS_CLICKED);
  }

  public openVoicemailDetails(voicemailId: number): void {
    this.selectedVoicemailService.setSelectedItemId(voicemailId);
  }
  
  public loadMoreConversations(): Observable<any> {
    return this.voicemailsService.loadVoicemails(this.ADDITIONAL_VOICEMAILS_TO_LOAD_ON_SCROLL, this.voicemails.length)
      .pipe(
        tap((voicemails) => {
          this.voicemails.push(...voicemails);
        })
      );
  }

  private loadMostRecentVoicemails(): void {
    this.voicemailsService.getVoicemails().subscribe((voicemails) => {
      this.voicemails = voicemails;
      this.loadedInitialVoicemails = true;
    });
  }

  private loadSelectedVoicemailId(): void {
    this.selectedVoicemailService.getSelectedItemId()
      .pipe(takeUntil(this.ON_DESTROY))
      .subscribe((contactId) => this.setSelectedVoicemailId(contactId));
  }

  private setSelectedVoicemailId(voicemailId: number): void {
    this.selectedVoicemailId = voicemailId;

    if (voicemailId) {
      this.selectedVoicemailService.navigateToVoicemailById(voicemailId);
    }

    this.changeDetectorRef.detectChanges();
  }
}
