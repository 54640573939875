import { Component } from '@angular/core';

@Component({
  selector: 'mc-call-details-skeleton',
  templateUrl: './call-details-skeleton.component.html',
  styleUrls: []
})
export class CallDetailsSkeletonComponent {

}
