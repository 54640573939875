<div id="addNumberModal">
  <h1 class="text-2xl font-lato font-normal tracking-wide secondary-color py-4 px-5">Add number</h1>
  <div mat-dialog-content class="border-y p-6">
    <form class="mb-7" [formGroup]="contactForm">
      <div class="py-3 mb-5">
        <mat-radio-group aria-label="Select an option" class="flex flex-row mat-radio">
          <mat-radio-button [value]="false" (change)="onTabSwitch($event)" checked class="mr-[40px]">Create new contact</mat-radio-button>
          <mat-radio-button [value]="true" (change)="onTabSwitch($event)">Add to existing contact</mat-radio-button>
        </mat-radio-group>
      </div>
      <label class="flex flex-col" *ngIf="createNewContact">
        <span class="font-lato font-normal secondary-color-500 tracking-wide text-sm">Full name</span>
        <input formControlName="fullName" matInput
        class="placeholder:text-gray-300 placeholder:font-lato placeholder:font-light placeholder:tracking-wide tracking-wide font-lato secondary-color font-normal mt-1 block bg-white border border-slate-200 rounded focus:outline-none w-full py-3 px-4"
        placeholder="John Doe" type="text" name="name"/>
      </label>
      <mat-error *ngIf="createNewContact && contactForm.touched && contactForm.controls.fullName.hasError('required')">Not a valid Full name
      </mat-error>
      <mat-error *ngIf="createNewContact && contactForm.touched &&  contactForm.controls.fullName.hasError('contactExists')">Contact with this name already exists
      </mat-error>
      <label class="flex flex-col" *ngIf="addToExistingContact">
        <span class="font-lato font-normal secondary-color-500 tracking-wide text-sm">Select the existing contact</span>
        <mat-form-field appearance="outline">
          <mat-select placeholder="None" [(value)]="selectedContact"
          class="focus:outline-none border rounded placeholder:text-gray-300 placeholder:font-lato placeholder:font-light">
          <mat-option [value]="contact" *ngFor="let contact of contacts"
            class="font-lato font-normal tracking-wide secondary-color text-sm">{{contact.fullName}}</mat-option>
        </mat-select>
        </mat-form-field>
      </label>
    </form>

    <form [formGroup]="phoneNumbersForm">
      <ng-container formArrayName="phoneNumbers" *ngFor="let item of phoneNumbersForm.controls.phoneNumbers.controls; let i=index">
        <div class="flex flex-row gap-4 mt-5" [formGroupName]="i">
          <label class="flex flex-col">
            <span class="font-lato font-normal secondary-color-500 text-sm" matTooltip="Preferred number"
                  aria-label="Button that displays a tooltip when focused or hovered over">Pn</span>
            <label class="form__answer">
              <input type="radio" id="i" formControlName="isPreferred" [value]="isPreferred" (click)="setPreffered(item)">
              <img *ngIf="!item?.value?.isPreferred" src="../../../assets/images/icons/star-white.svg" alt="Preferred number white" class="relative bottom-0 cursor-pointer">
              <img *ngIf="item?.value?.isPreferred" src="../../../assets/images/icons/star-yellow.svg" alt="Preferred number"  class="relative bottom-0 cursor-pointer">
            </label>
          </label>
          <label class="flex flex-col ">
            <span class="font-lato font-normal secondary-color-500 text-sm">Label</span>
            <mat-form-field appearance="outline" class="w-50">
              <mat-select formControlName="label" class="focus:outline-none border rounded bg-white border-slate-200">
                <mat-option value="" class="font-lato font-normal tracking-wide secondary-color text-sm">None</mat-option>
                <mat-option value="home" class="font-lato font-normal tracking-wide secondary-color text-sm">Home</mat-option>
                <mat-option value="mobile" class="font-lato font-normal tracking-wide secondary-color text-sm">Mobile</mat-option>
              </mat-select>
            </mat-form-field>
          </label>
          <label class="flex flex-col grow relative">
            <span class="font-lato font-normal secondary-color-500 tracking-wide text-sm">Phone number</span>
            <ngx-mat-intl-tel-input 
              [preferredCountries]="['us', 'ca']"
              [enablePlaceholder]="true"
              [enableSearch]="true"
              name="phone"
              describedBy="phoneNumber"
              format="national"
              inputPlaceholder="(123) 456 7890"
              class="phone-input placeholder:text-gray-300 placeholder:font-lato placeholder:font-light placeholder:tracking-wide tracking-wide font-lato secondary-color font-normal mt-1 block bg-white border border-slate-200 rounded focus:outline-none w-full py-3 px-4"
              formControlName="phoneNumber">
            </ngx-mat-intl-tel-input>
          </label>
          <label *ngIf="phoneNumbersForm.controls.phoneNumbers.controls.length - 1 > 0"
                 class="flex flex-none ml-auto items-center justify-start mt-5 cursor-pointer" (click)="removeRow(i)">
            <mat-icon>close</mat-icon>
          </label>
        </div>
        <mat-error *ngIf="item.touched && item.get('phoneNumber').hasError('invalidPhoneNumber')" class="pl-[11.8rem]">Not a valid Phone
          Number
        </mat-error>
        <mat-error *ngIf=" item.touched && item.get('phoneNumber').hasError('phoneNumberExists')" class="pl-[11.8rem]">Contact with this phone number already exists
        </mat-error>
        <mat-error *ngIf=" item.touched && item.get('phoneNumber').hasError('required')" class="pl-[11.8rem]">Phonenumber can not be empty
        </mat-error>
      </ng-container>
      <a (click)="addRow()" class="flex flex-row items-center cursor-pointer primary-color w-fit mt-3">
        <mat-icon>add_circle</mat-icon>
        <span class="secondary-color-500 underline text-sm font-lato font-light tracking-normal ml-2">Add phone</span>
      </a>
    </form>
  </div>
  <div class="flex justify-between py-3 px-5">
    <button mat-dialog-close
            class="bg-white border rounded focus:ring-violet-300 hover:bg-gray-150 focus:outline-none py-1 px-5 ">
      <span class="tracking-wide font-lato secondary-color text-sm leading-loose"> Close</span>
    </button>
    <button
    (click)="validateAndSubmit()"
            class="bg-primary-color border rounded focus:bg-primary-color hover:bg-primary-light-color focus:outline-none py-1 px-5"
            [ngClass]="{'disabled': hasInvalidState}"
            [disabled]="hasInvalidState"
            >
      <span class="text-white tracking-wide font-lato font-light text-sm leading-loose">Save</span>
    </button>
  </div>

  <mat-menu #menu="matMenu" class="custom-menu">
    <button mat-menu-item><span class="secondary-color text-base font-normal font-lato">Home</span></button>
    <button mat-menu-item><span class="secondary-color text-base font-normal font-lato">Mobile</span></button>
    <button mat-menu-item><span class="secondary-color text-base font-normal font-lato">Work</span></button>
    <button mat-menu-item><span class="secondary-color text-base font-normal font-lato">Other</span></button>
  </mat-menu>
</div>
