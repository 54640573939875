<div class="flex flex-col min-h-full bg-white justify-start">
  <ng-container *ngIf="loadedVoicemail else loadingTemplate">
    <div class="flex flex-row justify-between pl-7 pr-4 py-4 bottom-gray-border">
      <div class="flex flex-row items-center">
        <button (click)="goToContactsProfilePage()" [class.no-active]="!voicemail.contact" >
          <mc-avatar [size]="AvatarSize.SMALL" [avatarUrl]="voicemail.contact?.pictureUrl" class="header-img"></mc-avatar>
        </button>
        <div *ngIf="voicemail.contact" class="grid leading-3 ml-3">
          <button (click)="goToContactsProfilePage()" class="header-btn">
            <span class="text-lato secondary-color text-base leading-4">{{ voicemail.contact.fullName }}</span>        
          </button>
          <span class="text-lato secondary-color-500 text-sm font-light">{{ voicemail.callerPhoneNumber }}</span>
        </div>
    
        <div *ngIf="!voicemail.contact" class="grid ml-3">
          <span class="text-lato secondary-color text-base leading-4">{{ voicemail.callerPhoneNumber }}</span>
        </div>
      </div>
      <div class="flex flex-row items-center">
        <button (click)="callNumber()" class="p-3 hover:bg-gray-200 hover:rounded-full hover:p-3" mat-icon-button>
          <img alt="phone call" class="w-5 h-5" src="assets/images/icons/phone-call.svg" />
        </button>
        <button (click)="messageNumber()" class="ml-2 p-3 hover:bg-gray-200 hover:rounded-full hover:p-3" mat-icon-button>
          <img alt="sms" class="w-5 h-5" src="assets/images/icons/sms.svg" />
        </button>
        <button [matMenuTriggerFor]="menu" class="p-3 ml-1 hover:bg-gray-200 hover:rounded-full hover:p-3"
          mat-icon-button>
          <img alt="more" class="w-5 h-5" src="assets/images/icons/more.svg" />
        </button>
      </div>
    </div>
    <div class="flex items-center justify-center">
      <div class="flex items-center flex-col py-36 w-12/12 md:w-10/12 xl:w-6/12 2xl:w-4/12">
        <mc-avatar [avatarUrl]="voicemail.contact?.pictureUrl" [size]="AvatarSize.LARGE"></mc-avatar>
        <div class="mt-4 text-lato secondary-color-500 text-sm font-light">{{ voicemail.createdAt | date: 'MMM dd, HH:mm a' }}</div>
        <div class="text-lato secondary-color text-sm mt-6 text-center">
          {{ voicemail.transcriptionText }}
        </div>
      </div>
    </div>
    
    <div class="flex flex-row justify-center items-center border-t fixed bottom-0 w-custom" *ngIf="loadedRecording" >
      <ang-music-player [audioList]="musicPlayerAudioList" [next]="false" [previous]="false" id="angMusicPlayer"></ang-music-player>
    </div>
    <div class="flex flex-row justify-center items-start border-t fixed bottom-0 w-custom" style="height:104px;" *ngIf="!loadedRecording">
      <mc-music-player-skeleton></mc-music-player-skeleton>
    </div>
  </ng-container>
</div>

<ng-template #loadingTemplate>
</ng-template>

<mat-menu #menu="matMenu" class="custom-menu" menu>
  <ng-template matMenuContent>
    <button *ngIf="!voicemail.contact" (click)="openAddNumberDialog()" mat-menu-item class="menu-item">
      <span class="font-lato text-base font-normal secondary-color">Add Number</span>
    </button>
    <button (click)="openDeleteVoicemailDialog()" mat-menu-item class="menu-item">
      <span class="font-lato text-base font-normal secondary-color">Delete</span>
    </button>
  </ng-template>
</mat-menu>
