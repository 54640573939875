import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
  Upload: any;
};

export type AvailableTwilioPhoneNumbersInput = {
  areaCode: Scalars['Int'];
  countryCode: Scalars['String'];
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type BillingAccount = {
  __typename?: 'BillingAccount';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type Call = {
  __typename?: 'Call';
  callType?: Maybe<CallType>;
  conversation?: Maybe<Conversation>;
  conversationId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<CallStatus>;
  twilioId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type CallFetchInput = {
  conversationId: Scalars['Int'];
  count?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export enum CallStatus {
  ANSWERED = 'ANSWERED',
  BUSY = 'BUSY',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NO_ANSWER = 'NO_ANSWER',
  QUEUED = 'QUEUED',
  RINGING = 'RINGING'
}

export enum CallType {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING'
}

export type Contact = {
  __typename?: 'Contact';
  createdAt?: Maybe<Scalars['Date']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  phoneNumbers?: Maybe<Array<Maybe<PhoneNumber>>>;
  pictureIdentifier?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['Int']>;
};

export type ContactDeleteInput = {
  contactId: Scalars['Int'];
};

export type ContactEditInput = {
  contactId: Scalars['Int'];
  fullName?: InputMaybe<Scalars['String']>;
  phoneNumbers?: InputMaybe<Array<ContactPhoneNumberEditInput>>;
  pictureBase64?: InputMaybe<Scalars['String']>;
};

export type ContactInput = {
  fullName: Scalars['String'];
  phoneNumbers: Array<PhoneNumberInput>;
  pictureBase64?: InputMaybe<Scalars['String']>;
};

export type ContactPhoneNumberEditInput = {
  id?: InputMaybe<Scalars['Int']>;
  isPreferred: Scalars['Boolean'];
  label?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type Conversation = {
  __typename?: 'Conversation';
  calls?: Maybe<Array<Maybe<Call>>>;
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  latestCall?: Maybe<Call>;
  latestSms?: Maybe<Sms>;
  participantNumber?: Maybe<Scalars['String']>;
  smses?: Maybe<Array<Maybe<Sms>>>;
  type?: Maybe<ConversationType>;
  updatedAt?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['Int']>;
  userNumber?: Maybe<Scalars['String']>;
};

export type ConversationSmsFetchInput = {
  conversationId?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export enum ConversationType {
  CALL = 'CALL',
  SMS = 'SMS',
  VOICEMAIL = 'VOICEMAIL'
}

export type CreateBillingAccount = {
  description: Scalars['String'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  teamId: Scalars['Int'];
};

export type CreateCallInput = {
  receivingNumber: Scalars['String'];
};

export type CreateConversationInput = {
  participantNumber: Scalars['String'];
  type: ConversationType;
};

export type CreateVoicemailGreetingInput = {
  duration: Scalars['Int'];
  name: Scalars['String'];
  voicemailGreeting: Scalars['Upload'];
};

export type CreateVoicemailInput = {
  callerPhoneNumber: Scalars['String'];
  duration?: InputMaybe<Scalars['Int']>;
  receiverId: Scalars['Int'];
  twilioRecordingId?: InputMaybe<Scalars['String']>;
};

export type DeleteCallInput = {
  callId: Scalars['Int'];
};

export type DeleteConversationInput = {
  conversationId: Scalars['Int'];
};

export type DeleteVoicemailGreetingInput = {
  voicemailGreetingId: Scalars['Int'];
};

export type DeleteVoicemailInput = {
  voicemailId: Scalars['Int'];
};

export type GetRecentCallConversationsInput = {
  count: Scalars['Int'];
  offset: Scalars['Int'];
};

export type GetRecentCallsInput = {
  count: Scalars['Int'];
  offset: Scalars['Int'];
};

export type GetRecentSmsConversationsInput = {
  count: Scalars['Int'];
  offset: Scalars['Int'];
};

export enum GraphQlError {
  BAD_REQUEST = 'BAD_REQUEST',
  ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  UNAUTHENTICATED = 'UNAUTHENTICATED'
}

export type KycAnswers = {
  __typename?: 'KycAnswers';
  agency?: Maybe<Scalars['String']>;
  changesRequested?: Maybe<Scalars['Boolean']>;
  contactPersonContactForm?: Maybe<Scalars['String']>;
  contactPersonName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  plan?: Maybe<Scalars['String']>;
  proofOfWorkImage?: Maybe<Scalars['String']>;
  submitted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  workTitle?: Maybe<Scalars['String']>;
};

export enum KycStatus {
  DENIED = 'DENIED',
  KYC_DISABLED = 'KYC_DISABLED',
  LIMITED_ACCESS = 'LIMITED_ACCESS',
  VETTED = 'VETTED',
  VETTING_REQUIRED = 'VETTING_REQUIRED'
}

export type MixPanelInput = {
  eventData: Scalars['JSON'];
  eventName: Scalars['String'];
};

export type MixPanelResponse = {
  __typename?: 'MixPanelResponse';
  responseStatus?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createBillingAccount: BillingAccount;
  createCall: Call;
  createContact: Contact;
  createConversation: Conversation;
  createVoicemail: Voicemail;
  createVoicemailGreeting: VoicemailGreeting;
  deleteCall: Call;
  deleteContact: Contact;
  deleteConversation?: Maybe<Conversation>;
  deleteUserAvatar: User;
  deleteVoicemail: Voicemail;
  deleteVoicemailGreeting: VoicemailGreeting;
  editContact: Contact;
  purchaseTwilioPhoneNumber: TwilioPhoneNumber;
  saveKycAnswers?: Maybe<KycAnswers>;
  sendSMSVerificationCode: SendSmsVerificationCodeResponse;
  sendSms?: Maybe<Sms>;
  trackEvent?: Maybe<MixPanelResponse>;
  updateMyProfile: User;
  updatePhoneNumber: UpdatePhoneNumberResponse;
  updateVoicemailGreeting: VoicemailGreeting;
  verifyPhoneNumber: User;
};


export type MutationCreateBillingAccountArgs = {
  value: CreateBillingAccount;
};


export type MutationCreateCallArgs = {
  value: CreateCallInput;
};


export type MutationCreateContactArgs = {
  value: ContactInput;
};


export type MutationCreateConversationArgs = {
  value: CreateConversationInput;
};


export type MutationCreateVoicemailArgs = {
  value: CreateVoicemailInput;
};


export type MutationCreateVoicemailGreetingArgs = {
  value: CreateVoicemailGreetingInput;
};


export type MutationDeleteCallArgs = {
  value: DeleteCallInput;
};


export type MutationDeleteContactArgs = {
  value: ContactDeleteInput;
};


export type MutationDeleteConversationArgs = {
  value: DeleteConversationInput;
};


export type MutationDeleteVoicemailArgs = {
  value: DeleteVoicemailInput;
};


export type MutationDeleteVoicemailGreetingArgs = {
  value: DeleteVoicemailGreetingInput;
};


export type MutationEditContactArgs = {
  value: ContactEditInput;
};


export type MutationPurchaseTwilioPhoneNumberArgs = {
  value: PurchaseTwilioPhoneNumberInput;
};


export type MutationSaveKycAnswersArgs = {
  value: SaveKycAnswersInput;
};


export type MutationSendSmsVerificationCodeArgs = {
  value: SendSmsVerificationCodeInput;
};


export type MutationSendSmsArgs = {
  value: SendSmsInput;
};


export type MutationTrackEventArgs = {
  value: MixPanelInput;
};


export type MutationUpdateMyProfileArgs = {
  value: UpdateMyProfileInput;
};


export type MutationUpdatePhoneNumberArgs = {
  value: UpdatePhoneNumberInput;
};


export type MutationUpdateVoicemailGreetingArgs = {
  value: UpdateVoicemailGreetingInput;
};


export type MutationVerifyPhoneNumberArgs = {
  value: VerifyPhoneNumberInput;
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  contactId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  isPreferred?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PhoneNumberInput = {
  contactId?: InputMaybe<Scalars['Int']>;
  isPreferred: Scalars['Boolean'];
  label?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export enum PhoneNumberStatus {
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
  NONE = 'NONE',
  PENDING = 'PENDING'
}

export type PurchaseTwilioPhoneNumberInput = {
  phoneNumber: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  activeVoicemailGreeting?: Maybe<VoicemailGreeting>;
  availablePhoneNumbers: Array<TwilioPhoneNumber>;
  billingAccounts: Array<BillingAccount>;
  contacts: Array<Contact>;
  conversation: Conversation;
  conversationByContactId?: Maybe<Conversation>;
  conversationByParticipantNumber?: Maybe<Conversation>;
  conversationCalls: Array<Call>;
  conversationSmses: Array<Sms>;
  countSmses: Scalars['Int'];
  generateTwilioAccessToken: TwilioAccessToken;
  getCallByTwilioId: Call;
  getKycAnswers?: Maybe<KycAnswers>;
  getLatestIncomingCall: Call;
  getRecentCalls: Array<Call>;
  listCallConversations: Array<Conversation>;
  listSmsConversations: Array<Conversation>;
  me: User;
  phoneNumber?: Maybe<PhoneNumber>;
  searchSmses?: Maybe<Array<Maybe<Sms>>>;
  templatesGlobal: Array<Template>;
  voicemailGreetings: Array<Maybe<VoicemailGreeting>>;
  voicemails: Array<Maybe<Voicemail>>;
};


export type QueryAvailablePhoneNumbersArgs = {
  input: AvailableTwilioPhoneNumbersInput;
};


export type QueryBillingAccountsArgs = {
  teamId?: InputMaybe<Scalars['Int']>;
};


export type QueryConversationArgs = {
  id: Scalars['Int'];
};


export type QueryConversationByContactIdArgs = {
  contactId: Scalars['Int'];
  type: ConversationType;
};


export type QueryConversationByParticipantNumberArgs = {
  participantNumber: Scalars['String'];
  type: ConversationType;
};


export type QueryConversationCallsArgs = {
  input: CallFetchInput;
};


export type QueryConversationSmsesArgs = {
  input: ConversationSmsFetchInput;
};


export type QueryCountSmsesArgs = {
  input: SearchSmsesInput;
};


export type QueryGenerateTwilioAccessTokenArgs = {
  type: TwilioAccessTokenType;
};


export type QueryGetCallByTwilioIdArgs = {
  twilioId: Scalars['String'];
};


export type QueryGetLatestIncomingCallArgs = {
  participantNumber: Scalars['String'];
};


export type QueryGetRecentCallsArgs = {
  input: GetRecentCallsInput;
};


export type QueryListCallConversationsArgs = {
  input: GetRecentCallConversationsInput;
};


export type QueryListSmsConversationsArgs = {
  input: GetRecentSmsConversationsInput;
};


export type QueryPhoneNumberArgs = {
  input: PhoneNumberInput;
};


export type QuerySearchSmsesArgs = {
  input: SearchSmsesInput;
};


export type QueryTemplatesGlobalArgs = {
  input?: InputMaybe<TemplatesGlobalInput>;
};


export type QueryVoicemailGreetingsArgs = {
  input?: InputMaybe<VoicemailGreetingsInput>;
};


export type QueryVoicemailsArgs = {
  input: VoicemailInput;
};

export type RequestKycAnswersEditingResult = {
  __typename?: 'RequestKycAnswersEditingResult';
  requested?: Maybe<Scalars['Boolean']>;
};

export type SaveKycAnswersInput = {
  agency?: InputMaybe<Scalars['String']>;
  contactPersonContactForm?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  plan?: InputMaybe<Scalars['String']>;
  proofOfWorkImage?: InputMaybe<Scalars['Upload']>;
  workTitle?: InputMaybe<Scalars['String']>;
};

export type SearchSmsesInput = {
  count?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};

export type SendSmsInput = {
  body: Scalars['String'];
  participantNumber: Scalars['String'];
};

export type SendSmsVerificationCodeInput = {
  phoneNumber: Scalars['String'];
};

export type SendSmsVerificationCodeResponse = {
  __typename?: 'SendSmsVerificationCodeResponse';
  sent: Scalars['Boolean'];
};

export type Sms = {
  __typename?: 'Sms';
  body?: Maybe<Scalars['String']>;
  conversation?: Maybe<Conversation>;
  conversationId: Scalars['Int'];
  createdAt?: Maybe<Scalars['Date']>;
  deliveryStatus?: Maybe<SmsDeliveryStatus>;
  id: Scalars['Int'];
  smsType?: Maybe<SmsStatus>;
  twilioId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export enum SmsDeliveryStatus {
  ACCEPTED = 'ACCEPTED',
  DELIVERED = 'DELIVERED',
  DELIVERY_UNKNOWN = 'DELIVERY_UNKNOWN',
  FAILED = 'FAILED',
  QUEUED = 'QUEUED',
  RECEIVED = 'RECEIVED',
  SCHEDULED = 'SCHEDULED',
  SENDING = 'SENDING',
  SENT = 'SENT',
  UNDELIVERED = 'UNDELIVERED'
}

export type SmsFetchInput = {
  conversationId: Scalars['Int'];
  count?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export enum SmsStatus {
  RECEIVED = 'RECEIVED',
  SENT = 'SENT'
}

export type Subscription = {
  __typename?: 'Subscription';
  callStatusUpdated: Call;
  newCallReceived: Call;
  newSmsReceived: Sms;
  smsStatusUpdated: Sms;
};

export type Template = {
  __typename?: 'Template';
  content: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  isGlobal: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  name: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type TemplatesGlobalInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchValue?: InputMaybe<Scalars['String']>;
};

export type TwilioAccessToken = {
  __typename?: 'TwilioAccessToken';
  token: Scalars['String'];
};

export enum TwilioAccessTokenType {
  CHAT = 'CHAT',
  VOICE = 'VOICE'
}

export type TwilioPhoneNumber = {
  __typename?: 'TwilioPhoneNumber';
  friendlyName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type UpdateMyProfileInput = {
  avatarBase64?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  verificationCode?: InputMaybe<Scalars['String']>;
};

export type UpdatePhoneNumberInput = {
  phoneNumber: Scalars['String'];
  shouldSendVerificationMessage?: InputMaybe<Scalars['Boolean']>;
};

export type UpdatePhoneNumberResponse = {
  __typename?: 'UpdatePhoneNumberResponse';
  phoneNumber: Scalars['String'];
  phoneNumberStatus?: Maybe<Scalars['String']>;
};

export type UpdateVoicemailGreetingInput = {
  activate?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  voicemailGreetingId: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  emailAddress?: Maybe<Scalars['String']>;
  emailAddressHash?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumberStatus?: Maybe<PhoneNumberStatus>;
  profilePictureIdentifier?: Maybe<Scalars['String']>;
  twilioPhoneNumber?: Maybe<Scalars['String']>;
};

export type VerifyPhoneNumberInput = {
  code: Scalars['String'];
};

export type Voicemail = {
  __typename?: 'Voicemail';
  callerPhoneNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  transcriptionText?: Maybe<Scalars['String']>;
  twilioRecordingId?: Maybe<Scalars['String']>;
};

export type VoicemailGreeting = {
  __typename?: 'VoicemailGreeting';
  active?: Maybe<Scalars['Boolean']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

export type VoicemailGreetingsInput = {
  count?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type VoicemailInput = {
  count?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type BillingAccountsQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['Int']>;
}>;


export type BillingAccountsQuery = { __typename?: 'Query', billingAccounts: Array<{ __typename?: 'BillingAccount', id?: number | null, name?: string | null, isDefault?: boolean | null, teamId?: number | null, createdAt?: any | null }> };

export type CreateBillingAccountMutationVariables = Exact<{
  value: CreateBillingAccount;
}>;


export type CreateBillingAccountMutation = { __typename?: 'Mutation', createBillingAccount: { __typename?: 'BillingAccount', id?: number | null, name?: string | null, isDefault?: boolean | null, teamId?: number | null, createdAt?: any | null } };

export type GetKycAnswersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetKycAnswersQuery = { __typename?: 'Query', getKycAnswers?: { __typename?: 'KycAnswers', id?: number | null, workTitle?: string | null, agency?: string | null, contactPersonName?: string | null, contactPersonContactForm?: string | null, plan?: string | null, proofOfWorkImage?: string | null, submitted?: boolean | null, changesRequested?: boolean | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type SaveKycAnswersMutationVariables = Exact<{
  value: SaveKycAnswersInput;
}>;


export type SaveKycAnswersMutation = { __typename?: 'Mutation', saveKycAnswers?: { __typename?: 'KycAnswers', id?: number | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type TemplatesGlobalQueryVariables = Exact<{
  input?: InputMaybe<TemplatesGlobalInput>;
}>;


export type TemplatesGlobalQuery = { __typename?: 'Query', templatesGlobal: Array<{ __typename?: 'Template', id: number, subject?: string | null, content: string }> };

export type CallStatusFeedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type CallStatusFeedSubscription = { __typename?: 'Subscription', callStatusUpdated: { __typename?: 'Call', id?: number | null, conversationId?: number | null, twilioId?: string | null, duration?: number | null, status?: CallStatus | null, callType?: CallType | null } };

export type CallsQueryVariables = Exact<{
  input: CallFetchInput;
}>;


export type CallsQuery = { __typename?: 'Query', conversationCalls: Array<{ __typename?: 'Call', id?: number | null, conversationId?: number | null, twilioId?: string | null, duration?: number | null, status?: CallStatus | null, callType?: CallType | null, createdAt?: any | null, updatedAt?: any | null }> };

export type GetCallByTwilioIdQueryVariables = Exact<{
  twilioId: Scalars['String'];
}>;


export type GetCallByTwilioIdQuery = { __typename?: 'Query', getCallByTwilioId: { __typename?: 'Call', id?: number | null, conversationId?: number | null, twilioId?: string | null, duration?: number | null, status?: CallStatus | null, callType?: CallType | null, createdAt?: any | null, updatedAt?: any | null, conversation?: { __typename?: 'Conversation', id?: number | null, userId?: number | null, userNumber?: string | null, participantNumber?: string | null, contactId?: number | null, createdAt?: any | null, updatedAt?: any | null, contact?: { __typename?: 'Contact', id?: number | null, fullName?: string | null, pictureIdentifier?: string | null } | null } | null } };

export type GetLatestIncomingCallQueryVariables = Exact<{
  participantNumber: Scalars['String'];
}>;


export type GetLatestIncomingCallQuery = { __typename?: 'Query', getLatestIncomingCall: { __typename?: 'Call', id?: number | null, conversationId?: number | null, twilioId?: string | null, duration?: number | null, status?: CallStatus | null, callType?: CallType | null, createdAt?: any | null, updatedAt?: any | null, conversation?: { __typename?: 'Conversation', id?: number | null, userId?: number | null, userNumber?: string | null, participantNumber?: string | null, contactId?: number | null, createdAt?: any | null, updatedAt?: any | null, contact?: { __typename?: 'Contact', id?: number | null, fullName?: string | null, pictureIdentifier?: string | null } | null } | null } };

export type CreateCallMutationVariables = Exact<{
  value: CreateCallInput;
}>;


export type CreateCallMutation = { __typename?: 'Mutation', createCall: { __typename?: 'Call', id?: number | null, conversationId?: number | null, twilioId?: string | null, duration?: number | null, status?: CallStatus | null, callType?: CallType | null, createdAt?: any | null, updatedAt?: any | null, conversation?: { __typename?: 'Conversation', id?: number | null, userId?: number | null, userNumber?: string | null, participantNumber?: string | null, contactId?: number | null, createdAt?: any | null, updatedAt?: any | null } | null } };

export type NewCallFeedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NewCallFeedSubscription = { __typename?: 'Subscription', newCallReceived: { __typename?: 'Call', id?: number | null, conversationId?: number | null, twilioId?: string | null, duration?: number | null, status?: CallStatus | null, callType?: CallType | null } };

export type GetCallConversationByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCallConversationByIdQuery = { __typename?: 'Query', conversation: { __typename?: 'Conversation', id?: number | null, userId?: number | null, userNumber?: string | null, participantNumber?: string | null, contactId?: number | null, createdAt?: any | null, updatedAt?: any | null, latestCall?: { __typename?: 'Call', id?: number | null, twilioId?: string | null, duration?: number | null, status?: CallStatus | null, callType?: CallType | null, createdAt?: any | null, updatedAt?: any | null } | null } };

export type GetConversationByContactIdAndTypeQueryVariables = Exact<{
  contactId: Scalars['Int'];
  type: ConversationType;
}>;


export type GetConversationByContactIdAndTypeQuery = { __typename?: 'Query', conversationByContactId?: { __typename?: 'Conversation', id?: number | null, userNumber?: string | null, participantNumber?: string | null, contactId?: number | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type GetConversationByParticipantNumberAndTypeQueryVariables = Exact<{
  participantNumber: Scalars['String'];
  type: ConversationType;
}>;


export type GetConversationByParticipantNumberAndTypeQuery = { __typename?: 'Query', conversationByParticipantNumber?: { __typename?: 'Conversation', id?: number | null, userId?: number | null, contactId?: number | null, userNumber?: string | null, participantNumber?: string | null, type?: ConversationType | null, createdAt?: any | null, updatedAt?: any | null, latestCall?: { __typename?: 'Call', id?: number | null, twilioId?: string | null, duration?: number | null, status?: CallStatus | null, callType?: CallType | null, createdAt?: any | null, updatedAt?: any | null } | null, latestSms?: { __typename?: 'Sms', id: number, body?: string | null, conversationId: number, twilioId?: string | null, createdAt?: any | null, deliveryStatus?: SmsDeliveryStatus | null, smsType?: SmsStatus | null } | null, contact?: { __typename?: 'Contact', id?: number | null, fullName?: string | null, pictureIdentifier?: string | null } | null } | null };

export type CreateConversationMutationVariables = Exact<{
  value: CreateConversationInput;
}>;


export type CreateConversationMutation = { __typename?: 'Mutation', createConversation: { __typename?: 'Conversation', id?: number | null, userId?: number | null, userNumber?: string | null, participantNumber?: string | null, type?: ConversationType | null, contactId?: number | null, createdAt?: any | null, updatedAt?: any | null } };

export type DeleteConversationMutationVariables = Exact<{
  value: DeleteConversationInput;
}>;


export type DeleteConversationMutation = { __typename?: 'Mutation', deleteConversation?: { __typename?: 'Conversation', id?: number | null } | null };

export type ListCallConversationQueryVariables = Exact<{
  input: GetRecentCallConversationsInput;
}>;


export type ListCallConversationQuery = { __typename?: 'Query', listCallConversations: Array<{ __typename?: 'Conversation', id?: number | null, userId?: number | null, contactId?: number | null, userNumber?: string | null, participantNumber?: string | null, type?: ConversationType | null, createdAt?: any | null, updatedAt?: any | null, latestCall?: { __typename?: 'Call', id?: number | null, twilioId?: string | null, duration?: number | null, status?: CallStatus | null, callType?: CallType | null, createdAt?: any | null, updatedAt?: any | null } | null, contact?: { __typename?: 'Contact', id?: number | null, fullName?: string | null, pictureIdentifier?: string | null } | null }> };

export type ListSmsConversationQueryVariables = Exact<{
  input: GetRecentSmsConversationsInput;
}>;


export type ListSmsConversationQuery = { __typename?: 'Query', listSmsConversations: Array<{ __typename?: 'Conversation', id?: number | null, userId?: number | null, contactId?: number | null, userNumber?: string | null, participantNumber?: string | null, type?: ConversationType | null, createdAt?: any | null, updatedAt?: any | null, latestSms?: { __typename?: 'Sms', id: number, body?: string | null, conversationId: number, twilioId?: string | null, createdAt?: any | null, deliveryStatus?: SmsDeliveryStatus | null, smsType?: SmsStatus | null } | null }> };

export type GetSmsConversationByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetSmsConversationByIdQuery = { __typename?: 'Query', conversation: { __typename?: 'Conversation', id?: number | null, userId?: number | null, userNumber?: string | null, participantNumber?: string | null, contactId?: number | null, createdAt?: any | null, updatedAt?: any | null, latestSms?: { __typename?: 'Sms', id: number, body?: string | null, conversationId: number, twilioId?: string | null, createdAt?: any | null, deliveryStatus?: SmsDeliveryStatus | null, smsType?: SmsStatus | null } | null } };

export type ConversationSmsesQueryVariables = Exact<{
  input: ConversationSmsFetchInput;
}>;


export type ConversationSmsesQuery = { __typename?: 'Query', conversationSmses: Array<{ __typename?: 'Sms', id: number, conversationId: number, body?: string | null, deliveryStatus?: SmsDeliveryStatus | null, smsType?: SmsStatus | null, createdAt?: any | null, updatedAt?: any | null }> };

export type ListSmsConversationsQueryVariables = Exact<{
  input: GetRecentSmsConversationsInput;
}>;


export type ListSmsConversationsQuery = { __typename?: 'Query', listSmsConversations: Array<{ __typename?: 'Conversation', id?: number | null, userId?: number | null, contactId?: number | null, userNumber?: string | null, participantNumber?: string | null, type?: ConversationType | null, createdAt?: any | null, updatedAt?: any | null, latestSms?: { __typename?: 'Sms', id: number, conversationId: number, body?: string | null, twilioId?: string | null, createdAt?: any | null, deliveryStatus?: SmsDeliveryStatus | null, smsType?: SmsStatus | null } | null }> };

export type NewSmsFeedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NewSmsFeedSubscription = { __typename?: 'Subscription', newSmsReceived: { __typename?: 'Sms', id: number, body?: string | null, smsType?: SmsStatus | null, deliveryStatus?: SmsDeliveryStatus | null, conversationId: number, conversation?: { __typename?: 'Conversation', id?: number | null, userId?: number | null, contactId?: number | null, participantNumber?: string | null, createdAt?: any | null, updatedAt?: any | null } | null } };

export type SearchAndCountSmsesQueryVariables = Exact<{
  input: SearchSmsesInput;
}>;


export type SearchAndCountSmsesQuery = { __typename?: 'Query', countSmses: number, searchSmses?: Array<{ __typename?: 'Sms', id: number, conversationId: number, body?: string | null, smsType?: SmsStatus | null, createdAt?: any | null, conversation?: { __typename?: 'Conversation', contactId?: number | null, participantNumber?: string | null, userNumber?: string | null } | null } | null> | null };

export type SearchSmsesQueryVariables = Exact<{
  input: SearchSmsesInput;
}>;


export type SearchSmsesQuery = { __typename?: 'Query', searchSmses?: Array<{ __typename?: 'Sms', id: number, conversationId: number, body?: string | null, smsType?: SmsStatus | null, createdAt?: any | null, conversation?: { __typename?: 'Conversation', contactId?: number | null, participantNumber?: string | null, userNumber?: string | null } | null } | null> | null };

export type SendMessageMutationVariables = Exact<{
  value: SendSmsInput;
}>;


export type SendMessageMutation = { __typename?: 'Mutation', sendSms?: { __typename?: 'Sms', id: number, conversationId: number, body?: string | null, deliveryStatus?: SmsDeliveryStatus | null, smsType?: SmsStatus | null, createdAt?: any | null, updatedAt?: any | null, conversation?: { __typename?: 'Conversation', id?: number | null, userId?: number | null, contactId?: number | null, userNumber?: string | null, participantNumber?: string | null } | null } | null };

export type SmsStatusFeedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SmsStatusFeedSubscription = { __typename?: 'Subscription', smsStatusUpdated: { __typename?: 'Sms', id: number, body?: string | null, smsType?: SmsStatus | null, deliveryStatus?: SmsDeliveryStatus | null, conversationId: number } };

export type TrackEventMutationVariables = Exact<{
  value: MixPanelInput;
}>;


export type TrackEventMutation = { __typename?: 'Mutation', trackEvent?: { __typename?: 'MixPanelResponse', responseStatus?: number | null } | null };

export type GenerateTwilioAccessTokenQueryVariables = Exact<{
  type: TwilioAccessTokenType;
}>;


export type GenerateTwilioAccessTokenQuery = { __typename?: 'Query', generateTwilioAccessToken: { __typename?: 'TwilioAccessToken', token: string } };

export type GetAvailablePhoneNumbersQueryVariables = Exact<{
  input: AvailableTwilioPhoneNumbersInput;
}>;


export type GetAvailablePhoneNumbersQuery = { __typename?: 'Query', availablePhoneNumbers: Array<{ __typename?: 'TwilioPhoneNumber', phoneNumber?: string | null, friendlyName?: string | null }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id?: number | null, firstName?: string | null, lastName?: string | null, emailAddress?: string | null, emailAddressHash?: string | null, twilioPhoneNumber?: string | null, phoneNumber?: string | null, phoneNumberStatus?: PhoneNumberStatus | null, profilePictureIdentifier?: string | null } };

export type CreateContactMutationVariables = Exact<{
  value: ContactInput;
}>;


export type CreateContactMutation = { __typename?: 'Mutation', createContact: { __typename?: 'Contact', id?: number | null, fullName?: string | null, pictureIdentifier?: string | null, createdAt?: any | null, updatedAt?: any | null, phoneNumbers?: Array<{ __typename?: 'PhoneNumber', id?: number | null, label?: string | null, phoneNumber?: string | null, isPreferred?: boolean | null, createdAt?: any | null, updatedAt?: any | null } | null> | null } };

export type DeleteContactMutationVariables = Exact<{
  value: ContactDeleteInput;
}>;


export type DeleteContactMutation = { __typename?: 'Mutation', deleteContact: { __typename?: 'Contact', id?: number | null, fullName?: string | null } };

export type EditContactMutationVariables = Exact<{
  value: ContactEditInput;
}>;


export type EditContactMutation = { __typename?: 'Mutation', editContact: { __typename?: 'Contact', id?: number | null, fullName?: string | null, pictureIdentifier?: string | null, createdAt?: any | null, updatedAt?: any | null, phoneNumbers?: Array<{ __typename?: 'PhoneNumber', id?: number | null, label?: string | null, phoneNumber?: string | null, isPreferred?: boolean | null, createdAt?: any | null, updatedAt?: any | null } | null> | null } };

export type PurchaseTwilioPhoneNumberMutationVariables = Exact<{
  value: PurchaseTwilioPhoneNumberInput;
}>;


export type PurchaseTwilioPhoneNumberMutation = { __typename?: 'Mutation', purchaseTwilioPhoneNumber: { __typename?: 'TwilioPhoneNumber', phoneNumber?: string | null } };

export type MyContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyContactsQuery = { __typename?: 'Query', contacts: Array<{ __typename?: 'Contact', id?: number | null, fullName?: string | null, pictureIdentifier?: string | null, createdAt?: any | null, updatedAt?: any | null, phoneNumbers?: Array<{ __typename?: 'PhoneNumber', id?: number | null, label?: string | null, phoneNumber?: string | null, isPreferred?: boolean | null, createdAt?: any | null, updatedAt?: any | null } | null> | null }> };

export type SendSmsVerificationCodeMutationVariables = Exact<{
  value: SendSmsVerificationCodeInput;
}>;


export type SendSmsVerificationCodeMutation = { __typename?: 'Mutation', sendSMSVerificationCode: { __typename?: 'SendSmsVerificationCodeResponse', sent: boolean } };

export type UpdateMyProfileMutationVariables = Exact<{
  value: UpdateMyProfileInput;
}>;


export type UpdateMyProfileMutation = { __typename?: 'Mutation', updateMyProfile: { __typename?: 'User', id?: number | null, firstName?: string | null, lastName?: string | null, profilePictureIdentifier?: string | null, phoneNumber?: string | null, twilioPhoneNumber?: string | null, phoneNumberStatus?: PhoneNumberStatus | null } };

export type UpdatePhoneNumberMutationVariables = Exact<{
  value: UpdatePhoneNumberInput;
}>;


export type UpdatePhoneNumberMutation = { __typename?: 'Mutation', updatePhoneNumber: { __typename?: 'UpdatePhoneNumberResponse', phoneNumber: string, phoneNumberStatus?: string | null } };

export type VerifyPhoneNumberMutationVariables = Exact<{
  value: VerifyPhoneNumberInput;
}>;


export type VerifyPhoneNumberMutation = { __typename?: 'Mutation', verifyPhoneNumber: { __typename?: 'User', phoneNumberStatus?: PhoneNumberStatus | null } };

export type CreateVoicemailMutationVariables = Exact<{
  value: CreateVoicemailInput;
}>;


export type CreateVoicemailMutation = { __typename?: 'Mutation', createVoicemail: { __typename?: 'Voicemail', id?: number | null, callerPhoneNumber?: string | null, twilioRecordingId?: string | null, duration?: number | null } };

export type DeleteVoicemailMutationVariables = Exact<{
  value: DeleteVoicemailInput;
}>;


export type DeleteVoicemailMutation = { __typename?: 'Mutation', deleteVoicemail: { __typename?: 'Voicemail', id?: number | null } };

export type ActiveVoicemailGreetingQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveVoicemailGreetingQuery = { __typename?: 'Query', activeVoicemailGreeting?: { __typename?: 'VoicemailGreeting', id?: number | null, userId?: number | null, name?: string | null, duration?: number | null } | null };

export type CreateVoicemailGreetingMutationVariables = Exact<{
  value: CreateVoicemailGreetingInput;
}>;


export type CreateVoicemailGreetingMutation = { __typename?: 'Mutation', createVoicemailGreeting: { __typename?: 'VoicemailGreeting', id?: number | null, userId?: number | null, name?: string | null, duration?: number | null } };

export type DeleteVoicemailGreetingMutationVariables = Exact<{
  value: DeleteVoicemailGreetingInput;
}>;


export type DeleteVoicemailGreetingMutation = { __typename?: 'Mutation', deleteVoicemailGreeting: { __typename?: 'VoicemailGreeting', id?: number | null, userId?: number | null, name?: string | null } };

export type UpdateVoicemailGreetingMutationVariables = Exact<{
  value: UpdateVoicemailGreetingInput;
}>;


export type UpdateVoicemailGreetingMutation = { __typename?: 'Mutation', updateVoicemailGreeting: { __typename?: 'VoicemailGreeting', id?: number | null, userId?: number | null, name?: string | null } };

export type VoicemailGreetingsQueryVariables = Exact<{ [key: string]: never; }>;


export type VoicemailGreetingsQuery = { __typename?: 'Query', voicemailGreetings: Array<{ __typename?: 'VoicemailGreeting', id?: number | null, userId?: number | null, active?: boolean | null, name?: string | null, duration?: number | null } | null> };

export type VoicemailsQueryVariables = Exact<{
  input: VoicemailInput;
}>;


export type VoicemailsQuery = { __typename?: 'Query', voicemails: Array<{ __typename?: 'Voicemail', id?: number | null, callerPhoneNumber?: string | null, twilioRecordingId?: string | null, transcriptionText?: string | null, createdAt?: any | null } | null> };

export const BillingAccountsDocument = gql`
    query BillingAccounts($teamId: Int) {
  billingAccounts(teamId: $teamId) {
    id
    name
    isDefault
    teamId
    createdAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BillingAccountsGQL extends Apollo.Query<BillingAccountsQuery, BillingAccountsQueryVariables> {
    document = BillingAccountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBillingAccountDocument = gql`
    mutation CreateBillingAccount($value: CreateBillingAccount!) {
  createBillingAccount(value: $value) {
    id
    name
    isDefault
    teamId
    createdAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBillingAccountGQL extends Apollo.Mutation<CreateBillingAccountMutation, CreateBillingAccountMutationVariables> {
    document = CreateBillingAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetKycAnswersDocument = gql`
    query GetKycAnswers {
  getKycAnswers {
    id
    workTitle
    agency
    contactPersonName
    contactPersonContactForm
    plan
    proofOfWorkImage
    submitted
    changesRequested
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetKycAnswersGQL extends Apollo.Query<GetKycAnswersQuery, GetKycAnswersQueryVariables> {
    document = GetKycAnswersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveKycAnswersDocument = gql`
    mutation SaveKycAnswers($value: SaveKycAnswersInput!) {
  saveKycAnswers(value: $value) {
    id
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveKycAnswersGQL extends Apollo.Mutation<SaveKycAnswersMutation, SaveKycAnswersMutationVariables> {
    document = SaveKycAnswersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplatesGlobalDocument = gql`
    query TemplatesGlobal($input: TemplatesGlobalInput) {
  templatesGlobal(input: $input) {
    id
    subject
    content
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplatesGlobalGQL extends Apollo.Query<TemplatesGlobalQuery, TemplatesGlobalQueryVariables> {
    document = TemplatesGlobalDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CallStatusFeedDocument = gql`
    subscription CallStatusFeed {
  callStatusUpdated {
    id
    conversationId
    twilioId
    duration
    status
    callType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CallStatusFeedGQL extends Apollo.Subscription<CallStatusFeedSubscription, CallStatusFeedSubscriptionVariables> {
    document = CallStatusFeedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CallsDocument = gql`
    query Calls($input: CallFetchInput!) {
  conversationCalls(input: $input) {
    id
    conversationId
    twilioId
    duration
    status
    callType
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CallsGQL extends Apollo.Query<CallsQuery, CallsQueryVariables> {
    document = CallsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCallByTwilioIdDocument = gql`
    query GetCallByTwilioId($twilioId: String!) {
  getCallByTwilioId(twilioId: $twilioId) {
    id
    conversationId
    twilioId
    duration
    status
    callType
    createdAt
    updatedAt
    conversation {
      id
      userId
      userNumber
      participantNumber
      contactId
      createdAt
      updatedAt
      contact {
        id
        fullName
        pictureIdentifier
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCallByTwilioIdGQL extends Apollo.Query<GetCallByTwilioIdQuery, GetCallByTwilioIdQueryVariables> {
    document = GetCallByTwilioIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLatestIncomingCallDocument = gql`
    query GetLatestIncomingCall($participantNumber: String!) {
  getLatestIncomingCall(participantNumber: $participantNumber) {
    id
    conversationId
    twilioId
    duration
    status
    callType
    createdAt
    updatedAt
    conversation {
      id
      userId
      userNumber
      participantNumber
      contactId
      createdAt
      updatedAt
      contact {
        id
        fullName
        pictureIdentifier
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLatestIncomingCallGQL extends Apollo.Query<GetLatestIncomingCallQuery, GetLatestIncomingCallQueryVariables> {
    document = GetLatestIncomingCallDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCallDocument = gql`
    mutation CreateCall($value: CreateCallInput!) {
  createCall(value: $value) {
    id
    conversationId
    twilioId
    duration
    status
    callType
    createdAt
    updatedAt
    conversation {
      id
      userId
      userNumber
      participantNumber
      contactId
      createdAt
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCallGQL extends Apollo.Mutation<CreateCallMutation, CreateCallMutationVariables> {
    document = CreateCallDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewCallFeedDocument = gql`
    subscription NewCallFeed {
  newCallReceived {
    id
    conversationId
    twilioId
    duration
    status
    callType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NewCallFeedGQL extends Apollo.Subscription<NewCallFeedSubscription, NewCallFeedSubscriptionVariables> {
    document = NewCallFeedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCallConversationByIdDocument = gql`
    query GetCallConversationById($id: Int!) {
  conversation(id: $id) {
    id
    userId
    userNumber
    participantNumber
    latestCall {
      id
      twilioId
      duration
      status
      callType
      createdAt
      updatedAt
    }
    contactId
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCallConversationByIdGQL extends Apollo.Query<GetCallConversationByIdQuery, GetCallConversationByIdQueryVariables> {
    document = GetCallConversationByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetConversationByContactIdAndTypeDocument = gql`
    query GetConversationByContactIdAndType($contactId: Int!, $type: ConversationType!) {
  conversationByContactId(contactId: $contactId, type: $type) {
    id
    userNumber
    participantNumber
    contactId
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetConversationByContactIdAndTypeGQL extends Apollo.Query<GetConversationByContactIdAndTypeQuery, GetConversationByContactIdAndTypeQueryVariables> {
    document = GetConversationByContactIdAndTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetConversationByParticipantNumberAndTypeDocument = gql`
    query GetConversationByParticipantNumberAndType($participantNumber: String!, $type: ConversationType!) {
  conversationByParticipantNumber(
    participantNumber: $participantNumber
    type: $type
  ) {
    id
    userId
    contactId
    userNumber
    participantNumber
    type
    createdAt
    updatedAt
    latestCall {
      id
      twilioId
      duration
      status
      callType
      createdAt
      updatedAt
    }
    latestSms {
      id
      body
      conversationId
      twilioId
      createdAt
      deliveryStatus
      smsType
    }
    contact {
      id
      fullName
      pictureIdentifier
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetConversationByParticipantNumberAndTypeGQL extends Apollo.Query<GetConversationByParticipantNumberAndTypeQuery, GetConversationByParticipantNumberAndTypeQueryVariables> {
    document = GetConversationByParticipantNumberAndTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateConversationDocument = gql`
    mutation CreateConversation($value: CreateConversationInput!) {
  createConversation(value: $value) {
    id
    userId
    userNumber
    participantNumber
    type
    contactId
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateConversationGQL extends Apollo.Mutation<CreateConversationMutation, CreateConversationMutationVariables> {
    document = CreateConversationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteConversationDocument = gql`
    mutation deleteConversation($value: DeleteConversationInput!) {
  deleteConversation(value: $value) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteConversationGQL extends Apollo.Mutation<DeleteConversationMutation, DeleteConversationMutationVariables> {
    document = DeleteConversationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListCallConversationDocument = gql`
    query ListCallConversation($input: GetRecentCallConversationsInput!) {
  listCallConversations(input: $input) {
    id
    userId
    contactId
    userNumber
    participantNumber
    type
    createdAt
    updatedAt
    latestCall {
      id
      twilioId
      duration
      status
      callType
      createdAt
      updatedAt
    }
    contact {
      id
      fullName
      pictureIdentifier
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListCallConversationGQL extends Apollo.Query<ListCallConversationQuery, ListCallConversationQueryVariables> {
    document = ListCallConversationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListSmsConversationDocument = gql`
    query ListSmsConversation($input: GetRecentSmsConversationsInput!) {
  listSmsConversations(input: $input) {
    id
    userId
    contactId
    userNumber
    participantNumber
    type
    createdAt
    updatedAt
    latestSms {
      id
      body
      conversationId
      twilioId
      createdAt
      deliveryStatus
      smsType
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListSmsConversationGQL extends Apollo.Query<ListSmsConversationQuery, ListSmsConversationQueryVariables> {
    document = ListSmsConversationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSmsConversationByIdDocument = gql`
    query GetSmsConversationById($id: Int!) {
  conversation(id: $id) {
    id
    userId
    userNumber
    participantNumber
    latestSms {
      id
      body
      conversationId
      twilioId
      createdAt
      deliveryStatus
      smsType
    }
    contactId
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSmsConversationByIdGQL extends Apollo.Query<GetSmsConversationByIdQuery, GetSmsConversationByIdQueryVariables> {
    document = GetSmsConversationByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConversationSmsesDocument = gql`
    query ConversationSmses($input: ConversationSmsFetchInput!) {
  conversationSmses(input: $input) {
    id
    conversationId
    body
    deliveryStatus
    smsType
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConversationSmsesGQL extends Apollo.Query<ConversationSmsesQuery, ConversationSmsesQueryVariables> {
    document = ConversationSmsesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListSmsConversationsDocument = gql`
    query ListSmsConversations($input: GetRecentSmsConversationsInput!) {
  listSmsConversations(input: $input) {
    id
    userId
    contactId
    userNumber
    participantNumber
    type
    createdAt
    updatedAt
    latestSms {
      id
      conversationId
      body
      twilioId
      createdAt
      deliveryStatus
      smsType
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListSmsConversationsGQL extends Apollo.Query<ListSmsConversationsQuery, ListSmsConversationsQueryVariables> {
    document = ListSmsConversationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewSmsFeedDocument = gql`
    subscription NewSmsFeed {
  newSmsReceived {
    id
    body
    smsType
    deliveryStatus
    conversationId
    conversation {
      id
      userId
      contactId
      participantNumber
      createdAt
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NewSmsFeedGQL extends Apollo.Subscription<NewSmsFeedSubscription, NewSmsFeedSubscriptionVariables> {
    document = NewSmsFeedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchAndCountSmsesDocument = gql`
    query SearchAndCountSmses($input: SearchSmsesInput!) {
  searchSmses(input: $input) {
    id
    conversationId
    body
    smsType
    conversation {
      contactId
      participantNumber
      userNumber
    }
    createdAt
  }
  countSmses(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchAndCountSmsesGQL extends Apollo.Query<SearchAndCountSmsesQuery, SearchAndCountSmsesQueryVariables> {
    document = SearchAndCountSmsesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchSmsesDocument = gql`
    query SearchSmses($input: SearchSmsesInput!) {
  searchSmses(input: $input) {
    id
    conversationId
    body
    smsType
    conversation {
      contactId
      participantNumber
      userNumber
    }
    createdAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchSmsesGQL extends Apollo.Query<SearchSmsesQuery, SearchSmsesQueryVariables> {
    document = SearchSmsesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendMessageDocument = gql`
    mutation SendMessage($value: SendSmsInput!) {
  sendSms(value: $value) {
    id
    conversationId
    conversation {
      id
      userId
      contactId
      userNumber
      participantNumber
    }
    body
    deliveryStatus
    smsType
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendMessageGQL extends Apollo.Mutation<SendMessageMutation, SendMessageMutationVariables> {
    document = SendMessageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SmsStatusFeedDocument = gql`
    subscription SmsStatusFeed {
  smsStatusUpdated {
    id
    body
    smsType
    deliveryStatus
    conversationId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SmsStatusFeedGQL extends Apollo.Subscription<SmsStatusFeedSubscription, SmsStatusFeedSubscriptionVariables> {
    document = SmsStatusFeedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TrackEventDocument = gql`
    mutation trackEvent($value: MixPanelInput!) {
  trackEvent(value: $value) {
    responseStatus
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TrackEventGQL extends Apollo.Mutation<TrackEventMutation, TrackEventMutationVariables> {
    document = TrackEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateTwilioAccessTokenDocument = gql`
    query GenerateTwilioAccessToken($type: TwilioAccessTokenType!) {
  generateTwilioAccessToken(type: $type) {
    token
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateTwilioAccessTokenGQL extends Apollo.Query<GenerateTwilioAccessTokenQuery, GenerateTwilioAccessTokenQueryVariables> {
    document = GenerateTwilioAccessTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAvailablePhoneNumbersDocument = gql`
    query GetAvailablePhoneNumbers($input: AvailableTwilioPhoneNumbersInput!) {
  availablePhoneNumbers(input: $input) {
    phoneNumber
    friendlyName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAvailablePhoneNumbersGQL extends Apollo.Query<GetAvailablePhoneNumbersQuery, GetAvailablePhoneNumbersQueryVariables> {
    document = GetAvailablePhoneNumbersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MeDocument = gql`
    query Me {
  me {
    id
    firstName
    lastName
    emailAddress
    emailAddressHash
    twilioPhoneNumber
    phoneNumber
    phoneNumberStatus
    profilePictureIdentifier
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MeGQL extends Apollo.Query<MeQuery, MeQueryVariables> {
    document = MeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateContactDocument = gql`
    mutation createContact($value: ContactInput!) {
  createContact(value: $value) {
    id
    fullName
    pictureIdentifier
    createdAt
    updatedAt
    phoneNumbers {
      id
      label
      phoneNumber
      isPreferred
      createdAt
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateContactGQL extends Apollo.Mutation<CreateContactMutation, CreateContactMutationVariables> {
    document = CreateContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteContactDocument = gql`
    mutation deleteContact($value: ContactDeleteInput!) {
  deleteContact(value: $value) {
    id
    fullName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteContactGQL extends Apollo.Mutation<DeleteContactMutation, DeleteContactMutationVariables> {
    document = DeleteContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditContactDocument = gql`
    mutation editContact($value: ContactEditInput!) {
  editContact(value: $value) {
    id
    fullName
    pictureIdentifier
    createdAt
    updatedAt
    phoneNumbers {
      id
      label
      phoneNumber
      isPreferred
      createdAt
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditContactGQL extends Apollo.Mutation<EditContactMutation, EditContactMutationVariables> {
    document = EditContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PurchaseTwilioPhoneNumberDocument = gql`
    mutation purchaseTwilioPhoneNumber($value: PurchaseTwilioPhoneNumberInput!) {
  purchaseTwilioPhoneNumber(value: $value) {
    phoneNumber
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseTwilioPhoneNumberGQL extends Apollo.Mutation<PurchaseTwilioPhoneNumberMutation, PurchaseTwilioPhoneNumberMutationVariables> {
    document = PurchaseTwilioPhoneNumberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyContactsDocument = gql`
    query MyContacts {
  contacts {
    id
    fullName
    pictureIdentifier
    createdAt
    updatedAt
    phoneNumbers {
      id
      label
      phoneNumber
      isPreferred
      createdAt
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyContactsGQL extends Apollo.Query<MyContactsQuery, MyContactsQueryVariables> {
    document = MyContactsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendSmsVerificationCodeDocument = gql`
    mutation sendSMSVerificationCode($value: SendSmsVerificationCodeInput!) {
  sendSMSVerificationCode(value: $value) {
    sent
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendSmsVerificationCodeGQL extends Apollo.Mutation<SendSmsVerificationCodeMutation, SendSmsVerificationCodeMutationVariables> {
    document = SendSmsVerificationCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMyProfileDocument = gql`
    mutation updateMyProfile($value: UpdateMyProfileInput!) {
  updateMyProfile(value: $value) {
    id
    firstName
    lastName
    profilePictureIdentifier
    phoneNumber
    twilioPhoneNumber
    phoneNumberStatus
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMyProfileGQL extends Apollo.Mutation<UpdateMyProfileMutation, UpdateMyProfileMutationVariables> {
    document = UpdateMyProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePhoneNumberDocument = gql`
    mutation updatePhoneNumber($value: UpdatePhoneNumberInput!) {
  updatePhoneNumber(value: $value) {
    phoneNumber
    phoneNumberStatus
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePhoneNumberGQL extends Apollo.Mutation<UpdatePhoneNumberMutation, UpdatePhoneNumberMutationVariables> {
    document = UpdatePhoneNumberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VerifyPhoneNumberDocument = gql`
    mutation verifyPhoneNumber($value: VerifyPhoneNumberInput!) {
  verifyPhoneNumber(value: $value) {
    phoneNumberStatus
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class VerifyPhoneNumberGQL extends Apollo.Mutation<VerifyPhoneNumberMutation, VerifyPhoneNumberMutationVariables> {
    document = VerifyPhoneNumberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateVoicemailDocument = gql`
    mutation createVoicemail($value: CreateVoicemailInput!) {
  createVoicemail(value: $value) {
    id
    callerPhoneNumber
    twilioRecordingId
    duration
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateVoicemailGQL extends Apollo.Mutation<CreateVoicemailMutation, CreateVoicemailMutationVariables> {
    document = CreateVoicemailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteVoicemailDocument = gql`
    mutation deleteVoicemail($value: DeleteVoicemailInput!) {
  deleteVoicemail(value: $value) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteVoicemailGQL extends Apollo.Mutation<DeleteVoicemailMutation, DeleteVoicemailMutationVariables> {
    document = DeleteVoicemailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ActiveVoicemailGreetingDocument = gql`
    query ActiveVoicemailGreeting {
  activeVoicemailGreeting {
    id
    userId
    name
    duration
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ActiveVoicemailGreetingGQL extends Apollo.Query<ActiveVoicemailGreetingQuery, ActiveVoicemailGreetingQueryVariables> {
    document = ActiveVoicemailGreetingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateVoicemailGreetingDocument = gql`
    mutation createVoicemailGreeting($value: CreateVoicemailGreetingInput!) {
  createVoicemailGreeting(value: $value) {
    id
    userId
    name
    duration
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateVoicemailGreetingGQL extends Apollo.Mutation<CreateVoicemailGreetingMutation, CreateVoicemailGreetingMutationVariables> {
    document = CreateVoicemailGreetingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteVoicemailGreetingDocument = gql`
    mutation deleteVoicemailGreeting($value: DeleteVoicemailGreetingInput!) {
  deleteVoicemailGreeting(value: $value) {
    id
    userId
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteVoicemailGreetingGQL extends Apollo.Mutation<DeleteVoicemailGreetingMutation, DeleteVoicemailGreetingMutationVariables> {
    document = DeleteVoicemailGreetingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateVoicemailGreetingDocument = gql`
    mutation updateVoicemailGreeting($value: UpdateVoicemailGreetingInput!) {
  updateVoicemailGreeting(value: $value) {
    id
    userId
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateVoicemailGreetingGQL extends Apollo.Mutation<UpdateVoicemailGreetingMutation, UpdateVoicemailGreetingMutationVariables> {
    document = UpdateVoicemailGreetingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VoicemailGreetingsDocument = gql`
    query VoicemailGreetings {
  voicemailGreetings {
    id
    userId
    active
    name
    duration
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class VoicemailGreetingsGQL extends Apollo.Query<VoicemailGreetingsQuery, VoicemailGreetingsQueryVariables> {
    document = VoicemailGreetingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VoicemailsDocument = gql`
    query Voicemails($input: VoicemailInput!) {
  voicemails(input: $input) {
    id
    callerPhoneNumber
    twilioRecordingId
    transcriptionText
    createdAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class VoicemailsGQL extends Apollo.Query<VoicemailsQuery, VoicemailsQueryVariables> {
    document = VoicemailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }