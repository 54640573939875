import { BehaviorSubject, Observable } from "rxjs";
import { ConnectionStateType } from "../util/connection-state-type";
import { NotificationService } from "../shared/services/notification.service";
import { ErrorNotificationComponent } from "../pages/calls/error-message-notification/error-notification.component";

export abstract class ConnectionStateService {
  protected abstract title: string;
  protected abstract failMessage: string;
  
  protected connnectionState = new BehaviorSubject(ConnectionStateType.NONE);
  private activeToastrId: number;
  
  constructor(protected notificationService: NotificationService) {
  }
  
  public getConnectionState(): Observable<ConnectionStateType> {
    return this.connnectionState.asObservable();
  }
  
  public updateConnectionState(state: ConnectionStateType): void {
    this.connnectionState.next(state);
    this.handleConnectionStateChange(state);
  }
  
  protected handleConnectionStateChange(state: ConnectionStateType): void {
    switch (state) {
        case ConnectionStateType.CONNECTED:
            this.clearErrorNotification();
            break;
        case ConnectionStateType.FAILED:
            this.showErrorNotifcation();
            break;
        case ConnectionStateType.NONE:
            break;
    }
  }

  private showErrorNotifcation(): void {
    if (this.activeToastrId) {
      return;
    }
    
    const errorNotification = this.notificationService.showError(this.failMessage, "Connection failure", {
        disableTimeOut: true,
        toastComponent: ErrorNotificationComponent,
        payload: {
          title: this.title,
          message:this.failMessage,
        },
        toastClass:"notification-box",
        positionClass: "notification-top-right",
    });
    
    this.activeToastrId = errorNotification.toastId;
  }
  
  private clearErrorNotification(): void {
    this.notificationService.clear(this.activeToastrId);
    this.activeToastrId = undefined;
  }
}
