import { Injectable } from "@angular/core";
import { map, Observable, ReplaySubject, take, tap } from "rxjs";
import { ContactsService } from "src/app/shared/services/contacts.service";
import { Conversation, ConversationSmsesGQL, ConversationType, GetConversationByContactIdAndTypeGQL, GetSmsConversationByIdGQL, ListSmsConversationGQL, NewSmsFeedGQL, SendMessageGQL, Sms, SmsStatusFeedGQL, SearchSmsesGQL, DeleteConversationGQL, CreateConversationGQL, GetConversationByParticipantNumberAndTypeGQL, SearchAndCountSmsesGQL } from "src/generated/graphql";
import { ConversationsService } from "./conversations.service";
import { NotificationService } from "./notification.service";

type QueriedSms = {
  hasLoaded: boolean;
  messages: Sms[];
}

export interface SearchAndCountSmsesResults {
  totalCount: number;
  messages: Sms[];
}

@Injectable({ providedIn: 'root' })
export class MessagingService extends ConversationsService<Sms> {

  private readonly latestMessagesByConversationId = new Map<number, ReplaySubject<QueriedSms>>();

  public constructor(
    protected contactsService: ContactsService,
    protected deleteConversationGQL: DeleteConversationGQL,
    protected createConversationGQL: CreateConversationGQL,
    private getSmsConversationByIdGQL: GetSmsConversationByIdGQL,
    private getConversationByContactIdGQL: GetConversationByContactIdAndTypeGQL,
    private getConversationByParticipantNumberGQL: GetConversationByParticipantNumberAndTypeGQL,
    private listSmsConversationGQL: ListSmsConversationGQL,
    private newSmsFeedGQL: NewSmsFeedGQL,
    private smsStatusFeedGQL: SmsStatusFeedGQL,
    private conversationSmsesGQL: ConversationSmsesGQL,
    private sendMessageGQL: SendMessageGQL,
    private searchSmsesGQL: SearchSmsesGQL,
    private searchAndCountSmsesGQL: SearchAndCountSmsesGQL,
    private notificationService: NotificationService,
  ) {
    super(contactsService, deleteConversationGQL, createConversationGQL);
    this.registerConversationHandlers();
  }

  public getLatestMessagesByConversationId(conversationId: number): Observable<Sms[]> {
    let messagesSubject = this.latestMessagesByConversationId.get(conversationId);
    if (messagesSubject) {
      return messagesSubject.pipe(map(({ messages: smses }) => smses));
    }

    messagesSubject = new ReplaySubject<QueriedSms>(1);
    this.latestMessagesByConversationId.set(conversationId, messagesSubject);

    this.loadMessagesByConversationId(conversationId, 0, this.CONVERSATIONS_TO_LOAD_INITIALLY)
      .subscribe(messages => messagesSubject.next({ hasLoaded: true, messages }));

    return messagesSubject.pipe(map(({ messages }) => messages));
  }

  public loadMessagesByConversationId(conversationId: number, offset: number, count: number): Observable<Sms[]> {
    return this.conversationSmsesGQL.fetch({
      input: {
        conversationId,
        count,
        offset
      }
    }).pipe(map((response) => response.data.conversationSmses));
  }

  public sendMessage(participantNumber: string, body: string, conversation: Conversation): Observable<Sms> {
    return this.sendMessageGQL.mutate({
      value: { body, participantNumber }
    }).pipe(
      map(response => response.data.sendSms),
      tap(message => {
        if (!conversation) {
          this.handleNewConversationItem(message)
        } else {
          this.handleNewItemInExistingConversation(message, conversation);
        }
      })
    );
  }

  public searchSmses(query: string, count?: number, offset?: number): Observable<Sms[]> {
    return this.searchSmsesGQL.fetch({
      input: { query, count, offset }
    }).pipe(map(response => response.data.searchSmses));
  }

  public searchAndCountSmses(query: string, count?: number, offset?: number): Observable<SearchAndCountSmsesResults> {
    return this.searchAndCountSmsesGQL.fetch({
      input: { query, count, offset }
    }).pipe(map(response => {
      return {
        totalCount: response.data.countSmses,
        messages: response.data.searchSmses
      }
    }));
  }

  protected getLoadConversationsQuery(count: number, offset: number): Observable<Conversation[]> {
    return this.listSmsConversationGQL
      .fetch({ input: { count, offset } })
      .pipe(map(response => response.data.listSmsConversations));
  }

  protected getLoadConversationByIdQuery(id: number): Observable<Conversation> {
    return this.getSmsConversationByIdGQL.fetch({ id })
      .pipe(map(response => response.data.conversation));
  }

  protected getLoadConversationByContactIdQuery(contactId: number): Observable<Conversation> {
    return this.getConversationByContactIdGQL.fetch({ contactId, type: ConversationType.SMS })
      .pipe(map(response => response.data.conversationByContactId));
  }

  protected getLoadConversationByParticipantNumberQuery(participantNumber: string): Observable<Conversation> {
    return this.getConversationByParticipantNumberGQL.fetch({ participantNumber, type: ConversationType.SMS })
      .pipe(map(response => response.data.conversationByParticipantNumber));
  }

  protected getNewConversationItemFeed(): Observable<Sms> {
    return this.newSmsFeedGQL.subscribe()
      .pipe(
        tap(response => this.notificationService.showNewMessage(response.data.newSmsReceived)),
        map(response => response.data.newSmsReceived)
        );
  }

  protected getConversationItemStatusUpdateFeed(): Observable<Sms> {
    return this.smsStatusFeedGQL.subscribe()
      .pipe(map(response => response.data.smsStatusUpdated));
  }

  protected handleConversationItemStatusUpdateFeed(message: Sms): void {
    const messagesSubject = this.latestMessagesByConversationId.get(message.conversationId);
    if (!messagesSubject) return;

    messagesSubject.pipe(take(1)).subscribe(({ messages }) => {
      const cachedMessage = messages.find(msg => msg.id === message.id);
      cachedMessage.deliveryStatus = message.deliveryStatus;
      cachedMessage.body = message.body;
      cachedMessage.updatedAt = message.updatedAt;
    });
  }

  protected handleNewItemInNewConversation(message: Sms): void {
    message.conversation.latestSms = message;

  }

  protected handleNewItemInExistingConversation(message: Sms, conversation: Conversation): void {
    let messagesSubject = this.latestMessagesByConversationId.get(message.conversationId);
    if (!messagesSubject) {
      const newMessagesSubject = new ReplaySubject<QueriedSms>(1);

      newMessagesSubject.next({ hasLoaded: false, messages: [] });
      messagesSubject = this.latestMessagesByConversationId
        .set(conversation.id, newMessagesSubject)
        .get(conversation.id);
    }

    messagesSubject.pipe(take(1))
      .subscribe(({ hasLoaded, messages }) => {
        if (hasLoaded) {
          messages.push(message);
          conversation.latestSms = message;
          conversation.updatedAt = message.createdAt;
          messagesSubject.next({ hasLoaded, messages });
          return;
        }

        // Fetch messages since they haven't been loaded yet.
        // No need to add call manually, simply fetch all latest messages...
        conversation.latestSms = message;
        conversation.updatedAt = message.createdAt;
        this.loadMessagesByConversationId(conversation.id, 0, this.CONVERSATIONS_TO_LOAD_INITIALLY)
          .pipe(take(1))
          .subscribe(messages => messagesSubject.next({ hasLoaded: true, messages }));
      });
  }
}
