import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AvatarSize } from 'src/app/shared/avatar/avatar.component';
import { Conversation } from '../../../generated/graphql';
import { Observable, Subject, takeUntil } from 'rxjs';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { Title } from '@angular/platform-browser';
import { ConversationPage } from 'src/app/shared/conversations/conversation-page';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectedMessagingConversationService } from 'src/app/shared/services/selection/selected-messaging-conversation.service';
import { TrackingService } from 'src/app/services/tracking-service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';
import { PhoneNumberFormattingService } from 'src/app/shared/services/phone-number-formatting.service';

@Component({
  selector: 'mc-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent extends ConversationPage implements OnInit, OnDestroy {

  private readonly ON_COMPONENT_DESTROY = new Subject<void>();
  private readonly title = 'Hello Grove - Messages';
  public readonly AvatarSize = AvatarSize;

  public loadingConversations = true;
  public loadingMoreConversations = false;

  public conversations: Conversation[] = [];
  public selectedConversationId: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: Title,
    private messagingService: MessagingService,
    private selectedConversationService: SelectedMessagingConversationService,
    private trackingService: TrackingService,
    private phoneFormatingService: PhoneNumberFormattingService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.titleService.setTitle(this.title);
    const participantNumber = this.phoneFormatingService.formatE164(this.route.snapshot.queryParamMap.get("phoneNumber"));
    
    this.loadSelectedConversationId();
    this.loadConversations();
    
    if(participantNumber) {
      this.selectedConversationService.openConversationWithParticipantNumber(participantNumber);
    }
  }

  public ngOnDestroy(): void {
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }

  public openConversation(conversationId: number) {
    this.selectedConversationService.openConversationById(conversationId);
    this.trackingService.trackEvent(TrackingEventType.MESSAGES_OPEN_CONVERSATION);
  }

  public startNewConversation(): void {
    this.router.navigate(['messages', 'new']);
    this.trackingService.trackEvent(TrackingEventType.SMS_SEND_MESSAGE);
  }

  public loadMoreConversations(): Observable<Conversation[]> {
    return this.messagingService.loadMoreConversations();
  }

  private loadConversations(): void {
    this.loadingConversations = true;
    this.messagingService.getConversations()
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe((conversations) => {
        this.conversations = conversations;
        this.loadingConversations = false;
      });
  }

  private loadSelectedConversationId(): void {
    this.selectedConversationService.getSelectedItemId()
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe((selectedConversationId: number) => this.setSelectedConversationId(selectedConversationId));
  }

  private setSelectedConversationId(selectedConversationId: number): void {
    this.selectedConversationId = selectedConversationId;
    if (selectedConversationId) {
      this.selectedConversationService.navigateToConversationById(selectedConversationId);
    }

    this.changeDetectorRef.detectChanges();
  }
}
