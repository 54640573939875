<div>
  <h1 class="text-2xl font-lato font-medium tracking-wide secondary-color py-4 px-5">Set as active</h1>
  <div class="border-y px-6 py-8">
    <div class="text-base font-lato font-normal tracking-wide secondary-color">Do you want to set the
      ´<span class="primary-color">{{voicemailGreeting.name}}</span>´ voicemail as active?
    </div>
  </div>
  <div class="flex justify-between py-3 px-5">
    <button class="bg-white border rounded focus:ring-violet-300 hover:bg-gray-250 focus:outline-none py-1 px-5" mat-dialog-close (click)="trackCancelButton()">
      <span class="tracking-wide font-lato secondary-color text-sm leading-loose">Cancel</span>
    </button>
    <button (click)="onSave()" class="bg-primary-color border rounded focus:bg-primary-color hover:bg-primary-light-color focus:outline-none py-1 px-5">
      <span class="text-white tracking-wide font-lato font-light text-sm leading-loose">Save</span>
    </button>
  </div>
</div>
