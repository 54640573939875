import { Component, Input } from '@angular/core';
import { HeaderLinksElement } from "../../enums/header-links-element";
import { TrackingService } from 'src/app/services/tracking-service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';

@Component({
  selector: 'mc-header-links',
  templateUrl: './header-links.component.html',
  styleUrls: ['./header-links.component.scss']
})
export class HeaderLinksComponent {

  public readonly HeaderLinksElement = HeaderLinksElement;
  public readonly HeaderLinksToTrack = TrackingEventType;

  @Input()
  public hasUserCompletedAccountSetup: boolean;

  constructor(private TrackingService: TrackingService) {
  }

  public trackHeaderLinkClicks(element: TrackingEventType): void {
    this.TrackingService.trackEvent(element);
  }
}
