import { Component } from '@angular/core';

@Component({
  selector: 'mc-call-details-skeleton-item',
  templateUrl: './call-details-skeleton-item.component.html',
  styleUrls: []
})
export class CallDetailsSkeletonItemComponent {

}
