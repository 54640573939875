import { Component } from '@angular/core';

@Component({
  selector: 'mc-skeleton-item',
  templateUrl: './skeleton-item.component.html',
  styleUrls: ['./skeleton-item.component.scss']
})
export class SkeletonItemComponent {

}
