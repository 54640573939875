import { Call, CallStatus, CallStatusFeedGQL, CallsGQL, Conversation, ConversationType, CreateConversationGQL, DeleteConversationGQL, GetCallConversationByIdGQL, GetConversationByContactIdAndTypeGQL, GetConversationByParticipantNumberAndTypeGQL, GetLatestIncomingCallGQL, ListCallConversationGQL, NewCallFeedGQL, User } from "src/generated/graphql";
import { ConversationsService } from "./conversations.service";
import { Observable, ReplaySubject, map, take, zip } from "rxjs";
import { ContactsService } from "src/app/shared/services/contacts.service";
import { Injectable } from "@angular/core";
import { Call as VoiceSdkCall } from '@twilio/voice-sdk';
import { UserService } from "src/app/user/user.service";
import { GetCallByTwilioIdGQL } from '../../../generated/graphql';
import { VoiceDeviceService } from "./voice-device.service";

// Twilio device events. See https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice#events
export enum VoiceSdkCallEvents {
  INCOMING = 'incoming',
  ACCEPT = 'accept',
  REJECT = 'reject',
  CANCEL = 'cancel',
  DISCONNECT = 'disconnect',
  REGISTERED = 'registered',
  REGISTERING = 'registering',
  TOKEN_WILL_EXPIRE = 'tokenWillExpire',
  UNREGISTERED = 'unregistered',
  ERROR = 'error',
}

type QueriedCalls = {
  hasLoaded: boolean;
  calls: Call[];
}

@Injectable({ providedIn: 'root' })
export class CallService extends ConversationsService<Call> {

  public static readonly callEndedStatuses = [CallStatus.COMPLETED, CallStatus.CANCELLED, CallStatus.FAILED, CallStatus.NO_ANSWER];

  private readonly latestCallsByConversationId = new Map<number, ReplaySubject<QueriedCalls>>();
  private readonly conversationsSet = new Set<number>();

  private user: User;

  public constructor(
    protected contactsService: ContactsService,
    protected deleteConversationGQL: DeleteConversationGQL,
    protected createConversationGQL: CreateConversationGQL,
    private listCallConversationsQuery: ListCallConversationGQL,
    private callConversationByIdQuery: GetCallConversationByIdGQL,
    private incomingCallFeedGql: NewCallFeedGQL,
    private getConversationByContactIdGQL: GetConversationByContactIdAndTypeGQL,
    private GetConversationByParticipantNumberGQL: GetConversationByParticipantNumberAndTypeGQL,
    private callStatusFeedGql: CallStatusFeedGQL,
    private callsQuery: CallsGQL,
    private userService: UserService,
    private getCallByTwilioIdQuery: GetCallByTwilioIdGQL,
    private getLatestIncomingCallQuery: GetLatestIncomingCallGQL,
    private voiceDeviceService: VoiceDeviceService,
  ) {
    super(contactsService, deleteConversationGQL, createConversationGQL);
    this.loadUser();
    this.createConversationSet();
    // this.registerConversationHandlers();
  }

  private loadUser(): void {
    this.userService.getCurrentUser()
      .pipe(take(1))
      .subscribe(user => this.user = user);
  }

  public getUser(): User {
    return this.user;
  }

  private createConversationSet(): void {
    this.conversations.pipe(take(1)).subscribe(conversations => {
      conversations.forEach(({ id }) => this.conversationsSet.add(id));
    });
  }

  public handleIncomingCall(callback: (call: VoiceSdkCall) => void): void {
    this.voiceDeviceService.registerCallBack(VoiceSdkCallEvents.INCOMING, callback).subscribe();
  }

  public handleAcceptCall(callback: (call: VoiceSdkCall) => void): void {
     this.voiceDeviceService.registerCallBack(VoiceSdkCallEvents.ACCEPT, callback).subscribe();
  }

  public createVoiceCall(phoneNumber: string): Observable<VoiceSdkCall> {
    return this.voiceDeviceService.createVoiceCall({
      params: {
        From: this.user.twilioPhoneNumber,
        To: phoneNumber,
        Direction: 'outbound',
      }
    });
  }

  public cacheNewCall(call: Call, conversation: Conversation): void {
    if (this.conversationsSet.has(conversation.id)) {
      this.handleNewItemInExistingConversation(call, conversation);
      return;
    }
    this.handleNewItemInNewConversation(call, conversation);
  }

  public cacheNewCallAndConversation(call: Call, conversation: Conversation): void {
    this.handleNewItemInNewConversation(call, conversation);
  }

  public updateCachedCall(callId: number, conversationId: number, value: Pick<Call, 'duration' | 'status'>): void {
    this.latestCallsByConversationId.get(conversationId)?.pipe(take(1))
      .subscribe(({ calls }) => {
        const selectedCall = calls?.find(({ id }) => id === callId);
        if (!selectedCall) return;

        selectedCall.duration = value.duration;
        if (value.status) selectedCall.status = value.status;
        selectedCall.updatedAt = new Date();
      });
  }

  protected getLoadConversationsQuery(count: number, offset: number): Observable<Conversation[]> {
    return this.listCallConversationsQuery
      .fetch({ input: { count, offset } })
      .pipe(map(({ data }) => data.listCallConversations));
  }

  protected getLoadConversationByIdQuery(conversationId: number): Observable<Conversation> {
    return this.callConversationByIdQuery.fetch({ id: conversationId })
      .pipe(map(({ data }) => data.conversation));
  }

  protected getLoadConversationByContactIdQuery(contactId: number): Observable<Conversation> {
    return this.getConversationByContactIdGQL.fetch({ contactId, type: ConversationType.CALL })
      .pipe(map(response => response.data.conversationByContactId));
  }

  protected getLoadConversationByParticipantNumberQuery(participantNumber: string): Observable<Conversation> {
    return this.GetConversationByParticipantNumberGQL.fetch({ participantNumber, type: ConversationType.CALL })
      .pipe(map(response => response.data.conversationByParticipantNumber));
  }

  protected getNewConversationItemFeed(): Observable<Call> {
    return this.incomingCallFeedGql.subscribe()
      .pipe(map(({ data }) => data.newCallReceived));
  }

  protected getConversationItemStatusUpdateFeed(): Observable<Call> {
    return this.callStatusFeedGql.subscribe()
      .pipe(map(({ data }) => data.callStatusUpdated));
  }

  protected handleConversationItemStatusUpdateFeed(call: Call): void {
    const callsSubject = this.latestCallsByConversationId.get(call.conversationId);

    if (!callsSubject) return;

    callsSubject.pipe(take(1)).subscribe(({ calls }) => {
      const cachedCall = calls.find(c => c.id === call.id);
      cachedCall.callType = call.callType;
      cachedCall.status = call.status;
      cachedCall.duration = call.duration;
      cachedCall.updatedAt = call.updatedAt;
    });
  }

  protected handleNewItemInNewConversation(call: Call, conversation: Conversation): void {
    conversation.latestCall = call;
    this.conversationsSet.add(conversation.id);

    zip(this.conversations.pipe(take(1)), this.contactsService.getCurrentContacts())
      .subscribe(([conversations, contacts]) => {
        conversation.contact = contacts.find(({ phoneNumbers }) => phoneNumbers.some(phoneNumber => phoneNumber.phoneNumber === conversation.participantNumber));
        conversations.unshift(conversation);
        this.conversations.next(conversations);
      });

    if (call) {
      if (!this.latestCallsByConversationId.has(conversation.id)) {
        this.latestCallsByConversationId.set(conversation.id, new ReplaySubject<QueriedCalls>(1));
      }

      this.latestCallsByConversationId.get(conversation.id)
        .next({ hasLoaded: true, calls: [call] });
    }
  }

  protected handleNewItemInExistingConversation(call: Call, conversation: Conversation): void {
    let callsSubject = this.latestCallsByConversationId.get(conversation.id);
    if (!callsSubject) {
      const newCallsSubject = new ReplaySubject<QueriedCalls>(1);

      newCallsSubject.next({ hasLoaded: false, calls: [] });
      callsSubject = this.latestCallsByConversationId
        .set(conversation.id, newCallsSubject)
        .get(conversation.id);
    }

    callsSubject.pipe(take(1))
      .subscribe(({ hasLoaded }) => {
        if (hasLoaded) {
          this.addNewCallToReplaySubject(callsSubject, call, conversation);
          return;
        }

        // Fetch calls since they haven't been loaded yet.
        // No need to add call manually, simply fetch all latest calls...
        conversation.latestCall = call;
        conversation.updatedAt = call.createdAt;
        this.loadCallsByConversationId(conversation.id, 0, this.CONVERSATIONS_TO_LOAD_INITIALLY)
          .pipe(take(1))
          .subscribe(calls => {
            callsSubject.next({ hasLoaded: true, calls });
          });
      });
  }

  private addNewCallToReplaySubject(callsSubject: ReplaySubject<QueriedCalls>, call: Call, conversation: Conversation): void {
    conversation.latestCall = call;
    conversation.updatedAt = call.createdAt;

    this.conversations.pipe(take(1)).subscribe(conversations => {
      conversations.forEach((c, i) => {
        if (c.id !== conversation.id) return;
        conversations.splice(i, 1);
      });
      conversations.unshift(conversation);
      this.conversations.next(conversations);
    });

    callsSubject.pipe(take(1)).subscribe(({ calls }) => {
      calls.unshift(call);
      callsSubject.next({ hasLoaded: true, calls });
    });
  }

  public getLatestCallsByConversationId(conversationId: number): Observable<Call[]> {
    let callSubject = this.latestCallsByConversationId.get(conversationId);

    if (callSubject) {
      return callSubject.pipe(map(({ calls }) => calls));
    }

    callSubject = new ReplaySubject<QueriedCalls>(1);
    this.latestCallsByConversationId.set(conversationId, callSubject);

    this.loadCallsByConversationId(conversationId, 0, this.CONVERSATIONS_TO_LOAD_INITIALLY)
      .subscribe(calls => callSubject.next({ hasLoaded: true, calls }));

    return callSubject.pipe(map(({ calls }) => calls));
  }

  public loadCallsByConversationId(conversationId: number, offset: number, count: number): Observable<Call[]> {
    return this.callsQuery.fetch({
      input: {
        conversationId,
        count,
        offset
      }
    }).pipe(map((response) => response.data.conversationCalls));
  }

  private fetchLatestCall(
    participantNumber: string,
    callback: (call: Call) => void,
  ): void {
    this.getLatestIncomingCallQuery
      .fetch({ participantNumber })
      .pipe(map(({ data }) => data.getLatestIncomingCall))
      .subscribe((call) => {
        if (!call.conversation.contact) {
          this.contactsService
            .getContactById(call.conversation.contactId)
            .subscribe(contact => call.conversation.contact = contact);
        }
        callback(call);
      });
  }

  public fetchCallWithTwilioIdOrParticipantNumber(
    twilioId: string,
    participantNumber: string,
    callback: (call: Call) => void,
  ): void {
    this.getCallByTwilioIdQuery
      .fetch({ twilioId })
      .subscribe(({ errors, data }) => {
        if (errors) {
          // Call SID may not match with the backend. See https://github.com/twilio/twilio-voice.js/issues/139
          this.fetchLatestCall(participantNumber, callback);
          return;
        }

        const call = data.getCallByTwilioId;
        if (!call.conversation.contact) {
          this.contactsService
            .getContactById(call.conversation.contactId)
            .subscribe(contact => call.conversation.contact = contact);
        }
        callback(call);
      });
  }

  public getCallByTwillioId(twillioId: string): Observable<Call> {
    return this.getCallByTwilioIdQuery
    .fetch({ twilioId: twillioId })
    .pipe(map(({ data }) => data.getCallByTwilioId));
  }
}
