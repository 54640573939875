import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AvatarSize } from 'src/app/shared/avatar/avatar.component';
import { AddEditContactComponent } from './add-edit-contact/add-edit-contact.component';
import { ContactsService, ContactWithPicture } from '../../shared/services/contacts.service';
import { Subject, takeUntil } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { Contact } from '../../../generated/graphql';
import { SelectedContactService } from 'src/app/shared/services/selection/selected-contact.service';
import { TrackingService } from 'src/app/services/tracking-service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';

@Component({
  selector: 'mc-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, OnDestroy {

  private readonly ON_COMPONENT_DESTROY = new Subject<void>();
  private readonly title = 'Hello Grove - Contacts';
  public readonly AvatarSize = AvatarSize;

  public contacts: ContactWithPicture[];
  public selectedContactId: number;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private contactsService: ContactsService,
    private titleService: Title,
    private selectedContactService: SelectedContactService,
    private trackingService: TrackingService
  ) {
  }

  public ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.getContacts();
    this.loadSelectedContactId();
  }

  public addNewContact(): void {
    this.dialog.open(AddEditContactComponent, {
      width: '600px',
    })
    .afterClosed()
    .subscribe((contact: Contact) => {
      if (contact) {
        this.setSelectedContact(contact.id);
      }
    });
    this.trackingService.trackEvent(TrackingEventType.CONTACTS_ADD_NEW_BUTTON_CLICKED);
  }

  public ngOnDestroy(): void {
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }

  public selectContact(contactId: number): void {
    this.selectedContactService.setSelectedItemId(contactId);
  }

  private getContacts(): void {
    this.contactsService.getContacts()
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe(contacts => this.contacts = contacts);
  }

  private loadSelectedContactId(): void {
    this.selectedContactService.getSelectedItemId()
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe((contactId) => this.setSelectedContact(contactId));
  }

  private setSelectedContact(contactId: number): void {
    this.selectedContactId = contactId;

    if (contactId) {
      this.selectedContactService.navigateToContactById(contactId);
    }

    this.changeDetectorRef.detectChanges();
  }
}
