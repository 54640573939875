import { Injectable } from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import { DeleteGreetingModalComponent } from './delete-greeting-modal/delete-greeting-modal.component';
import { GreetingModalComponent } from './greeting-modal/greeting-modal.component';
import { SetActiveModalComponent } from './set-active-modal/set-active-modal.component';

@Injectable()
export class VoicemailGreetingModalFactoryService {

  constructor(private dialog : MatDialog) { }

  public openSetActiveModal(matDialogConfig ?: MatDialogConfig) : MatDialogRef<SetActiveModalComponent> {
    return this.dialog.open(SetActiveModalComponent,matDialogConfig);
  }

  public openCreateVoicemailGreetingModal(matDialogConfig ?: MatDialogConfig) : MatDialogRef<GreetingModalComponent> {
    return this.dialog.open(GreetingModalComponent,matDialogConfig);
  }

  public openDeleteVoicemailGreetingModal(matDialogConfig ?: MatDialogConfig) : MatDialogRef<DeleteGreetingModalComponent> {
    return this.dialog.open(DeleteGreetingModalComponent,matDialogConfig);
  }
}
