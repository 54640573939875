import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { AccountSetupStep } from './account-setup-step.component';
import { PhoneNumberFormattingService } from 'src/app/shared/services/phone-number-formatting.service';

@Component({
  selector: 'account-setup',
  templateUrl: './account-setup.component.html',
  styleUrls: ['./account-setup.component.scss']
})
export class AccountSetupComponent implements AfterViewInit, OnDestroy {

  public readonly currentStep = new BehaviorSubject<AccountSetupStep>(AccountSetupStep.WELCOME_PAGE);
  public readonly isCurrentStepValid = new BehaviorSubject<boolean>(false);
  public readonly canMoveBackward = new BehaviorSubject<boolean>(false);

  public readonly moveForward = new Subject<void>();

  public readonly forwardButtonClick = new Subject<void>();

  private readonly TOTAL_STEPS_COUNT = Object.keys(AccountSetupStep).length / 2;
  private readonly ON_DESTROY = new Subject<void>();

  public readonly form = this.formBuilder.group({
    twilioPhoneNumber: ['', [Validators.required]],
    phoneNumber: ['', [Validators.required, this.phoneNumberValidator.phoneNumberValidator()]],
    phoneNumberVerified: [false, Validators.requiredTrue]
  });

  @ViewChild('stepper', { static: true })
  private stepper: MatStepper;

  public isFirstStep = true;
  public isLastStep = false;

  constructor(
    private formBuilder: FormBuilder,
    private phoneNumberValidator: PhoneNumberFormattingService
  ) {
  }

  public ngAfterViewInit(): void {
    this.stepper.selectionChange
      .pipe(takeUntil(this.ON_DESTROY))
      .subscribe(({ selectedIndex }) => this.handleStepIndexChanges(selectedIndex));

    this.moveForward
      .pipe(takeUntil(this.ON_DESTROY))
      .subscribe(() => this.stepper.next());
  }

  public ngOnDestroy(): void {
    this.ON_DESTROY.next();
    this.ON_DESTROY.complete();

    this.currentStep.complete();
    this.moveForward.complete();
    this.forwardButtonClick.complete();
  }

  public goForward() {
    this.forwardButtonClick.next();

    if (this.isCurrentStepValid.value) {
      this.stepper.next();
    }
  }

  public goBack() {
    this.stepper.previous();
  }

  private handleStepIndexChanges(selectedIndex: number): void {
    this.isFirstStep = selectedIndex === 0;
    this.isLastStep = selectedIndex + 1 === this.TOTAL_STEPS_COUNT;
    this.currentStep.next(selectedIndex);
  }
}
