import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SelectedContactService } from 'src/app/shared/services/selection/selected-contact.service';
import { UserService } from 'src/app/user/user.service';
import { User } from 'src/generated/graphql';

@Component({
  selector: 'mc-contact-details-placeholder',
  templateUrl: './contact-details-placeholder.component.html',
  styleUrls: ['./contact-details-placeholder.component.scss']
})
export class ContactDetailsPlaceholderComponent implements OnInit, OnDestroy {
  
  public readonly ON_COMPONENT_DESTROY = new Subject<void>();

  public user: User;

  public constructor(
    private userService: UserService,
    private selectedContactId: SelectedContactService
  ) {
  }

  public ngOnInit(): void {
    this.loadUser();
    this.selectedContactId.setSelectedItemId(null);
  }

  public ngOnDestroy(): void {
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }

  private loadUser(): void {
    this.userService.getUser()
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe((user) => this.user = user);
  }

}
