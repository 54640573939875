import { Injectable } from "@angular/core";
import { Sms } from "src/generated/graphql";
import { Router } from "@angular/router";
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr'
import { MessageNotificationComponent } from "../message-notification/message-notification.component";


@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private toastr: ToastrService,
              private router: Router) { }

  public showSuccess(message: string, title: string): ActiveToast<any> {
   return this.toastr.success(message, title);
  }

  public showWarning(message: string, title: string): ActiveToast<any> {
   return this.toastr.warning(message, title);
  }

  public showError(message: string, title: string, config?: Partial<IndividualConfig> ): ActiveToast<any> {
    return this.toastr.error(message, title, config);
  }

  public showNewMessage(sms: Sms): void {
    const isInMessagesPage = this.router.isActive('/messages', { paths: 'subset', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' });

    if (!isInMessagesPage) {
      this.toastr.show("","", {
        toastComponent: MessageNotificationComponent,
        toastClass:"notification-box",
        positionClass: "notification-top-right",
        newestOnTop:true,
        payload: sms,
      });
    }
  }

  public clear(toastrId: number): void {
    this.toastr.clear(toastrId);
  } 
}
