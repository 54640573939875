<div class="flex flex-col w-full p-[20px]">
    <div class="w-[490px] h-[5px] rounded-full bg-gray-300 mb-2"></div>
  <div class="flex flex-row justify-between mb-3">
    <div class="w-[45px] h-[13px] rounded-full bg-gray-300"></div>
    <div class="w-[45px] h-[13px] rounded-full bg-gray-300"></div>
  </div>
  <div class="flex flex-row justify-between">
    <div class="w-[30px] h-[20px] rounded-full bg-gray-300"></div>
    <div class="w-[30px] h-[20px] rounded-full bg-gray-300"></div>
    <div class="w-[30px] h-[20px] rounded-full bg-gray-300"></div>
  </div>
</div>
  