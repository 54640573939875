<div class="w-full animate-pulse flex flex-row gap-x-4 px-6 items-center">
  <div class="w-6 h-6">
    <mat-icon class="text-gray-300">call_made</mat-icon>
  </div>
  <div class="flex flex-row py-4 w-full justify-between items-end border-b">
    <div class="flex flex-col">
      <div class="w-32 h-2.5 rounded-full bg-gray-300 mb-2"></div>
      <div class="flex flex-row gap-x-2">
        <div class="w-28 h-2 rounded-full bg-gray-300"></div>
        <div class="w-12 h-2 rounded-full bg-gray-300"></div>
      </div>
    </div>
    <div class="w-12 h-2 rounded-full bg-gray-300"></div>
  </div>
</div>
