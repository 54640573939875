import { Component } from '@angular/core';

@Component({
  selector: 'mc-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent {
  
}
