import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { UserService } from 'src/app/user/user.service';
import { AccountSetupStep, AccountSetupStepComponent } from '../account-setup-step.component';

@Component({
  selector: 'summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent extends AccountSetupStepComponent {

  public constructor(
    private router: Router,
    private userService: UserService
  ) {
    super();
  }

  public get step(): AccountSetupStep {
    return AccountSetupStep.SUMMARY;
  }

  public handleStepSelection(): void {
    super.handleStepSelection();
    this.hideBackwardButton();
    this.updateCurrentStepValidity(true);
  }

  public handleInitialStepSelection(): void {
    super.handleInitialStepSelection();

    this.forwardButtonClick
      .pipe(take(1))
      .subscribe(() => this.userService.getUser(true).pipe(take(1)).subscribe(() => this.router.navigate(['/'])));

    this.updateCurrentStepValidity(true);
  }

}
