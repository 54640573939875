import { Injectable } from "@angular/core";
import { SelectedItemService } from "./selected-item.service";
import { CallService } from "../call.service";
import { Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class SelectedCallingConversationService extends SelectedItemService {
  constructor(
    private callService: CallService,
    private router: Router,
  ) {
    super();
  }

  public openConversationByContactId(contactId: number): void {
    this.callService.getConversationByContactId(contactId).subscribe((conversation) => {
      this.setSelectedItemId(conversation?.id);
      if (!conversation) return;
      this.navigateToConversationById(conversation.id);
    });
  }

  public openConversationById(conversationId: number): void {
    this.setSelectedItemId(conversationId);
    this.navigateToConversationById(conversationId);
  }

  public goBackToConversationPage(conversationId: number): void {
    this.setSelectedItemId(conversationId);
    this.router.navigate(['calls', conversationId]);
  }

  public callInConversationById(conversationId: number): void {
    this.setSelectedItemId(conversationId);
    this.openCallById(conversationId);
  }

  public navigateToConversationById(conversationId: number): void {    
    if (this.isAlreadyNavigatedToConversationPage(conversationId)) {
      return;
    }

    this.router.navigate(['calls', conversationId]);
  }

  public openCallById(conversationId: number): void {    
    this.router.navigate(['calls', conversationId, 'outgoing']);
  }

  private isAlreadyNavigatedToConversationPage(conversationId: number): boolean {
    return this.router.isActive('/calls/' + conversationId, { paths: 'subset', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' });
  }
}
