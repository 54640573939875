import { Component } from '@angular/core';
import { AccountSetupStep, AccountSetupStepComponent } from '../account-setup-step.component';
import { AreaCode, USA_AREA_CODES } from './area-codes';
import { takeUntil } from 'rxjs';
import { GetAvailablePhoneNumbersGQL, TwilioPhoneNumber } from 'src/generated/graphql';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'twilio-number-picker',
  templateUrl: './twilio-number-picker.component.html',
  styleUrls: ['./twilio-number-picker.component.scss']
})
export class TwilioNumberPickerComponent extends AccountSetupStepComponent {

  public readonly USA_AREA_CODES = USA_AREA_CODES;

  public filteredAreaCodes: AreaCode[] = USA_AREA_CODES;

  public availablePhoneNumbers: TwilioPhoneNumber[] = [];
  public loadingAvailablePhoneNumbers = false;
  public initialLoadingOfAvailablePhoneNumbers = true;

  public areaCodeControl = new FormControl();

  public constructor(private availablePhoneNumbersGQL: GetAvailablePhoneNumbersGQL) {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.filterAreaCodesBasedOnSearchValue();
  }

  public get step(): AccountSetupStep {
    return AccountSetupStep.TWILIO_NUMBER_PICKER;
  }

  public handleStepSelection(): void {
    super.handleStepSelection();
    this.showBackwardButton();
  }

  public loadAvailablePhoneNumbers(areaCode: number): void {
    this.loadingAvailablePhoneNumbers = true;
    this.disableControls();

    const input = { countryCode: "US", areaCode };
    this.availablePhoneNumbersGQL.fetch({ input })
      .subscribe((response) => {
        this.loadingAvailablePhoneNumbers = false;
        this.initialLoadingOfAvailablePhoneNumbers = false;
        this.enableControls();
        if (!response.data) {
          return;
        }

        this.availablePhoneNumbers = response.data.availablePhoneNumbers;
      });
  }

  private filterAreaCodesBasedOnSearchValue(): void {
    this.areaCodeControl.valueChanges
      .pipe(takeUntil(this.ON_DESTROY))
      .subscribe((value: number | string) => {
        const searchValue = typeof value === 'string' ? value.toLowerCase() : value;
        this.filteredAreaCodes = this.USA_AREA_CODES.filter((areaCode) => this.matchesAreaCode(searchValue, areaCode));
      });
  }

  private matchesAreaCode(value: number | string, areaCode: AreaCode): boolean {
    return areaCode.code === +value || areaCode.lowercaseState.startsWith(value as string);
  }

  private enableControls(): void {
    this.control.enable();
    this.areaCodeControl.enable();
  }

  private disableControls(): void {
    this.control.disable();
    this.areaCodeControl.disable();
  }

}
