import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContactsService, ContactWithPicture } from 'src/app/shared/services/contacts.service';
import { ConversationType } from 'src/generated/graphql';

export type PhoneNumberOrContact = string | ContactWithPicture;

@Component({
  selector: 'search-contacts',
  templateUrl: './search-contacts.component.html',
  styleUrls: ['./search-contacts.component.scss']
})
export class SearchContactsComponent implements OnInit, OnDestroy {

  public readonly ConversationType = ConversationType;
  private readonly ON_DESTROY = new Subject<void>();

  @Input()
  public type = ConversationType.SMS;

  @Input()
  public defaultValue?: PhoneNumberOrContact;

  @Output()
  public onNumberOrContactSelected = new EventEmitter<PhoneNumberOrContact>();

  @Output()
  public onEnter = new EventEmitter<void>();

  public filteredContacts: ContactWithPicture[] = [];
  public contactSearchControl = new FormControl<PhoneNumberOrContact>(null);

  private contacts: ContactWithPicture[] = [];

  public constructor(private contactsService: ContactsService) {
  }

  public ngOnInit(): void {
    this.autofillDefaultValue();
    this.loadContacts();
    this.addContactFormValueChangeFilter();
  }

  public ngOnDestroy(): void {
    this.ON_DESTROY.next();
    this.ON_DESTROY.complete();
  }

  public displayContactInAutoComplete(contact: PhoneNumberOrContact): string {
    if (typeof contact === 'string') return contact;
    return contact?.fullName;
  }

  public selectContact(contact: PhoneNumberOrContact): void {
    this.onNumberOrContactSelected.next(contact);
  }

  public emitEnter(): void {
    this.onEnter.emit();
  }

  private loadContacts(): void {
    this.contactsService.getCurrentContacts().subscribe((contacts) => this.contacts = contacts);
  }

  private addContactFormValueChangeFilter(): void {
    this.contactSearchControl.valueChanges
      .pipe(takeUntil(this.ON_DESTROY))
      .subscribe((value) => {
        if (!value) {
          this.filteredContacts = this.contacts;
        } else if (typeof value === 'string') {
          this.filteredContacts = this.contacts.filter((contact) => this.matchesContactNameOrPhone(value, contact));
        } else {
          this.filteredContacts = [];
        }
      });
  }

  private matchesContactNameOrPhone(value: string, contact: ContactWithPicture): boolean {
    const valueLowerCased = value.toLowerCase();
    const nameLowerCased = contact.fullName.toLowerCase();

    if (nameLowerCased.startsWith(valueLowerCased)) {
      return true;
    }

    return contact.phoneNumbers.some((phoneNumber) => phoneNumber.phoneNumber.toLowerCase().startsWith(valueLowerCased));
  }

  private autofillDefaultValue(): void {
    if (!this.defaultValue) return;

    this.contactSearchControl.setValue(this.defaultValue);
  }

}
