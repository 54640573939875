import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Conversation } from '../../../../../generated/graphql';
import { AvatarSize } from '../../../../shared/avatar/avatar.component';
import { Call as VoiceSdkCall } from '@twilio/voice-sdk';
import { ContactWithPicture } from "src/app/shared/services/contacts.service";
import { Subject, takeUntil } from "rxjs";
import { VoiceDeviceService } from '../../../../shared/services/voice-device.service';
import { CallManager, CallManagerService } from "src/app/shared/services/call-manager.service";

@Component({
  selector: 'mc-messages-call-inprogress',
  templateUrl: './call-inprogress.component.html',
  styleUrls: ['./call-inprogress.component.scss']
})
export class MessageCallInprogressComponent implements OnInit, OnDestroy {

  private readonly ON_COMPONENT_DESTROY = new Subject<void>();
  private readonly AUDIO_INPUT_DEVICE_KEY = 'audioInputDevice';

  @Input()
  public conversation: Conversation;

  @Input()
  public twilioCall: VoiceSdkCall;

  // Returns the duration of the call in seconds
  @Output()
  public callEnded = new EventEmitter<void>();

  public readonly AvatarSize = AvatarSize;
  
  public call: CallManager;
  public isCallEnded = false;
  public isCallMuted = false;
  public seconds: number = 0;
  public audioDevices: MediaDeviceInfo[];
  public selectedAudioDevice: MediaDeviceInfo;

  public constructor(
    private voiceDeviceService: VoiceDeviceService,
    private callManagerService: CallManagerService,
  ) { }

  public get currentCallStatus(): string {
    let status = this.twilioCall?.status();
    return this.capitalize(this.twilioToNormalStatus(status));
  }

  public ngOnInit(): void {
    this.callManagerService.getAcceptedCall()
      .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
      .subscribe(call => {
        this.call = call;

        this.call?.callDurationTimer.observable
          .pipe(takeUntil(this.ON_COMPONENT_DESTROY))
          .subscribe(seconds => this.seconds = seconds);
      });

    this.listAudioDevices()
      .then(devices => {
        this.audioDevices = devices;

        const savedInputDeviceId = window.localStorage.getItem(this.AUDIO_INPUT_DEVICE_KEY);
        let audioDevice: MediaDeviceInfo = undefined;
        if (savedInputDeviceId) {
          audioDevice = devices.find(({ deviceId }) => savedInputDeviceId === deviceId);
        }
        this.setNewAudioDevice(audioDevice || devices[0]);
      });
  }

  public toggleMuteCall(): void {
    this.setIsCallMuted(!this.isCallMuted);
  }

  private setIsCallMuted(val: boolean): void {
    this.twilioCall?.mute(val);
    this.isCallMuted = val;
  }

  public endCall() {
    this.isCallEnded = true;
    this.callEnded.emit();
  }

  public get contactImageUrl(): string {
    const contact = this.conversation.contact as ContactWithPicture;
    return contact?.pictureUrl;
  }

  private capitalize(value: string): string {
    if (!value.length) return '';
    if (value.length === 1) return value.toUpperCase();

    return value[0].toUpperCase() + value.substring(1);
  }

  private twilioToNormalStatus(status?: VoiceSdkCall.State): string {
    if (!status && this.isCallEnded) return 'disconnected';
    if (!status && !this.isCallEnded) return 'connecting';

    switch (status) {
      case VoiceSdkCall.State.Open:
        return 'connected';
      default:
        return status;
    }
  }

  private async listAudioDevices(): Promise<MediaDeviceInfo[]> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioDevices: MediaDeviceInfo[] = [];
    for (const device of devices) {
      if (device.kind !== 'audioinput') continue;

      const isDuplicateDevice = audioDevices.some(cur => device.groupId === cur.groupId);
      if (isDuplicateDevice) continue;
      audioDevices.push(device);
    }
    return audioDevices;
  }

  public setNewAudioDevice(newAudioDevice: MediaDeviceInfo): void {
    this.voiceDeviceService.setAudioDevice(newAudioDevice);
    this.selectedAudioDevice = newAudioDevice;
    window.localStorage.setItem(this.AUDIO_INPUT_DEVICE_KEY, this.selectedAudioDevice.deviceId);
  }

  public isAudioDeviceSelected(deviceId: string): boolean {
    return this.selectedAudioDevice.deviceId === deviceId;
  }

  public ngOnDestroy(): void {
    this.ON_COMPONENT_DESTROY.next();
    this.ON_COMPONENT_DESTROY.complete();
  }
}
