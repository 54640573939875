import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TrackingService } from 'src/app/services/tracking-service';
import { VoicemailGreetingWithRecording, VoicemailGreetingService } from 'src/app/services/voicemail-greeting.service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';

@Component({
  selector: 'mc-set-active-modal',
  templateUrl: './set-active-modal.component.html',
  styleUrls: ['./set-active-modal.component.scss']
})
export class SetActiveModalComponent {

  public voicemailGreeting: VoicemailGreetingWithRecording;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: VoicemailGreetingWithRecording,
    private voicemailGreetingService: VoicemailGreetingService,
    private dialogRef: MatDialogRef<SetActiveModalComponent>,
    private trackingService: TrackingService,
  ) {
    this.voicemailGreeting = data;
  }

  public onSave(): void {
    this.voicemailGreetingService.updateVoicemailGreeting({
      activate: true,
      voicemailGreetingId: this.voicemailGreeting.id
    }).subscribe({
      next: () => {
        this.dialogRef.close(true);
        this.trackingService.trackEvent(TrackingEventType.VOICEMAIL_GREETINGS_SET_AS_ACTIVE_SUCCESS);
      },
      error: () => {
        this.trackingService.trackEvent(TrackingEventType.VOICEMAIL_GREETINGS_SET_AS_ACTIVE_FAILURE);
      }
    });
  }

  public trackCancelButton() {
    this.trackingService.trackEvent(TrackingEventType.VOICEMAIL_GREETINGS_SET_AS_ACTIVE_CANCEL_BUTTON_CLICK);
  }
}
