import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberFormattingService } from '../services/phone-number-formatting.service';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  public constructor(private phoneNumberFormattingService: PhoneNumberFormattingService) { }

  public transform(rawPhoneNumber: string): string {
    return this.phoneNumberFormattingService.format(rawPhoneNumber);
  }
}
