import { Injectable } from "@angular/core";
import { SelectedItemService } from "./selected-item.service";
import { Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class SelectedVoicemailService extends SelectedItemService {
  constructor(private router: Router) {
    super();
  }

  public navigateToVoicemailHomepage(): void {
    this.setSelectedItemId(null);
    this.router.navigate(['voicemails']);
  }

  public navigateToVoicemailById(voicemailId: number): void {
    if (this.isAlreadyNavigatedToVoicemailsPage(voicemailId)) {
      return;
    }

    this.router.navigate(['voicemails', voicemailId]);
  }

  private isAlreadyNavigatedToVoicemailsPage(voicemailId: number): boolean {
    return this.router.isActive('/voicemails/' + voicemailId, { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' });
  }
}
