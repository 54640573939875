import { Component } from '@angular/core';

@Component({
  selector: 'mc-greetings-skeleton-item',
  templateUrl: './greetings-skeleton-item.component.html',
  styleUrls: ['./greetings-skeleton-item.component.scss']
})
export class GreetingsSkeletonItemComponent {

}
