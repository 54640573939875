import { Component } from "@angular/core";
import { Call as VoiceSdkCall } from '@twilio/voice-sdk';;
import { AvatarSize } from "../avatar/avatar.component";
import { Conversation } from "src/generated/graphql";
import { CallManagerService } from "../services/call-manager.service";
import { Router } from "@angular/router";
import { CallService } from "../services/call.service";

@Component({
  selector: 'mc-incoming-call-widget',
  templateUrl: 'incoming-call-widget.component.html',
  styleUrls: ['incoming-call-widget.component.scss']
})
export class IncomingCallWidgetComponent {

  public readonly AvatarSize = AvatarSize;
  public pendingIncomingCall = false;
  public pendingIncomingTwilioCall?: VoiceSdkCall;
  public incomingTwilioCall?: VoiceSdkCall;

  public pendingIncomingCallConversationContactPicture: string;
  public pendingIncomingCallConversation: Conversation;

  public constructor(
    private router: Router,
    private callManagerService: CallManagerService,
    private callService: CallService,
  ) {
    this.setupIncomingCall();
  }

  public acceptPendingIncomingCall(): void {
    this.callManagerService.acceptPendingCall(
      ({ conversation }) => {
        this.pendingIncomingCall = false;
        this.router.navigate(['calls', conversation.id, 'incoming']);
      },
    );
  }

  public rejectPendingIncomingCall() {
    this.callManagerService.rejectPendingCall();
    this.pendingIncomingCall = false;
    this.incomingTwilioCall = undefined;
    this.pendingIncomingCall = undefined;
  }

  private setupIncomingCall(): void {
    this.callManagerService.getPendingCall()
      .subscribe(callManager => {
        if (!callManager) {
          this.clearPendingIncomingCall();
          return;
        }
        this.callService.cacheNewCall(callManager.call, callManager.conversation)
        
        this.pendingIncomingCall = true;
        this.pendingIncomingCallConversation = callManager.conversation;
        this.pendingIncomingTwilioCall = callManager.twilioCall;
      });
  }

  public clearPendingIncomingCall(): void {
    this.pendingIncomingCall = false;
    this.pendingIncomingTwilioCall = undefined;
    this.pendingIncomingCallConversation = undefined;

    this.incomingTwilioCall = undefined;
  }
}
