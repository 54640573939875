<div id="deleteContact">
  <h1 class="text-2xl font-lato font-normal tracking-wide secondary-color py-3 px-5">Delete contact</h1>
  <div mat-dialog-content class="border-y px-4">
    <div class="font-lato font-normal secondary-color tracking-wide text-base py-5">Are you sure that you want to delete
      this person: <b *ngIf="contact">{{ contact.fullName }}</b></div>
  </div>
  <div class="flex justify-between py-3 px-4">
    <button mat-dialog-close class="bg-white border rounded focus:ring-violet-300 hover:bg-gray-250 focus:outline-none py-1 px-5" (click)="close()">
      <span class="tracking-wide font-lato secondary-color text-sm leading-loose">Close</span>
    </button>
    <button (click)="deleteContact()" [disabled]="deleting" [class.disabled]="deleting"  class="bg-primary-color border rounded focus:bg-primary-color hover:bg-primary-light-color focus:outline-none py-1 px-5">
        <span class="text-white tracking-wide font-lato font-light text-sm leading-loose">Delete</span>
    </button>
  </div>
</div>
