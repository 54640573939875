import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'mc-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent {

  constructor(
    private location: Location
  ) {
    console.error("Invalid url access: " + this.location.path());
  }
}
