<div class="container main-content mx-0 pb-[100px] sm:mx-auto px-6 md:px-4 lg:px-12 pt-6 md:pt-12 lg:pt-16 ">
  <p class="font-lato font-normal tracking-wide secondary-color leading-loose mb-2 text-xl sm:text-2xl">Verification</p>
  <p class="font-lato tracking-wide secondary-color-500 mb-6 text-base sm:text-lg">Write below your physical number and click ‘Next’ in order to verify the phone number.</p>
  <p class="font-lato tracking-wide secondary-color-500 text-base sm:text-lg mb-8">The virtual number you have chosen is: <span class="primary-color font-normal">{{ selectedTwilioNumber | phoneNumber }}</span></p>

  <div class="flex justify-start flex-col">
    <form [formGroup]="form" class="flex flex-col relative">
      <span class="font-lato font-normal secondary-color tracking-wide text-sm sm:text-base mb-1">Phone number</span>
      <div class="example-full-width bg-white w-full md:w-5/12 mat-style">
        <ngx-mat-intl-tel-input
          #phoneInput
          [preferredCountries]="['us', 'ca']"
          [enablePlaceholder]="true"
          [enableSearch]="true"
          name="phone"
          describedBy="phoneNumber"
          format="national"
          class="phone-input"
          inputPlaceholder="(123) 456 7890"
          formControlName="phoneNumber">
        </ngx-mat-intl-tel-input>
      </div>
    </form>
    <mat-error *ngIf="verificationMessageSendLimitError" class="font-lato text-sm">Max verification message send attempts reached. Please try again later.</mat-error>
    <mat-error *ngIf="hasAttemptedToProceedWithAnInvalidPhoneNumber" class="font-lato text-sm">Invalid phone number. Please try again later.</mat-error>
  </div>

  <div class="flex md:justify-end justify-center">
    <img class="float-right lg:w-4/12 md:w-6/12 sm:w-7/12 w-9/12 mt-12 sm:mt-8" src="../../../assets/images/verification.svg" alt="Location img">
  </div>
</div>
