<div class="border-b header-wrapper relative">
  <mat-toolbar class="bg-primary-color">
    <a [class.disabled-link]="!hasUserCompletedAccountSetup" [routerLink]="'/'">
      <img class="h-[40px] md:h-[40px]" src="../../../assets/images/main-logo.svg" alt="user"/>
    </a>

    <div class="mr-8">
      <a [class.disabled-link]="!hasUserCompletedAccountSetup" class="sm:ml-4 ml-1 text-white sm:text-2xl text-xl font-normal font-lato" [routerLink]="'/'">Hello Grove</a>
    </div>

    <mc-header-links [hasUserCompletedAccountSetup]="hasUserCompletedAccountSetup"></mc-header-links>

    <span class="spacer"></span>

    <mc-global-search *ngIf="hasUserCompletedAccountSetup"></mc-global-search>

    <!-- <button mat-icon-button class="md:mr-2 hover:bg-gray-150">
      <img src="assets/images/icons/question.svg" alt="questions"/>
    </button> -->

    <button [matMenuTriggerFor]="menu" class="py-1 flex flex-row items-center ml-5">
      <mc-avatar [avatarUrl]="avatarUrl | async" [size]="AvatarSize.SMALL"></mc-avatar>
      <span class="flex ml-1">
        <mat-icon class="light-white">arrow_drop_down</mat-icon>
      </span>
    </button>
  </mat-toolbar>
</div>

<mat-menu #menu="matMenu" class="custom-menu">
  <button [disabled]="!hasUserCompletedAccountSetup" (click)="goToUserProfile()" mat-menu-item><span class="secondary-color text-base font-normal font-lato">Profile</span></button>
  <button (click)="logout()" mat-menu-item><span class="secondary-color text-base font-normal font-lato">Log out</span></button>
</mat-menu>
