<div class="flex space-x-4">
  <div class="flex-shrink-0 self-center">
    <mc-avatar [size]="AvatarSize.SMALL" [avatarUrl]="contactImageUrl"></mc-avatar>
  </div>
  <div class="flex-1 min-w-0 self-center">
    <div class="flex justify-between items-center">
      <div class="font-lato text-sm md:text-base font-normal text-gray-900 truncate grow">
        <ng-container *ngIf="conversation.contact; let contact;">
          {{ contact.fullName }}
        </ng-container>

        <ng-container *ngIf="!conversation.contact">
          {{ conversation.participantNumber | phoneNumber }}
        </ng-container>
      </div>
      <div class="inline-flex font-lato text-xs md:text-[13px] font-light secondary-color-500 flex-none ml-2">
        {{ timestamp }}
      </div>
    </div>
    <p *ngIf="conversation.latestSms" class="font-lato text-xs md:text-[13px] font-light secondary-color-500 tracking-wide truncate">
      <ng-container *ngIf="conversation.latestSms.smsType === 'SENT'">
        <span>You: </span>
      </ng-container>
      {{ conversation.latestSms.body }}
    </p>
  </div>
</div>