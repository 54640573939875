import { Injectable } from '@angular/core';
import * as RecordRTC from "recordrtc";
import { Observable, Subject } from "rxjs";
import { recorderConfig } from "../util/recorder.config";

@Injectable({
  providedIn: 'root'
})
export class VoiceRecordingService {
  private recorder: RecordRTC;
  private audioStream : MediaStream;

  private secondsCounterSubject = new Subject<void>();

  constructor() {
    recorderConfig.ondataavailable = () => {
        this.secondsCounterSubject.next();
    }
  }

  public async startRecording(): Promise<void> {
    this.getMediaStream().then((stream) => {
      this.recorder = new RecordRTC(stream, recorderConfig);
      this.audioStream = stream;
      this.recorder.startRecording();
    })
  }

  public isRecorderInitialized() : boolean {
    return !!this.recorder;
  }

  public pauseRecording(): void {
    this.recorder.pauseRecording();
  }

  public resumeRecording(): void {
    this.recorder.resumeRecording();
  }

  public stopRecording(): Promise<Blob> {
      return new Promise(resolve =>{
        this.recorder.stopRecording(() => {
          const recordingBlob = this.recorder.getBlob();
          this.audioStream.getTracks()[0].stop();
          resolve(recordingBlob);
        });
      })
  }

  public clearRecording(): void {
    this.recorder.stopRecording(() => this.recorder.destroy());
  }

  public getTimeOfRecording(): Observable<void> {
    return this.secondsCounterSubject.asObservable();
  }

  private getMediaStream(): Promise<MediaStream> {
    return navigator.mediaDevices.getUserMedia({audio: true, video: false});
  }
}
