import { Component, OnInit } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { Sms } from 'src/generated/graphql';
import { ContactsService } from '../services/contacts.service';
import { SelectedMessagingConversationService } from '../services/selection/selected-messaging-conversation.service';

@Component({
  selector: 'mc-message-notification',
  templateUrl: './message-notification.component.html',
  styleUrls: ['./message-notification.component.scss']
})
export class MessageNotificationComponent extends Toast implements OnInit {
  public recivedSms: Sms;
  public senderName: string;
  public senderNumber: string;

  constructor(protected toastService: ToastrService,
    public toastPackage: ToastPackage,
    private contactService: ContactsService,
    private selectedMessagingConversationService: SelectedMessagingConversationService
  ) {
    super(toastService, toastPackage);
    this.recivedSms = toastPackage.config.payload;
  }

  public ngOnInit(): void {
    this.senderNumber = this.recivedSms.conversation.participantNumber;
    this.contactService.getContactById(this.recivedSms.conversation.contactId).subscribe(contact => {
      this.senderName = contact?.fullName;
    })
  }

  public goToConversation(): void {
    this.selectedMessagingConversationService.openConversationById(this.recivedSms.conversationId);
  }
}
