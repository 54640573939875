import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class FileValidationService {
  private readonly SUPPORTED_IMAGE_FORMATS = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];

  public isSupported(file: File) {
    return this.SUPPORTED_IMAGE_FORMATS.includes(file.type);
  }
}
