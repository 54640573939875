import { Injectable } from "@angular/core";
import { map, Observable, ReplaySubject, switchMap, take, tap } from "rxjs";
import { MeGQL, User } from "src/generated/graphql";
import { AuthService } from "../auth/auth.service";

@Injectable()
export class UserService {

  public readonly user = new ReplaySubject<User>(1);

  public constructor(
    private authService: AuthService,
    private meGQL: MeGQL,
  ) {
    authService.isLoggedIn.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.loadUser();
      }
    });
  }

  public getUser(forceReload = false): Observable<User> {
    if (forceReload) {
      return this.fetchUser();
    }

    return this.user;
  }

  public getCurrentUser(): Observable<User> {
    return this.getUser().pipe(take(1));
  }

  public getAvatarUrl(): Observable<string> {
    return this.getUser().pipe(map((user) => this.buildUserAvatarUrl(user)));
  }

  public buildUserAvatarUrl(user: User): string {
    if (!user.profilePictureIdentifier) {
      return null;
    }

    return `/api/storage/user/${user.id}/profile-picture/${user.profilePictureIdentifier}`;
  }

  public reloadUser(): void {
    this.getUser(true).pipe(take(1)).subscribe();
  }

  private loadUser(): void {
    this.fetchUser().subscribe();
  }

  private fetchUser(): Observable<User> {
    return this.meGQL.fetch()
    .pipe(
      map((data) => data.data.me),
      tap({
        next: (user) => {
          const userFromResponse = user;
          this.user.next(userFromResponse);
        }
      }),
      switchMap(() => this.user)
    );
  }
}
