import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContactsService, ContactWithPicture } from '../../../shared/services/contacts.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { CallService } from 'src/app/shared/services/call.service';
import { Observable, map, zip } from 'rxjs';
import { TrackingService } from 'src/app/services/tracking-service';
import { TrackingEventType } from 'src/app/util/tracking-event-type';

@Component({
  selector: 'mc-delete-contact',
  templateUrl: './delete-contact.component.html',
  styleUrls: ['./delete-contact.component.scss']
})
export class DeleteContactComponent {

  public contact: ContactWithPicture;

  public deleting: boolean;

  constructor(
    private dialogRef: MatDialogRef<DeleteContactComponent>,
    private contactsService: ContactsService,
    private messagingService: MessagingService,
    private callService: CallService,
    @Inject(MAT_DIALOG_DATA) public data: ContactWithPicture,
    private trackingService: TrackingService,
  ) {
    this.contact = data;
  }

  public deleteContact(): void {
    this.deleting = true;
    this.contactsService.deleteContact({ contactId: this.contact.id })
      .subscribe({
        next: () => {
          this.reloadConversations().subscribe(() => {
            this.deleting = false;
            this.close();
          })
        },
        error: () => {
          this.deleting = false;
          console.error("Failed to delete contact");
        }
      });
  }

  public close(): void {
    this.dialogRef.close();
    this.trackingService.trackEvent(TrackingEventType.CONTACTS_DELETE_CONTACT_CANCEL_BUTTON_CLICKED);
  }

  private reloadConversations(): Observable<void> {
    return zip(
      this.messagingService.reloadConversations(),
      this.callService.reloadConversations()
    ).pipe(map(()=> undefined));
  }
}
