<form class="example-form w-full" id="mainSelect" (keyup.enter)="emitEnter()">
    <span class="font-lato font-normal secondary-color tracking-wide">
      <span *ngIf="type === ConversationType.SMS">To:</span>
      <span *ngIf="type === ConversationType.CALL">Call:</span>
    </span>
    <mat-form-field class="example-full-width w-7/12 xl:w-4/12" appearance="fill">
      <input type="text"
             placeholder="Pick one"
             aria-label="Number"
             matInput
             [formControl]="contactSearchControl"
             placeholder="Type a name or a phone number"
             [matAutocomplete]="auto"
             (ngModelChange)="selectContact($event)"
             class="font-lato font-normal secondary-color border tracking-wide placeholder:tracking-wide placeholder:text-gray-300 placeholder:font-lato placeholder:font-light">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayContactInAutoComplete">
        <mat-option *ngFor="let contact of filteredContacts" [value]="contact" class="flex justify-start ...">
            <div><mc-avatar [avatarUrl]="contact.pictureUrl"></mc-avatar></div>
            <div class="ml-3">{{ contact.fullName }}</div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>  
</form>

