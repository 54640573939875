<div class="flex flex-row justify-between items-center justify-center pl-7 pr-5 py-4 bottom-gray-border">
  <search-contacts
    [type]="ConversationType.CALL"
    [defaultValue]="phoneNumberFromURL"
    (onNumberOrContactSelected)="onNumberOrContactSelected($event)"
    (onEnter)="startCall()"
    class="w-full"></search-contacts>

  <button
    *ngIf="!selectedPhoneNumber || disableCallButton"
    mat-icon-button
    class="bg-gray-150 px-5 h-[34px] rounded-md">
    <img class="w-[20px]" src="../../../assets/images/icons/gray-phone-call.svg">
  </button>

  <button
    *ngIf="selectedPhoneNumber && !disableCallButton"
    mat-icon-button
    class="bg-primary-color px-5 h-[34px] rounded-md"
    (click)="startCall()">
    <img class="w-[20px]" src="../../../assets/images/icons/white-phone-call.svg">
  </button>
</div>

<div 
  *ngIf="shouldStartCall && phoneNumberFromURL"
  class="w-full py-32 flex flex-col items-center justify-center">
  <h1 class="text-md">Starting call in <b>{{ seconds }}</b>...</h1>
  <button
    mat-icon-button
    (click)="cancelAutoCall()"
    class="mt-5 bg-slate-300 px-5 h-[34px] rounded-md">
    Cancel
  </button>
</div>
