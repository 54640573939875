import { Component } from '@angular/core';

@Component({
  selector: 'mc-greetings-skeleton',
  templateUrl: './greetings-skeleton.component.html',
  styleUrls: ['./greetings-skeleton.component.scss']
})
export class GreetingsSkeletonComponent {

}
