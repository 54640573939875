<div class="rounded notification-box cursor-pointer" toastContainer>
  <a class="close-btn"><mat-icon>close</mat-icon></a>
  <div class="flex flex-row border-b items-center px-4 py-2">
    <img src="../../../assets/images/sidebar-icons/blue-sms.svg">
    <div class="ml-2 font-lato font-normal text-base secondary-color">Messages</div>
    <div class="ml-auto text-xs secondary-color-500">Now</div>
  </div>
  <div class="px-4 pt-2 pb-3" (click)="goToConversation()">
    <div *ngIf="senderName" class="font-bold text-base tracking-wide">{{senderName}}</div>
    <div *ngIf="!senderName" class="font-bold text-base tracking-wide">{{senderNumber | phoneNumber}}</div>
    <div class="text-gray-700 text-sm tracking-wide truncate mb-2">
      {{recivedSms.body}}
    </div>
    <div (click)="goToConversation()" class="ml-auto text-xs tracking-wide secondary-color-500">Click to view!</div>
  </div>
</div>