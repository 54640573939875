<ng-container *ngIf="displayOverlay">
  <div class="rounded ongoing-call-box cursor-pointer">
    <div class="flex flex-row-reverse pt-1 mb-[-6px]">
      <button (click)="maximizePage()">
        <img src="../../../assets/images/icons/maximize.svg">
      </button>
    </div>
    
    <div class="flex flex-row items-center pl-2 justify-between">
      <div class="flex flex-row">
        <mc-avatar [size]="AvatarSize.MEDIUM" [avatarUrl]="contact?.pictureUrl"></mc-avatar>
        <div class="flex flex-col mx-3">
          <div *ngIf="contact;else number" class="font-lato font-normal text-base">{{contact.fullName}}</div>
          <ng-template #number>
            <div class="font-lato font-normal text-base">{{participantNumber | phoneNumber}}</div>
          </ng-template>
          <div class="font-lato font-light text-sm">{{callDurationInSeconds | secondsToMinutes}}</div>
        </div>
      </div>
      <div class="flex flex-row action-btn">
        <button (click)="toggleMute()" click="" *ngIf="!callMuted" mat-stroked-button class="justify-center items-center flex">
          <img src="../../../assets/images/icons/unmute-call.svg">
        </button>
        <button (click)="toggleMute()" *ngIf="callMuted" mat-stroked-button class="justify-center items-center flex">
          <img src="../../../assets/images/icons/mute-call.svg">
        </button>
        <button (click)="hangupCall()" mat-stroked-button class="justify-center items-center flex end-call">
          <img src="../../../assets/images/icons/end-call.svg">
        </button>
      </div>
    </div>
  </div>
</ng-container>