import { Component, ElementRef, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs';
import { PhoneNumberFormattingService } from 'src/app/shared/services/phone-number-formatting.service';
import { GraphQlError, UpdatePhoneNumberGQL } from 'src/generated/graphql';
import { AccountSetupStep, AccountSetupStepComponent } from '../account-setup-step.component';

@Component({
  selector: 'personal-phone-number',
  templateUrl: './personal-phone-number.component.html',
  styleUrls: ['./personal-phone-number.component.scss']
})
export class PersonalPhoneNumberComponent extends AccountSetupStepComponent {

  @ViewChild('phoneInput', { static: true })
  public phoneInput: ElementRef;

  public selectedTwilioNumber: string;
  public hasAttemptedToProceedWithAnInvalidPhoneNumber = false;
  public verificationMessageSendLimitError = false;

  public constructor(
    private updatePhoneNumberGQL: UpdatePhoneNumberGQL,
    private phoneNumberFormattingService: PhoneNumberFormattingService,
  ) {
    super();
  }

  public get elementToFocus(): HTMLElement {
    return this.phoneInput.nativeElement;
  }

  public get step(): AccountSetupStep {
    return AccountSetupStep.PHONE_NUMBER;
  }

  /**
   * We need to override this method without calling the super class's implementation
   * because even if the value of the FormControl is valid, we still don't know if the
   * verification text message will be sent.
   */
  public handleValueChanges(): void {
    this.control.valueChanges
      .pipe(takeUntil(this.ON_DESTROY))
      .subscribe((value: string) => {
        this.handleNewValue(value);
        if (!this.control.valid) {
          this.updateCurrentStepValidity(false);
          return;
        }
      });
  }

  public handleStepSelection(): void {
    super.handleStepSelection();

    this.selectedTwilioNumber = this.form.value.twilioPhoneNumber;
  }

  public handleForwardProgress(): void {
    super.handleForwardProgress();

    this.updatePhoneNumberAndStepValidity();
  }

  private updatePhoneNumberAndStepValidity(): void {
    const phoneNumber = this.phoneNumberFormattingService.formatE164(this.control.value);
    this.updatePhoneNumberGQL.mutate({
      value: { phoneNumber, shouldSendVerificationMessage: true }
    }).subscribe((response) => {
      if (response.errors && response.errors.length > 0) {
        this.hasAttemptedToProceedWithErrors = true;
        super.handleForwardProgressWithErrors();
        const error = response.errors[0];
        if (error.extensions['code'] === GraphQlError.TOO_MANY_REQUESTS) {
          this.verificationMessageSendLimitError = true;
        } else if (error.extensions['code'] === GraphQlError.BAD_REQUEST) {
          this.hasAttemptedToProceedWithAnInvalidPhoneNumber = true;
        }
        return;
      } else {
        this.hasAttemptedToProceedWithAnInvalidPhoneNumber = false;
        this.verificationMessageSendLimitError = false;
      }

      this.updateCurrentStepValidity(true);
      this.moveForwardManually();
    });
  }
}
